<template>
  <div
    id="btn-main-menu"
  >
    <v-tooltip
      color="rgb(225, 232, 238);"
      left
      max-width="300"
      elevation="2"
      :disabled="$vuetify.breakpoint.xsOnly"
    >
      <template
        #activator="{ on, attrs }"
      >
        <div
          style="background: #ffffff;border-radius: 50%;"
          :class="$vuetify.breakpoint.smAndUp ? 'ma-3' : ''"
        >
          <!-- :disabled="isMenuDisabled" -->
          <v-btn
            :elevation="0"
            fab
            :class="$vuetify.breakpoint.smAndUp ? 'ma-1' : ''"
            dark
            color="rgb(225, 232, 238)"
            class=""
            style="pointer-events: all;"
            v-on="on"
            @click.prevent="setMainMenuOpenState"
          >
            <!-- <v-icon
            size="24"
            style="color: rgb(0, 62, 110);"
          >
            {{ mdiMenuOpen }}
          </v-icon> -->

            <!-- style="width: auto; color: rgb(0, 62, 110);" -->
            <v-icon
              :class="showIconClass ? 'bounce' : ''"
              style="width: auto; color: var(--v-primary-base);"
              size="26"
            >
              {{ `icon-technology-0` + getItemTechnologyID }}
            </v-icon>
          </v-btn>
        </div>
      </template>
      <span
        v-if="locale === 'de'"
      >
        Auswahlmenü öffnen
      </span>
      <span
        v-else
      >
        Open selection menu
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

import { mdiMenuOpen } from '@mdi/js'

export default {
  name: 'OpenMainMenu',

  data: () => ({
    mdiMenuOpen: mdiMenuOpen,
    showIconClass: true
  }),

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'isMainMenuOpen',
      'isMenuDisabled',
      'itemSelected'
    ]),

    getItemTechnologyID () {
      return Object.keys(this.itemSelected).length !== 0 ? this.itemSelected.categories.filter(cat => cat.type.query_key === 'technology')[0].value.value_id : ''
    }
  },

  methods: {
    ...mapActions('webgis', [
      'set_main_menu_open_state'
    ]),

    setMainMenuOpenState () {
      if (!this.isMenuDisabled) {
        this.showIconClass = false

        // if (!this.isMainMenuOpen) {
        if (this.$vuetify.breakpoint.smAndDown) {
          eventBus.$emit('set-webgis-main-menu', true)
        }
        // }
        this.set_main_menu_open_state(!this.isMainMenuOpen)
      }
    }
  }
}
</script>

<style scoped>
.bounce {
  -webkit-animation: bounce 2s;
          animation: bounce 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  60% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}

@keyframes bounce {
0%,
25%,
50%,
75%,
100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  60% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}
</style>
