var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column px-3 py-1",staticStyle:{"background":"rgb(225, 232, 238)"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"text-body-1",staticStyle:{"margin-bottom":"1px"},style:([_vm.idCount === 0? {'color': 'rgba(0, 0, 0, 0.38)'} : {}])},[(_vm.locale==='de')?_c('span',[_vm._v(" Installierte Leistung: ")]):_c('span',[_vm._v(" Installed capacity: ")])]),_c('div',{staticClass:"d-flex justify-space-between align-center text-body-1 font-weight-medium"},[(_vm.isValueAvailable)?_c('div',[_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.getCapacityValueString(_vm.itemCapacityValue[0], false))+" ")]),(_vm.itemCapacityValue[0] !== 0 &&
              String(_vm.itemCapacityValue[1]).length >= 4 &&
              String(_vm.itemCapacityValue[0]).length < 4)?_c('span',[_vm._v(" kW ")]):_vm._e(),_c('span',[_vm._v("  -  ")]),_c('span',[_vm._v(" "+_vm._s(_vm.getCapacityValueString(_vm.itemCapacityValue[1], false))+" ")]),(String(_vm.itemCapacityValue[1]).length < 4)?_c('span',[_vm._v(" kW ")]):_c('span',[_vm._v(" MW ")])])]):_vm._e()])]),_c('div',[_c('v-range-slider',{staticClass:"align-center",attrs:{"disabled":_vm.isMenuDisabled ||
        !_vm.isValueAvailable ||
        (_vm.itemCapacityMinMaxValue[1] - _vm.itemCapacityMinMaxValue[0]) === 100 ||
        _vm.layerQueryValuesMinMaxLocal.capacity_installed_max ===
        _vm.layerQueryValuesMinMaxLocal.capacity_installed_min,"max":_vm.itemCapacityMinMaxValue[1],"min":_vm.itemCapacityMinMaxValue[0],"step":_vm.rangeSliderSteps,"tick-size":2,"ticks":true,"hide-details":""},on:{"change":_vm.setRangeSliderCapacity},model:{value:(_vm.itemCapacityValue),callback:function ($$v) {_vm.itemCapacityValue=$$v},expression:"itemCapacityValue"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }