<template>
  <v-card
    id="chart1"
    ref="chart_card"
    elevation="0"
    rounded="0"
    class="d-flex flex-column fill-height"
    style="width: 100%;"
  >
    <VChart
      ref="chart1"
      style="width: 100%;"
      renderer="canvas"
      :autoresize="true"
    />
  </v-card>
</template>

<script>
//  IMPORT
// import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

import _ from 'lodash'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

import {
  // SVGRenderer,
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart,
  LineChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  VisualMapComponent
} from 'echarts/components'

use([
  // SVGRenderer,
  CanvasRenderer,
  BarChart,
  LineChart,
  GridComponent,
  TooltipComponent,
  VisualMapComponent
])

export default {
  name: 'RegionReportChartLine',

  components: {
    VChart
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'canvas'
    }
  },

  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    itemDataValueMax: {
      type: Number,
      default: 0
    },
    dataValueUnitObj: {
      type: Object,
      default () {
        return {}
      }
    },
    maximumFractionDigits: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      itemDataYears: [],
      minimumFractionDigits: 0,
      series: {
        areaStyle: {
          color: 'rgb(225, 232, 238)'
        },
        connectNulls: true,
        symbol: 'none',
        data: [],
        lineStyle: {
          type: 'solid',
          width: 3
        },
        type: 'line',
        showAllSymbol: true
      },
      echartsOption: {
        color: [],
        emphasis: {
          disabled: true
        },
        grid: {
          bottom: '5%',
          containLabel: true,
          height: '95%',
          left: 'auto',
          right: '12px',
          top: '5%',
          width: 'auto'
        },
        selectedMode: 'false',
        series: [],
        textStyle: {
          color: '#003e6e',
          fontFamily: 'Roboto',
          fontSize: 14,
          fontWeight: '400'
        },
        tooltip: {
          alwaysShowContent: false,
          axisPointer: {
            snap: true,
            type: 'line'
          },
          borderWidth: 0,
          extraCssText: 'z-index: 1;',
          padding: 8,
          textStyle: {
            color: '#003e6e',
            fontFamily: 'Roboto',
            fontWeight: '400'
          },
          trigger: 'axis'
        },
        visualMap: {
          dimension: 0,
          pieces: [],
          show: false,
          type: 'piecewise'
        },
        xAxis: {
          axisLine: {
            // lineStyle: {
            //   color: '#003e6e'
            // },
            show: false
          },
          axisTick: {
            alignWithLabel: true
          },
          boundaryGap: true,
          data: [],
          splitLine: {
            show: false
          },
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            show: true,
            showMaxLabel: false
          },
          scale: false,
          nameGap: 0,
          splitLine: {
            show: true
          },
          type: 'value'
        }
      }
    }
  },
  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'echartsDefaults',
      'bkgItems'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataMinMax',
      'dashboardItemsDataYears',
      'dashboardItemsYearSelected'
    ])
  },

  watch: {
    locale (newVal, oldVal) {
      if (this.$refs.chart1 !== undefined) {
        this.$refs.chart1.setOption(this.echartsOption)
      }
    }
  },

  beforeDestroy () {
    // console.log('start', this.$refs.chart1)
    this.$refs.chart1.chart.dispose()
    this.$refs.chart1.chart = null
  },

  async mounted () {
    if (this.$refs.chart1 !== undefined) {
      this.itemDataByItem = _.cloneDeep(this.itemData.filter(ds => ds.item === this.item.id))

      if (this.dataValueUnitObj.title_short_de.match(/^%$/)) {
        this.itemDataByItem.forEach((ds) => {
          ds.data_value = ds.data_value * 100
        })
      }

      // NOTE Sort datasets by year, because datasets are processed in the order of returned data
      this.itemDataByItem.sort((a, b) => a.data_year - b.data_year)

      this.itemDataYears = await this.get_datasets_years({ data: this.itemDataByItem })

      // Set echarts defauls from store
      this.echartsOption.series = this.echartsDefaults.map.series
      this.echartsOption.textStyle = this.echartsDefaults.textStyle

      // Set colors and highlight color at array position of selected dataset
      this.echartsOption.color = _.cloneDeep(this.echartsDefaults.color)

      // Determine if line or bar chart
      if (this.itemDataYears.length < 2) {
      // Bar chart
        this.series.type = 'bar'
        this.echartsOption.xAxis.splitLine.show = false
        this.echartsOption.yAxis.splitLine.show = true
      }

      // Get highest value in dataset
      this.echartsOption.yAxis.min = 0 // await this.get_datasets_value_min(this.itemDataByItem)

      // this.itemDataValueMax = await this.get_datasets_value_max(this.itemDataByItem)
      // Math.ceil(this.itemDataValueMax) <- leads to wrong y-Axis if the values are very low

      // NOTE workaround for values with "n"
      this.echartsOption.yAxis.max = this.itemDataValueMax + 0.0001

      if (this.dataValueUnitObj.title_short_de.match(/^n$/)) {
        this.echartsOption.yAxis.minInterval = 1
      }

      // if ((this.dataValueUnitObj.title_short_de.match(/^%$/))
      // ) {
      //   this.echartsOption.yAxis.minInterval = 25
      // } else {
      //   this.echartsOption.yAxis.minInterval = this.itemDataValueMax / 4
      // }

      // Create series datasets
      const series = []

      const dataValues = this.itemDataByItem.map((ds) => {
        if (ds.data_value === null &&
      ds.data_year < this.item.report_year + 1) {
          return 0
        } else {
          return ds.data_value
        }
      })

      // NOTE Deep clone is needed for resolving inheritance issues
      const seriesObj = _.cloneDeep(this.series)

      // Set data array of data series
      seriesObj.data = dataValues

      // // Set name of data series (-> BKG (BEZ +) GEN)
      // seriesObj.name = await this.get_ags_gen_title({
      //   ags: this.itemDataByItem[0].ags
      // })

      series.push(seriesObj)

      // Order created data and set it on the series data attribute
      this.echartsOption.series = series

      // TODO Callback function for number formating
      // https://echarts.apache.org/en/option.html#yAxis.axisLabel.formatter

      // if ((this.dataValueUnitObj.title_short_de.match(/^%$/) &&
      //    this.item.id !== 8)
      // ) {
      //   this.echartsOption.yAxis.max = 100
      // }

      // this.echartsOption.yAxis.name = this.dataValueUnitObj.title_short_de
      this.echartsOption.yAxis.axisLabel.formatter = (value) => `${value.toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })}`

      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        if (!isNaN(params[0].value)) {
          const value = params[0].value

          return `<div class="d-flex flex-column align-center">
          <div class="text-h6 font-weight-bold">${value.toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })}
          ${this.dataValueUnitObj.title_short_de}</div>
          <div>(${params[0].name})</div>
          </div>`
        }
      }

      const indexReportYear = this.itemDataYears.indexOf(this.item.report_year)

      const pieces = [{
        lte: indexReportYear,
        color: '#003e6e',
        symbol: 'none'
      },
      {
        gt: indexReportYear + 1, // this.item.report_year + 1,
        // color: 'rgb(225, 232, 238)',
        color: 'rgba(0, 64, 112, 0.4)',
        symbol: 'circle',
        symbolSize: 7
      }]

      this.echartsOption.visualMap.pieces = pieces

      // X-axis -> Set year as category
      this.echartsOption.xAxis.data = this.itemDataYears

      // Set options on the echarts instance
      if (this.$refs.chart1 !== undefined) {
        this.$refs.chart1.setOption(this.echartsOption)
      }
    }
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_bkg_items',
      'get_ags_gen_title'
    ]),

    ...mapActions('dashboard_items', [
      'get_datasets_value_max',
      'get_datasets_value_min',
      'get_datasets_years',
      'get_item_data_value_unit'
    ])
  }
}
</script>

<style lang="scss">

</style>
