<template>
  <div>
    <div
      class="d-flex justify-center mb-3"
    >
      <v-slide-group
        v-model="slideGroupValue"
        show-arrows
        active-class="btn-select-category-group-active"
        class="d-flex align-center webgis-region-spatial-btn-group mb-3"
      >
        <v-slide-item
          v-for="spatialType in spatialTypeItems"
          :key="spatialType.ade"
          v-slot="{ active, toggle }"
          :disabled="isMenuDisabled"
        >
          <v-btn
            :active-value="active"
            :style="[isWelcomeDialogOpen ? {'height': '28px'} : {'height': '18px'} ]"
            class="btn-select align-self-end my-0"
            rounded
            style=" width: auto; border-radius: 6px;"
            text
            @click="toggle && selectSpatialType(spatialType)"
          >
            <span
              class="align-self-end"
            >
              <span>
                {{ spatialType[`title_${locale}`] }}
              </span>
            </span>
          </v-btn>
        </v-slide-item>
      </v-slide-group>

      <!-- <v-btn-toggle
            ref="btn_toogle"
            v-model="btnGroupSpatialType"
            active-class="btn-select-category-group-active"
            borderless
            dense
            group
            mandatory
          >
            <v-btn
              v-for="spatialType in spatialTypeItems"
              :key="spatialType.ade"
              style="height: 18px; border-radius: 6px;"
              class="btn-select align-self-end pa-2 my-0 mx-2"
              text
              rounded
              @click="selectSpatialType(spatialType)"
            >
              <span
                class="align-self-end"
              >
                <span>
                  {{ spatialType[`title_${locale}`] }}
                </span>

              </span>
            </v-btn>
          </v-btn-toggle> -->
    </div>

    <Transition
      name="fade"
      mode="in-out"
      appear
    >
      <div
        v-if="slideGroupValue !== -1"
        class="d-flex justify-center"
      >
        <!-- :menu-props="{ maxWidth: dropDownWidth }" -->
        <!-- :value="selectState[selectValue.type.query_key]" -->
        <div
          class="d-inline-flex justify-center align-center"
          style="width: auto;"
        >
          <v-autocomplete
            ref="select"
            :class="[$vuetify.breakpoint.mdAndUp ? 'text-h5 font-weight-medium':'px-1 text-h6 font-weight-medium']"
            :disabled="isMenuDisabled || regionValuesFiltered.length === 1 || slideGroupValue === -1"
            :items="regionValuesFiltered"
            :placeholder="selectRegionPlaceholder"
            :style="[$vuetify.breakpoint.smAndUp ? {'min-width': '350px'} : {'min-width': '300px'}]"
            :value="regionAgsSelected"
            auto-select-first
            background-color="rgb(225, 232, 238)"
            class="region-select d-flex justify-center text-truncate"
            clearable
            dense
            filled
            item-text="gen"
            item-value="ags"
            return-object
            rounded
            style="width: auto; "
            @change="selectRegion"
          >
            <template
              #selection="data"
            >
              <span>
                <span
                  v-if="locale"
                >
                  <span
                    v-if="data.item.nbd"
                  >
                    {{ data.item.bez }}
                  </span>

                  {{ data.item.gen }}

                  {{ data.item[`title_${locale}`] }}
                </span>
              </span>
            </template>

            <template
              #item="data"
            >
              <div
                style="width: 100%;"
                class="region-select-dropdown-element pb-1 pt-2"
              >
                <div>
                  <span
                    v-if="locale"
                  >

                    {{ data.item.gen }}

                    <span
                      v-if="data.item.nbd"
                    >
                      ({{ data.item.bez }})
                    </span>

                    {{ data.item[`title_${locale}`] }}

                  </span>
                </div>
              </div>
            </template>
          </v-autocomplete>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'MenuRegionSelect',

  data () {
    return {
      defaultRegion: {
        ade: 1,
        ags: '00000000',
        bez: 'Bundesrepublik',
        gen: 'Deutschland',
        gen_slug: 'bundesrepublik-deutschland',
        nbd: true
      },
      selectRegionPlaceholder: '',
      slideGroupValue: null,
      spatialTypeItems: [{
        ade: 1,
        title_de: 'Deutschland',
        title_en: 'Germany'
      }, {
        ade: 2,
        title_de: 'Bundesländer',
        title_en: 'Federal States'
      }, {
        ade: 4,
        title_de: 'Landkreise',
        title_en: 'Counties'
      }]
    }
  },

  computed: {
    ...mapState('main', [
      'isMenuDisabled',
      'isWelcomeDialogOpen',
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems'
    ]),

    ...mapState('report', [
      'regionAgsSelected',
      'spatialTypeSelected'
    ]),

    regionValuesFiltered () {
      return this.bkgItems.filter(item => item.ade === this.spatialTypeSelected)
    }
  },

  watch: {
    locale () {
      this.selectRegionPlaceholder = this.getPlaceholderText(this.regionValuesFiltered)
    },

    regionValuesFiltered (newVal, oldVal) {
      this.selectRegionPlaceholder = this.getPlaceholderText(this.regionValuesFiltered)
    },

    spatialTypeSelected: {
      handler (newVal) {
        this.slideGroupValue = this.spatialTypeItems.indexOf(this.spatialTypeItems.filter(item => item.ade === newVal)[0])
      },
      immediate: true
    }
  },

  async mounted () {
    if (this.bkgItems.length === 0) {
      await this.get_bkg_items({
        ade: 4, // FIXME
        gf: 4
      })
    }

    // this.defaultRegion = this.bkgItems.filter(item => item.ags === '00000000' && item.ade === 1)[0]
  },

  methods: {
    ...mapActions('main', [
      'set_menu_disabled'
    ]),

    ...mapActions('dashboard_main', [
      'get_bkg_items'
    ]),

    ...mapActions('report', [
      'set_spatial_type_selected',
      'set_region_ags_selected'
    ]),

    selectSpatialType (spatialType) {
      this.set_spatial_type_selected(spatialType.ade)

      eventBus.$emit('regionreport-select-spatial-type', spatialType)

      if (spatialType.ade === 1) {
        this.set_region_ags_selected('00000000')
        const regionGermany = this.bkgItems.filter(item => item.ags === '00000000' && item.ade === 1)[0]

        setTimeout(() => {
          eventBus.$emit('regionreport-select-region', regionGermany)
        }, 700)
      }
    },

    selectRegion (region) {
      eventBus.$emit('regionreport-select-region', region)
    },

    getPlaceholderText (regions) {
      if (regions.length !== 0) {
        if (regions[0].ade === 2) {
          if (this.locale === 'de') {
            return 'Bitte wählen Sie ein Bundesland aus'
          } else {
            return 'Please select a federal state'
          }
        } else if (regions[0].ade === 4) {
          if (this.locale === 'de') {
            return 'Bitte wählen Sie einen Landkreis aus'
          } else {
            return 'Please select a county'
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.v-list > div:not(:first-child) > div.region-select-dropdown-element {
  border-top: 1px dotted var(--v-secondary-lighten1);
}

</style>
