<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="200"
    close-delay="200"
    :disabled="$vuetify.breakpoint.xsOnly"
  >
    <v-btn
      class="btn_menu"
      depressed
      color="white"
      plain
      elevation="0"
      :href="`mailto:ee-monitor@ufz.de?subject=%5B%20EE-Monitor%20${mailtoTitle}%20%5D`"
    >
      <v-icon
        size="14"
        class="mr-2"
      >
        {{ mdiEmail }}
      </v-icon>

      <span
        v-if=" hover === false"
      >
        <span
          v-if="locale === 'de'"
        >
          Kontakt
        </span>

        <span
          v-else
        >
          Contact
        </span>
      </span>

      <v-expand-x-transition>
        <span
          v-if="hover"
        >
          <span
            v-if="locale === 'de'"
          >
            Kontakt E-Mail
          </span>

          <span
            v-else
          >
            Contact E-Mail
          </span>
        </span>
      </v-expand-x-transition>
    </v-btn>
  </v-hover>
</template>

<script>
import { mapState } from 'vuex'

import { mdiEmail } from '@mdi/js'

export default {
  name: 'BtnContactEmailComponent',

  data () {
    return {
      mdiEmail: mdiEmail
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),
    mailtoTitle () {
      return this.locale === 'de' ? 'Anfrage' : 'Request'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
