import axios from 'axios'

import _ from 'lodash'

import { eventBus } from '@/utils/eventbus.js'

export default {

  async get_bkg_items ({ state, commit, rootState }, {
    ade,
    gf
  }
  ) {
    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/bkg-vg250/?gf=${gf}&ade=${ade}`)
      .catch((err) => {
      // NOTE -> 404
      // TODO Error Handling
        console.log('error get_bkg_items ', err)
      })

    if (response && response.status === 200) {
      if (response.data.length !== 0) {
        // Sort items by GEN attribute (name of region without prefix)
        const sortedItems = _.sortBy(response.data, [(o) => { return o.gen }])

        // Set region items in store
        if (state.bkgItems.length === 0) {
          commit('SET_BKG_ITEMS', { items: sortedItems })
        }

        // Return sorted items
        return sortedItems
      } else {
      // TODO Error handling
        eventBus.$emit('push-to-page-error-404')
      }
    } else {
      // TODO Error handling
      eventBus.$emit('push-to-page-error-404')
    }
  },

  async get_bkg_item ({ commit, rootState }, {
    ade,
    ags,
    gf
  }
  ) {
    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/bkg-vg250/?gf=${gf}&ags=${ags}`)
      .catch((err) => {
      // NOTE -> 404
      // TODO Error Handling

        eventBus.$emit('push-to-page-error-404')

        console.log('error get_bkg_items ', err)
      })

    if (response && response.status === 200) {
      if (response.data.length !== 0) {
        // commit('SET_BKG_ITEMS', { items: response.data })
        return response.data
      } else {
      // TODO Error handling
        eventBus.$emit('push-to-page-error-404')
      }
    } else {
      // TODO Error handling
      eventBus.$emit('push-to-page-error-404')
    }
  },

  async get_corine_items ({ commit, rootState }) {
    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/corine/`)
      .catch((err) => {
        // NOTE -> 404
        // TODO Error Handling
        eventBus.$emit('push-to-page-error-404')
        console.log('error get_corine_items ', err)
      })

    if (response && response.status === 200) {
      if (response.data.length !== 0) {
        // commit('SET_CORINE_ITEMS', { items: response.data })
        return response.data
      } else {
      // TODO Error handling
        eventBus.$emit('push-to-page-error-404')
      }
    } else {
      // TODO Er ror handling
      eventBus.$emit('push-to-page-error-404')
    }
  },

  get_ags_gen_title ({ state }, {
    ags
  }) {
    const bkgItem = state.bkgItems.filter(bkgItem => bkgItem.ags === ags)[0]

    if (bkgItem.nbd === false) {
      return bkgItem.gen
    } else {
      if (bkgItem.ade !== 2) {
        return `${bkgItem.bez} ${bkgItem.gen}`
      } else {
        return `${bkgItem.gen}`
      }
    }
  }

  // set_state_option_sidebar ({ commit }, bool) {
  //   commit('SET_STATE_OPTION_SIDEBAR', bool)
  // }

  // TODO later make actions from these component methods
  // getSingleBkgItem ({ agsArray }) {
  //   const bkgItemsFiltered = agsArray.map(async (ags) => {
  //     const agsName = await this.get_bkg_item({
  //       ags: ags,
  //       gf: 4
  //     })
  //     return agsName[0]
  //   })
  //   return Promise.all(bkgItemsFiltered)
  // },

  // getAllBkgItems ({ adeArray }) {
  //   const bkgItemsFiltered = adeArray.map(async (ade) => {
  //     const agsName = await this.get_bkg_items({
  //       ade: ade,
  //       gf: 4
  //     })
  //     return agsName
  //   })
  //   return Promise.all(bkgItemsFiltered)
  // },

}
