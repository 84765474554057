<template>
  <div>
    <v-btn
      v-show="fab"
      v-scroll="onScroll"
      fab
      dark
      fixed
      small
      bottom
      right
      color="primary"
      class="ma-6"
      style="position: fixed;"

      @click="toTop"
    >
      <v-icon>
        {{ mdiChevronUp }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiChevronUp } from '@mdi/js'

export default {
  name: 'ScrollToTop',

  data: () => ({
    fab: false,
    mdiChevronUp: mdiChevronUp
  }),

  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') { return }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>
</style>
