<template>
  <div
    v-if="user.isAuthenticated"
  >
    <!-- <HeaderMain
      app
      :image-file-name="'solarpark-Bockelwitz-2_schinkel.jpg'"
    /> -->
    <!-- <v-container
      fluid
      class="fill-height pa-0"
    > -->
    <RegionReportMain />
    <!-- </v-container> -->
  </div>
</template>

<script>
import RegionReportMain from '@/components/apps/report/region/RegionReportMain.vue'
import HeaderMain from '@/components/main/header/HeaderMain.vue'

import { mapState } from 'vuex'

export default {
  name: 'ReportView',

  components: {
    RegionReportMain
    // HeaderMain
  },

  computed: {
    ...mapState('main', [
      'user'
    ])
  },

  beforeDestroy () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },

  mounted () {
    // console.log('ReportView mounted')
    if (!this.user.isAuthenticated) {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
