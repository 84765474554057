<template>
  <div
    class="fill-height"
  >
    <v-card
      ref="card_map"
      flat
      class="d-flex flex-column fill-height"
      rounded="0"
      style="background: none;"
    >
      <div
        class="d-flex flex-shrink-1 order-0"
      >
        <v-card
          ref="card_map_menu"
          flat
          rounded="0"
          class="d-flex px-6 align-center justify-space-between"
          style="background: #e1e8ee; width: 100%; height: auto; min-height: 30px;"
        >
          <div
            v-if="locale === 'de'"
            class="text-h6"
          >
            CORINE Bodenbedeckungsklassen in 3 Ebenen
          </div>

          <div
            v-else
            class="text-h6"
          >
            CORINE Landcover Classes in 3 levels
          </div>

          <div>
            <v-btn
              v-if="panelLevel1.length === 0"
              text
              depressed
              small
              class="ml-3"
              style="text-transform: none !important; border: dotted 1px var(--v-secondary-lighten1);"
              @click="openAllPanels()"
            >
              <span
                v-if="locale === 'de'"
              >
                <span
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  Alle Corine Ebenen öffnen
                </span>
                <span
                  v-else
                >
                  Öffnen
                </span>
              </span>

              <span
                v-else
                class="text-lowercase"
              >
                <span
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  Open all levels
                </span>
                <span
                  v-else
                >
                  Open
                </span>
              </span>
            </v-btn>

            <v-btn
              v-else
              text
              depressed
              small
              class="ml-3"
              style="text-transform: none !important; border: dotted 1px var(--v-secondary-lighten1);"
              @click="closeAllPanels()"
            >
              <span
                v-if="locale === 'de'"
              >
                <span
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  Alle Ebenen schließen
                </span>
                <span
                  v-else
                >
                  Schließen
                </span>
              </span>

              <span
                v-else
              >
                <span
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  Close all corine levels
                </span>
                <span
                  v-else
                >
                  Close
                </span>
              </span>
            </v-btn>
          </div>
        </v-card>
      </div>

      <div
        class="d-flex flex-column fill-height"
      >
        <v-sheet
          ref="sheet_map_wrapper"
          class="d-flex fill-height pa-3"
          :style="{'height': cardHeight - 30 + 'px'}"
        >
          <v-row
            :style="{'max-height': cardHeight - 48 + 'px'}"
            no-gutters
            justify="center"
            align="start"
            class="expansion-panels-heatmap fill-height section-scroll"
          >
            <v-expansion-panels
              v-model="panelLevel1"
              class="pa-0"
              flat
              multiple
              tile
            >
              <v-expansion-panel
                v-for="(itemsLevel1, idxLevel1) in itemDataDestructered"
                :key="idxLevel1"
                class="pr-3 py-0"
              >
                <v-expansion-panel-header>
                  <template
                    #actions
                  >
                    <v-icon
                      size="20"
                    >
                      {{ mdiChevronDown }}
                    </v-icon>
                  </template>

                  <div
                    class="d-flex flex-column space-between"
                  >
                    <div
                      class="text-h6 px-3 pb-0 pt-2"
                    >
                      {{ itemsLevel1[0][0].index_text }}.
                      {{ itemsLevel1[0][0][`title_${locale}`] }}
                    </div>

                    <div
                      style="height: 44px;"
                    >
                      <ChartsHeatmapCorine
                        :item-data="itemDataLevel1"
                        :item-data-value-max="itemDataValueMax.level1"
                        :item-data-years="itemDataYears"
                        :item-corine="itemsLevel1[0][0]"
                        :dashboard-item="dashboardItem"
                        :data-value-unit="dataValueUnit"
                        :corine-items="corineItems"
                        :height="cardHeight"
                        :maximum-fraction-digits="maximumFractionDigitsLevel1"
                      />
                    </div>
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content
                  class="pa-0"
                >
                  <v-expansion-panels
                    v-model="panelLevel2"
                    multiple
                    flat
                    tile
                    class="pt-3 px-3"
                  >
                    <v-expansion-panel
                      v-for="(itemsLevel2, idxLevel2) in itemsLevel1"
                      :key="idxLevel2"
                    >
                      <v-expansion-panel-header>
                        <template
                          #actions
                        >
                          <v-icon
                            size="20"
                          >
                            {{ mdiChevronDown }}
                          </v-icon>
                        </template>

                        <div
                          class="d-flex flex-column justify-space-between pb-2"
                        >
                          <div
                            class="text-h6 px-3 pb-1 pt-2"
                          >
                            {{ itemsLevel2[1][0].index_text }}.
                            {{ itemsLevel2[1][0][`title_${locale}`] }}
                          </div>

                          <div
                            class="d-flex-inline"
                            style="height: 44px;"
                          >
                            <ChartsHeatmapCorine
                              :item-data="itemDataLevel2"
                              :item-data-value-max="itemDataValueMax.level2"
                              :item-data-years="itemDataYears"
                              :item-corine="itemsLevel2[1][0]"
                              :dashboard-item="dashboardItem"
                              :data-value-unit="dataValueUnit"
                              :corine-items="corineItems"
                              :height="cardHeight"
                              :maximum-fraction-digits="maximumFractionDigitsLevel2"
                            />
                          </div>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content
                        class="pl-3 pr-4"
                      >
                        <div
                          v-for="(itemsLevel3 ,idxLevel3) in itemsLevel2[2] "
                          :key="idxLevel3"
                          class="pt-2"
                        >
                          <div
                            class="d-flex flex-column justify-space-between expansion-panel-level-3 "
                            style="padding-right: 40px;"
                          >
                            <div
                              class="d-flex justify-space-between px-3 pt-3 pb-2"
                            >
                              <div>
                                <v-tooltip
                                  top
                                >
                                  <!-- TODO background opacity light blue color="var(--v-primary-base)" -->
                                  <template
                                    #activator="{ on, attrs }"
                                  >
                                    <span
                                      class="text-h6"
                                      v-on="on"
                                    >
                                      {{ itemsLevel3.index_text }}.
                                      {{ itemsLevel3[`title_${locale}`] }}
                                    </span>
                                  </template>

                                  <div
                                    style="width: 200px;"
                                  >
                                    <span
                                      class="text-body-1"
                                    >
                                      {{ itemsLevel3[`info_text_teaser_${locale}`] }}
                                    </span>
                                  </div>
                                </v-tooltip>

                                <!-- <div>
                                  ({{ itemsLevel3[`info_text_teaser_${locale}`] }})
                                </div> -->
                              </div>

                              <div
                                class="align-self-end"
                              >
                                <v-btn
                                  style="height: 22px; border-radius: 6px;"
                                  class="btn-title align-self-end pa-2 mr-2"
                                  text
                                  color="primary"
                                  rounded
                                  @click.stop="openDialog(itemsLevel3)"
                                >
                                  <!-- @click="openAllPanels()" -->
                                  <span
                                    v-if="locale === 'de'"
                                    class="text-capitalize"
                                  >
                                    weitere Informationen

                                    <!-- <span
                                      class="text-lowercase"
                                    >
                                      öffnen
                                    </span> -->
                                  </span>

                                  <span
                                    v-else
                                    class="text-lowercase"
                                  >
                                    More information
                                  </span>
                                </v-btn>
                              </div>
                            </div>

                            <div
                              style="height: 44px;"
                            >
                              <ChartsHeatmapCorine
                                :item-data="itemDataLevel3"
                                :item-data-value-max="itemDataValueMax.level3"
                                :item-corine="itemsLevel3"
                                :item-data-years="itemDataYears"
                                :dashboard-item="dashboardItem"
                                :data-value-unit="dataValueUnit"
                                :corine-items="corineItems"
                                :height="cardHeight"
                                :maximum-fraction-digits="maximumFractionDigitsLevel3"
                              />
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <!--
              <VChart
                :id="`chart_heatmap`"
                ref="chart_heatmap"
                :autoresize="true"
              /> -->
        </v-sheet>
      </div>
    </v-card>

    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="600"
      style="background: #ffffff"
    >
      <ChartsHeatmapDialog
        class="fill-height"
        :item="dialogItem"
        @close-dialog="dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>

import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

import { mdiChevronDown } from '@mdi/js'

// LODASH
import _ from 'lodash'

// ECHARTS
// import { use } from 'echarts/core'
// import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
// import * as echarts from 'echarts/index.blank'

// COMPONENTS
// import DashboardItemMenu from '@/components/dashboard/inputs/DashboardItemMenu.vue'
import ChartsHeatmapCorine from '@/components/dashboard/charts/ChartsHeatmapCorine'
import ChartsHeatmapDialog from '@/components/dashboard/charts/ChartsHeatmapDialog'

// import {
//   SVGRenderer
//   // CanvasRenderer
// } from 'echarts/renderers'

// import {
//   HeatmapChart
// } from 'echarts/charts'

// import {
//   GridComponent,
//   LegendComponent,
//   TitleComponent,
//   TooltipComponent,
//   VisualMapComponent
// } from 'echarts/components'

// use([
//   SVGRenderer,
//   // CanvasRenderer,
//   HeatmapChart,
//   VisualMapComponent,
//   TitleComponent,
//   GridComponent,
//   TooltipComponent,
//   LegendComponent
// ])

// import Moment from 'moment'
// import { extendMoment } from 'moment-range'
//  EXTEND Moment.js with Moment-Range
// const moment = extendMoment(Moment)

export default {
  name: 'ChartsHeatmapCorineWrapperComponent',

  // mixins: [globalFunctions],

  components: {
    // DashboardItemMenu,
    // VChart,
    ChartsHeatmapCorine,
    ChartsHeatmapDialog
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  // provide: {
  //   [INIT_OPTIONS_KEY]: {
  //     renderer: 'svg'
  //   }
  // },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    cardHeight: {
      type: Number,
      default: 0
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      minimumFractionDigitsLevel1: 0,
      minimumFractionDigitsLevel2: 0,
      minimumFractionDigitsLevel3: 0,
      maximumFractionDigitsLevel1: 0,
      maximumFractionDigitsLevel2: 0,
      maximumFractionDigitsLevel3: 0,
      corineItems: [],
      dataValueUnit: '',
      dialog: false,
      dialogItem: {},
      isMenuUpdated: false,
      itemDataCorineDatasetIds: [],
      itemDataDestructered: [],
      itemDataLevel1: [],
      itemDataLevel2: [],
      itemDataLevel3: [],
      itemDataSorted: [],
      itemDataValueMax: {
        level1: null,
        level2: null,
        level3: null
      },
      itemDataYears: [],
      mdiChevronDown: mdiChevronDown,
      panelLevel1: [],
      panelLevel2: [],
      showLoadingIndicator: false,
      dataechartsOptions: {
        // tooltip: {
        //   trigger: 'item',
        //   axisPointer: {
        //     label: {
        //       show: false
        //     }
        //   }
        // },
        animation: true,
        bottom: 'auto',
        grid: {
          bottom: '70',
          id: 'chartid',
          left: '33%',
          right: '1%',
          top: '80'
        },
        series: [{
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          itemStyle: {
            borderColor: '#999',
            borderWidth: 0.4,
            emphasis: {
              borderWidth: 1,
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.2)'
            }
          },
          label: {
            normal: {
              show: false
            }
          },
          type: 'heatmap'
        }],
        // title: {
        //   text: '',
        //   left: 'center',
        //   textStyle: {
        //     color: 'red', // '#003e6e',
        //     fontSize: 14,
        //     fontWeight: '400',
        //     fontFamily: 'Roboto'
        //   }
        // },
        toolbox: {
          show: false
        },
        tooltip: {
          formatter: (params, ticket, callback) => {
            return params.seriesName + ': ' + // formatNumber(params.value[2]) +
            params.value[2] + ' ' + this.valueUnit
          },
          position: 'top'
        },
        top: 'auto',
        visualMap: {
          calculable: false,
          inRange: {
            color: ['#ffffff', '#003e6e']
          },
          left: 'center',
          orient: 'horizontal',
          realtime: false,
          top: 30
        },
        xAxis: {
          data: [],
          splitLine: {
            lineStyle: {
              color: '#999',
              width: 0.2
            },
            show: true
          },
          type: 'category'
        },
        yAxis: {
          data: [],
          splitLine: {
            lineStyle: {
              color: '#999',
              width: 0.2
            },
            show: true
          },
          type: 'category'
        }
      }
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_items', [
      // 'dashboardItemsDataQuery',
      // 'dashboardItemsSelectsBasic'
    ])

  },

  watch: {
    // locale (newVal, oldVal) {
    //   this.setVisualmapText().then(async (text) => {
    //     this.dataechartsOptions.visualMap.text = text

    //     // this.dataechartsOptions.yAxis.data = await this.getCorineTitles(this.itemDataCorineDatasetIds)
    //     // this.$refs.chart_heatmap.setOption(this.dataechartsOptions)
    //   })
    // },

    // isMenuUpdated (newVal, oldVal) {
    //   if (newVal === true) {
    //     // this.$refs.chart_heatmap.resize()
    //     this.isMenuUpdated = false
    //   }
    // },

    // height (newVal, oldVal) {
    //   // Resize map canvas if height provided by props changes
    //   // this.$refs.chart_heatmap.resize()
    // }

    async itemData () {
      // Update data changes
      await this.updateData()
    }
  },

  async mounted () {
    // Get value unit title_short (string)
    const dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.dashboardItem
    })
    this.dataValueUnit = dataValueUnitObj.title_short_de

    // Get array with corine items
    this.corineItems = await this.get_corine_items()

    await this.updateData()

    await this.updateHeatmapCorine()
  },

  beforeDestroy () {
    eventBus.$off('resize-echarts-map')
    eventBus.$off('loading-indicator-echart-component')
    eventBus.$off('update-echarts-corine')
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_corine_items'
    ]),

    ...mapActions('dashboard_items', [
      'get_datasets_value_max',
      'get_datasets_value_min',
      'get_datasets_years',
      'get_item_data_value_unit',
      'get_max_fraction_digits'
    ]),

    openAllPanels () {
      this.panelLevel1 = Array.from(Array(this.itemDataDestructered.length).keys())
      this.panelLevel2 = Array.from(Array(this.itemDataDestructered.length).keys())
    },

    closeAllPanels () {
      this.panelLevel1 = []
      this.panelLevel2 = []
    },

    openDialog (item) {
      this.dialogItem = item
      this.dialog = true
    },

    async filterByLevel ({ data, level }) {
      const clcItemsPerLevel = this.corineItems.filter(item => item.level === level)

      const levelIds = clcItemsPerLevel.map(item => item.id)

      const dataFiltered = data.filter(ds => {
        return levelIds.some(id => id === ds.corine)
      })

      this.itemDataValueMax[`level${level}`] = await this.get_datasets_value_max(dataFiltered)

      return dataFiltered
    },

    async updateData () {
      const itemDataCloned = _.cloneDeep(this.itemData)

      this.itemDataSorted = _.sortBy(itemDataCloned, [(o) => { return o.corine }])

      // Get all years in data
      this.itemDataYears = await this.get_datasets_years({
        data: this.itemDataSorted
      })

      // Filter data by level
      this.itemDataLevel1 = await this.filterByLevel({ data: this.itemDataSorted, level: 1 })
      this.itemDataLevel2 = await this.filterByLevel({ data: this.itemDataSorted, level: 2 })
      this.itemDataLevel3 = await this.filterByLevel({ data: this.itemDataSorted, level: 3 })

      const minValueLevel1 = await this.get_datasets_value_min(this.itemDataLevel1)
      const minValueLevel2 = await this.get_datasets_value_min(this.itemDataLevel2)
      const minValueLevel3 = await this.get_datasets_value_min(this.itemDataLevel3)

      this.maximumFractionDigitsLevel1 = await this.get_max_fraction_digits({ minValue: minValueLevel1 })
      this.maximumFractionDigitsLevel2 = await this.get_max_fraction_digits({ minValue: minValueLevel2 })
      this.maximumFractionDigitsLevel3 = await this.get_max_fraction_digits({ minValue: minValueLevel3 })
    },

    async updateHeatmapCorine () {
      // TODO Filter CLC class IDs who are in the datasets

      // Filter CLC class IDs data options activated for the item
      const filteredDataOptions = this.dashboardItem.data_options.filter(opt => opt.type.query_key.includes('corine_level_'))

      const dataOptionCorineIds = filteredDataOptions.flatMap((dataOption) => {
        return dataOption.values.map((value) => {
          return value.value_id
        })
      })

      const levelOne = this.corineItems.filter(item => item.level === 1)

      this.itemDataDestructered = levelOne.map((itemLevel1, indexLevel1) => {
        const levelTwo = this.corineItems.filter((clcItem) => {
          return clcItem.level === 2 &&
          clcItem.level1 === indexLevel1 + 1 &&
          dataOptionCorineIds.some(id => id === clcItem.id)
        })

        const result = levelTwo.map((itemLevel2, indexLevel2) => {
          const itemLevel3 = this.corineItems.filter((clcItem) => {
            return clcItem.level === 3 &&
           clcItem.level1 === indexLevel1 + 1 &&
            clcItem.level2 === indexLevel2 + 1 &&
            dataOptionCorineIds.some(id => id === clcItem.id)
          })
          return [itemLevel1, [itemLevel2], itemLevel3]
        })

        return result
      })
    },

    additionalValues (valueQueryKeys) {
      const selects = valueQueryKeys.map((qk, idx, arr) => {
        let corineValues

        const corineLevelId = qk.substring(qk.length - 1)

        if (idx === 0) {
          corineValues = this.corineItems.filter((item) => {
            return item.level === Number(corineLevelId)
          })
        } else {
          corineValues = this.corineItems.filter(item =>
            item.level === Number(corineLevelId)
          )
        }

        return {
          queryKey: qk,
          values: corineValues
        }
      })

      return selects
    },

    async setVisualmapText () {
      const valueMax = await this.get_datasets_value_max(this.itemDataSorted)

      return [
        `${valueMax.toLocaleString(this.locale)} ${this.dataValueUnit}`,
         `0 ${this.dataValueUnit}`
      ]
    },

    serieValue (year, corineCode) {
      // Specific Value of day and hour (time)
      const dataOfClc = this.itemDataSorted.filter(e => {
        return e.corine === corineCode && e.data_year === year
      })

      // Check if there is missing data because of "winter-/summertime" change
      if (dataOfClc[0].data_value !== null) {
        return dataOfClc[0].data_value
      } else {
        return -1
      }
    },

    async getCorineTitles (corineIdArray) {
      const titleArray = corineIdArray.map((corineId) => {
        const corineItemFiltered = this.corineItems.filter(item => item.id === corineId)
        return corineItemFiltered[0][`title_${this.locale}`]
      })

      return titleArray
    },

    getCorineTitle ({ clcId, corineItems }) {
      const corineItem = corineItems.filter(corineItem => corineItem.id === clcId)

      return corineItem[0][`title_${this.locale}`]
    }

  }
}
</script>

<style lang="scss">
  .v-expansion-panel-header,
  .expansion-panel-level-3 {
    border: 0.1px solid var(--v-backgroundColor-base);
  }

  .v-expansion-panel-header__icon {
    padding: 12px !important;
  }
</style>
