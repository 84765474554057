<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="200"
    close-delay="200"
    :disabled="$vuetify.breakpoint.xsOnly"
  >
    <v-btn
      class="btn_menu"
      depressed
      elevation0
      color="white"
      plain
      elevation="0"
      @click="setLocale(locale)"
    >
      <v-icon
        size="14"
        class="mr-2"
      >
        {{ mdiTranslateVariant }}
      </v-icon>

      <span
        v-if=" hover === false"
      >
        <span
          :class="[locale === 'de' ? 'font-weight-bold' : 'font-weight-light']"
        >
          DE
        </span>
        /
        <span
          :class="[locale === 'en' ? 'font-weight-bold' : 'font-weight-light']"
        >
          EN
        </span>
      </span>

      <v-expand-x-transition>
        <span
          v-if="hover"
        >
          <span
            v-if="locale === 'de'"
          >
            Set language to English
          </span>

          <span
            v-else
          >
            Sprache Deutsch auswählen
          </span>
        </span>
      </v-expand-x-transition>
    </v-btn>
  </v-hover>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { mdiTranslateVariant } from '@mdi/js'

export default {
  name: 'SelectLocaleComponent',

  data () {
    return {
      mdiTranslateVariant: mdiTranslateVariant
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItems'
    ])
  },

  methods: {
    ...mapActions('main', [
      'set_locale'
    ]),

    setLocale (locale) {
      this.set_locale({
        item: this.dashboardItems,
        route: this.$route,
        locale
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
