<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="200"
    close-delay="200"
    :disabled="$vuetify.breakpoint.xsOnly"
  >
    <router-link
      v-slot="{ href, navigate }"
      custom
      :to="{name: 'EEMonitorAbout', hash: '#div-cititation'}"
    >
      <v-btn
        class="btn_menu"
        depressed
        color="white"
        plain
        elevation="0"
        @click="navigate"
      >
        <v-icon
          size="14"
          class="mr-2"
        >
          {{ mdiTextBoxEditOutline }}
        </v-icon>

        <span
          v-if=" hover === false"
        >
          <span
            v-if="locale === 'de'"
          >
            Zitation
          </span>

          <span
            v-else
          >
            Citation
          </span>
        </span>

        <v-expand-x-transition>
          <span
            v-if="hover"
          >
            <span
              v-if="locale === 'de'"
            >
              EE-Monitor zitieren
            </span>

            <span
              v-else
            >
              How to cite EE-Monitor
            </span>
          </span>
        </v-expand-x-transition>
      </v-btn>
    </router-link>
  </v-hover>
</template>

<script>
import { mapState } from 'vuex'

import { mdiTextBoxEditOutline } from '@mdi/js'

export default {
  name: 'BtnCititationLink',

  data () {
    return {
      mdiTextBoxEditOutline: mdiTextBoxEditOutline
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  }
}
</script>

<style lang="scss" scoped>

</style>
