<template>
  <div>
    <v-card
      :rounded="$vuetify.breakpoint.mdAndUp"
      :elevation="$vuetify.breakpoint.mdAndUp ? 2 : 0"
    >
      <v-toolbar
        dark
        dense
        flat
        color="primary"
        height="36"
        class="popup-toolbar"
        :style="[$vuetify.breakpoint.mdAndUp ? {'border-radius': '6px 6px 0 0'} : {'border-radius': 0}]"
      >
        <v-toolbar-title
          class="d-flex justify-start align-center text-h6 font-weight-medium"
        >
          <span
            v-if="locale === 'de'"
          >
            Auswahlmenü
          </span>
          <span
            v-else
          >
            Selection menu
          </span>
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            dark
            :disabled="isMenuDisabled"
            @click="setMainMenuOpenState"
          >
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- optional -->
      <v-tabs
        v-model="selectedTab"
        centered
        show-arrows
        grow
        background-color="rgb(225, 232, 238)"
        light
      >
        <v-tab
          v-for="item in items"
          :key="item.id"
          :href="`#tab-${item.id}`"
          class="pa-0"
          :disabled="isMenuDisabled"
          @click="selectItem(item)"
        >
          <v-tooltip
            color="rgb(225, 232, 238);"
            top
            max-width="300"
            :disabled="$vuetify.breakpoint.xsOnly"
          >
            <template
              #activator="{ on }"
            >
              <div
                class="d-flex justify-center align-center fill-height"
                style="width: 100%;"
                v-on="on"
              >
                <v-icon
                  style="width: auto"
                  size="26"
                >
                  {{ `icon-technology-0` + getItemTechnologyID(item) }}
                </v-icon>
              </div>
            </template>
            <div
              class="d-flex flex-column justify-center"
            >
              <div
                class="text-h6"
              >
                {{ item[`title_plural_${locale}`] }}
              </div>
            </div>
          </v-tooltip>
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-if="items.length !== 0"
        v-model="selectedTab"
      >
        <v-tab-item
          v-for="item in items"
          :key="item.id"
          :value="`tab-${item.id}`"
        >
          <v-card
            flat
            class="pa-3"
          >
            <v-row
              class="fill-height"
            >
              <v-col>
                <!-- LAYER ITEM TITLE -->
                <v-card
                  rounded="0"
                  elevation="0"
                  class="mb-3"
                >
                  <div>
                    <LayerTitleMenu
                      :item="itemSelected"
                    />
                  </div>

                  <div
                    class="py-2"
                    style="border-bottom: dotted 1px var(--v-secondary-lighten1);"
                  >
                    <LayerStatistics />
                  </div>
                </v-card>

                <!-- RE-PLANT FILTER -->

                <div>
                  <div
                    class="mb-1 text-h6 font-weight-bold"
                  >
                    <span
                      v-if="locale === 'de'"
                    >
                      Anlagen-Filter:
                    </span>
                    <span
                      v-else
                    >
                      RE-plant filter:
                    </span>
                  </div>

                  <!-- SELECTs - DATA OPTIONs -->

                  <SelectOptions
                    class="webgis-select mb-2"
                  />

                  <!-- TIME SLIDER - YEAR  -->

                  <SliderCapacityInstalled
                    class="webgis-select mb-2"
                  />

                  <!-- RANGE SLIDER - CAPACITY INSTALLED -->

                  <SliderTime
                    class="webgis-select"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions, mapMutations } from 'vuex'

import { mdiClose } from '@mdi/js'

import LayerStatistics from '@/components/apps/re_plant_locations/webgis/info/LayerStatistics.vue'
import LayerTitleMenu from '@/components/apps/re_plant_locations/webgis/info/LayerTitleMenu.vue'
import SelectOptions from '@/components/apps/re_plant_locations/webgis/menus/SelectOptions.vue'
import SliderCapacityInstalled from '@/components/apps/re_plant_locations/webgis/menus/SliderCapacityInstalled.vue'
import SliderTime from '@/components/apps/re_plant_locations/webgis/menus/SliderTime.vue'

export default {
  name: 'WebGisMenuMain',

  components: {
    LayerStatistics,
    LayerTitleMenu,
    SelectOptions,
    SliderCapacityInstalled,
    SliderTime
  },

  data: () => ({
    selectedTab: '',
    mdiClose: mdiClose
  }),

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'items',
      'itemSelected',
      'isMainMenuOpen',
      'isMenuDisabled',
      'mainMenuSelectedTab'
    ])
  },

  watch: {
    mainMenuSelectedTab: {
      handler (newVal) {
        if (newVal !== undefined) {
          this.selectedTab = newVal
        }
      },
      immediate: true
    }
  },

  // beforeDestroy () {
  // },

  // mounted () {
  // },

  methods: {
    ...mapActions('webgis', [
      'set_main_menu_open_state'
    ]),

    setMainMenuOpenState () {
      this.set_main_menu_open_state(!this.isMainMenuOpen)
      eventBus.$emit('set-webgis-main-menu', false)
    },

    selectItem (item) {
      eventBus.$emit('select-webgis-item', item)
    },

    getItemTechnologyID (item) {
      return item.categories.filter(cat => cat.type.query_key === 'technology')[0].value.value_id
    }
  }
}
</script>

<style lang="scss">
.v-tab {
  border-bottom: 1px dotted var(--v-primary-base) !important;
  // border-bottom: 0 !important; // var(--v-primary-base) 1.5px solid;
}

.v-tab {
  .icon {
    color: var(--v-secondary-base);
  }

  &.v-tab--active {
    .icon {
      color: var(--v-primary-base);
    }
  }
}

.v-slide-group__content > a:hover {
  .v-icon {
    color: var(--v-primary-base) !important;
    opacity: 0.8;
  }
}

.webgis-select {
  .webgis-select-options {
    .v-select__selections {
      display: flex;
      align-items: stretch;
    }
  }

  .v-input__slot {
    margin: 0 !important;
  }

  .v-text-field__details {
    display: none !important;
  }

  button .v-icon__svg {
    height: 16px;
    width: 16px;
  }

  .theme--light.v-icon {
    color: rgba(0, 0, 0, 0.45);
  }

  .v-text-field > .v-input__control > .v-input__slot:after,
  .v-text-field > .v-input__control > .v-input__slot:before {
    border: none;
    visibility: hidden;
  }

  .v-select__slot {
    border-bottom: 1px dotted var(--v-primary-base);
  }

  .v-select__selections > span {
    overflow: hidden;
    max-width: 99%;
  }

  .v-text-field .v-input__append-inner {
    margin-top: 0;
  }

  .v-text-field input {
    padding: 4px 0 4px;
  }

  .v-select__selection--comma {
    margin: 0;
  }
}

.webgis-select-info-tooltip {
  border: 1px dotted var(--v-primary-base) !important;
  background: #ffffff;
}
</style>
