<template>
  <!--  -->
  <div
    class="d-flex flex-column fill-height"
  >
    <div
      class="chart-title-area"
      style="width: 100%; "
    >
      <div
        class="px-6 d-flex flex-row align-center justify-start fill-height text-h6"
        style="height: 30px; width: 100%;"
      >
        {{ chartTitle }}
      </div>
    </div>

    <div
      class="pa-3"
      style="height: 100%;"
    >
      <VChart
        ref="chart1"
        renderer="svg"
        :autoresize="true"
        :option="option"
      />
    </div>
  </div>
</template>

<script>
//  IMPORT
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

import _ from 'lodash'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

import {
  SVGRenderer
} from 'echarts/renderers'
import {
  BarChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
} from 'echarts/components'

use([
  SVGRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
])

export default {
  name: 'ChartsLine0ComponentPage',

  components: {
    VChart
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'svg'
    }
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default: 1
    },
    spatialTypeId: {
      type: Number,
      default: 2
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      chartTitle: '',
      data: [],
      dataAgs: [],
      dataValueUnit: '',
      dataValueUnitObj: {},
      echartsOption: {
        grid: {
          bottom: '0',
          containLabel: true,
          height: 'auto',
          left: '10',
          right: '20',
          top: '30',
          width: 'auto'
        },
        itemStyle: {// borderColor: 'rgb(0, 62, 110, 1)'
        },
        legend: {
          show: false
        },
        selectedMode: 'false',
        series: [],
        toolbox: {
          feature: {
            saveAsImage: {
              show: true
            }
          },
          show: false
        },
        tooltip: {
          alwaysShowContent: false,
          axisPointer: {
            snap: true,
            type: 'line'
          },
          backgroundColor: 'rgba(245, 245, 245, 0.9)',
          borderWidth: 1,
          confine: true,
          order: 'valueDesc',
          padding: 8,
          trigger: 'axis'
        },
        xAxis: {
          axisLabel: {},
          axisPointer: {
            handle: {
              icon: 'none',
              show: false
            },
            label: {
              show: false
            },
            type: 'line'
          },
          axisTick: {
            alignWithLabel: true
          },
          data: [],
          splitLine: {
            show: true
          },
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            formatter: '{value}'
          },
          textStyle: {
            color: '#003e6e',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: '400'
          },
          type: 'value'
        }
      },
      isValueNaN: false,
      itemDataFiltered: [],
      itemDataYears: [],
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      option: {},
      series: {
        data: [],
        emphasis: {
          focus: 'series',
          itemStyle: {// color: '#003E6E',
            // borderColor: '#003E6E' // borderStyle: 'solid'
          },
          label: {
            show: false
          },
          lineStyle: {
            color: '#003E6E',
            type: 'solid',
            width: 6
          }
        },
        itemStyle: {
          color: dataItem => {
            return dataItem.color
          }
        },
        type: 'bar'
      }
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'echartsDefaults',
      'bkgItems'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataMinMax',
      'dashboardItemsDataYears'
    ])

  },

  watch: {
    locale: {
      handler (newLocale) {
        if (newLocale !== undefined) {
          if (this.$refs.chart1 !== undefined) {
            this.$refs.chart1.setOption(this.echartsOption)
          }
          this.chartTitle = newLocale === 'de' ? 'Bundesrepublik Deutschland' : 'Germany'
        }
      },
      immediate: true
    },

    height (newVal, oldVal) {
      this.$refs.chart1.resize()
    },

    itemData (newVal, oldVal) {
      this.updateChart()
    }
  },

  async mounted () {
    this.chartTitle = this.locale === 'de' ? 'Bundesrepublik Deutschland' : 'Germany'

    // Get array of distinct years in data
    this.itemDataYears = this.dashboardItemsDataYears

    // Set echarts defauls from store
    this.echartsOption.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.tooltip.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.tooltip = _.cloneDeep(this.echartsDefaults.tooltip)
    this.echartsOption.xAxis = _.cloneDeep(this.echartsDefaults.xAxis)
    this.echartsOption.yAxis.axisLabel.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)

    // Set x-Axis Interval (index:number, value: string) => boolean
    this.echartsOption.xAxis.axisLabel.interval = await this.get_axis_value_interval({ years: this.itemDataYears })
    this.echartsOption.xAxis.axisTick.interval = await this.get_axis_value_interval({ years: this.itemDataYears, interval: 1 })

    // Get value unit title_short (string)
    this.dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.dashboardItem
    })
    this.dataValueUnit = this.dataValueUnitObj.title_short_de

    this.updateChart()

    eventBus.$on('set-locale', (locale) => {
      this.chartTitle = locale === 'de' ? 'Bundesrepublik Deutschland' : 'Germany'

      // this.echartsOption.title.text = this.setTitleText(spatialTypeSelected)

      // if (showLegend === true) {
      //   this.chartTitle = this.setTitleText(spatialTypeSelected)
      // }

      this.$refs.chart1.setOption(this.echartsOption)
    })

    eventBus.$on('resize-echarts-chart1', (dimensions) => {
      this.$refs.chart1.chart.resize()
    })

    // EMIT EVENT - CLICK ON CHART AREA
    // REVIEW Console ERROR
    // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart
    // this.$refs.chart1.chart.getZr().on('click', params => {
    //   this.chartsOnClick(params)
    // })

    // EVENT lISTENER - UPDATE CHART BY SELECTED YEAR
    // eventBus.$on(`update-echarts-map-component`, async ({
    //   year
    // }) => {
    //   this.echartsOption.xAxis.axisPointer.value = String(year)
    //   this.$refs.chart1.setOption(this.echartsOption)
    // })

    // EVENT LISTENER ECHARTS -> MOUSEOVER
    // this.$refs.chart1.chart.on('mouseover', async (params) => {
    //   this.echartsOption.xAxis.axisPointer.handle.show = true
    //   this.$refs.chart1.setOption(this.echartsOption)
    // })

    // EVENT LISTENER ECHARTS -> MOUSEOUT
    this.$refs.chart1.chart.on('mouseout', async (params) => {
      this.echartsOption.xAxis.axisPointer.handle.show = false
      this.$refs.chart1.setOption(this.echartsOption)
    })

    // EVENT LISTENER ECHARTS -> GLOBALOUT
    this.$refs.chart1.chart.on('globalout', async (params) => {
      this.echartsOption.xAxis.axisPointer.handle.show = false
      this.$refs.chart1.setOption(this.echartsOption)
    })
  },

  beforeDestroy () {
    this.$refs.chart1.chart.dispose()
    this.$refs.chart1.chart = null

    eventBus.$off('highlight-charts-series')
    eventBus.$off('resize-echarts-chart1')
    eventBus.$off('set-locale')

    // this.$eventHub.off('emit-set-echarts-barchart-data')
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_ags_gen_title'
    ]),

    ...mapActions('dashboard_items', [
      'get_item_data_value_unit'
    ]),

    ...mapMutations('dashboard_items', [
      'SET_ITEM_YEAR_SELECTED'
    ]),

    ...mapActions('dashboard_items', [
      'get_axis_value_interval',
      'get_datasets_ags',
      'get_datasets_value_max',
      'get_datasets_value_min',
      'get_datasets_years',
      'get_max_fraction_digits'
    ]),

    setTitleText (spatialTypeSelected) {
      if (this.locale === 'de') {
        if (spatialTypeSelected === 2) {
          return 'Alle Bundesländer'
        } else {
          return 'Alle Landkreise'
        }
      } else {
        if (spatialTypeSelected === 4) {
          return 'All federal states'
        } else {
          return 'All counties'
        }
      }
    },

    roundToTwo (num) {
      return Math.ceil((num + Number.EPSILON) * 1000000) / 1000000
      // return +(Math.round(num + 'e+2') + 'e-2')
    },

    chartsOnClick (params) {
      // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart

      let categoryName

      this.$refs.chart1.chart.dispatchAction({
        type: 'downplay'
      })

      if (params.event.target) {
        const pointInPixel = [params.offsetX, params.offsetY]
        const pointInGrid = this.$refs.chart1.chart.convertFromPixel('grid', pointInPixel)
        categoryName = this.$refs.chart1.chart.getModel().get('xAxis')[0].data[pointInGrid[0]]
      } else {
        categoryName = params.name
      }

      eventBus.$emit('update-echarts-map-component', {
        year: Number(categoryName)
      })

      this.SET_ITEM_YEAR_SELECTED({
        year: Number(categoryName)
      })
    },

    async updateChart () {
      const itemDataCloned = _.cloneDeep(this.itemData)
      // Create series datasets
      const series = []
      const agsId = '00000000'

      // this.chartTitle = await this.get_ags_gen_title({
      //   ags: agsId
      // })

      // Filter data by agsId ("00000000" -> Germany)
      this.itemDataFiltered = itemDataCloned.filter(ds => ds.ags === agsId)

      // Check if all values are Null
      const isValueNaN = this.checkIfValueNaN(this.itemDataFiltered)

      // Get highest value in dataset
      const valueMax = await this.get_datasets_value_max(this.itemDataFiltered)

      if (isValueNaN === false) {
        this.echartsOption.yAxis.show = true

        this.echartsOption.yAxis.min = 0

        this.echartsOption.yAxis.max = valueMax
      } else {
        this.echartsOption.yAxis.show = false
      }

      // NOTE workaround for values with "n"
      this.echartsOption.yAxis.max = valueMax + 0.0001

      if (this.dataValueUnitObj.title_short_de.match(/^n$/)) {
        this.echartsOption.yAxis.minInterval = 1
      }

      const dataValues = this.itemDataFiltered.map((ds) => {
        if (ds.data_value === null) {
          return 0 // ds.data_value // 0
        } else {
          return ds.data_value
        }
      })

      // console.log('dataValues ', dataValues)

      // NOTE Deep clone is needed for resolving inheritance issues
      const seriesObj = _.cloneDeep(this.series)

      // Set data array of data series
      seriesObj.data = dataValues

      // seriesObj.itemStyle.color = '#333' // NOTE Just for testing

      // Set name of data series (-> BKG (BEZ +) GEN)
      seriesObj.name = await this.get_ags_gen_title({
        ags: agsId
      })

      // if (agsId === '00000000') {
      //   seriesObj.lineStyle = {
      //     color: '#003e6e',
      //     width: 4,
      //     type: 'line'
      //   }
      // }

      seriesObj.itemStyle = {
        borderColor: '#003e6e',
        color: 'rgb(225, 232, 238)',
        borderWidth: 1,
        type: 'solid'
      }

      series.push(seriesObj)

      // Set created series data
      this.echartsOption.series = series

      // TODO Callback function for number formating
      // https://echarts.apache.org/en/option.html#yAxis.axisLabel.formatter

      // Set number of min & max digits for the number of value descendants
      if (!this.dataValueUnit.match(/^n$/)) {
        const minValue = await this.get_datasets_value_min(this.itemDataFiltered)
        this.maximumFractionDigits = await this.get_max_fraction_digits({ minValue })
      }

      if (this.dataValueUnit === '%') {
        this.echartsOption.yAxis.max = 100
      }

      this.echartsOption.yAxis.name = this.dataValueUnit
      this.echartsOption.yAxis.axisLabel.formatter = (value) => `${value.toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })}`

      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        const title = `<div class="text-h6">
                      ${params.seriesName} (${params.name})
                      </div>`

        if (isNaN(params.value)) {
          return `${title}<div>${this.locale === 'de' ? 'kein Wert' : 'no value'}</div>`
        } else {
          return `${title}
          <div>${params.value.toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits + 1,
            maximumFractionDigits: this.maximumFractionDigits + 1
          })}
          ${this.dataValueUnit}</div>`
        }
      }

      // X-axis -> Set year as category
      this.echartsOption.xAxis.data = this.itemDataYears

      // Set options on the echarts instance
      this.$refs.chart1.setOption(this.echartsOption)
    },

    checkIfValueNaN (datasets) {
      const datasetNullValues = datasets.filter(ds => ds.data_value === null)
      return datasetNullValues.length === datasets.length
    },

    echartsData (AGS) {
      if (AGS === '' || AGS === undefined) {
        this.echartsOption.series[0].data = []
        this.echartsOption.yAxis.max = 0
      } else {
        const itemDataFiltered = this.attributesActiveFL.filter((e) => {
          return e.AGS === AGS
        })

        this.itemDataFiltered = itemDataFiltered[0]

        const dataX = this.getDates(
          this.selectTemporalSelected,
          this.dateRange,
          this.dateDefinitions
        )

        // const dataY = itemDataFiltered[0].value.split(',').map(e => {
        //   if (e === '') {
        //     return '0'
        //   } else if (e === '-1') {
        //     return '0'
        //   } else {
        //     return e
        //   }
        // })

        // const dataY = itemDataFiltered[0].value
        const dataY = itemDataFiltered[0][this.selectIndicatorSelected.value]

        this.echartsOption.xAxis.data = dataX
        this.echartsOption.yAxis.max = this.FL_MaxValue[
          this.selectIndicatorSelected
        ]
        this.echartsOption.series[0].data = dataY
        this.echartsOption.series[0].itemStyle.color = this.FL_RendererColor[1]
      }
    },

    getDates (temp, dateRange, dateDefinitions) {
      if (Object.keys(dateRange).length !== 0) {
        const def = dateDefinitions[temp.ID - 1]

        const dateRangeDates = Array.from(dateRange.by(def.rangeBy))

        let dates = []
        dates = dateRangeDates.map(d => d.format(def.timeline))

        return dates
      }
    }
  }
}
</script>

<style lang="scss">

</style>
