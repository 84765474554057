<template>
  <div>
    <div
      class="d-flex flex-row flex-nowrap"
    >
      <div
        class="flex-grow-1"
        style="min-width: 0;"
      >
        <!-- :label="select.type[`title_${locale}`]" -->
        <v-select
          v-if="Object.keys(select).length !== 0"
          :ref="`ref_select_${select.type.query_key}`"
          :value="selectValue"
          clearable
          return-object
          :disabled="isMenuDisabled"
          :item-text="select.type.select_item_text_field"
          :item-value="select.type.query_field"
          :items="selectSortValues({
            values: select.values,
            sortValues: select.type.sort_alphabetically
          } )"
          :clear-icon="mdiCloseCircleOutline"

          class="text-truncate webgis-select webgis-select-options"
          @change="setValue({event: $event, selectValue: select})"
          @click:clear="removeFocus()"
        >
          <template
            #selection="data"
          >
            <div
              v-if="locale"
              class="d-flex align-end"
              style="max-width: 95%; overflow: hidden; white-space: normal; margin-top: 4px;"
            >
              <span
                class="text-body-1 font-weight-medium"
              >
                {{ data.item[`title_${locale}`] }}
              </span>
            </div>
          </template>

          <template
            #item="data"
          >
            <span
              v-if="locale"
              class="text-body-1"
            >
              {{ data.item[`title_${locale}`] }}
            </span>
          </template>

          <template
            #label
          >
            <span
              class="text-body-1"
            >
              {{ select.type[`title_${locale}`] }}
            </span>
            <!-- <v-tooltip
                  color="rgb(225, 232, 238);"
                  left
                  max-width="300"
                  content-class="webgis-select-info-tooltip"
                >
                  <template
                    #activator="{ on }"
                  >
                    <span
                      class="text-body-1"
                      v-on="on"
                    >
                      {{ select.type[`title_${locale}`] }}
                    </span>
                  </template>
                  <div>
                    {{ select.type[`title_${locale}`] }}
                  </div>
                </v-tooltip> -->
          </template>

          <!-- <template
            #prepend
          >
            <v-tooltip
              color="rgb(225, 232, 238);"
              left
              max-width="300"
              content-class="webgis-select-info-tooltip"
            >
              <template
                #activator="{ on }"
              >
                <span
                  class="text-body-1"
                  v-on="on"
                >
                  <v-icon
                    class="info-tooltip-icon"
                    style="width: 100%; height: 100%;"
                  >
                    {{ mdiInformationVariantCircle }}
                  </v-icon>
                </span>
              </template>
              <div>
                {{ select.type[`title_${locale}`] }}
              </div>
            </v-tooltip>
          </template> -->
        </v-select>
      </div>
      <!--
      <div
        class="d-flex flex-row align-center justify-center flex-grow-0"
      >
        <span>

          <v-tooltip
            color="rgb(225, 232, 238);"
            left
            max-width="300"
            content-class="webgis-select-info-tooltip"
          >
            <template
              #activator="{ on }"
            >
              <span
                class="text-body-1"
                v-on="on"
              >
                <div>
                  <div
                    style="border-left: 1px dotted var(--v-primary-base) !important;  padding: 6px 0px 6px 10px; margin-left: 12px;"
                  >
                    <v-icon
                      class="info-tooltip-icon"
                      style="width: 100%; height: 100%;"
                    >
                      {{ mdiInformationVariantCircle }}
                    </v-icon>
                  </div>
                </div>

              </span>
            </template>
            <div>

              {{ select.type[`title_${locale}`] }}
            </div>
          </v-tooltip>
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import _ from 'lodash'

import { mdiInformationVariantCircle, mdiCloseCircleOutline } from '@mdi/js'

export default {
  name: 'SelectAdditional',

  props: {
    select: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      mdiCloseCircleOutline: mdiCloseCircleOutline,
      mdiInformationVariantCircle: mdiInformationVariantCircle,
      selectLocal: {},
      selectValue: null,
      selectQueryKey: ''
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'isMenuDisabled',
      'itemDataQuery'
    ])

    // selectValuesSorted () {
    //   return this.select === null ? [] : _.orderBy(this.select.values, [`title_${this.locale}`, ['asc']])
    // }
  },

  watch: {
    select: {
      handler (newVal, oldVal) {
        if (newVal !== null && Object.keys(newVal).length !== 0) {
          this.selectLocal = _.cloneDeep(newVal)
        }
      },
      immediate: true
    },

    itemDataQuery: {
      handler (newItemDataQuery) {
        if (newItemDataQuery !== undefined) {
          if (Object.keys(this.select).length !== 0) {
            // console.log('newItemDataQuery ', newItemDataQuery)

            const selectQueryKey = this.select.type.query_key
            // console.log('selectQueryKey', selectQueryKey)

            // console.log('select ', select)

            for (const [key, value] of Object.entries(newItemDataQuery)) {
              // console.log('key, value ', key, value)
              if (key === selectQueryKey) {
                // console.log('key ===selectQueryKey ', key === selectQueryKey)
                if (value !== null) {
                  const updatedSelectValue = this.select.values.filter(val => val.value_id === value)[0]
                  this.selectValue = updatedSelectValue

                  // console.log('this.selectValue ', this.selectValue)
                } else {
                  this.selectValue = null
                }
              }
            }
          }
        }
      },
      immediate: true,
      deep: true
    }
  },

  mounted () {
    this.selectLocal = _.cloneDeep(this.select)
    // console.log('selAdd this.selectLocal ', this.selectLocal)
  },

  // async beforeUpdate () {
  //   console.log('selAdd beforeUpdate', JSON.parse(JSON.stringify(this.selectLocal)))
  //   await this.setValue({ event: null, selectValue: JSON.parse(JSON.stringify(this.selectLocal)) })
  //   this.$nextTick(() => {})
  // },

  async beforeDestroy () {
    // console.log('selAdd beforeDestroy', JSON.parse(JSON.stringify(this.selectLocal)))
    await this.setValue({ event: null, selectValue: JSON.parse(JSON.stringify(this.selectLocal)) })
    this.$nextTick(() => {})
  },

  methods: {
    ...mapActions('webgis', [
      'set_menu_disabled',
      'update_data_query'
    ]),

    selectSortValues ({ values, sortValues }) {
      if (sortValues === true) {
        return values === null ? [] : _.orderBy(values, [`title_${this.locale}`, ['asc']])
      } else {
        return values === null ? [] : values
      }
    },

    async setValue ({ event, selectValue }) {
      this.selectQueryKey = selectValue.type.query_key
      // console.log('selAdd event, selectValue ', event, selectValue)

      if (Object.keys(selectValue).length !== 0) {
        this.set_menu_disabled(true)

        const queryKey = selectValue.type.query_key
        const eventValue = event === null ? null : event[selectValue.type.query_field]
        // console.log('selAdd queryKey, eventValue ', queryKey, eventValue)
        this.update_data_query({
          query: {
            [queryKey]: eventValue
          }
        })

        this.$emit('update-data', { event, selectValue })
      }

      this.removeFocus()
    },

    removeFocus () {
      if (this.$refs[`ref_select_${this.selectQueryKey}`] !== undefined) {
        this.$refs[`ref_select_${this.selectQueryKey}`].blur()
      }
    }
  }
}
</script>

<style lang="scss">
.webgis-select {
  .v-label.v-label--active,
  .v-label.v-label--is-disable {
    color: rgba(0, 0, 0, 0.8);
  }
}
</style>
