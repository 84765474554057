export default () => ({
  dashboardComparisonStatus: false,
  dashboardItems: {}, // FIXME -> null | Refactor every check for empty object etc.
  dashboardItemsData: [],
  dashboardItemsDataValueUnit: [],
  dashboardItemsDataOptionQueryKeys: [],
  dashboardItemsDataAttributesMerged: [],
  dashboardItemsDataMinMax: [],
  dashboardItemsDataQuery: [],
  dashboardItemsDataYears: [],
  dashboardItemsSelectsBasic: [],
  dashboardItemsSelectsOptional: [],
  // dashboardItemsAgsIds: [], // NOTE not used atm
  dashboardItemsYearSelected: null,
  dashboardLayout: [],
  showSelectOptional: false
})
