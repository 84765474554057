import axios from 'axios'

import { eventBus } from '@/utils/eventbus.js'

export default {
  async get_item_list_report ({
    commit,
    dispatch,
    rootState
  }, {
    application
  }) {
    const {
      data
    } = await axios.get(rootState.main.baseURL + 'api/v1/eemonitor/items_report/').catch(err => {
      console.log('error get_item_list_report ', err)
      eventBus.$emit('push-to-page-error-404')
    }) // TODO Error Handling

    const itemListReport = data.filter(item => {
      return item.show_in_applications.some(app => {
        return app.id === application.id
      })
    })
    commit('SET_ITEM_LIST_REPORT', {
      itemListReport
    })
  },

  set_item_list_report_range_data ({
    commit
  }, payload) {
    commit('SET_ITEM_LIST_REPORT_RANGE_DATA', payload)
  },

  set_item_list_sorted ({
    commit
  }, payload) {
    commit('SET_ITEM_LIST_SORTED', payload)
  },

  set_regionreport_defaults ({
    commit,
    state
  }) {
    commit('main/SET_DIALOG_WELCOME', true, {
      root: true
    })
  },

  set_region_ags_selected ({
    commit
  }, payload) {
    commit('SET_REGION_AGS_SELECTED', payload)
  },

  set_spatial_type_selected ({
    commit
  }, payload) {
    commit('SET_SPATIAL_TYPE_SELECTED', payload)
  }

}
