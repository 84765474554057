<template>
  <!--  -->
  <div
    class="d-flex flex-column fill-height"
  >
    <div
      class="chart-title-area"
      style="width: 100%; "
    >
      <div
        class="px-6 d-flex flex-row align-center justify-start fill-height text-h6"
        style="height: 30px; width: 100%;"
      >
        {{ chartTitle }}
      </div>
    </div>

    <!-- <div
      class="d-flex grow"
      style="position: relative"
    >
      <div
        id="chart_overlay"
        style="position: absolute; width: 100%; height: 100%; z-index: 1; pointer-events: none; object-fit: contain; top: 100px;"
        class="d-flex flex-row fill-height justify-center align-center"
      >
        <v-card
          v-if="isValueNaN"
          class="pa-4"
          style="width: 200px;"
        >
          <span
            v-if="locale === 'de'"
          >
            Für die Auswahl ist kein Wert zur Darstellung verfügbar.
          </span>

          <span
            v-else
          >
            No value is available for display for the selection.
          </span>
        </v-card>
      </div>
    </div> -->

    <div
      class="fill-height pa-3"
    >
      <VChart
        ref="chart_boxplot"
        renderer="svg"
        :autoresize="true"
        :option="option"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

//  IMPORT
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

import {
  SVGRenderer
} from 'echarts/renderers'
import {
  BoxplotChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
} from 'echarts/components'

use([
  SVGRenderer,
  BoxplotChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
])

export default {
  name: 'ChartsBoxplotComponent',

  components: {
    VChart
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'svg'
    }
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default: 1
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    spatialTypeId: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      dataFiltered: [],
      itemDataYears: [],
      isMouseOver: false,
      chartTitle: '',
      data: [],
      dataAgs: [],
      dataValueUnit: '',
      isValueNaN: false,
      option: {},
      showLegend: false,
      spatialTypeSelected: undefined,
      targetValueColor: 'red', // TODO Needs to be defined
      targetValueYear: 2022,
      series: {
        data: [],
        itemStyle: {
          color: 'rgb(216,225,233)'
        },
        name: '',
        type: 'boxplot',
        select: {
          disabled: true
        }
      },
      echartsOption: {
        color: '#003e6e',
        grid: {
          show: true,
          borderWidth: 0.5,
          bottom: '8px',
          containLabel: true,
          height: 'auto',
          left: '10px',
          right: '12px',
          top: '30px'
          // width: '100%'
        },
        itemStyle: {
          borderColor: 'rgb(0, 62, 110, 1)'
        },
        series: [{
          // emphasis: {
          //   disabled: true
          // }
        }],
        tooltip: {
          alwaysShowContent: false,
          axisPointer: {
            type: 'line',
            snap: true
          },
          trigger: 'axis'
        },
        xAxis: {
          offset: 2,
          axisLabel: {},
          axisLine: {
            lineStyle: {
              color: '#003e6e'
            },
            show: true
          },
          axisPointer: {
            handle: {
              icon: 'none',
              show: false
            },
            label: {
              show: false
            },
            shadowStyle: {
              color: 'rgb(0, 62, 110, 0.04)'
            },
            type: 'shadow',
            value: ''
          },
          axisTick: {
            alignWithLabel: true,
            show: true
          },
          data: [],
          nameGap: 30,
          splitLine: {
            show: true
          },
          type: 'category'
        },
        yAxis: {
          scale: false,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            // formatter: '{value}'
          },
          name: '',
          type: 'value'
        }
      }
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems',
      'echartsDefaults'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataQuery',
      'dashboardItemsYearSelected',
      'dashboardItemsDataYears',
      'dashboardItemsDataMinMax'
    ])
  },

  watch: {
    locale (newVal, oldVal) {
      // Set options with changed toLocaleString(s)
      this.$refs.chart_boxplot.setOption(this.echartsOption)
    },

    height (newVal, oldVal) {
      this.$refs.chart_boxplot.resize()
    }

    // itemData () {
    // }
  },

  async mounted () {
    // Set echarts defaults from state
    this.echartsOption.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.tooltip.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.yAxis.axisLabel.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.xAxis.axisLabel.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)

    // Init set of selected year -> x-Axis axisPointer
    this.echartsOption.xAxis.axisPointer.value = String(this.dashboardItemsYearSelected)

    // Get array of distinct years in data
    this.itemDataYears = this.dashboardItemsDataYears

    // Set x-Axis Interval (index:number, value: string) => boolean
    this.echartsOption.xAxis.axisLabel.interval = await this.get_axis_value_interval({ years: this.itemDataYears })
    this.echartsOption.xAxis.axisTick.interval = await this.get_axis_value_interval({ years: this.itemDataYears, interval: 1 })

    // Get value unit title_short (string)
    const dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.dashboardItem
    })
    this.dataValueUnit = dataValueUnitObj.title_short_de

    // ECHARTS - EVENT LISTENER

    // EVENT lISTENER - UPDATE CHART BY SELECTED YEAR
    eventBus.$on('update-echarts-map-component', async ({
      year
    }) => {
      if (!this.isMouseOver) {
        this.echartsOption.xAxis.axisPointer.value = String(year)
        this.echartsOption.xAxis.axisPointer.handle.show = true
        this.$refs.chart_boxplot.setOption(this.echartsOption)
      }
    })

    // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart
    this.$refs.chart_boxplot.chart.getZr().on('click', params => {
      this.chartsOnClick(params)
    })

    // NOTE Deactivated because the tooltip vanishes the moment the boxblot line is hoovered
    // // EVENT LISTENER ECHARTS -> MOUSEOVER
    // this.$refs.chart_boxplot.chart.on('mouseover', async (params) => {
    //   if (this.echartsOption.xAxis.axisPointer.handle.show === false) {
    //     this.isMouseOver = true

    //     // Set year (params.name -> category/year x-Axis)
    //     this.echartsOption.xAxis.axisPointer.value = String(params.name)

    //     this.echartsOption.xAxis.axisPointer.handle.show = true
    //     this.$refs.chart_boxplot.setOption(this.echartsOption)
    //   }
    // })

    this.$refs.chart_boxplot.chart.on('globalout', async (params) => {
      this.isMouseOver = false

      this.$refs.chart_boxplot.chart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })

      // Set selected year from state -> x-Axis axisPointer
      this.echartsOption.xAxis.axisPointer.value = String(this.dashboardItemsYearSelected)

      this.echartsOption.xAxis.axisPointer.handle.show = true
      this.$refs.chart_boxplot.setOption(this.echartsOption)
    })

    // EVENT LISTENER ECHARTS -> MOUSEOUT
    this.$refs.chart_boxplot.chart.on('mouseout', async (params) => {
      this.isMouseOver = false
      this.echartsOption.xAxis.axisPointer.handle.show = false
      this.$refs.chart_boxplot.setOption(this.echartsOption)
    })

    //
    // EVENTBUS - EVENT LISTENER
    //

    eventBus.$on('loading-indicator-echart-component', () => {
      this.$refs.chart_boxplot.chart.showLoading()
    })

    eventBus.$on('resize-echarts-chart_boxplot', (dimensions) => {
      this.$refs.chart_boxplot.chart.resize()
    })

    eventBus.$on('load-echarts-boxplot-component', async ({
      spatialTypeSelected,
      agsIdSelected
    }) => {
      // Set selected year from state -> x-Axis axisPointer
      this.echartsOption.xAxis.axisPointer.value = String(this.dashboardItemsYearSelected)

      this.spatialTypeSelected = spatialTypeSelected

      this.dataFiltered = this.itemData.filter((ds) => {
        if (agsIdSelected === undefined) {
          return ds.spatial_type === 1
        } else {
          return ds.ags === agsIdSelected
        }
      })

      if (agsIdSelected !== undefined) {
        this.chartTitle = await this.get_ags_gen_title({
          ags: agsIdSelected
        })
      } else {
        this.chartTitle = await this.get_ags_gen_title({
          ags: '00000000'
        })
      }

      // Get highest value in dataset
      const valueMax = await this.get_datasets_value_max(this.dataFiltered)

      if (this.dataValueUnit === '%') {
        // NOTE -> Safety: Set x-Axis to 100% if value of kind percentage
        this.echartsOption.yAxis.max = 100
      } else {
        this.echartsOption.yAxis.max = valueMax
      }

      // Set min value of y-Axis
      this.echartsOption.yAxis.min = 0

      this.dataAgs = await this.get_datasets_ags(this.data)

      // Create series datasets
      const seriesDatasets = []
      for (const itemDataYear of this.itemDataYears) {
        const datasets = this.dataFiltered.filter((ds) => {
          return ds.data_year === itemDataYear
        })

        // Sort datasets ascending by foreign key of data_statistic
        // NOTE data_statistic values --> Minimum = 1 | Quartile 1 = 2 | Median = 3 | Quartile 3 = 4 | Maximum = 5 | Arithmetic mean = 6
        datasets.sort((a, b) => a.data_statistic - b.data_statistic)

        // Remove data_statistic "arithmetic mean" value (data_statistic === 6, index === 5 in dataset array)
        datasets.splice(5, 1)

        const dataValues = datasets.map((ds, idx, arr) => {
          // TODO number of digits should be set by lowest value in dataset
          if (ds.data_value === null) {
            return 0
          } else {
            return ds.data_value
          }
        })

        seriesDatasets.push(dataValues)
      }

      // NOTE Deep cloning is necessary for resolving inheritance issues
      const seriesObj = _.cloneDeep(this.series)

      // Set data array of data series
      seriesObj.data = seriesDatasets

      seriesObj.name = this.chartTitle

      // Set created series data
      this.echartsOption.series = seriesObj

      // Set number of min & max digits for the number of value descendants
      if (!this.dataValueUnit.match(/^n$/)) {
        const minValue = await this.get_datasets_value_min(this.dataFiltered)
        this.maximumFractionDigits = await this.get_max_fraction_digits({ minValue })
      }

      this.echartsOption.yAxis.name = this.dataValueUnit

      // NOTE min/max digits set to 0 because yAxis was jumping left/right based on different fraction digits values
      this.echartsOption.yAxis.axisLabel.formatter = (value) => `${value.toLocaleString(this.locale, {
            minimumFractionDigits: 0, // this.minimumFractionDigits,
            maximumFractionDigits: 1 // this.maximumFractionDigits
          })}`

      const dataOptionsStatistic = this.dashboardItem.data_options.filter((opt) => {
        return opt.type.query_key === 'data_statistic'
      })[0].values

      const dataOptionsStatisticSliced = dataOptionsStatistic.slice(0, 5).reverse()

      // const valueFormatter = (value) => `${value.toLocaleString(this.locale, {
      //       minimumFractionDigits: this.minimumFractionDigits,
      //       maximumFractionDigits: this.maximumFractionDigits
      //     })}`

      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        // Title with year (axisValue) in parentheses
        const title = `<div class="text-h6">${params[0].seriesName} (${params[0].axisValue})</div>`

        const statisticTable = dataOptionsStatisticSliced.map((opt, idx, arr) => {
          // NOTE data arr ["year-index", min, q1, median, q2, max]
          // Cut off index of category ("year" as index) and reverse (min -> max to max -> min)
          const paramsDataSliced = params[0].data.slice(1, 6).reverse()

          const value = paramsDataSliced[idx]
          const title = opt[`title_${this.locale}`]

          const htmlTableRowStatisticValue = `
                    <tr style="padding:0; margin:0;">
                      <td style="padding: 0 24px 0 0;">
                        ${title}
                      </td>
                      <td style="text-align: right;">
                        ${value.toLocaleString(this.locale, {
              minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })} ${this.dataValueUnit}
                      </td>
                    </tr>`

          return htmlTableRowStatisticValue
        }).join('')

        const html = `${title}<div><table style="width: 100%; border-collapse: collapse;"><tbody>${statisticTable}</tbody></table></div>`

        return html
      }

      // TODO Harmonize year category labels with years shown on the slider ticks
      // X-Axis -> Set year as category
      this.echartsOption.xAxis.data = this.itemDataYears

      // Set options on the echarts instance
      this.$refs.chart_boxplot.setOption(this.echartsOption)

      this.$refs.chart_boxplot.chart.hideLoading()
    })
  },

  beforeDestroy () {
    this.$refs.chart_boxplot.chart.dispose()
    this.$refs.chart_boxplot.chart = null

    eventBus.$off('loading-indicator-echart-component')
    eventBus.$off('update-echarts-map-component')
    eventBus.$off('load-echarts-boxplot-component')
    eventBus.$off('resize-echarts-chart_boxplot')
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_ags_gen_title'
    ]),

    ...mapActions('dashboard_items', [
      'get_axis_value_interval',
      'get_datasets_ags',
      'get_datasets_value_max',
      'get_datasets_value_min',
      'get_item_data_value_unit',
      'get_max_fraction_digits'
    ]),

    ...mapMutations('dashboard_items', [
      'SET_ITEM_YEAR_SELECTED'
    ]),

    chartsOnClick (params) {
      // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart

      let categoryName

      // this.$refs.chart_boxplot.chart.dispatchAction({
      //   type: 'downplay'
      // })

      if (params.event.target) {
        const pointInPixel = [params.offsetX, params.offsetY]
        const pointInGrid = this.$refs.chart_boxplot.chart.convertFromPixel('grid', pointInPixel)
        categoryName = this.$refs.chart_boxplot.chart.getModel().get('xAxis')[0].data[pointInGrid[0]]
      } else {
        categoryName = params.name
      }

      if (categoryName !== undefined) {
        eventBus.$emit('update-boxplot-map-component', {
          year: Number(categoryName)
        })

        this.SET_ITEM_YEAR_SELECTED({
          year: Number(categoryName)
        })
      }
    }
  }
}
</script>

<style lang="scss">

</style>
