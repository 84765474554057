import axios from 'axios'

export default {
  async get_data ({
    rootState, commit
  }, {
    yearCommissioningMax
  }) {
    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/re_location_data/?year_commissioning_max=${yearCommissioningMax}`)
      .catch(err => console.log('error getGeojsonData ', err)) // TODO Error Handling

    if (response.status === 200) {
      if (response.data.length !== 0) {
        commit('SET_DATA', response.data)
      } else {
        // TODO Error handling
      }
    } else {
      // TODO Error handling
    }
  }
}
