<template>
  <div
    v-if="itemObject.basic_data"
  >
    <!-- <div
      class="text-h5 mb-1 font-weight-medium"
    >
      Kennzahlenbeschreibung
    </div>

    <div
      class="text-body-1 mb-3"
    >
      {{ itemObject.detail_info[0][`info_text_teaser_${locale}`] }}
    </div> -->

    <div
      v-for="description in ordererdDescriptions"
      :key="description.id"
    >
      <div
        class="text-h6 mb-1 font-weight-medium"
      >
        {{ description.question[`text_${locale}`] }}
      </div>

      <div
        class="text-body-1 mb-3"
      >
        {{ description[`text_${locale}`] }}
      </div>
    </div>

    <div
      v-if="itemObject.basic_data.length !== 0"
    >
      <div
        class="text-h6 mb-1 font-weight-medium"
      >
        <span
          v-if="locale === 'de'"
        >
          Datengrundlage der Kennzahl:
        </span>

        <span
          v-else
        >
          Data basis of the indicator:
        </span>
      </div>

      <div
        v-for="(basicDataItem, index) in itemObject.basic_data"
        :key="index"
        class="mb-1 text-body-1"
      >
        <!-- <span
                    class="text-h6 font-weight-medium"
                  >{{ basicDataItem.type[`title_${locale}`] }}
                  </span> -->

        <!-- <span>
                    -
                  </span> -->
        <span>
          {{ basicDataItem.data_source_text }}
        </span>

        <span>
          <span
            v-if="basicDataItem.data_source_url.length !== 0"
          >
            |

            <v-icon
              class="pr-1"
              size="15"
              color="var(--v-base-primary)"
            >
              {{ mdiOpenInNew }}
            </v-icon><a

              :href="basicDataItem.data_source_url"
              target="_blank"
            >Link</a>
          </span>

        </span>

        <span
          v-show="basicDataItem.published_on.length !== 0"
        >
          <span>
            |
          </span>

          <span
            v-if="locale === 'de'"
          >
            Veröffentlichung:
          </span>

          <span
            v-else
          >
            Published:
          </span>

          <span>
            {{ basicDataItem.published_on }}
          </span>
        </span>

        <span
          v-show="basicDataItem.accessed_on.length !== 0"
        >
          <span>
            |
          </span>

          <span
            v-if="locale === 'de'"
          >
            Abgerufen:
          </span>

          <span
            v-else
          >
            Accessed on:
          </span>

          <span>
            {{ basicDataItem.accessed_on }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import _ from 'lodash'

import { mdiOpenInNew } from '@mdi/js'

export default {
  name: 'ItemDescriptionContentComponent',

  props: {
    itemObject: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      mdiOpenInNew: mdiOpenInNew
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ordererdDescriptions () {
      return _.orderBy(
        this.itemObject.detail_description,
        'question.id'
      )
    }
  }
}
</script>
