// AXIOS
import axios from 'axios'

// LODASH
import _ from 'lodash'

// ESRI
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer'
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils'
import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import * as projection from '@arcgis/core/geometry/projection'
import Extent from '@arcgis/core/geometry/Extent'

// ESRI CONFIG
// import config from '@arcgis/core/config'
// config.assetsPath = './assets'

const initWebGis = async ({
  basemap,
  constraints,
  container,
  highlightOptions,
  navigation,
  uiComponents
}) => {
  const spatialReference = new SpatialReference({
    wkid: 3857
  })

  // EXTENT GERMANY - EPSG:3857 - WGS 84 / Pseudo-Mercator
  // 653028.0019073752919212,5986277.1177128124982119 : 1674447.2594843327533454,7373282.0358533067628741

  const extent = new Extent({
    xmin: 653028,
    ymin: 5986277,
    xmax: 1674447,
    ymax: 7373282,
    spatialReference: spatialReference
  })

  await projection.load()

  const map = await createMap({
    basemap
  })

  const mapView = await createMapView({
    constraints,
    container,
    extent,
    highlightOptions,
    map,
    navigation,
    spatialReference,
    uiComponents
  })

  reactiveUtils.whenOnce(
    () => mapView?.ready)
    .then(() => {
      if (map.basemap) {
        if (map.basemap.referenceLayers.items.length !== 0) {
          // TODO Set false only on distinct basemap layers
          map.basemap.referenceLayers.items[0].visible = false
        }
      }
      // this.esriMap.basemap.baseLayers.items[0].visible = false
    })

  return { map, mapView }
}

const createMap = async ({ basemap }) => {
  const newMap = await new Map({
    basemap
  })
  return newMap
}

const createMapView = async ({
  constraints,
  container,
  extent,
  highlightOptions,
  map,
  navigation,
  spatialReference,
  uiComponents,
  zoom
}) => {
  const newMapView = await new MapView({
    constraints,
    container,
    extent,
    highlightOptions,
    map,
    navigation,
    spatialReference,
    zoom
  })

  newMapView.ui.components = uiComponents

  // stops propagation of default behavior when an event fires
  // function stopEvtPropagation (event) {
  //   event.stopPropagation()
  // }

  // TODO Set distinct mapView interaction possibilities
  // e.g. https://developers.arcgis.com/javascript/latest/sample-code/sandbox/?sample=view-disable-navigation

  // disables pinch-zoom and panning on the view
  // newMapView.on('drag', stopEvtPropagation)

  return newMapView
}

const getBaseLayer = async ({ geoJsonFileName }) => {
  // Base paths
  const geoJsonFileFolderPath = 'webgis/geojson/'
  const appRootFolder = process.env.VUE_APP_ROOT_FOLDER ? process.env.VUE_APP_ROOT_FOLDER : ''
  const appPort = process.env.VUE_APP_PORT ? process.env.VUE_APP_PORT : ''
  const baseURL = `${process.env.VUE_APP_HOST_URL}${appPort}/${appRootFolder}`

  // Set complete URL for GeoJson file
  const url = `${baseURL}${geoJsonFileFolderPath}${geoJsonFileName}`

  // Retrieve GeoJson file based on query params and register it to the echarts instance
  const { data } = await axios.get(url)

  return data
}

const createLayer = async ({
  copyright,
  data,
  // fields,
  title
}) => {
  // Create blob of data
  const blob = new Blob([JSON.stringify(data)], {
    type: 'application/json'
  })

  // URL reference to the blob
  const url = URL.createObjectURL(blob)

  // create new geojson layer using the blob url
  const layer = new GeoJSONLayer({
    copyright,
    editingEnabled: true,
    // fields,
    objectIdField: 'id',
    outFields: ['*'],
    spatialReference: {
      wkid: 3857
    },
    title,
    url
  })

  return layer
}

const getGeojsonPointData = async ({ baseURL, itemId, spatialField, yearCommissioningMax }) => {
  const response = await axios.get(`${baseURL}api/v1/eemonitor/re_location_data/?item=${itemId}&spatial_field=${spatialField}&year_commissioning_max=${yearCommissioningMax}`)
    .catch(err => console.log('error getGeojsonPointData ', err)) // TODO Error Handling

  if (response.status === 200) {
    if (response.data.length !== 0) {
      return response.data
    } else {
    // TODO Error handling
    }
  } else {
  // TODO Error handling
  }
}

const getGeojsonPolygonData = async ({ baseURL, itemId, spatialField }) => {
  const response = await axios.get(`${baseURL}api/v1/eemonitor/re_location_data/?item=${itemId}&spatial_field=${spatialField}`)
    .catch(err => console.log('error getGeojsonPolygonData ', err)) // TODO Error Handling

  if (response.status === 200) {
    if (response.data.length !== 0) {
      return response.data
    } else {
    // TODO Error handling
    }
  } else {
  // TODO Error handling
  }
}

export {
  createLayer,
  getBaseLayer,
  getGeojsonPointData,
  getGeojsonPolygonData,
  initWebGis
}
