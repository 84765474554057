<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    style="width: 100%; height: 100%;"
  >
    <HeaderHome
      app
      :image-file-name="'thomas-richter-B09tL5bSQJk-unsplash_2.jpg'"
    />

    <v-container
      id="scroll-target"
      style="max-width: 1200px; width: auto;"
    >
      <div>
        <BreadcrumbNav />
      </div>

      <v-row
        align="start"
        no-gutters
        class="mb-6"
      >
        <v-col>
          <v-card
            flat
            class="fill-height pa-6 "
            rounded="0"
          >
            <v-row
              no-gutters
              class="pb-1 flex-column"
              justify="start"
              align="start"
            >
              <v-col
                class="text-h4 font-weight-medium pb-2"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Datenschutzerklärung
                </span>
                <span
                  v-else
                >
                  Privacy Policy Statement
                </span>
              </v-col>

              <v-col
                class="text-body-1"
              >
                <div
                  class="privacy_policy"
                  v-html="application[`privacy_policy_${locale}`]"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        no-gutters
      >
        <v-col>
          <PrivacyPolicyOptout />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import HeaderHome from '@/components/main/header/HeaderHome.vue'
import BreadcrumbNav from '@/components/main/navigation/BreadcrumbNav.vue'
import PrivacyPolicyOptout from '@/components/base/privacy/PrivacyPolicyOptout.vue'

export default {
  name: 'PrivacyPolicy',

  components: {
    HeaderHome,
    BreadcrumbNav,
    PrivacyPolicyOptout
  },

  computed: {
    ...mapState('main', [
      'application',
      'locale'
    ])
  }
}
</script>

<style lang="scss">
.privacy_policy {
  p {
  margin-bottom: 0 !important;
  }
}
</style>
