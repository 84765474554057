<template>
  <div
    style="height: fit-content;"
  >
    <v-card
      rounded="0"
      elevation="0"
      class="d-flex flex-column justify-space-between pa-3 fill-height"
      style="z-index: 2;"
    >
      <!-- :style="[$vuetify.breakpoint.smAndDown ? {'height': 'auto'} : {}]" -->
      <div
        class="d-flex justify-center align-center text-h4 font-weight-medium text-center pa-3"
        style="height: 20%"
      >
        <span
          v-if="locale==='de'"
        >
          EE-Anlagenstandorte (Web-GIS)
        </span>
        <span
          v-else
        >
          RE plant data (Web-GIS)
        </span>
      </div>

      <div
        class="d-flex justify-center align-center text-h4 text-center pa-3"
        style="height: 20%"
      >
        <span
          v-if="locale==='de'"
        >
          Bitte wählen Sie eine Technologie
        </span>
        <span
          v-else
        >
          Please select a technology
        </span>
      </div>

      <v-row
        class="fill-height mx-3 "
        :class="[$vuetify.breakpoint.mdAndUp ? 'my-6 justify-space-around' : 'justify-space-between']"
      >
        <v-col
          v-for="item in items"
          :key="item.id"
          :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
          class="d-flex justify-center align-center"
        >
          <Transition
            name="fade"
            mode="in-out"
            appear
          >
            <v-btn
              color="primary"
              raised
              style="height: auto; padding: 0; width: 100%; "
              class="d-flex btn-item-select-dialog"
            >
              <v-card
                elevation="2"
                style="height: 100px; background: none;"
                class="d-flex justify-center align-center pa-3"
                :style="[$vuetify.breakpoint.smAndUp ? {'width': '100%', 'height': 'auto'} : {'height': 'auto', 'width': '100%'}]"
                @click="selectItem(item)"
              >
                <v-row
                  dense
                >
                  <v-col
                    class="shrink d-flex align-center"
                    style="height: auto;"
                  >
                    <v-icon
                      color="#ffffff"
                      style="width: auto"
                      class="pa-3"
                      :size="$vuetify.breakpoint.xsOnly ? 30 : 45"
                    >
                      {{ `icon-technology-0` + getItemTechnologyID(item) }}
                    </v-icon>
                  </v-col>
                  <v-col
                    class="grow"
                    style="height: auto;"
                  >
                    <div
                      style="width: 100%"
                      class="d-flex align-center justify-start fill-height white--text text-h5"
                    >
                      <span
                        style="width: auto; text-align: start; text-transform: none;"
                      >
                        {{ item[`title_plural_${locale}`] }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-btn>
          </Transition>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DialogStartWebGisContent',

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'items'
    ])
  },

  methods: {
    ...mapActions('main', [
      'set_dialog_welcome'
    ]),

    ...mapActions('webgis', [
      'set_main_menu_open_state'
    ]),

    getItemTechnologyID (item) {
      return item.categories.filter(cat => cat.type.query_key === 'technology')[0].value.value_id
    },

    selectItem (item) {
      eventBus.$emit('select-webgis-item', item)

      this.set_dialog_welcome(false)

      if (this.$vuetify.breakpoint.mdAndUp !== undefined) {
        if (this.$vuetify.breakpoint.mdAndUp) {
          this.$nextTick(() => {
            this.set_main_menu_open_state(true)
          })
        } else {
          this.set_main_menu_open_state(false)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.btn-item-select-dialog {
   .v-btn__content {
     width: 100% !important;
  }
}

.webgis-dialog {
  z-index: 2 !important;
}
:root {
  --dialog-top: 0px;
  --dialog-bottom: 0px;
  --dialog-height: auto;
}

.v-dialog--fullscreen {
  top: var(--dialog-top);
  bottom: var(--dialog-bottom);
  height: var(--dialog-height);
}

</style>
