import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('main', [
      'locale'
    ])
  },

  methods: {
    getCapacityValueString  (cap, showSymbol, maxDigits = 1) {
      let symbolString

      if (cap >= 1000) {
        const value = cap / 1000

        symbolString = showSymbol ? 'MW' : ''

        return `${value.toLocaleString(this.locale, { minimumFractionDigits: 0, maximumFractionDigits: maxDigits })} ${symbolString}`
      } else {
        symbolString = showSymbol ? 'kW' : ''

        return `${cap.toLocaleString(this.locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} ${symbolString}`
      }
    }
  }
}
