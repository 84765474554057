<template>
  <v-dialog
    v-model="isDialogOpenState"
    scrollable
    transition="dialog-bottom-transition"
    max-width="800"
    style="background: #ffffff"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :style="[$vuetify.breakpoint.smAndDown ? {'z-index': 2000} : {}]"
  >
    <!-- :style="[$vuetify.breakpoint.smAndDown ? {'top': $vuetify.application.top + 'px'} : {}]" -->
    <v-card
      rounded="0"
      elevation="0"
    >
      <v-card-title
        class="pa-0 ma-0"
      >
        <v-toolbar
          dark
          dense
          flat
          color="primary"
          height="36"
        >
          <v-toolbar-title
            class="text-h6 ml-2"
            style="line-height: 2.3;"
          >
            {{ itemDescriptionTitle }}: {{ itemObject[`title_${locale}`] }}
          </v-toolbar-title>

          <v-spacer />

          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="isDialogOpenState = false"
            >
              <v-icon>
                {{ mdiClose }}
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <!-- <v-card-text
        style="color: var(--v-base-primary)"
        class="px-5 py-4 section-scroll"
      > -->
      <ItemDescriptionContent
        :item-object="itemObject"
        class="px-5 py-4 section-scroll"
        style="color: var(--v-base-primary)"
      />
      <!-- </v-card-text> -->

      <v-divider
        v-if="$route.name !== 'Dashboard'"
      />

      <v-card-actions
        v-if="$route.name !== 'Dashboard' && itemObject.has_data"
        class="d-flex flex-row justify-end"
      >
        <v-btn
          v-if="itemObject.has_data"
          outlined
          small
          elevation="0"
          color="var(--v-primary-base)"
          class="mx-3"
          style="text-transform: none; border: dotted 1px var(--v-secondary-lighten1);"
          @click="selectItem(itemObject)"
        >
          <span
            v-if="locale === 'de'"
          >
            <span>
              Kennzahl anzeigen
            </span>
          </span>

          <span
            v-else
          >
            Show indicator
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { eventBus } from '@/utils/eventbus.js'

import { mdiClose } from '@mdi/js'

import ItemDescriptionContent from '@/components/main/dialog/ItemDescriptionContent.vue'

export default {
  name: 'SelectMainDialogComponent',

  components: {
    ItemDescriptionContent
  },

  data () {
    return {
      isDialogOpenState: false,
      itemObject: {},
      mdiClose: mdiClose
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    itemDescriptionTitle () {
      return this.locale === 'de' ? 'Kennzahlbeschreibung' : 'Indicator description'
    },

    dialogHeight () {
      return this.$vuetify.breakpoint.height * 0.8
    }
  },

  watch: {
    isDialogOpenState (newValue, oldValue) {
      if (newValue === false) {
        setTimeout(() => {
          this.itemObject = {}
        }, 600)
      }
    }
  },

  async mounted () {
    eventBus.$on('open-dialog-item-description', async (item) => {
      if (Object.keys(item).length !== 0) {
        this.itemObject = await this.getItem(item)
        this.isDialogOpenState = true
      }
    })
  },

  beforeDestroy () {
    eventBus.$off('open-dialog-item-description')
  },

  methods: {
    ...mapActions('dashboard_items', [
      'get_item'
    ]),

    async getItem (item) {
      const responseItemObject = await this.get_item({
        slug: item.slug_de
      })

      return responseItemObject
    },

    selectItem (itemObject) {
      this.$router.push({
        name: 'Dashboard',
        params: {
          fromRoute: this.$route,
          loadOnlyItem: true,
          slug: itemObject[`slug_${this.locale}`]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
