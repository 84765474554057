<template>
  <!--  -->
  <div
    class="d-flex flex-column fill-height"
  >
    <ChartCardHeader
      :title="chartTitle"
    />
    <div
      style="position: relative;"
      class="d-flex flex-column fill-height"
    >
      <div
        class="d-flex fill-height pa-3"
        :style="`height: ${height - 20}px`"
      >
        <VChart
          ref="chart1"
          renderer="svg"
          :autoresize="true"
          :option="option"
        />

        <Transition
          name="fade"
          mode="in-out"
          appear
        >
          <div
            v-show="isLegendOpen"
            style="width: auto; "
            :style="`height: ${height - 45}px`"
          >
            <LegendDialog
              :ref-chart="$refs.chart1"
              :height="height"
            />
          </div>
        </Transition>
      </div>

      <!-- <BtnOpenLegend
        :ref-echarts="$refs.chart1"
        :is-legend-open="isLegendOpen"
        @legend-state="isLegendOpen =!isLegendOpen"
      /> -->

      <v-btn
        class="btn-select btn-select-category-group-active"
        style="text-transform: none; height: 22px; border-radius: 6px; margin-top: 0;"
        right
        top
        text
        absolute
        @click="openLegend"
      >
        <span
          v-if="!isLegendOpen"
        >
          <span
            v-if="locale==='de'"
          >
            Legende anzeigen
          </span>
          <span
            v-else
          >
            Open Legend
          </span>
        </span>
        <span
          v-else
        >
          <span
            v-if="locale==='de'"
          >
            Legende schließen
          </span>
          <span
            v-else
          >
            Close Legend
          </span>
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
//  IMPORT
import { eventBus } from '@/utils/eventbus.js'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

import _ from 'lodash'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

import {
  // CanvasRenderer,
  SVGRenderer
} from 'echarts/renderers'
import {
  BarChart,
  LineChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
} from 'echarts/components'

// COMPONENTS

import ChartCardHeader from '@/components/dashboard/cardtitle/ChartCardHeader.vue'
import LegendDialog from '@/components/dashboard/legend/LegendDialog.vue'
// import BtnOpenLegend from '@/components/dashboard/button/BtnOpenLegend.vue'

use([
  // CanvasRenderer,
  SVGRenderer,
  BarChart,
  LineChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
])

export default {
  name: 'ChartsLineComponentPage',
  // mixins: [globalFunctions],

  components: {
    VChart,
    ChartCardHeader,
    LegendDialog
    // BtnOpenLegend
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'svg'
      // renderer: 'canvas'
    }
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    height: {
      type: Number,
      default: 1
    },
    spatialTypeId: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      dataValueUnitObj: {},
      isLegendOpen: false,
      agsOfSelectedRegions: [],
      agsIdSelected: '',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      itemDataYears: [],
      chartTitle: '',
      data: [],
      itemDataFiltered: [],
      dataAgs: [],
      dataFiltered: {},
      dataValueUnit: '',
      isValueNaN: false,
      option: {},
      showLegend: false,
      spatialTypeSelected: undefined,
      targetValueColor: 'red', // TODO Needs to be defined
      targetValueYear: 2022,
      series: {
        data: [],
        emphasis: {
          focus: 'series',
          itemStyle: {
            // color: '#003E6E',
            borderColor: '#ffdf33' // borderStyle: 'solid'
          },
          label: {
            show: false
          },
          lineStyle: {
            color: '#ffdf33',
            type: 'solid',
            width: 3
          }
        },
        itemStyle: {
          // color: dataItem => {
          //   return dataItem.color
          // }
        },
        lineStyle: {
          type: 'solid',
          width: 1
        },
        select: {
          disabled: false,
          itemStyle: {
            borderColor: '#000000', // '#ffdf33',
            borderWidth: 2,
            opacity: 0.5
          }
        },
        symbol: 'circle',
        symbolSize: 8,
        // triggerLineEvent: true,
        type: 'line'
      },
      echartsOption: {
        color: [],
        grid: {
          bottom: '14px',
          containLabel: true,
          // height: 'auto',
          left: '10',
          right: '10', // '230',
          top: '42px',
          width: 'auto' // width: 'auto'
        },
        // legend: {
        //   align: 'right',
        //   animation: true,
        //   animationDurationUpdate: 800,
        //   backgroundColor: '#ffffff',
        //   bottom: 10,
        //   icon: 'circle',
        //   lineStyle: {
        //     type: 'solid'
        //   },
        //   orient: 'vertical',
        //   pageIconColor: 'rgb(0, 62, 110, 1)',
        //   pageIconSize: 10,
        //   right: 10,
        //   show: true,
        //   textStyle: {
        //     fontSize: 14,
        //     fontWeight: 400,
        //     // overflow: 'break',
        //     width: 140
        //   },
        //   top: 10,
        //   type: 'scroll',
        //   width: 210
        // },
        series: [],
        // textStyle: {
        //   color: '#003e6e',
        //   fontFamily: 'Roboto',
        //   fontSize: 14,
        //   fontWeight: '400'
        // },
        // title: {
        //   left: 'center',
        //   text: '',
        //   textStyle: {
        //     color: '#003e6e',
        //     fontFamily: 'Roboto',
        //     fontSize: 14,
        //     fontWeight: '400'
        //   }
        // },
        toolbox: {
          feature: {
            saveAsImage: {
              show: true
            }
          },
          show: false
        },
        tooltip: {},
        xAxis: {
          axisLabel: {},
          axisLine: {
            lineStyle: {
              color: '#003e6e'
            },
            show: true
          },
          axisTick: {
            alignWithLabel: true,
            show: true
          },
          splitLine: {
            show: true
          },
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            formatter: '{value}' // axisPointer: {
            //   show: true,
            //   snap: true
            // }
          },
          textStyle: {
            color: '#003e6e',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: '400'
          },
          splitLine: {
            show: true
          },
          type: 'value'
        }
      }
    }
  },
  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems',
      'echartsDefaults'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataYears'
      // 'dashboardItemsYearSelected'
    ])

    // setChartTitle () {
    //   return this.locale === 'de' ? this.spatialTypeSelected === 2 ? 'Alle Bundesländer' : 'Alle Landkreise' : this.spatialTypeSelected === 2 ? 'All federal states' : 'All counties'
    // }
  },

  watch: {
    locale (newVal, oldVal) {
      // Set options with changed toLocaleString(s) and GEN names to selected locale
      if (this.showLegend !== true) {
        this.chartTitle = this.setTitleText(this.spatialTypeSelected)
        this.$refs.chart1.setOption(this.echartsOption)
        eventBus.$emit('open-charts-legend-dialog', this.$refs.chart1)
      }
    },

    height (newVal, oldVal) {
      this.$refs.chart1.resize()
    }
  },

  async mounted () {
    // Get array of distinct years in data
    this.itemDataYears = this.dashboardItemsDataYears

    // Determine if LINE or BAR chart
    if (this.itemDataYears.length < 2) {
      // Bar chart
      this.series.type = 'bar'
      this.series.selectedMode = 'single'
      this.series.itemStyle.opacity = 0.5
      this.series.itemStyle.borderColor = '#003e6e'
      this.series.itemStyle.borderWidth = 2
      this.series.itemStyle.type = 'solid'

      this.echartsOption.xAxis.splitLine.show = false
      this.echartsOption.yAxis.splitLine.show = true
    } else {
      this.series.selectedMode = false
    }

    // Set echarts defauls from store
    // this.echartsOption.series = _.cloneDeep(this.echartsDefaults.map.series)
    this.echartsOption.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.xAxis = _.cloneDeep(this.echartsDefaults.xAxis)
    this.echartsOption.yAxis.axisLabel.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.tooltip = _.cloneDeep(this.echartsDefaults.tooltip)
    this.echartsOption.tooltip.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)

    // Set x-Axis Interval (index:number, value: string) => boolean
    this.echartsOption.xAxis.axisLabel.interval = await this.get_axis_value_interval({ years: this.itemDataYears })
    this.echartsOption.xAxis.axisTick.interval = await this.get_axis_value_interval({ years: this.itemDataYears, interval: 1 })

    // Get value unit title_short (string)
    this.dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.dashboardItem
    })
    this.dataValueUnit = this.dataValueUnitObj.title_short_de

    eventBus.$on('resize-echarts-chart1', (dimensions) => {
      this.$refs.chart1.chart.resize()
    })

    // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart
    // NOTE not working atm
    // this.$refs.chart1.chart.getZr().on('click', params => {
    //   this.chartsOnClick(params)
    // })

    // this.$refs.chart1.chart.getZr().on('mouseover', params => {
    //   console.log('mouseover params ', params)
    //   let data
    //   if (params.event.target) {
    //     const pointInPixel = [params.offsetX, params.offsetY]
    //     const pointInGrid = this.$refs.chart1.chart.convertFromPixel('series', pointInPixel)
    //     console.log('pointInGrid ', pointInGrid)
    //     // data = this.$refs.chart1.chart.getModel().get('xAxis')[0].data[pointInGrid[0]]
    //     data = this.$refs.chart1.chart.getModel().get('series')[pointInGrid[0]]

    //     // data = this.$refs.chart1.chart.getModel()
    //   } else {
    //     data = params.name
    //   }
    //   console.log('data ', data)
    // })

    eventBus.$on('load-echarts-line-1-component', async ({
      agsIdSelected,
      agsIdSelectedArray,
      showLegend,
      spatialTypeSelected
    }) => {
      this.agsIdSelected = agsIdSelected

      // console.log('line agsIdSelected ', agsIdSelected)
      // console.log('line agsIdSelectedArray ', agsIdSelectedArray)
      // console.log('line showLegend ', showLegend)
      // console.log('line spatialTypeSelected ', spatialTypeSelected)

      // this.isValueNaN = isValueNaN
      this.showLegend = showLegend
      this.spatialTypeSelected = spatialTypeSelected

      const agsIdSelectedArrayClone = _.cloneDeep(agsIdSelectedArray)

      if (this.series.type !== 'bar') {
        agsIdSelectedArrayClone[0] = '00000000'
      }

      if (spatialTypeSelected === 4 &&
      agsIdSelected !== undefined) {
        if (this.series.type !== 'bar') {
          agsIdSelectedArrayClone[1] = agsIdSelected.substr(0, 2)
        }
      }

      if (spatialTypeSelected === 2) {
        // NOTE SPATIAL_TYPE / ADE === 2 --> FEDERAL STATES

        if (agsIdSelected !== undefined) {
          // NOTE region (federal state) selected --> ags ID

          if (this.series.type === 'line') {
            // NOTE if LINE CHART

            this.agsOfSelectedRegions = agsIdSelectedArrayClone.filter(region => region)
            this.itemDataFiltered = this.agsOfSelectedRegions.flatMap((ags) => {
              const data = this.itemData.filter(ds => ds.ags === ags)
              return data
            })
          } else {
            // NOTE if BAR CHART

            // Only highlight/emphasis selected region
            // TODO needs refactoring

            const agsTitle = await this.get_ags_gen_title({
              ags: agsIdSelected
            })

            this.$refs.chart1.chart.dispatchAction({
              type: 'select',
              seriesName: agsTitle
            })

            // console.log('series, agsTitle', this.echartsOption.series, agsTitle)

            // eventBus.$emit(`highlight-charts-series`, agsIdSelected)

            // setTimeout(async () => {
            //   const agsTitle = await this.get_ags_gen_title({
            //     ags: agsIdSelected
            //   })

            //   this.$refs.chart1.chart.dispatchAction({
            //     type: 'downplay',
            //     seriesName: agsTitle
            //   })
            // }, 2000)
          }
        } else {
          // NOTE NO REGION SELECTED (federal state)

          this.itemDataFiltered = this.itemData.filter(ds => ds.spatial_type === 2)

          this.agsOfSelectedRegions = this.bkgItems.filter(item => item.ade === 2).flatMap(item => item.ags)
        }
      } else if (spatialTypeSelected === 4) {
        // NOTE SPATIAL_TYPE / ADE === 4 --> COUNTIES

        if (agsIdSelectedArrayClone[1] !== undefined) {
          if (agsIdSelected !== undefined &&
          agsIdSelected.length === 5) {
            if (this.series.type === 'bar') {
              eventBus.$emit('highlight-charts-series', agsIdSelected)

              setTimeout(async () => {
                const agsTitle = await this.get_ags_gen_title({
                  ags: agsIdSelected
                })

                this.$refs.chart1.chart.dispatchAction({
                  type: 'downplay',
                  seriesName: agsTitle
                })
              }, 2000)
            } else {
              this.agsOfSelectedRegions = agsIdSelectedArrayClone.filter(region => region)

              this.itemDataFiltered = this.agsOfSelectedRegions.flatMap((ags) => {
                const data = this.itemData.filter(ds => ds.ags === ags)
                return data
              })
            }
          } else {
            if (this.itemDataYears.length > 1) {
              this.itemDataFiltered = this.itemData.filter(ds => ds.ags.startsWith(agsIdSelectedArrayClone[1]))

              const bkgItemsFiltered = this.bkgItems.filter(item => item.ags.startsWith(agsIdSelectedArrayClone[1]))

              this.agsOfSelectedRegions = bkgItemsFiltered.flatMap(item => item.ags)
            } else {
              this.itemDataFiltered = this.itemData.filter(ds => ds.spatial_type === 4 && ds.ags.startsWith(agsIdSelectedArrayClone[1]))

              const bkgItemsFiltered = this.bkgItems.filter(item => item.ade === 4 && item.ags.startsWith(agsIdSelectedArrayClone[1]))

              this.agsOfSelectedRegions = bkgItemsFiltered.flatMap(item => item.ags)
            }
          }
        } else {
          if (agsIdSelected !== undefined &&
          agsIdSelected.length === 5) {
            if (this.series.type !== 'bar') {
              this.agsOfSelectedRegions = agsIdSelectedArrayClone.filter(region => region)

              this.itemDataFiltered = this.agsOfSelectedRegions.flatMap((ags) => {
                const data = this.itemData.filter(ds => ds.ags === ags)
                return data
              })
            } else {
              this.itemDataFiltered = this.itemData.filter(ds => ds.spatial_type === 4 && ds.ags.startsWith(agsIdSelectedArrayClone[3].substr(0, 2)))

              const bkgItemsFiltered = this.bkgItems.filter(item => item.ade === 4 && item.ags.startsWith(agsIdSelectedArrayClone[3].substr(0, 2)))

              this.agsOfSelectedRegions = bkgItemsFiltered.flatMap(item => item.ags)

              eventBus.$emit('highlight-charts-series', agsIdSelected)
            }
          } else {
            this.itemDataFiltered = this.itemData.filter(ds => ds.spatial_type === 2)

            const bkgItemsFiltered = this.bkgItems.filter(item => item.ade === 2)

            this.agsOfSelectedRegions = bkgItemsFiltered.flatMap(item => item.ags)
          }
        }
      }

      if (spatialTypeSelected === 2) {
        if (agsIdSelectedArray.some(reg => reg !== undefined)) {
          if (this.series.type !== 'bar') {
            const titles = await Promise.all(this.agsOfSelectedRegions.map(async (ags) => {
              const title = await this.get_ags_gen_title({
                ags: ags
              })
              return title
            }))
            this.chartTitle = titles.join(', ')
          }
        } else {
          this.chartTitle = this.setTitleText(spatialTypeSelected)
        }
      } else {
        if (spatialTypeSelected === 4) {
          if (agsIdSelectedArray.every(reg => reg === undefined)) {
            this.chartTitle = this.setTitleText(spatialTypeSelected)
          } else if (agsIdSelectedArray[3] !== undefined) {
            if (this.series.type !== 'bar') {
              const titles = await Promise.all(this.agsOfSelectedRegions.map(async (ags) => {
                const title = await this.get_ags_gen_title({
                  ags: ags
                })
                return title
              }))
              this.chartTitle = titles.join(', ')
            } else {
              this.chartTitle = this.setTitleText(spatialTypeSelected)
            }
          } else {
            this.chartTitle = this.setTitleText(spatialTypeSelected)
          }
        }
      }

      // Check if all values are Null
      const isValueNaN = this.checkIfValueNaN(this.itemDataFiltered)

      // Get highest value in dataset
      const valueMax = await this.get_datasets_value_max(this.itemDataFiltered)

      if (isValueNaN === false) {
        this.echartsOption.yAxis.show = true

        this.echartsOption.yAxis.min = 0

        this.echartsOption.yAxis.max = valueMax
      } else {
        this.echartsOption.yAxis.show = false
      }

      // NOTE workaround for values with "n"
      this.echartsOption.yAxis.max = valueMax + 0.0001

      if (this.dataValueUnitObj.title_short_de.match(/^n$/)) {
        this.echartsOption.yAxis.minInterval = 1
      }

      // this.dataAgs = await this.get_datasets_ags(this.itemDataFiltered)

      // Set colors and highlight color at array position of selected dataset
      this.echartsOption.color = _.cloneDeep(this.echartsDefaults.color)
      if (this.itemDataYears.length > 1) {
        if (this.spatialTypeId === 2) {
          this.echartsOption.color[1] = '#ffdf33'
        } else if (this.spatialTypeId === 4) {
          this.echartsOption.color[2] = '#ffdf33'
        }
      } else {
        if (this.itemDataYears.length > 1) {
          this.echartsOption.color[1] = '#003e6e'
        }
      }

      // Create series datasets
      const series = []
      for (const agsId of this.agsOfSelectedRegions) {
        const agsDatasets = this.itemData.filter(ds => {
          return ds.ags === agsId
        })

        // console.log('agsDatasets ', agsDatasets)

        const dataValues = agsDatasets.map((ds) => {
          // if ( this.itemDataYears.length < 2) {
          //   // Bar chart
          //   if (agsId === '00000000') {
          //     return 0
          //   }
          // }

          if (ds.data_value === null) {
            return 0 // ds.data_value // 0
          } else {
            return ds.data_value
          }
        })

        // console.log('dataValues ', dataValues)

        // NOTE Deep clone is needed for resolving inheritance issues
        const seriesObj = _.cloneDeep(this.series)

        // Set data array of data series
        seriesObj.data = dataValues
        // seriesObj.itemStyle.color = '#333' // NOTE Just for testing

        // Set name of data series (-> BKG (BEZ +) GEN)
        seriesObj.name = await this.get_ags_gen_title({
          ags: agsId
        })

        if (this.dataAgs.length > 1) {
          if (agsId === '00000000') {
            seriesObj.lineStyle = {
              color: '#5470C6',
              width: 2,
              type: 'dashed'
            }
          }
        }

        // if (agsId !== '00000000' && agsId !== agsIdSelected) {
        //   seriesObj.lineStyle = {
        //     // color: '#5470C6',
        //     width: 2,
        //     type: 'dotted'
        //   }
        // }
        // FIXME Color does not match color palette (Legend) if spatial_type = 2

        if (agsId === agsIdSelected && this.itemDataYears.length > 1) {
          seriesObj.lineStyle = {
            color: '#ffdf33',
            width: 4,
            type: 'solid'
          }
          seriesObj.itemStyle = {
            color: '#ffdf33' // '#003E6E'
            // width: 4,
            // type: 'solid'
          }
        }

        series.push(seriesObj)
      }

      // Order created data and set it on the series data attribute
      this.echartsOption.series = _.orderBy(series, 'name')

      // TODO Callback function for number formating
      // https://echarts.apache.org/en/option.html#yAxis.axisLabel.formatter

      // Set number of min & max digits for the number of value descendants
      if (!this.dataValueUnit.match(/^n$/)) {
        const minValue = await this.get_datasets_value_min(this.itemDataFiltered)
        this.maximumFractionDigits = await this.get_max_fraction_digits({ minValue })
      }

      if (this.dataValueUnit.match(/^%$/)) {
        this.echartsOption.yAxis.max = 100
      }

      this.echartsOption.yAxis.name = this.dataValueUnit
      this.echartsOption.yAxis.axisLabel.formatter = (value) => `${value.toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })}`

      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        const title = `<div class="text-h6">
                      ${params.seriesName} (${params.name})
                      </div>`

        if (isNaN(params.value)) {
          return `${title}<div>${this.locale === 'de' ? 'kein Wert' : 'no value'}</div>`
        } else {
          return `${title}
          <div>${params.value.toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })}
          ${this.dataValueUnit}</div>`
        }
      }

      // let tooltipDisplay = ''
      // this.$refs.chart1.chart.on('mouseover', (params) => {
      //   console.log(params)
      //   // Check if it's hovering a line
      //   if (params.componentSubType === 'line') {
      //     // get hovered line series name
      //     tooltipDisplay = `${params.seriesName}<br> ${params.value} ${this.dataValueUnit}`
      //   }
      // })

      // // Called when your mouse leaves an object (params : the object you leave)
      // this.$refs.chart1.chart.on('mouseout', (params) => {
      //   tooltipDisplay = ''
      // })
      // function formatter (params) {
      //   // console.log(params)
      //   return tooltipDisplay
      // }
      // this.echartsOption.tooltip.formatter = formatter

      // X-axis -> Set year as category
      this.echartsOption.xAxis.data = this.dashboardItemsDataYears
      // this.echartsOption.xAxis.min = -1
      // this.echartsOption.xAxis.max = this.dashboardItemsDataYears.length

      // Set options on the echarts instance
      this.$refs.chart1.setOption(this.echartsOption, true)
      this.$refs.chart1.resize()

      eventBus.$emit('open-charts-legend-dialog', this.$refs.chart1)
    })

    let agsTitleSelected

    if (this.series.type === 'line') {
      eventBus.$on('highlight-charts-series', (
        agsId
      ) => {
        this.$refs.chart1.chart.dispatchAction({
          type: 'downplay',
          seriesName: agsTitleSelected
        })

        setTimeout(async () => {
          if (agsId !== undefined) {
            // if (this.bkgItems.length !== 0 && this.bkgItems !== undefined) {
            const agsTitle = await this.get_ags_gen_title({
              ags: agsId
            })

            this.$refs.chart1.chart.dispatchAction({
              type: 'highlight',
              seriesName: agsTitle
            })

            agsTitleSelected = agsTitle

          // }
          } else {
            this.$refs.chart1.chart.dispatchAction({
              type: 'downplay',
              seriesName: agsTitleSelected
            })
          }
        }, 10)
      })
    }
  },

  beforeDestroy () {
    this.$refs.chart1.chart.dispose()
    this.$refs.chart1.chart = null

    eventBus.$off('highlight-charts-series')
    eventBus.$off('load-echarts-line-1-component')
    eventBus.$off('resize-echarts-chart1')
    eventBus.$off('set-locale')

    // this.$eventHub.off('emit-set-echarts-barchart-data')
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_ags_gen_title'
    ]),

    ...mapActions('dashboard_items', [
      'get_axis_value_interval',
      'get_datasets_ags',
      'get_datasets_value_max',
      'get_datasets_value_min',
      'get_datasets_years',
      'get_max_fraction_digits',
      'get_item_data_value_unit'
    ]),

    ...mapMutations('dashboard_items', [
      'SET_ITEM_YEAR_SELECTED'
    ]),

    openLegend () {
      this.isLegendOpen = !this.isLegendOpen
      const chartWidth = this.$refs.chart1.$el.clientWidth
      // console.log('this.$refs.chart1', this.$refs.chart1.getOption())

      eventBus.$emit('open-charts-legend-dialog', this.$refs.chart1)

      if (this.isLegendOpen === true) {
        this.$refs.chart1.resize({
          width: chartWidth - 200,
          bottom: '8px',
          containLabel: true,
          left: '10',
          // right: '10',
          top: '42px'
        })
      } else {
        this.$refs.chart1.resize({ width: 'auto' })
      }
    },

    setTitleText (spatialTypeSelected) {
      if (this.locale === 'de') {
        if (spatialTypeSelected === 2) {
          return 'Alle Bundesländer'
        } else {
          if (this.agsIdSelected === undefined) {
            return 'Alle Bundesländer'
          } else {
            return 'Alle Landkreise'
          }
        }
      } else {
        if (spatialTypeSelected === 2) {
          return 'All federal states'
        } else {
          if (this.agsIdSelected === undefined) {
            return 'All federal states'
          } else {
            return 'All counties'
          }
        }
      }
    },

    checkIfValueNaN (datasets) {
      const datasetNullValues = datasets.filter(ds => ds.data_value === null)
      return datasetNullValues.length === datasets.length
    }

    // chartsOnClick (params) {
    //   // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart

    //   let categoryName

    //   this.$refs.chart1.chart.dispatchAction({
    //     type: 'downplay'
    //   })

    //   if (params.event.target) {
    //     const pointInPixel = [params.offsetX, params.offsetY]
    //     const pointInGrid = this.$refs.chart1.chart.convertFromPixel('grid', pointInPixel)
    //     categoryName = this.$refs.chart1.chart.getModel().get('xAxis')[0].data[pointInGrid[0]]
    //   } else {
    //     categoryName = params.name
    //   }

    //   if (categoryName !== undefined) {
    //     eventBus.$emit(`update-echarts-map-component`, {
    //       year: Number(categoryName)
    //     })

    //     this.SET_ITEM_YEAR_SELECTED({
    //       year: Number(categoryName)
    //     })
    //   }
    // }
  }
}
</script>

<style lang="scss">

</style>
