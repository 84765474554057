<template>
  <div>
    <div
      v-if="itemSelectsOptionalDropdown.length !== 0"
      class="d-flex flex-column px-3 py-2"
      style="background: rgb(225, 232, 238);"
    >
      <div
        v-for="(select, index) in itemSelectsOptionalDropdown"
        :key="index"
      >
        <div
          class="d-flex flex-row"
        >
          <div
            class="flex-grow-1"
            style="min-width: 0;"
          >
            <!-- :label="select.type[`title_${locale}`]" -->
            <v-select
              :ref="`ref_select_${select.type.query_key}`"
              :clearable="select.values.length !== 1"
              :disabled="isMenuDisabled || select.values.length === 1"
              :item-text="select.type.select_item_text_field"
              :item-value="select.type.query_field"
              :items="selectSortValues({
                values: select.values,
                sortValues: select.type.sort_alphabetically
              })"
              :value="selectValue[index]"
              :clear-icon="mdiCloseCircleOutline"
              class="text-truncate webgis-select webgis-select-options"
              return-object
              @change="selectUpdated({
                event: $event,
                selectValue: select, index
              })"
              @click:clear="removeFocus()"
            >
              <template
                #selection="data"
              >
                <div
                  v-if="locale"
                  class="d-inline-flex align-end"
                  style="max-width: 95%; overflow: hidden; white-space: normal; margin-top: 4px;"
                >
                  <span
                    class="text-body-1 font-weight-medium"
                  >
                    {{ data.item[`title_${locale}`] }}
                  </span>
                </div>
              </template>

              <template
                #item="data"
              >
                <span
                  v-if="locale"
                  class="text-body-1"
                >
                  {{ data.item[`title_${locale}`] }}
                </span>
              </template>

              <template
                #label
              >
                <span
                  class="text-body-1"
                >
                  {{ select.type[`title_${locale}`] }}
                </span>
                <!-- <v-tooltip
                  color="rgb(225, 232, 238);"
                  left
                  max-width="300"
                  content-class="webgis-select-info-tooltip"
                >
                  <template
                    #activator="{ on }"
                  >
                    <span
                      class="text-body-1"
                      v-on="on"
                    >
                      {{ select.type[`title_${locale}`] }}
                    </span>
                  </template>
                  <div>
                    {{ select.type[`title_${locale}`] }}
                  </div>
                </v-tooltip> -->
              </template>

              <!-- <template
                #prepend
              >
                <v-tooltip
                  color="rgb(225, 232, 238);"
                  left
                  max-width="300"
                  content-class="webgis-select-info-tooltip"
                >
                  <template
                    #activator="{ on }"
                  >
                    <span
                      class="text-body-1"
                      v-on="on"
                    >
                      <v-icon
                        class="info-tooltip-icon"
                        style="width: 100%; height: 100%;"
                      >
                        {{ mdiInformationVariantCircle }}
                      </v-icon>
                    </span>
                  </template>
                  <div>
                    {{ select.type[`title_${locale}`] }}
                  </div>
                </v-tooltip>
              </template> -->
            </v-select>
          </div>

          <!-- <div
            class="d-flex flex-row align-center justify-center"
          >
            <span>

              <v-tooltip
                color="rgb(225, 232, 238);"
                left
                max-width="300"
                content-class="webgis-select-info-tooltip"
              >
                <template
                  #activator="{ on }"
                >
                  <span
                    class="text-body-1"
                    v-on="on"
                  >
                    <div>
                      <div
                        style="border-left: 1px dotted var(--v-primary-base) !important;  padding: 6px 0px 6px 10px; margin-left: 12px;"
                      >
                        <v-icon
                          class="info-tooltip-icon"
                          style="width: 100%; height: 100%;"
                        >
                          {{ mdiInformationVariantCircle }}
                        </v-icon>
                      </div>
                    </div>

                  </span>
                </template>
                <div>

                  {{ select.type[`title_${locale}`] }}
                </div>
              </v-tooltip>
            </span>
          </div> -->
        </div>

        <!-- SELECTs - ADDITIONAL DATA OPTION -->

        <div
          v-if="selectValue !== undefined &&
            selectValue[index] !== null &&
            selectValue[index] !== undefined &&
            selectValue[index].data_option_sub_class.length !== 0"
          class="webgis-select"
        >
          <div
            :key="`key-${selectValue[index].value_id}`"
          >
            <div
              v-for="(select_sc, index_sc) in selectValue[index].data_option_sub_class"
              :key="index_sc"
            >
              <SelectAdditional
                :select="select_sc"
                @update-data="selectUpdated"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

import _ from 'lodash'

import { mdiInformationVariantCircle, mdiCloseCircleOutline } from '@mdi/js'

import SelectAdditional from '@/components/apps/re_plant_locations/webgis/menus/SelectAdditional.vue'

export default {
  name: 'SelectOptions',

  components: {
    SelectAdditional
  },

  data () {
    return {
      selectValue: [],
      firstInput: false,
      mdiCloseCircleOutline: mdiCloseCircleOutline,
      mdiInformationVariantCircle: mdiInformationVariantCircle,
      selectByUser: false,
      selectQueryKey: ''
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'isMenuDisabled',
      'itemDataQuery',
      'itemSelectsBasic',
      'itemSelectsOptional'
    ]),

    itemSelectsOptionalDropdown () {
      return this.itemSelectsOptional.length !== 0 ? this.itemSelectsOptional.filter(select => select.type.option_type === 'optional' && select.type.select_type !== 'select-slider-range' && select.type.select_type !== 'select-slider' && select.type.is_subclass === false) : []
    }
  },

  watch: {
    // locale: {
    //   handler (newVal, oldVal) {
    //     this.$forceUpdate()
    //   },
    //   immediate: true
    // },

    itemSelectsOptionalDropdown: {
      // NOTE Workaround for setting default_value because select can not have v-model and :value at the same time.
      handler (newVal, oldVal) {
        if (newVal.length !== 0) {
          const filteredValue = newVal.filter(select => select.values.length === 1)
          if (filteredValue.length !== 0) {
            const filteredValueIndex = newVal.indexOf(filteredValue[0])
            this.selectValue[filteredValueIndex] = filteredValue[0].value_default
          } else {
            this.selectValue = []
          }
        }
      },
      immediate: true
    },

    itemDataQuery: {
      handler (newItemDataQuery) {
        if (newItemDataQuery !== undefined) {
          if (this.itemSelectsOptionalDropdown.length !== 0) {
            // console.log('newItemDataQuery ', newItemDataQuery)

            this.itemSelectsOptionalDropdown.forEach((select, index, array) => {
              const selectQueryKey = select.type.query_key
              // console.log('selectQueryKey', selectQueryKey)

              // console.log('select ', select)

              for (const [key, value] of Object.entries(newItemDataQuery)) {
                // console.log('key, value ', key, value)
                if (key === selectQueryKey) {
                  // console.log('key ===selectQueryKey ', key === selectQueryKey)
                  if (value !== null) {
                    const updatedSelectValue = select.values.filter(val => val.value_id === value)[0]
                    this.selectValue[index] = updatedSelectValue

                    // console.log('this.selectValue ', this.selectValue)
                  } else {
                    this.selectValue[index] = null
                  }
                }
              }
            })
            this.selectByUser = true
          }
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    ...mapActions('webgis', [
      'set_menu_disabled',
      'update_data_query'
    ]),

    selectSortValues ({ values, sortValues }) {
      if (sortValues === true) {
        return values === null ? [] : _.orderBy(values, [`title_${this.locale}`, ['asc']])
      } else {
        return values === null ? [] : values
      }
    },

    selectUpdated ({ event, selectValue, index }) {
      this.selectQueryKey = selectValue.type.query_key

      if (this.selectByUser !== false) {
        // console.log('selectUpdated event, selectValue, index ', event, selectValue, index)

        if (Object.keys(selectValue).length !== 0) {
          this.set_menu_disabled(true)

          const queryKey = selectValue.type.query_key
          const eventValue = event === null ? null : event[selectValue.type.query_field]

          this.update_data_query({
            query: {
              [queryKey]: eventValue
            }
          })

          eventBus.$emit('webgis-menu-select-update', { queryState: this.itemDataQuery, event })
        }

        this.removeFocus()
      }
    },

    removeFocus () {
      // const filteredRef = Object.keys(this.$refs).filter((ref) => {
      //   if (ref.includes(`ref_select_${this.selectQueryKey}`)) {
      //     return true
      //   }
      //   return false
      // }).map((val) => { return this.$refs[val] })[0]
      // if (filteredRef !== undefined) {
      //   filteredRef[0].blur()
      // }

      const keys = []
      for (const [key, value] of Object.entries(this.$refs)) {
        if (new RegExp(String.raw`ref_select_${this.selectQueryKey}`).test(key)) {
          keys.push(value)
        }
      }
      keys.forEach(key => key[0].blur())
    }
  }
}
</script>

<style lang="scss">
.webgis-select {
  .v-label.v-label--active,
  .v-label.v-label--is-disable {
    color: rgba(0, 0, 0, 0.8);
  }
}
</style>
