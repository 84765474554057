<template>
  <v-row
    no-gutters
  >
    <v-col
      v-if="item !== undefined"
    >
      <v-card
        class="fill-height"
      >
        <v-toolbar
          dark
          dense
          flat
          color="primary"
          height="36"
        >
          <v-toolbar-title
            class="text-h6 ml-3"
          >
            {{ item[`title_${locale}`] }}
          </v-toolbar-title>

          <v-spacer />

          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="$emit('close-dialog')"
            >
              <v-icon>
                {{ mdiClose }}
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container
          fluid
        >
          <v-row
            class="pa-3"
          >
            <v-col>
              {{ item[`info_text_${locale}`] }}
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import { mdiClose } from '@mdi/js'

export default {
  name: 'ChartsHeatmapDialogComponent',

  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      mdiClose: mdiClose
    }
  },

  computed: {
    ...mapState('main', ['locale'])
  }
}
</script>

<style lang="scss" scoped>

</style>
