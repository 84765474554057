<template>
  <div
    class="d-flex flex-column px-3 py-1"
    style="background: rgb(225, 232, 238);"
  >
    <div
      class="d-flex justify-space-between align-center"
    >
      <div
        :style="[idCount === 0? {'color': 'rgba(0, 0, 0, 0.38)'} : {}]"
        style="margin-bottom: 1px;"
        class="text-body-1"
      >
        <span
          v-if="locale==='de'"
        >
          Installierte Leistung:
        </span>
        <span
          v-else
        >
          Installed capacity:
        </span>
      </div>
      <div
        class="d-flex justify-space-between align-center text-body-1 font-weight-medium"
      >
        <!-- v-if="isValueAvailable && idCount !== 0" -->
        <div
          v-if="isValueAvailable"
        >
          <!-- v-if="idCount > 1" -->
          <div>
            <span>
              {{ getCapacityValueString(itemCapacityValue[0], false) }}
            <!-- {{ itemCapacityValue[0].toLocaleString(locale) }} -->
            </span>
            <span
              v-if="itemCapacityValue[0] !== 0 &&
                String(itemCapacityValue[1]).length >= 4 &&
                String(itemCapacityValue[0]).length < 4"
            >
              kW
            </span>
            <span>
              &nbsp;-&nbsp;
            </span>
            <span>
              {{ getCapacityValueString(itemCapacityValue[1], false) }}
            <!-- {{ itemCapacityValue[1].toLocaleString(locale) }} -->
            </span>
            <span
              v-if="String(itemCapacityValue[1]).length < 4"
            >
              kW
            </span>
            <span
              v-else
            >
              MW
            </span>
          </div>

          <!-- v-else-if="idCount = 1" -->
          <!-- <div
          >
            {{ layerQueryValuesMinMaxLocal.capacity_installed_max }}
            <span
              v-if="String(layerQueryValuesMinMaxLocal.capacity_installed_max).length < 4"
            >
              kW
            </span>
            <span
              v-else
            >
              MW
            </span>
          </div> -->
        </div>

        <!-- <div
          v-else
        >
          <span
            class="text-body-1 font-weight-medium"
          >
            -
          </span>
        </div> -->
      </div>
    </div>

    <div>
      <!--  || idCount <= 1 -->

      <v-range-slider
        v-model="itemCapacityValue"
        :disabled="isMenuDisabled ||
          !isValueAvailable ||
          (itemCapacityMinMaxValue[1] - itemCapacityMinMaxValue[0]) === 100 ||
          layerQueryValuesMinMaxLocal.capacity_installed_max ===
          layerQueryValuesMinMaxLocal.capacity_installed_min"
        :max="itemCapacityMinMaxValue[1]"
        :min="itemCapacityMinMaxValue[0]"
        :step="rangeSliderSteps"
        :tick-size="2"
        :ticks="true"
        hide-details
        class="align-center"
        @change="setRangeSliderCapacity"
      />
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

// MIXINS
import mixinWebgis from '@/mixins/webgis.js'

// OTHER IMPORTS
import _ from 'lodash'

export default {
  name: 'SliderCapacityInstalled',

  mixins: [
    mixinWebgis
  ],

  data () {
    return {
      capacityOldValue: null,
      idCount: 0,
      itemCapacityValue: [0, 0],
      isValueAvailable: false,
      itemCapacityMinMaxValue: [0, 0],
      rangeSliderSteps: 100,
      layerQueryValuesMinMaxLocal: [0, 0]
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'isMenuDisabled',
      'itemCapacityMinMax',
      'itemDataQuery',
      'itemSelected',
      'layerQueryValuesMinMax',
      'layerStatisticValues'
    ])

    // rangeSliderSteps () {
    //   return (
    //     this.itemCapacityMinMaxValue[1] - this.itemCapacityMinMaxValue[0]) <= 1000
    //     ? 50
    //     : (this.itemCapacityMinMaxValue[1] - this.itemCapacityMinMaxValue[0]) <= 10000
    //         ? 100
    //         : (this.itemCapacityMinMaxValue[1] - this.itemCapacityMinMaxValue[0]) <= 20000
    //             ? 200
    //             : (this.itemCapacityMinMaxValue[1] - this.itemCapacityMinMaxValue[0]) >= 50000
    //                 ? 1000
    //                 : 500
    // }
  },

  watch: {
    // itemCapacityMinMax: {
    //   handler (newVal) {
    //     if (newVal !== null) {
    //       // newVal = [newVal.min, newVal.max]

    //     }
    //   },
    //   immediate: true
    // },

    'layerStatisticValues.id_count': {
      handler (newVal, oldVal) {
        if (newVal !== null) {
          this.idCount = newVal
        }
      },
      immediate: true
    },

    layerQueryValuesMinMax: {
      handler (newVal) {
        if (newVal !== null && newVal !== undefined) {
          if (newVal.capacity_installed_min !== null &&
        newVal.capacity_installed_max !== null) {
            // console.log('newVal.capacity_installed_max 1 ', newVal.capacity_installed_max)
            this.layerQueryValuesMinMaxLocal = newVal

            this.rangeSliderSteps = (
              newVal.capacity_installed_max - newVal.capacity_installed_min) <= 1000
              ? 50
              : (newVal.capacity_installed_max - newVal.capacity_installed_min) <= 10000
                  ? 100
                  : (newVal.capacity_installed_max - newVal.capacity_installed_min) <= 20000
                      ? 200
                      : (newVal.capacity_installed_max - newVal.capacity_installed_min) <= 50000
                          ? 500
                          : 1000

            this.itemCapacityMinMaxValue =
          [
            Math.floor(newVal.capacity_installed_min / this.rangeSliderSteps) * this.rangeSliderSteps,
            Math.ceil(newVal.capacity_installed_max / this.rangeSliderSteps) * this.rangeSliderSteps
          ]
            this.updateItemCapacityValue([newVal.capacity_installed_min, newVal.capacity_installed_max])
          }
        }
      },
      immediate: true,
      deep: true
    },

    'itemDataQuery.capacity_installed': {
      handler (newVal, oldVal) {
        if (newVal !== null && newVal !== undefined) {
          if (newVal.every(val => val !== null)) {
            this.isValueAvailable = true
            // this.itemCapacityValue = [
            //   Math.floor(newVal[0] / this.rangeSliderSteps) * this.rangeSliderSteps,
            //   Math.ceil(newVal[1] / this.rangeSliderSteps) * this.rangeSliderSteps
            // ]
          } else {
            this.isValueAvailable = false
            this.itemCapacityValue = [0, 0]
          }
        }
      },
      immediate: true,
      deep: true
    }

    // itemSelected: {
    //   handler (newVal, oldVal) {
    //     if (newVal !== undefined) {
    //       this.valueUnitObj = this.get_item_data_value_unit({ item: this.item })
    //     }
    //   },
    //   immediate: true
    // }
  },

  updated () {
    if (this.itemDataQuery.capacity_installed !== null && this.capacityOldValue === null) {
      this.capacityOldValue = this.itemDataQuery.capacity_installed
    }
  },

  beforeDestroy () {
    eventBus.$off('webgis-reset-local-state')
  },

  mounted () {
    eventBus.$on('webgis-reset-local-state', () => {
      this.capacityOldValue = null
    })
  },

  methods: {
    ...mapActions('webgis', [
      'set_menu_disabled',
      'update_data_query'
    ]),

    ...mapActions('dashboard_items', [
      'get_item_data_value_unit'
    ]),

    updateItemCapacityValue (newVal) {
      // console.log('this.rangeSliderSteps', this.rangeSliderSteps)
      this.itemCapacityValue = [
        Math.floor(newVal[0] / this.rangeSliderSteps) * this.rangeSliderSteps,
        Math.ceil(newVal[1] / this.rangeSliderSteps) * this.rangeSliderSteps
      ]
      // console.log(' this.itemCapacityValue', this.itemCapacityValue)
    },

    setRangeSliderCapacity (value) {
      // console.log('setRangeSliderCapacity ', value)

      if (JSON.stringify(this.capacityOldValue) !== JSON.stringify(value)) {
        this.set_menu_disabled(true)

        this.update_data_query({
          query: {
            capacity_installed: value
          }
        })

        eventBus.$emit('webgis-menu-slider-capacity-update', this.itemDataQuery)

        this.capacityOldValue = value
      }
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
