
export default {
  // SET_STATE_OPTION_SIDEBAR (state, e) {
  //   state.isOptionSidebarOpen = e
  // },
  SET_BKG_ITEMS (state, {
    items
  }) {
    state.bkgItems = items
  },
  SET_CORINE_ITEMS (state, {
    items
  }) {
    state.corineItems = items
  }
}
