import Vue from 'vue'
import VueRouter from 'vue-router'

// VIEWS
import ReportView from '@/views/ReportView.vue'
import DashboardView from '@/views/DashboardView.vue'
import EEMonReportView from '@/views/EEMonReportView.vue'
import EEMonitorHomeView from '@/views/EEMonitorHomeView.vue'
import RePlantLocationView from '@/views/RePlantLocationView.vue'

// VIEWS TEMPORARLY
// import LoginPageView from '@/views/LoginPageView'

// HOME
import EEMonitorAboutView from '@/views/EEMonitorAboutView.vue'

// CHILDREN EEMonReport
import EEMonReportAbout from '@/components/apps/report/about/EEMonReportAbout.vue'
import SelectMain from '@/components/apps/report/selection/SelectMain.vue'

// CHILDREN EEPlantLocations
import RePlantLocationsAbout from '@/components/apps/re_plant_locations/about/RePlantLocationsAbout.vue'
import WebGisMain from '@/components/apps/re_plant_locations/webgis/WebGisMain.vue'

// ERROR PAGES
import ErrorPage404 from '@/components/base/error/ErrorPage404.vue'

// PRIVACY POLICY
import PrivacyPolicy from '@/components/base/privacy/PrivacyPolicy.vue'

import {
  mdiHome,
  mdiMonitorDashboard,
  mdiMapSearchOutline,
  mdiInformationOutline
} from '@mdi/js'

Vue.use(VueRouter)

const routes = [
  // {
  //   component: LoginPageView,
  //   name: 'login',
  //   path: '/',
  //   meta: {
  //     breadcrumb: false,
  //     drawer: false,
  //     title_de: 'Login Seite',
  //     title_en: 'Login Page'
  //   }
  // },
  {
    component: EEMonitorHomeView,
    name: 'EEMonitorHome',
    path: '/',
    meta: {
      breadCrumb: 'EEMonitorHome',
      breadcrumb: true,
      drawer: false,
      icon: mdiHome,
      title_de: 'Home',
      title_en: 'Home'
    }
  },
  {
    component: EEMonitorAboutView,
    name: 'EEMonitorAbout',
    path: '/about',
    meta: {
      breadCrumb: 'About',
      breadcrumb: true,
      drawer: false,
      icon: mdiInformationOutline,
      title_de: 'Hintergrund EE-Monitor',
      title_en: 'About the EE-Monitor'
    }
  },
  {
    component: EEMonReportView,
    name: 'EEMonReport',
    path: '/report',
    redirect: '/report/about',
    meta: {
      breadCrumb: 'Monitoring-Report',
      breadcrumb: true,
      drawer: true,
      icon: mdiMonitorDashboard,
      title_de: 'Monitoring-Report',
      title_en: 'Monitoring-Report'
    },
    children: [
      {
        component: EEMonReportAbout,
        name: 'EEMonReportAbout',
        path: 'about',
        meta: {
          breadCrumb: 'About',
          breadcrumb: true,
          drawer: true,
          title_de: 'Über den Monitoring-Report',
          title_en: 'About the Monitoring-Report'
        }
      },
      {
        component: SelectMain,
        name: 'SelectMain',
        path: 'selection',
        meta: {
          breadCrumb: 'Kennzahlenauswahl',
          breadcrumb: true,
          drawer: true,
          title_de: 'Kennzahlenauswahl',
          title_en: 'Indicator selection'
        }
      },
      {
        component: ReportView,
        name: 'ReportView',
        path: 'region',
        meta: {
          breadCrumb: 'ReportView',
          breadcrumb: true,
          drawer: true,
          title_de: 'Regionalbericht',
          title_en: 'Region Report'
        },
        children: [
          {
            component: ReportView,
            name: 'ReportViewRegion',
            path: ':regionSlug',
            meta: {
              breadCrumb: 'RegionSlug',
              breadcrumb: false,
              drawer: false,
              title_de: 'Regionalbericht',
              title_en: 'Region Report'
            }
          }
        ]
      },
      {
        component: DashboardView,
        name: 'Dashboard',
        path: ':slug',
        meta: {
          breadCrumb: 'Dashboard',
          breadcrumb: true,
          drawer: false,
          title_de: 'Dashboard',
          title_en: 'Dashboard'
        }
      }
    ]
  },
  {
    component: RePlantLocationView,
    name: 're-plant-locations',
    path: '/re-plant-locations',
    redirect: '/re-plant-locations/about',
    meta: {
      breadCrumb: 'EE-Anlagenstandorte',
      breadcrumb: true,
      drawer: true,
      icon: mdiMapSearchOutline,
      title_de: 'EE-Anlagenstandorte',
      title_en: 'RE Plant Locations'
    },
    children: [
      {
        component: RePlantLocationsAbout,
        name: 'RePlantLocationsAbout',
        path: 'about',
        meta: {
          breadCrumb: 'About',
          breadcrumb: true,
          drawer: true,
          title_de: 'Über die Standortdaten',
          title_en: 'About the RE plant data'
        }
      },
      {
        component: WebGisMain,
        name: 'WebGisMain',
        path: 'webgis',
        meta: {
          breadCrumb: 'Web-GIS',
          breadcrumb: true,
          drawer: true,
          title_de: 'Web-GIS',
          title_en: 'Web-GIS'
        }
      }
    ]
  },
  {
    component: PrivacyPolicy,
    name: 'PrivacyPolicy',
    path: '/privacy',
    meta: {
      breadCrumb: 'Privacy Policy',
      breadcrumb: true,
      drawer: false,
      title_de: 'Datenschutzerklärung',
      title_en: 'Privacy Policy'
    }
  },
  {
    component: ErrorPage404,
    path: '*',
    meta: {
      breadcrumb: false,
      drawer: false,
      title_de: 'Keine Seite gefunden',
      title_en: 'No page found'
    }
  },
  {
    component: ErrorPage404,
    name: 'ErrorPage404',
    path: '/error-404',
    meta: {
      breadcrumb: false,
      drawer: false,
      title_de: 'Keine Seite gefunden',
      title_en: 'No page found'
    }
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    return { x: 0, y: 0 }
  }
})

export default router
