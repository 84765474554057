<template>
  <div
    v-if="user.isAuthenticated"
    id="scrolling"
    class="fill-height"
    style="width: 100%;"
  >
    <!-- mode="out-in" -->
    <transition
      name="fade"
      style="width: 100%;"
      class="fill-height"
    >
      <router-view />
    </transition>

    <DialogCititation />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import DialogCititation from '@/components/main/dialog/DialogCititation.vue'

export default {
  name: 'EEMonReportView',

  components: {
    DialogCititation
  },

  data () {
    return {
      applicatonName: 'eemonreport',
      applicatonItem: {}
    }
  },

  computed: {
    ...mapState('main', [
      'user'
    ])
  },

  beforeDestroy () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },

  async mounted () {
    if (!this.user.isAuthenticated) {
      this.$router.push('/')
    }

    this.applicatonItem = await this.get_application_item({
      applicatonName: this.applicatonName
    })

    // console.log('this.applicatonItem ', this.applicatonItem)
  },

  methods: {
    ...mapActions('main', [
      'get_application_item'
    ])
  }

  // head () {
  //   return {
  //     meta: [
  //       { hid: 'description', name: 'description', content: this.description }
  //     ]
  //   }
  // }

}
</script>

<style lang="scss" scoped>
</style>
