<template>
  <div
    class="fill-height"
  >
    <DashboardLoadingIndicator />

    <v-row
      dense
    >
      <!-- LEFT CHART AREA -->
      <v-col
        xl="9"
        lg="9"
        md="12"
        sm="12"
        cols="12"
      >
        <!-- ROW LEFT -->
        <v-row
          dense
        >
          <!-- CHART -->

          <v-col
            sm="12"
            cols="12"
            class="flex-nowrap flex-grow-0 flex-shrink-0"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="grow d-flex flex-column flex-nowrap"
              >
                <v-card
                  ref="card_charts_line_1"
                  rounded="0"
                  :elevation="cardElevation"
                  class="fill-height"
                  :style="{'height': cardHeight + 'px'}"
                >
                  <div
                    class="d-flex flex-column fill-height"
                  >
                    <ChartsHeatmapCorineWrapper
                      v-if="dashboardItem &&
                        itemData &&
                        dashboardItemsDataYears.length !== 0"
                      :dashboard-item="dashboardItem"
                      :card-height="cardHeight"
                      :spatial-type-id="spatialTypeId"
                      :item-data="itemData"
                    />
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- LEFT CHART AREA END  -->

      <!-- RIGHT MENU AREA START -->

      <v-col
        v-if="$vuetify.breakpoint.lgAndUp"
        xl="3"
        lg="3"
      >
        <v-card

          ref="card_optional_data_option"
          rounded="0"
          :elevation="cardElevation"
          class="section-scroll fill-height "
          :style="{'height': cardHeight + 'px'}"
        >
          <v-card
            rounded="0"
            :elevation="cardElevation"
            style="width: 100%;"
          >
            <DashboardItemMenu
              v-if="showSelectOptional &&
                dashboardItem &&
                itemData &&
                dashboardItemsDataYears.length !== 0"
              :select-query="selectOptional"
            />
          </v-card>
        </v-card>
      </v-col>

      <!-- <v-navigation-drawer
        v-if="$vuetify.breakpoint.mdAndDown"
        :value="isOptionSidebarOpen"
        absolute
        temporary
        right
        width="300"
        @input="optionSidebarInput"
      >
        <DashboardItemMenu
          v-if="dashboardItem &&
            itemData &&
            dashboardItemsDataYears.length !== 0 &&
            showSelectOptional"
          :select-query="selectOptional"
        />
      </v-navigation-drawer> -->

      <!-- MAIN ROW END -->
    </v-row>
  </div>
</template>

<script>

import ChartsHeatmapCorineWrapper from '@/components/dashboard/charts/ChartsHeatmapCorineWrapper'

import { mapState } from 'vuex'

import DashboardItemMenu from '@/components/dashboard/inputs/DashboardItemMenu.vue'

import DashboardLoadingIndicator from '@/components/main/overlays/DashboardLoadingIndicator'

export default {
  name: 'LayoutHeatmapCorineComponent',

  components: {
    ChartsHeatmapCorineWrapper,
    DashboardItemMenu,
    DashboardLoadingIndicator
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    cardHeight: {
      type: Number,
      default: 0
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    spatialTypeId: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      cardElevation: 0,
      showSelectOptional: false,
      selectOptional: []
    }
  },

  computed: {
    ...mapState('dashboard_items', [
      'dashboardItemsSelectsOptional',
      'dashboardItemsDataYears'
    ]),

    colNumberCardMap () {
      return this.showSelectOptional ? 8 : 6
    },

    colNumberCardChart () {
      return this.showSelectOptional ? 4 : 6
    },

    itemDescriptionClass () {
      return this.showSelectOptional ? 'mt-2' : ''
    }
  },

  watch: {
    dashboardItemsSelectsOptional (newVal, oldVal) {
      this.selectOptional = this.getSelectOptional()
    }
  },

  methods: {
    getSelectOptional () {
      if (this.dashboardItemsSelectsOptional) {
        if (this.dashboardItemsSelectsOptional.length !== 0) {
          const filteredSelect = this.dashboardItemsSelectsOptional.filter(opt => opt.type.select_type === 'select-panel-list')

          if (filteredSelect.length !== 0) {
            this.showSelectOptional = true

            return [filteredSelect[0].type.query_key]
          } else {
            this.showSelectOptional = false

            return []
          }
        } else {
          this.showSelectOptional = false

          return []
        }
      }
    }

  }

}
</script>

<style lang="scss" scoped>

</style>
