<template>
  <div
    class="fill-height"
  >
    <VChart
      id="chart_heatmap"
      ref="chart_heatmap"
      style="max-width: 100%;"
      :autoresize="true"
    />
  </div>
</template>

<script>

import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

// LODASH
import _ from 'lodash'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

import {
  SVGRenderer
} from 'echarts/renderers'

import {
  HeatmapChart
} from 'echarts/charts'

import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent
} from 'echarts/components'

use([
  SVGRenderer,
  HeatmapChart,
  VisualMapComponent,
  TitleComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent
])

// import Moment from 'moment'
// import { extendMoment } from 'moment-range'
//  EXTEND Moment.js with Moment-Range
// const moment = extendMoment(Moment)

export default {
  name: 'ChartsHeatmapCorineComponent',
  // mixins: [globalFunctions],

  components: {
    VChart
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'svg'
    }
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default: 0
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    itemDataValueMax: {
      type: Number,
      default: 0
    },
    maximumFractionDigits: {
      type: Number,
      default: 0
    },
    itemDataYears: {
      type: Array,
      default () {
        return []
      }
    },
    corineItems: {
      type: Array,
      default () {
        return []
      }
    },
    dataValueUnit: {
      type: String,
      default: ''
    },
    itemCorine: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      minimumFractionDigits: 0,
      itemDataSorted: [],
      showLoadingIndicator: false,
      echartsOption: {
        animation: true,
        bottom: 'auto',
        grid: {
          bottom: '0',
          height: '20px',
          id: 'chartid',
          left: '12px',
          right: '12px',
          top: '2'
        },
        series: [{
          emphasis: {
            itemStyle: {
              borderWidth: 1,
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.2)'
            }
          },
          itemStyle: {
            borderColor: '#999',
            borderWidth: 0.4
          },
          label: {
            show: false
          },
          type: 'heatmap'
        }],
        textStyle: {
          color: '#003e6e',
          fontFamily: 'Roboto',
          fontSize: 14
        },
        toolbox: {
          show: false
        },
        tooltip: {
          appendToBody: true,
          axisPointer: {
            label: {
              show: false
            }
          },
          trigger: 'item'
        },
        top: 'auto',
        visualMap: {
          calculable: false,
          inRange: {
            color: [
              '#ffffff', // REVIEW color of value 0
              '#003e6e'
            ]
          },
          // left: 'center',
          // orient: 'horizontal',
          // realtime: false,
          show: false
          // top: 30
        },
        xAxis: {
          data: [{
            textStyle: {
              color: '#c7c5c5',
              fontSize: '20px'
            }
          }],
          splitLine: {
            lineStyle: {
              color: '#999',
              width: 0.2
            },
            show: true
          },
          type: 'category'
        },
        yAxis: {
          data: [],
          splitLine: {
            lineStyle: {
              color: '#999',
              width: 0.2
            },
            show: false
          },
          type: 'category'
        }
      }
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_items', [
      // 'dashboardItemsDataQuery',
      // 'dashboardItemsSelectsBasic'
    ]),

    ...mapState('dashboard_main', [
      'echartsDefaults'
    ]),

    seriesTitle () {
      return this.locale === 'de' ? 'Bundesrepublik Deutschland' : 'Germany'
    }
  },

  watch: {
    locale (newVal, oldVal) {
      this.echartsOption.series[0].name = this.seriesTitle
      this.$refs.chart_heatmap.setOption(this.echartsOption)
    },

    height (newVal, oldVal) {
      // Resize map canvas if height provided by props changes
      this.$refs.chart_heatmap.resize()
    },

    itemData () {
      // Update map chart if a new maximum value is received
      this.updateHeatmapCorine()
    }
  },

  beforeMount () {
    // Set echarts defauls from store
    // this.echartsOption.series = this.echartsDefaults.map.series
    this.echartsOption.textStyle = this.echartsDefaults.textStyle
    // this.echartsOption.xAxis = this.echartsDefaults.xAxis
    this.echartsOption.tooltip = this.echartsDefaults.tooltip
    this.echartsOption.tooltip.textStyle = this.echartsDefaults.textStyle
  },

  async mounted () {
    // Get value unit title_short (string)
    // const dataValueUnitObj = await this.get_item_data_value_unit({
    //   item: this.dashboardItem
    // })
    // this.dataValueUnit = dataValueUnitObj.title_short_de

    // Get array with corine items
    // this.corineItems = await this.get_corine_items()

    //
    //  Event Listener VUE
    //

    eventBus.$on('loading-indicator-echart-component', () => {
      this.$refs.chart_heatmap.chart.showLoading()
      this.showLoadingIndicator = true
    })

    eventBus.$on('resize-echarts-map', () => {
      this.$refs.chart_heatmap.chart.resize()
    })

    let stateAgsSelected
    eventBus.$on('update-echarts-corine',
      async ({
        event,
        selectValue
      }) => {
        await this.updateHeatmapCorine()
      }
    )

    await this.updateHeatmapCorine()
  },

  beforeDestroy () {
    this.$refs.chart_heatmap.chart.dispose()
    this.$refs.chart_heatmap.chart = null

    eventBus.$off('resize-echarts-map')
    eventBus.$off('loading-indicator-echart-component')
    eventBus.$off('update-echarts-corine')
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_corine_items'
    ]),

    ...mapActions('dashboard_items', [
      'get_datasets_value_max'
      // 'get_datasets_value_min',
      // 'get_datasets_years',
      // 'get_item_data_value_unit'
    ]),

    // setElementsHights () {
    //   setTimeout(() => {
    //     // REVIEW
    //   }, 800)
    // },

    additionalValues (valueQueryKeys) {
      const selects = valueQueryKeys.map((qk, idx, arr) => {
        let corineValues

        const corineLevelId = qk.substring(qk.length - 1)

        if (idx === 0) {
          corineValues = this.corineItems.filter((item) => {
            return item.level === Number(corineLevelId)
          })
        } else {
          corineValues = this.corineItems.filter(item =>
            item.level === Number(corineLevelId)
          )
        }

        return {
          queryKey: qk,
          values: corineValues
        }
      })

      return selects
    },

    serieValue (year, corineCode) {
      // Specific Value of day and hour (time)
      const dataOfClc = this.itemDataSorted.filter(e => {
        return e.corine === corineCode && e.data_year === year
      })

      // Check if there is missing data because of "winter-/summertime" change
      if (dataOfClc[0].data_value !== null) {
        return dataOfClc[0].data_value
      } else {
        return -1
      }
    },

    async getCorineTitles (corineIdArray) {
      const titleArray = corineIdArray.map((corineId) => {
        const corineItemFiltered = this.corineItems.filter(item => item.id === corineId)
        return corineItemFiltered[0][`title_${this.locale}`]
      })

      return titleArray
    },

    getCorineTitle ({ clcId, corineItems }) {
      const corineItem = corineItems.filter(corineItem => corineItem.id === clcId)[0]

      return corineItem[`title_${this.locale}`]
    },

    async updateHeatmapCorine () {
      const itemDataCloned = _.cloneDeep(this.itemData)

      // Filter data
      this.itemDataSorted = itemDataCloned.filter(ds => ds.corine === this.itemCorine.id)

      // Set min & max values
      this.echartsOption.visualMap.min = 0
      this.echartsOption.visualMap.max = this.itemDataValueMax

      // Show loading indicator animation
      this.$refs.chart_heatmap.chart.showLoading()
      this.showLoadingIndicator = true

      // Get all corine datasset ID
      const clcId = this.itemDataSorted[0].corine

      const series = []
      this.itemDataYears.forEach((year, indexYear) => {
        const dataValue = this.serieValue(year, clcId)
        // NOTE data model --> [x, y, value]
        // NOTE y = 0 because it's only ONE heatmap row
        series.push([indexYear, 0, dataValue])
      })

      // NOTE FOR TESTING IF DATASET CLC IS CORRECT
      // this.echartsOption.xAxis.data = this.itemDataYears.map((yearTitle) => {
      //   const obj = {
      //     value: String(clcId),
      //     textStyle: {
      //       fontSize: 10,
      //       color: '#d9d4d4'
      //     }
      //   }
      //   return obj
      // })

      // SETTING CATEGORY -> X-AXIS -> Years (left to right)
      this.echartsOption.xAxis.data = this.itemDataYears.map((yearTitle) => {
        const obj = {
          value: yearTitle,
          textStyle: {
            fontSize: 10,
            color: '#d9d4d4'
          }
        }
        return obj
      })

      // Set data series on chart option series
      this.echartsOption.series[0].data = series

      // TODO translation
      this.echartsOption.series[0].name = this.seriesTitle

      // Set formatter for tooltip
      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        // NOTE params.data = [x, y, value] --> clc id ->  params.data[1]

        const corineTitle = this.getCorineTitle({
          clcId: clcId,
          corineItems: this.corineItems
        })

        if (params.data[2] === -1) {
          return `${corineTitle}<br/> ${this.locale === 'de' ? 'kein Wert' : 'no value'}`
        } else {
          // ${params.seriesName}
          return `${this.seriesTitle} (${params.name})<br>
          <span class="text-h6 font-weight-medium">${corineTitle}</span><br/>
          ${params.data[2].toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })}
          ${this.dataValueUnit}`
        }
      }

      // Set options on chart instance
      this.$refs.chart_heatmap.setOption(this.echartsOption)

      // console.log(this.$refs.chart_heatmap.getOption())

      // Hide loading indicator after finishing
      this.$refs.chart_heatmap.chart.hideLoading()
      this.showLoadingIndicator = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
