<template>
  <v-footer
    :absolute="absolute"
    :app="app"
    :fixed="fixed"
    color="backgroundColor"
    class="darken-1"
    style="z-index: 10;"
  >
    <div
      class="d-flex justify-space-between align-center text-body-2"
      style="width: 100%;"
    >
      <div
        class="text-truncate"
      >
        &copy; {{ new Date().getFullYear() }}
        <span
          class="mr-3"
        >
          <span>
            EE-Monitor /
          </span>
          <a
            href="https://www.ufz.de"
          >
            <span
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Helmholtz-Zentrum für Umweltforschung GmbH - UFZ
            </span>
            <span
              v-else
            >
              UFZ
            </span>
          </a>
        </span>
      </div>

      <!-- <span
        v-if="showBreakpointInfo && $vuetify.breakpoint.smAndUp"
        class="text-body-2"
      >
        breakpoint: {{ $vuetify.breakpoint.name }} | width:  {{ $vuetify.breakpoint.width }} | height:  {{ $vuetify.breakpoint.height }}
      </span> -->

      <div
        class="d-flex"
      >
        <div
          class="mr-3"
        >
          <a
            :href="`https://www.ufz.de/index.php?${locale}=36683`"
            target="_blank"
          >
            <span
              class="text-body-2"
            >
              <span
                v-if="locale==='de'"
              >
                Impressum
              </span>
              <span
                v-else
              >
                Imprint
              </span>
            </span>
          </a>
        </div>

        <router-link
          v-slot="{ href, navigate }"
          custom
          :to="{name: 'PrivacyPolicy'}"
          style="text-decoration: underline; cursor: pointer;"
        >
          <span
            class="text-body-2"
            @click="navigate"
          >
            <span
              v-if="locale==='de'"
            >
              Datenschutz
            </span>
            <span
              v-else
            >
              Privacy Policy
            </span>
          </span>
        </router-link>
      </div>
    </div>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MainFooter',

  props: {
    fixed: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: false
    },
    app: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showBreakpointInfo: false,
      title: 'EE-Monitor / Helmholtz-Zentrum für Umweltforschung GmbH - UFZ'
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  },

  mounted () {
    if (process.env.NODE_ENV !== 'production') {
      this.showBreakpointInfo = true
    }
  }
}
</script>

<style lang="scss" scoped>
.v-footer {
  padding: 2px 16px !important;
}

</style>
