<template>
  <div
    v-if="$vuetify.breakpoint.smAndUp"
    class="mb-3"
    :class="[$vuetify.breakpoint.smAndDown ? 'px-6' : 'px-3', $vuetify.breakpoint.xs ? 'mt-3' : '']"
  >
    <div
      class="d-flex align-center text-truncate"
    >
      <router-link
        :to="{name: 'EEMonitorHome'}"
        class="mr-3 text-body-2"
        style="text-decoration: none;"
      >
        <div
          style="cursor: pointer;"
        >
          {{ application[`title_${locale}`] }}
        </div>
      </router-link>

      <div
        class="d-inline-flex"
      >
        <div
          class="mr-3 text-body-2"
          style="font-weight: 500; line-height: 1.5rem; color: var(--v-primary-base);"
        >
          >
        </div>

        <v-breadcrumbs
          divider=">"
          class="pa-0 breadcrumb-nav text-body-2 flex-nowrap text-truncate"
          :items="breadCrumbs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BreadcrumbNav',

  computed: {
    ...mapState('main', [
      'application',
      'locale'
    ]),

    breadCrumbs () {
      const pathArray = this.$route.path.split('/')
      pathArray.shift()
      const breadCrumbs = [] // needed to handle the intermediary entries for nested vue routes

      let breadcrumb = ''
      let lastIndexFound = 0
      let disabled

      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${'/'}${pathArray[i]}`

        if (this.$route.matched[i] && Object.hasOwnProperty.call(this.$route.matched[i], 'meta') && Object.hasOwnProperty.call(this.$route.matched[i].meta, 'breadCrumb') && this.$route.matched[i].meta.breadcrumb === true) {
        // FIXME temporary workaround to make the Regionreport breadcrumb item not clickable. It is clickable because it has an (invisible) child route
          let disabled
          if (this.$route.matched[i].name === 'ReportView') {
            disabled = true
          } else {
            disabled = i + 1 === pathArray.length
          }

          breadCrumbs.push({
            disabled: disabled,
            exact: true,
            link: true,
            text: this.$route.matched[i].meta[`title_${this.locale}`] || pathArray[i],
            // href: i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? '/' + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            to: {
              name: this.$route.matched[i].name
            }
          })
          lastIndexFound = i
          breadcrumb = ''
        }
      }
      return breadCrumbs
    }
  }
}
</script>

<style lang="scss">
.v-breadcrumbs__item--disabled {
  color: var(--v-secondary-base) !important;
}
// .breadcrumb-nav {
//   .v-breadcrumbs__item  {
//     font-family: "Roboto", sans-serif !important;
//   }
//   // .v-breadcrumbs__item--disabled {
//   //   // color: #b1b1b1 !important;
//   //   text-decoration: underline;
//   // }
//   // .v-breadcrumbs__divider {
//   //   color: #ffffff !important;
//   // }
// }
</style>
