<template>
  <div>
    <!-- v-if="layerStatisticValues !== null" -->
    <div
      class="d-flex flex-column fill-height"
    >
      <!-- style="background: rgb(225, 232, 238); border-bottom: dotted 1px var(--v-secondary-lighten1);" -->

      <!-- REGION -->

      <v-row
        no-gutters
        style="border-bottom: dotted 1px var(--v-secondary-lighten1);"
        class="mb-2 py-2"
      >
        <v-col
          cols="auto"
          class="text-body-1"
        >
          <span
            v-if="locale==='de'"
          >
            Region:&nbsp;
          </span>
          <span
            v-else
          >
            Region:&nbsp;
          </span>
        </v-col>

        <v-col
          v-if="Object.keys(itemDataQuery).length !== 0"
          class="grow text-body-1 font-weight-medium"
        >
          <span
            v-if="itemDataQuery.spatial_unit !== '00000000'"
          >
            <span
              v-if="itemDataQuery.spatial_unit.length === 5"
            >
              <!-- {{ regionTitle }} -->
              {{ getAgsGenTitle({ags: itemDataQuery.spatial_unit}) }} ({{ getAgsGenTitle({ags: itemDataQuery.spatial_unit.slice(0,2)}) }})
            </span>

            <span
              v-if="itemDataQuery.spatial_unit.length === 2"
            >
              {{ getAgsGenTitle({ags: itemDataQuery.spatial_unit.slice(0,2)}) }}
            </span>
          </span>

          <span
            v-else
          >
            <span
              v-if="locale==='de'"
            >
              Bundesrepublik Deutschland
            </span>
            <span
              v-else
            >
              Germany
            </span>
          </span>
        </v-col>
      </v-row>

      <!-- PLANT COUNT -->

      <v-row
        no-gutters
      >
        <v-col
          :cols="6"
          class="text-body-1"
        >
          <span
            v-if="layerStatisticValues !== null"
          >
            <div
              v-if="itemDataQuery.time_format_operand === '<='"
            >
              <div
                v-if="layerStatisticValues.id_count > 1"
              >
                <span
                  v-if="locale==='de'"
                >
                  Anzahl (Zeitraum):
                </span>
                <span
                  v-else
                >
                  Count (year range):
                </span>
              </div>

              <div
                v-else-if="layerStatisticValues.id_count === 1"
              >
                <span
                  v-if="locale==='de'"
                >
                  Anzahl (Zubaujahr):
                </span>
                <span
                  v-else
                >
                  Count (year of expansion):
                </span>
              </div>
            </div>

            <div
              v-if="itemDataQuery.time_format_operand === '=' &&
                layerStatisticValues.id_count >= 1"
            >
              <span
                v-if="locale==='de'"
              >
                Anzahl (Zubaujahr):
              </span>
              <span
                v-else
              >
                Count (year of expansion):
              </span>
            </div>

            <div
              v-if="layerStatisticValues !== null &&
                layerStatisticValues.id_count === 0"
            >
              <span
                v-if="locale==='de'"
              >
                Anzahl:
              </span>
              <span
                v-else
              >
                Count:
              </span>
            </div>
          </span>

          <span
            v-else
          >
            <span
              v-if="locale==='de'"
            >
              Anzahl (Zeitraum):
            </span>
            <span
              v-else
            >
              Count (year range):
            </span>
          </span>
        </v-col>

        <v-col
          cols="auto"
          class="shrink text-body-1"
        >
          <div
            v-if="layerStatisticValues !== null"
          >
            <div
              v-if="layerQueryValuesMinMax.id_count !== 0"
            >
              <span
                v-if="itemDataQuery.time_format_operand === '<='"
              >
                <span
                  class="font-weight-medium"
                >
                  {{ layerStatisticValues.id_count.toLocaleString(locale) }}
                </span>

                <!-- v-if="layerStatisticValues.id_count > 1" -->
                <span>
                  ({{ layerQueryValuesMinMax.year_commissioning_min }} - {{ itemDataQuery.year_commissioning }})
                </span>

                <!-- <span
                  v-if="layerStatisticValues.id_count === 1"
                >
                  ({{ itemDataQuery.year_commissioning }})
                </span> -->
              </span>

              <span
                v-if="itemDataQuery.time_format_operand === '='"
              >
                <span
                  class="font-weight-medium"
                >
                  {{ layerStatisticValues.id_count.toLocaleString(locale) }}
                </span>

                ({{ itemDataQuery.year_commissioning }})
              </span>

              <span
                v-if="layerQueryValuesMinMax.id_count === 0"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Keine Anlagen
                </span>
                <span
                  v-else
                >
                  No RE-plants
                </span>
              </span>
            </div>

            <div
              v-else
              class="text-body-1 font-weight-medium"
            >
              <span
                v-if="locale === 'de'"
              >
                Keine Anlagen
              </span>
              <span
                v-else
              >
                No RE-plants
              </span>
            </div>
          </div>

          <!-- LOADING INDICATOR PLACEHOLDER -->

          <!-- TODO LOADING INDICATOR -->

          <span
            v-else
          >
            -
          </span>
        </v-col>
      </v-row>

      <!-- CAPACITY INSTALLED SUM -->

      <v-row
        no-gutters
      >
        <v-col
          :cols="6"
          class="text-body-1"
        >
          <span
            v-if="locale==='de'"
          >
            Installierte Leistung (&sum;):
          </span>
          <span
            v-else
          >
            Total installed capacity:
          </span>
        </v-col>

        <v-col
          cols="auto"
          class=" shrink text-body-1"
        >
          <span
            v-if="layerStatisticValues !== null"
          >

            <span
              v-if="layerStatisticValues.capacity_installed_sum !== null"
              class="font-weight-medium"
            >
              {{ getCapacityValueString(layerStatisticValues.capacity_installed_sum, true, 1) }}
            </span>
            <span
              v-else
            >-</span>
          </span>

          <!-- LOADING INDICATOR PLACEHOLDER -->

          <!-- TODO LOADING INDICATOR -->

          <span
            v-else
          >
            -
          </span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import { eventBus } from '@/utils/eventbus.js'
import { mapState } from 'vuex'

// MIXINS
import mixinWebgis from '@/mixins/webgis.js'

// WEBGIS HELPER METHODS
// import {
//   getCapacityValueString
// } from '@webgis/helper_methods.js'

export default {
  name: 'LayerStatistics',

  mixins: [
    mixinWebgis
  ],

  data () {
    return {
      regionTitle: ''
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'itemDataQuery',
      'itemSelected',
      'layerQueryValuesMinMax',
      'layerStatisticValues'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems'
    ])
  },

  // watch: {
  //   'itemDataQuery.spatial_type': {
  //     handler (newSpatialType) {
  //       console.log('newSpatialType ', newSpatialType)
  //       if (newSpatialType !== null) {
  //         if (newSpatialType === 4) {
  //           this.regionTitle = `${this.getAgsGenTitle({ ags: this.itemDataQuery.spatial_unit })}, ${this.getAgsGenTitle({ ags: this.itemDataQuery.spatial_unit.slice(0, 2) })}`
  //         } else if (newSpatialType === 2) {
  //           this.regionTitle = `${this.getAgsGenTitle({ ags: this.itemDataQuery.spatial_unit.slice(0, 2) })}`
  //         } else {
  //           this.regionTitle = this.locale === 'de' ? 'Bundesrepublik Deutschland' : 'Germany'
  //         }
  //       }
  //     }
  //   },
  //   immediate: true
  // },

  methods: {
    // getRegionTitel (ags) {
    //   const agsBySpatialType = this.itemDataQuery.spatial_type === 2 ? ags.slice(0, 2) : ags
    //   console.log('agsBySpatialType ', agsBySpatialType)

    //   const region = this.getAgsGenTitle({ ags: agsBySpatialType })

    //   console.log(region)
    //   return region
    // },

    getAgsGenTitle ({ ags }) {
      if (this.bkgItems.length !== null) {
        const bkgItem = this.bkgItems.filter(bkgItem => bkgItem.ags === ags)[0]
        if (bkgItem !== undefined) {
          if (bkgItem.nbd === false) {
            return bkgItem.gen
          } else {
            return `${bkgItem.bez} ${bkgItem.gen}`
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
