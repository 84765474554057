<template>
  <div>
    <v-row
      v-resize="setElementsHeights"
      no-gutters
    >
      <!-- ITEM DASHBOARD HEADER - START -->

      <v-col
        class="d-flex grow flex-column"
        style="width: 100%"
      >
        <v-card
          ref="dashboard_card_top"
          class="fill-height mb-3 py-2"
          style="height: auto; background: #ffffff;"
          :elevation="$vuetify.breakpoint.smAndDown ? 1 : 0"
          rounded="0"
          :style="[$vuetify.breakpoint.smAndDown ? {
            'position': 'sticky',
            'z-index': '5',
            'top': '28px',
            'border-bottom': ' 2px solid rgb(225, 232, 238)'
          } : {}]"
        >
          <!-- <v-row
            style="width: 100%; background: #ffffff;"
            class="py-2 fill-height"
            no-gutters
          >
            <v-col
              cols="12"
            >
              <div
                class="d-flex flex-column justify-center"
              > -->
          <DashboardHeaderContent
            ref="dashboard_item_info"
            :dashboard-item="dashboardItem"
          />
          <!-- </div>
            </v-col>
          </v-row> -->
        </v-card>

        <!-- ITEM DASHBOARD HEADER - END -->

        <!-- ITEM DASHBOARD ITEM LAYOUT - START -->

        <!-- :is="dashboardItem.layout_type.dashboard_layout" -->

        <component
          :is="dashboardLayout.dashboard_layout"
          v-if="dashboardLayout"
          :key="dashboardLayout.dashboard_layout.id"
          ref="dashboard_component"
          :card-height="dashboardLayoutCardHeight"
          :dashboard-item="dashboardItem"
          :item-data="itemData"
          :spatial-type-id="spatialTypeIdSelected"

          :style="[$vuetify.breakpoint.mdAndUp ? {'height': dashboardLayoutCardHeight + 'px'} : {}]"
          class="fill-height"
        />
        <!-- :style="[$vuetify.breakpoint.mdAndUp ? {'height': dashboardLayoutCardHeight + 'px'} : '']" -->

        <!-- ITEM DASHBOARD ITEM LAYOUT - END -->
      </v-col>
    </v-row>

    <DialogItemDescription
      class="fill-height"
    />
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions, mapMutations } from 'vuex'

import _ from 'lodash'

// HEADER CONTENT
import DashboardHeaderContent from '@/components/dashboard/DashboardHeaderContent.vue'

// DIALOGs
import DialogItemDescription from '@/components/main/dialog/DialogItemDescription.vue'

// DASHBOARD LAYOUTS
import LayoutStandard1 from '@/components/dashboard/layouts/LayoutStandard1.vue'
import LayoutStandard2 from '@/components/dashboard/layouts/LayoutStandard2.vue'
import LayoutExpansionPath from '@/components/dashboard/layouts/LayoutExpansionPath.vue'
import LayoutHeatmapCorine from '@/components/dashboard/layouts/LayoutHeatmapCorine.vue'
import LayoutBoxplot from '@/components/dashboard/layouts/LayoutBoxplot.vue'

export default {
  name: 'DashboardMain',

  components: {
    DashboardHeaderContent,
    DialogItemDescription,
    LayoutBoxplot,
    LayoutExpansionPath,
    LayoutHeatmapCorine,
    LayoutStandard1,
    LayoutStandard2
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      cardElevation: 1,
      dashboardLayoutCardHeight: 0,
      heightChartsMap: 0,
      heightMainContainer: 0,
      itemData: [],
      showDashboardComponent: false,
      spatialTypeIdSelected: undefined
    }
  },

  computed: {
    ...mapState('dashboard_items', [
      'dashboardLayout'
    ])
  },

  beforeMount () {
    window.scrollTo(0, 0)
  },

  beforeDestroy () {
    this.showDashboardComponent = false
    // this.DELETE_DASHBOARD_LAYOUT()  // FIXME deactivated for testing

    eventBus.$off('item-is-loaded-dashboard')
    eventBus.$off('resize-dashboard')
    eventBus.$off('update-loaded-dashboard')

    setTimeout(() => {
      this.reset_dashboard_item()
    }, 1000)
  },

  async mounted () {
    // const dataOptionSpatialTypeObj = this.dashboardItem.data_options.filter((opt) => opt.type.query_key === 'spatial_type')[0]

    this.showDashboardComponent = true

    this.setElementsHeights()

    eventBus.$on('resize-dashboard', () => {
      this.setElementsHeights()
    })

    eventBus.$on('update-loaded-dashboard', async (query) => {
      await this.updateDashboard(query)
    })

    // eventBus.$on(`item-is-loaded-dashboard`, async () => {
    // console.log('this.dashboardItem ', this.dashboardItem)

    // Get default option values for init dataset query
    const queryDefault = this.dashboardItem.data_options.map((option) => {
      // console.log('queryDefault option ', option)

      if (option.type.query_type === 'api') {
        return {
          [option.type.query_key]: option.value_default.value_id
        }

        // FIXME
        // TODO make it possible to decide if spatial_type is only of type 1 or more what kind of dashboardLayout is loaded and if all values but distinct data_category will be requested from the api
        // if (dataOptionSpatialTypeObj.values.length > 1 ||
        // option.type.query_key.includes('data_category')) {
        //   return {
        //     [option.type.query_key]: option.value_default.value_id
        //   }
        // } else {
        //   return {
        //     [option.type.query_key]: null
        //   }
        // }
      } else if (option.type.query_type === 'local') {
        // NOTE If corine all selects are local and will be set to null by default!!!!
        return {
          [option.type.query_key]: null
        }
      } else {
        return ''
      }
    })

    // TODO Refactor querydefault -> set_data_query
    // const queryDefaultOneObject = Object.assign({}, ...queryDefault)
    // console.log('queryDefault flat ', queryDefaultOneObject)

    this.set_data_query({
      query: queryDefault
    })

    // Retrieve the select options by "basic" and "optional" select group types and store these to VUEX
    const selectsBasic = this.dashboardItem.data_options.filter(opt => opt.type.option_type === 'basic')

    const selectsOptional = this.dashboardItem.data_options.filter(opt => opt.type.option_type === 'optional')

    this.set_select_values({
      selectsBasic,
      selectsOptional
    })

    await this.updateDashboard(queryDefault)
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_bkg_items',
      'set_bkg_items'
    ]),

    ...mapActions('dashboard_items', [
      'get_item_data',
      'set_data_query',
      'set_select_values',
      'reset_dashboard_item'
    ]),

    ...mapMutations('dashboard_items', [
      'DELETE_DASHBOARD_LAYOUT'
    ]),

    setElementsHeights () {
      setTimeout(() => {
        this.heightMainContainer = this.$vuetify.breakpoint.height - this.$vuetify.application.top - this.$vuetify.application.footer

        if (this.$vuetify.breakpoint.smAndDown) {
          this.dashboardLayoutCardHeight = this.heightMainContainer - this.$refs.dashboard_card_top.$el.clientHeight - 24
        } else {
          if (this.$refs.dashboard_card_top) {
            this.dashboardLayoutCardHeight = this.heightMainContainer - this.$refs.dashboard_card_top.$el.clientHeight - 36
          }
        }
      }, 610)
    },

    async updateDashboard (query) {
      eventBus.$emit('loading-indicator-echart-component')

      this.spatialTypeIdSelected = query.filter(queryItem => 'spatial_type' in queryItem)[0].spatial_type

      // NOTE atm all bkg items from spatial_type 1, 2 & 4 are retrieved
      await this.get_bkg_items({
        ade: 4, // FIXME
        gf: 4
      })

      let itemIdArray

      if (this.dashboardItem.context_items.length === 0) {
        itemIdArray = [this.dashboardItem.id]
      } else {
        const contextItemIds = this.dashboardItem.context_items.map((ci) => { return ci.item.id })
        itemIdArray = [this.dashboardItem.id, ...contextItemIds]
      }

      this.itemData = await this.get_item_data({
        item: this.dashboardItem,
        itemIdArray,
        query,
        spatialTypeId: this.spatialTypeIdSelected // NOTE use default spatial_type from data_options.value_dafault --> queryDefault
      })

      // console.log('this.itemData ', this.itemData)
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.4s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }

// .v-tabs-slider-wrapper, .v-tab {
//   max-width: 100%; // NOTE Workaround for only one Tab
// }
// .dashboard_card {
//   [role=dialog] {
//     z-index: 0 !important;
//   }
//   .v-dialog__content {
//   position: absolute;
//     .v-dialog {
//       box-shadow: none;
//     }
//   }
// }
// .tab-select {
//   padding: 0 2px !important;
// }
// .dashboard_card_02 {
//   [role=dialog] {
//     z-index: 0 !important;
//   }
//   .v-dialog__content {
//   position: absolute;
//     .v-dialog {
//       box-shadow: none;
//     }
//   }
// }
</style>
