<template>
  <div
    class="d-flex"
  >
    <v-hover
      v-slot="{ hover }"
      open-delay="200"
      close-delay="200"
      :disabled="$vuetify.breakpoint.xsOnly"
    >
      <v-btn
        depressed
        color="primary"
        plain
        text
        elevation="0"
        style="text-transform: none;"
        @click="$emit('click')"
      >
        <div
          v-if="hover === false"
        >
          <span
            v-if="locale === 'de'"
          >
            Kennzahl anzeigen
          </span>

          <span
            v-else
          >
            Show indicator
          </span>
        </div>

        <v-expand-x-transition>
          <div
            v-if="hover"
          >
            <span
              v-if="locale === 'de'"
            >
              Kennzahl einzeln anzeigen
            </span>

            <span
              v-else
            >
              Display indicator individually
            </span>
          </div>
        </v-expand-x-transition>

        <v-icon
          size="14"
          class="ml-2"
        >
          {{ mdiArrowRightThick }}
        </v-icon>
      </v-btn>
    </v-hover>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { mdiArrowRightThick } from '@mdi/js'

export default {
  name: 'BtnGoToIndicator',

  data () {
    return {
      mdiArrowRightThick: mdiArrowRightThick
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  }
}
</script>

<style lang="scss">

</style>
