const geojsonLayerItems = [
  {
    year: 2016,
    filename: '2016-DE-NUTS0.geojson',
    spatialTypeId: 1
  },
  {
    year: 2016,
    filename: '2016-DE-NUTS1.geojson',
    spatialTypeId: 2
  },
  {
    year: 2016,
    filename: '2016-DE-NUTS3.geojson',
    spatialTypeId: 4
  },
  {
    year: 2016,
    filename: '2016-DE-LAU2.geojson',
    spatialTypeId: 6
  }
]

export { geojsonLayerItems }
