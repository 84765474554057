<template>
  <div>
    <!-- <DrawerMain
      :show-drawer="showDrawer"
      :clipped="clipped"
      @click="setDrawer()"
    /> -->

    <DrawerMain
      :navigation-drawer-top="navigationDrawerTop"
      :navigation-drawer-height="navigationDrawerHeight"
    />

    <v-app-bar
      v-scroll="onScroll"
      class="nav_home"
      color="primary"
      dense
      :extended="$vuetify.breakpoint.smAndUp"
      prominent
      extension-height="36"
      fixed
      :src="require(`@/assets/img/${imageFileName}`)"
      app
      shrink-on-scroll
      fade-img-on-scroll
      height="150"
      :class="[offsetTop < 80 ? '' : 'header-resized nav_extension']"
    >
      <template
        #img="{ props }"
      >
        <v-img
          v-bind="props"
          gradient="180deg, rgba(0, 62, 110, 1) 0%, rgba(0, 62, 110, 0.6) 15%, rgba(255, 255, 255, 0)"
        />
      </template>

      <template
        #extension
      >
        <v-card
          style="width: 1200px"
          class="nav_bar ma-auto fill-height"
          rounded="0"
          elevation="0"
        >
          <div
            class="d-flex flex-row justify-space-between justify-sm-start align-center fill-height px-sm-3"
          >
            <div
              class="d-flex justify-center align-stretch fill-height"
              :style="[$vuetify.breakpoint.xsOnly ? {'width': '100%'}:{'width': 'auto'}]"
            >
              <router-link
                active-class="active"
                exact
                :to="{name: 'EEMonitorHome' }"
                style="text-decoration: none; width: 100%;"
                class="fill-height"
              >
                <div
                  class="mr-sm-2 px-3 py-1 text-h6 d-flex justify-center align-center fill-height"
                >
                  <span
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    Home
                  </span>

                  <v-icon
                    v-else
                    color="primary"
                    size="18"
                    class="mx-3"
                  >
                    {{ mdiHome }}
                  </v-icon>
                </div>
              </router-link>
            </div>

            <div
              class="d-flex justify-center align-stretch fill-height"
              :style="[$vuetify.breakpoint.xsOnly ? {'width': '100%'}:{'width': 'auto'}]"
            >
              <router-link
                active-class="active"
                :to="{name: 'EEMonitorAbout' }"
                style="text-decoration: none; width: 100%;"
                class="fill-height"
              >
                <div
                  class="mr-sm-2 px-3 py-1 text-h6 d-flex justify-center align-center fill-height"
                >
                  <span
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    <span
                      v-if="locale === 'de'"
                    >
                      Hintergrund
                    </span>
                    <span
                      v-else
                    >
                      About
                    </span>
                  </span>

                  <v-icon
                    v-else
                    color="primary"
                    size="18"
                    class="mx-3"
                  >
                    {{ mdiInformationOutline }}
                  </v-icon>
                </div>
              </router-link>
            </div>

            <div
              v-for="(item, index) in routes"
              :key="index"
              class="d-flex justify-center align-stretch fill-height"
              :style="[$vuetify.breakpoint.xsOnly ? {'width': '100%'}:{'width': 'auto'}]"
            >
              <router-link
                active-class="active"
                :to="{name: item.name }"
                style="text-decoration: none; width: 100%;"
                class="fill-height"
              >
                <div
                  class="mr-sm-2 px-3 py-1 text-h6 d-flex justify-center align-center fill-height text-truncate"
                >
                  <span
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    {{ item.meta[`title_${locale}`] }}
                  </span>

                  <v-icon
                    v-else
                    color="primary"
                    size="18"
                    class="mx-3"
                  >
                    {{ item.meta.icon }}
                  </v-icon>
                </div>
              </router-link>
            </div>
          </div>
        </v-card>
      </template>

      <div
        style="position: absolute; bottom: 0; width: 100%; min-height: 28px; height: 100%;"
      >
        <v-container
          class="toolbar-title-container fill-height d-flex align-center"
          style="max-width: 1200px; width: auto;"
          :style="[$vuetify.breakpoint.mdAndDown ? {'padding': '0 44px'} : {'padding': 0}]"
        >
          <!-- TODO fix heading position -->
          <!-- :style="[$vuetify.breakpoint.mdAndDown ? {'margin-left': '20px'} : {'padding': 0}]" -->
          <v-toolbar-title
            style="color: #ffffff; opacity: 1; cursor: pointer;"
            class="header-title-transition"
          >
            <div
              class="app-title-container d-flex fill-height app-title"
              :class="[offsetTop > 80 ? 'flex-row align-center' : $vuetify.breakpoint.lgAndUp ? 'flex-row align-center' : 'flex-column justify-center align-start']"
            >
              <router-link
                v-slot="{ href, navigate }"
                custom
                :to="{ name: 'EEMonitorHome'}"
              >
                <div
                  :class="[offsetTop > 80 ? 'text-h5' :
                    $vuetify.breakpoint.smAndUp ? 'text-h3 font-weight-bold' : 'text-h4 font-weight-medium']"
                  @click="navigate"
                >
                  {{ application[`title_${locale}`] }}
                </div>
              </router-link>

              <div
                v-if="application[`subtitle_${locale}`].length !== 0"
                class="d-flex"
                style="width: 100%;"
              >
                <Transition
                  name="fade"
                  mode="in-out"
                  appear
                >
                  <span
                    v-if="(offsetTop > 80 && $vuetify.breakpoint.mdAndUp) || offsetTop < 10"
                    class="d-flex"
                    style="width: 100%;"
                    :style="[$vuetify.breakpoint.xsOnly ? {'white-space': 'break-spaces'} : {}]"
                    :class="[offsetTop > 80 ? 'text-h5 font-weight-normal' : $vuetify.breakpoint.lgAndUp ? 'text-h3' : $vuetify.breakpoint.mdOnly ? 'text-h4' : $vuetify.breakpoint.smOnly ? 'text-h5' : 'text-h6']"
                  >
                    <span
                      v-if="(offsetTop < 80 && $vuetify.breakpoint.lgAndUp) || (offsetTop > 80 && $vuetify.breakpoint.mdAndUp)"
                    >
                      &nbsp;-&nbsp;
                    </span>{{ application[`subtitle_${locale}`] }}
                  </span>
                </Transition>
              </div>
            </div>
          </v-toolbar-title>
        </v-container>
      </div>

      <div
        class="d-flex align-center fill-height header-title-transition"
        style="width: 100%;"
        :style="[offsetTop < 136 ? {'max-height': '36px'} : {}]"
        :class="[offsetTop < 136 ? '' : 'align-center']"
      >
        <v-app-bar-nav-icon
          class="app-bar-nav-icon"
          color="white"
          style="left: 8px;"
          @click="openDrawer"
        />

        <v-spacer />

        <!-- <BtnLogout
          v-if="$vuetify.breakpoint.mdAndUp"
        /> -->

        <BtnContactEmail
          v-if="$vuetify.breakpoint.smAndUp"
        />

        <BtnCititationLink
          v-if="$vuetify.breakpoint.smAndUp"
        />

        <SelectLocale />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import { eventBus } from '@/utils/eventbus.js'

import {
  mdiHome,
  mdiInformationOutline,
  mdiChevronRight,
  mdiChevronLeft,
  mdiTranslateVariant
} from '@mdi/js'

import SelectLocale from '@/components/main/button/BtnSelectLocale.vue'
import BtnContactEmail from '@/components/main/button/BtnContactEmail.vue'
import BtnCititationLink from '@/components/main/button/BtnCititationLink.vue'
// import BtnLogout from '@/components/main/button/BtnLogout.vue'

import DrawerMain from '@/components/main/navigation/DrawerMain.vue'

export default {
  name: 'HeaderHome',

  components: {
    SelectLocale,
    BtnContactEmail,
    BtnCititationLink,
    // BtnLogout,
    DrawerMain
  },

  props: {
    imageFileName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      showDrawerLocal: false,
      clipped: true,
      offsetTop: 0,
      appBarHeight: 200,
      mdiChevronRight: mdiChevronRight,
      mdiChevronLeft: mdiChevronLeft,
      mdiTranslateVariant: mdiTranslateVariant,
      mdiHome: mdiHome,
      mdiInformationOutline: mdiInformationOutline
    }
  },

  computed: {
    ...mapState('main', [
      'application',
      'applicationsItemSelected',
      'isNavigationDrawerOpen',
      'locale',
      'routes'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItems'
    ]),

    navigationDrawerTop () {
      return this.$vuetify.breakpoint.xs ? 0 : this.offsetTop <= 132 ? this.$vuetify.breakpoint.xs ? 150 - this.offsetTop : 182 + 40 - this.offsetTop : this.$vuetify.breakpoint.xs ? 48 : 36 + 48
    },

    navigationDrawerHeight () {
      return this.$vuetify.breakpoint.xs ? this.$vuetify.breakpoint.height : this.$vuetify.breakpoint.height - this.$vuetify.application.top
    },

    route () {
      return this.$route
    }
  },

  mounted () {
    // console.log(this.$vuetify.breakpoint)
  },

  methods: {
    ...mapMutations('main', [
      'REMOVE_TOKEN'
      // 'SET_LOCALE'
    ]),

    ...mapActions('main', [
      'set_state_navigation_drawer'
    ]),

    openDrawer () {
      this.set_state_navigation_drawer(!this.isNavigationDrawerOpen)
    },

    onScroll (e) {
      this.offsetTop = e.srcElement.scrollingElement.scrollTop
      // console.log('this.offsetTop ', this.offsetTop)
      // if (this.offsetTop > 120) {
      //   this.appBarHeight = 28
      // } else {
      //   this.appBarHeight = 200
      // }
      // console.log('e.scrollingElement.scrollTop ', e)
    },

    toggleTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      // localStorage.setItem('useDarkTheme', this.$vuetify.theme.dark.toString())
    },

    setItem (value) {
      // console.log(value.title)
      this.titlePage = `${this.titleMainPage} - ${value.title}`
    }

  }
}
</script>

<style lang="scss">

.nav_bar {
 .active {
  >div {
    background:rgb(225, 232, 238);
  }
  }
}

.nav_home .v-toolbar__content {
  padding: 0 !important;
}

// .v-toolbar__extension {
//   padding: 4px 12px;
// }

.v-toolbar__title {
  padding: 0 !important;
  height: 100% !important;
}

.v-app-bar--is-scrolled {
  .app-title {
    margin-left: 20px !important;
  }
}

.app-title {
  margin-left: 24px;
}

.header-title-transition {
   -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.drawer-resized {
  &.nav_home.v-navigation-drawer {
    top: 28px !important;
    max-height: calc(100% - 48px) !important;
  }
}

</style>
