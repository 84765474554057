<template>
  <div>
    <HeaderHome
      app
      :image-file-name="'abby-anaday-Nnn2Dc6niVU-unsplash.jpg'"
    />

    <v-container
      id="scroll-target"
      class="fill-height"
      style="max-width: 1200px; width: auto;"
    >
      <div>
        <BreadcrumbNav />
      </div>

      <v-row
        align="start"
      >
        <v-col>
          <v-card
            flat
            class="fill-height pa-6 mb-6"
            rounded="0"
          >
            <v-row
              no-gutters
              class="pb-1 flex-column"
              justify="start"
              align="stretch"
            >
              <v-col
                class="text-h4 font-weight-medium pb-2"
              >
                <div
                  class="text-h4 font-weight-medium"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    Anlangenstandorte der Erneuerbaren Energien
                  </span>
                  <span
                    v-else
                  >
                    Renewable energy plant locations
                  </span>
                </div>
              </v-col>

              <v-col
                class="text-body-1"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Der EE-Monitor bietet die Möglichkeit, sich die Standorte der Erneuerbare-Energien-Anlagen für Deutschland anzeigen zu lassen. Diese umfassen Windenergieanlagen, PV-Freiflächenanlagen, Bioenergieanlagen und Wasserkraftanlagen und beinhalten neben Standortinformationen auch betriebliche und technische Informationen zu den Anlagen. Über ein Filtermenü können die Anlagendaten nach bestimmten Kriterien wie z.B. der Höhe der installierten Leistung oder dem Inbetriebnahmejahr gefiltert werden.
                </span>
                <span
                  v-else
                >
                  The EE-Monitor offers the possibility to view the locations of renewable energy plants for Germany. These include wind energy plants, ground-mounted PV plants, bioenergy plants and hydropower plants and, in addition to location information, also contain operational and technical information on the plants. A filter menu can be used to filter the plant data according to certain criteria, such as the amount of installed capacity or the year of commissioning.
                </span>
              </v-col>
            </v-row>
          </v-card>

          <!-- APPLICATION -->

          <v-card
            flat
            class="fill-height pa-6"
            rounded="0"
            style="background: rgb(225, 232, 238)"
          >
            <v-row
              class="flex-column"
            >
              <v-col>
                <v-row>
                  <v-col
                    v-for="(item, index) in items"
                    :key="index"
                    :xl="12 / items.length"
                    :lg="12 / items.length"
                    :md="12 / items.length"
                    :sm="12"
                    :cols="12"
                  >
                    <v-card
                      elevation="0"
                      class="fill-height"
                      hover
                      rounded="0"
                    >
                      <router-link
                        v-slot="{ href, navigate }"
                        custom
                        :to="{name: item.routeName}"
                        class="d-flex flex-column justify-space-between fill-height"
                      >
                        <span
                          @click="navigate"
                        >

                          <div>
                            <v-img
                              class="white--text grow"
                              gradient="to bottom,rgba(0, 88, 156, 1),rgba(0, 88, 156, 0.3)"
                              :height="$vuetify.breakpoint.smAndUp ? '190px' : '220px'"
                              :src="require(`@/assets/img/${item.image}`)"
                            >
                              <div
                                class="d-flex align-start fill-height px-6 py-5"
                              >
                                <v-row>
                                  <v-col
                                    xs="12"
                                    cols="12"
                                    fill-height
                                  >
                                    <div
                                      class="text-h4"
                                    >
                                      {{ item[`title_${locale}`] }}
                                    </div>

                                    <div
                                      class="text-h5 mt-2"
                                    >
                                      {{ item[`subtitle_${locale}`] }}
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-img>

                            <v-card-text
                              class="pa-6"
                            >
                              <div
                                class="justify text-body-1 fill-height"
                              >
                                {{ item[`info_text_${locale}`] }}
                              </div>
                            </v-card-text>
                          </div>

                          <div
                            class="align-self-end"
                            style="bottom: 0; width: 100%;"
                          >
                            <v-card-actions>
                              <div
                                class="d-flex justify-end"
                                style="width: 100%;"
                              >
                                <v-btn
                                  color="primary"
                                  text
                                  plain
                                  :block="$vuetify.breakpoint.xsOnly"
                                  style="color: rgb(0, 88, 156); text-transform: none;"
                                  :to="{name: item.routeName}"
                                >
                                  {{ item[`route_button_title_${locale}`] }}
                                </v-btn>
                              </div>
                            </v-card-actions>
                          </div>
                        </span>
                      </router-link>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <EEMonitorPartnerFunding />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapState } from 'vuex'

import HeaderHome from '@/components/main/header/HeaderHome.vue'
import BreadcrumbNav from '@/components/main/navigation/BreadcrumbNav.vue'
import EEMonitorPartnerFunding from '@/components/base/info/PartnerFunding.vue'

export default {
  name: 'RePlantLocationsAbout',

  components: {
    BreadcrumbNav,
    EEMonitorPartnerFunding,
    HeaderHome
  },

  data () {
    return {
      items: [
        {
          image: 'webgis_2023.png',
          routeName: 'WebGisMain',
          route_button_title_de: 'Zu den Standortdaten',
          route_button_title_en: 'To the RE location data',
          title_de: 'Standortdaten der EE-Anlagen in Deutschland',
          title_en: 'Location data of RE plants in Germany',
          subtitle_de: 'Darstellung im webbasierten Geoinformationssystem (Web-GIS)',
          subtitle_en: 'Representation in the web-based geographic information system (Web-GIS)',
          info_text_de: 'Wo befinden sich EE-Anlagen in Deutschland? Die Web-GIS-Anwendung beinhaltet die kartographische Darstellung der Standortdaten inklusive ausgewählter Anlagenparameter. Sie stellt die Entwicklung der erneuerbaren Energien zur Stromerzeugung (Windenergie, Photovoltaik, Bioenergie, Wasserkraft) bis 2020 in Deutschland dar.',
          info_text_en: 'Where are RE plants located in Germany? The Web-GIS application contains the cartographic representation of the location data including selected plant parameters. It shows the development of renewable energies for electricity generation (wind energy, photovoltaics, bioenergy, hydropower) up to the year 2020 in Germany.'
        }
      ]
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  }
}
</script>

<style lang="scss" scoped>

</style>
