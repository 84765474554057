<template>
  <v-tooltip
    bottom
    no-gutters
    :disabled="$vuetify.breakpoint.mdAndUp"
  >
    <template
      #activator="{ on, attrs }"
    >
      <v-row
        no-gutters
        class="text-center category-list-item fill-height pa-2"
        :style="setItemBackgroundColor(item)"
        v-bind="attrs"
        v-on="on"
      >
        <v-col
          v-if="item.type.title_de === 'Technologie'"
          cols="12"
          class="d-flex justify-center align-center pb-2"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ `icon-technology-0` + item.value_id }}
          </v-icon>
        </v-col>

        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          cols="12"
          class="d-flex justify-center align-center"
          style="line-height: 1.15;"
        >
          <!-- <h3 v-if="item.title_de.length > 50">
            {{ item.title_de | truncateString(30) }}
          </h3> -->
          <div
            v-if="$vuetify.breakpoint.lgAndUp"
            class="text-h5"
          >
            {{ item[`title_${locale}`] }}
          </div>

          <div
            v-else-if="($vuetify.breakpoint.smAndDown && item.type.query_key === 'fieldofaction')"
            class="text-xs-h6"
            style="word-break: break-all;"
          >
            {{ item[`title_short_${locale}`] }}
          </div>

          <div
            v-else
          >
            <div
              v-if="$vuetify.breakpoint.mdOnly"
              class="text-xs-h6"
            >
              {{ item[`title_${locale}`] }}
            </div>
            <div
              v-else
              class="text-xs-h6"
            >
              {{ item[`title_short_${locale}`] }}
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <span>
      {{ item[`title_${locale}`] }}
    </span>
  </v-tooltip>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'SelectMainCategoryItemPage',

  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },
    isCategoryItemSeleced: {
      type: Boolean,
      default: false
    },
    categoryItemSelected: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    ...mapState('main', ['locale'])
  },

  // mounted () {
  // },

  // beforeDestroy () {
  // },

  methods: {
    setItemBackgroundColor (item) {
      if (this.isCategoryItemSeleced === false) {
        if (item.color_hex !== '') { return `background: #${item.color_hex}` }
      } else if (item.id === this.categoryItemSelected.id) {
        if (item.color_hex !== '') { return `background: #${item.color_hex}` }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.category-list-item {
  border: 0.5px solid var(--v-secondary-base);
  border-radius: 5px;
  // &:hover {
  //   background: var(--v-secondaryLight-base);
  //   transition: .2s ease-in-out;
  // }
}
.v-card {
  transition: .4s ease-in-out;
}
// .v-card:not(.on-hover) {
//   opacity: 0.6;
// }

</style>
