<template>
  <div
    class="fill-height"
  >
    <DashboardLoadingIndicator />

    <v-row
      dense
    >
      <!-- LEFT CHART AREA -->
      <v-col
        :xl="showSelectOptional ? 9 : 12"
        :lg="showSelectOptional ? 9 : 12"
        md="12"
        sm="12"
        cols="12"
      >
        <!-- ROW LEFT -->
        <v-row
          dense
        >
          <!-- CHART-MAP -->

          <v-col
            xl="5"
            lg="5"
            md="5"
            sm="12"
            cols="12"
            class="flex-nowrap flex-grow-0 flex-shrink-0"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="grow d-flex flex-column flex-nowrap"
              >
                <v-card
                  ref="card_charts_line_1"
                  rounded="0"
                  :elevation="cardElevation"
                  class="fill-height"
                  :style="{'height': cardHeight + 'px'}"
                >
                  <div
                    class="d-flex flex-column fill-height"
                  >
                    <ChartsMapWrapper
                      v-if="dashboardItem &&
                        itemData &&
                        dashboardItemsDataYears.length !== 0"
                      :dashboard-item="dashboardItem"
                      :height="cardHeight"
                      :spatial-type-id="spatialTypeId"
                      :item-data="itemData"
                    />
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <!-- CHARTs KONTEXT START -->

          <v-col
            xl="7"
            lg="7"
            md="7"
            sm="12"
            cols="12"
          >
            <v-row
              dense
              style="flex-wrap: nowrap;"
            >
              <v-col
                cols="12"
                class="flex-grow-0"
              >
                <v-card
                  rounded="0"
                  :elevation="cardElevation"
                  class=""
                  :style="{'height': cardHeight + 'px'}"
                >
                  <div
                    class="d-flex flex-column fill-height"
                  >
                    <div
                      v-if="dashboardItem &&
                        itemData &&
                        dashboardItemsDataYears.length !== 0"
                      class="fill-height"
                      style="height: 35%;"
                    >
                      <ChartsBarSpatialType1
                        v-if="dashboardItemsDataYears.length !==1"
                        :dashboard-item="dashboardItem"
                        :spatial-type-id="spatialTypeId"
                        :height="cardHeight"
                        :item-data="itemData"
                      />

                      <!-- <div
                        v-if="dashboardItemsDataYears.length ===1"
                        class="fill-height"
                      >
                        <InfoOneYear
                          :dashboard-item="dashboardItem"
                          :spatial-type-id="spatialTypeId"
                          :height="cardHeight"
                          :item-data="itemData"
                        />
                      </div> -->
                    </div>

                    <div
                      :style="[dashboardItemsDataYears.length !== 1 ? {height: '65%'} : {height: '100%'}]"
                      class="fill-height grow"
                    >
                      <ChartsLineLayoutStandard2
                        v-if="dashboardItem &&
                          itemData &&
                          dashboardItemsDataYears.length !== 0"
                        :dashboard-item="dashboardItem"
                        :spatial-type-id="spatialTypeId"
                        :height="dashboardItemsDataYears.length !== 1 ? cardHeight * 0.65 : cardHeight"
                        :item-data="itemData"
                      />
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- LEFT CHART AREA END  -->

      <!-- RIGHT MENU AREA START -->

      <v-col
        v-if="showSelectOptional && $vuetify.breakpoint.lgAndUp"
        xl="3"
        lg="3"
      >
        <v-card

          ref="card_optional_data_option"
          rounded="0"
          :elevation="cardElevation"
          class="section-scroll"
          :style="{'height': cardHeight + 'px'}"
        >
          <DashboardItemMenu
            v-if="dashboardItem &&
              itemData &&
              dashboardItemsDataYears.length !== 0 &&
              showSelectOptional"
            :select-query="selectOptional"
          />
        </v-card>
      </v-col>

      <!-- <v-navigation-drawer
        v-if="$vuetify.breakpoint.mdAndDown"
        :value="isOptionSidebarOpen"
        absolute
        temporary
        right
        width="300"
        @input="optionSidebarInput"
      >
        <DashboardItemMenu
          v-if="dashboardItem &&
            itemData &&
            dashboardItemsDataYears.length !== 0 &&
            showSelectOptional"
          :select-query="selectOptional"
        />
      </v-navigation-drawer> -->

      <!-- MAIN ROW END -->
    </v-row>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import ChartsBarSpatialType1 from '@/components/dashboard/charts/ChartsBarSpatialType1'
import ChartsLineLayoutStandard2 from '@/components/dashboard/charts/ChartsLineLayoutStandard2.vue'
import ChartsMapWrapper from '@/components/dashboard/charts/ChartsMapWrapper'
import DashboardItemMenu from '@/components/dashboard/inputs/DashboardItemMenu.vue'

import DashboardLoadingIndicator from '@/components/main/overlays/DashboardLoadingIndicator'

import InfoOneYear from '@/components/dashboard/infobox/InfoOneYear.vue'

import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'DashboardLayoutStandard2',

  components: {
    ChartsBarSpatialType1,
    ChartsLineLayoutStandard2,
    ChartsMapWrapper,
    DashboardItemMenu,
    DashboardLoadingIndicator
    // InfoOneYear
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    cardHeight: {
      type: Number,
      default: 0
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    spatialTypeId: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      cardElevation: 0,
      showSelectOptional: undefined,
      selectOptional: [],
      itemOptionalSelect: []
    }
  },

  computed: {
    // ...mapState('dashboard_main', [
    //  // 'isOptionSidebarOpen'
    // ]),

    ...mapState('dashboard_items', [
      'dashboardItemsSelectsOptional',
      'dashboardItemsDataYears'
    ]),

    colNumberCardMap () {
      return this.showSelectOptional ? 4 : 5
    },

    colNumberCardChart () {
      return this.showSelectOptional ? 8 : 7
    }
  },

  watch: {
    dashboardItemsSelectsOptional (newVal, oldVal) {
      this.selectOptional = this.getSelectOptional()

      // TODO quick fix workarround - needs better way
      this.itemOptionalSelect = this.dashboardItemsSelectsOptional.filter(opt => opt.type.query_type === 'api')
    }
  },

  // mounted () {
  //   // this.getSelectOptional()
  // },

  methods: {
    ...mapActions('dashboard_main', [
      'set_state_option_sidebar'
    ]),

    optionSidebarInput (value) {
      this.set_state_option_sidebar(value)
    },

    getSelectOptional () {
      if (this.dashboardItemsSelectsOptional) {
        if (this.dashboardItemsSelectsOptional.length !== 0) {
          const filteredSelect = this.dashboardItemsSelectsOptional.filter(opt => opt.type.select_type === 'select-panel-list')

          if (filteredSelect.length !== 0) {
            this.showSelectOptional = true

            return [filteredSelect[0].type.query_key]
          } else {
            this.showSelectOptional = false

            eventBus.$emit('open-item-description')

            return []
          }
        } else {
          this.showSelectOptional = false

          eventBus.$emit('open-item-description')

          return []
        }
      } else {
        eventBus.$emit('open-item-description')

        this.showSelectOptional = false
      }
    }

  }

}
</script>

<style lang="scss" scoped>

</style>
