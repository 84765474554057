<template>
  <div>
    <HeaderHome
      app
      :image-file-name="'solarpark-Bockelwitz-2_schinkel.jpg'"
    />

    <v-container
      id="scroll-target"
      class="fill-height"
      style="max-width: 1200px; width: auto;"
    >
      <div>
        <BreadcrumbNav />
      </div>

      <v-row
        align="start"
      >
        <v-col>
          <v-card
            flat
            class="fill-height pa-6 mb-6"
            rounded="0"
          >
            <v-row
              no-gutters
              class="pb-1 flex-column"
              justify="start"
              align="stretch"
            >
              <v-col
                class="text-h4 font-weight-medium pb-2"
              >
                <div
                  class="text-h4 font-weight-medium"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    Kennzahlen des Monitoring-Reports
                  </span>
                  <span
                    v-else
                  >
                    Indicators of the Monitoring-Report
                  </span>
                </div>
              </v-col>

              <v-col
                class="text-body-1"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Das Monitoring des EE-Monitors umfasst 41 Kennzahlen, die die Naturverträglichkeit des Ausbaus der Erneuerbaren Energien nach Technologien im Zeitverlauf datengestützt beschreiben. Die Kennzahlen umfassen explizit naturschutzfachliche, aber auch energiewirtschaftliche Aspekte des Ausbaus der erneuerbaren Energien. Die Kennzahlen sind naturschutzfachlichen Zielfeldern zugeordnet, die Anforderungen an eine naturverträgliche Energiewende formulieren. Eine naturverträgliche Energiewende in diesem Sinne bedeutet, den Energiebedarf der Gesellschaft umweltverträglich und nachhaltig ohne Beeinträchtigung von Natur und Umwelt zu decken und mit den Erfordernissen des Umwelt- und Naturschutzes in Einklang zu bringen. Ziel ist es, durch geeignete Maßnahmen den ökologischen Fußabdruck der Energieerzeugung zu minimieren, Ökosysteme zu schützen und die biologische Vielfalt zu erhalten. Dazu gehört auch die effiziente Nutzung von Energie, um den Energiebedarf zu senken und die Umweltauswirkungen zu minimieren. Die Zielfelder einer naturverträglichen Energiewende basieren im Wesentlichen auf dem 5-Punkte-Papier (BMU 2017) sowie dem <a
                    href="https://www.bfn.de/publikationen/bfn-report/erneuerbare-energien-report"
                    target="_blank"
                  >Erneuerbare Energien Report des Bundesamtes für Naturschutz (BfN 2020)</a>.
                </span>
                <span
                  v-else
                >
                  indicators that describe the nature compatibility of the expansion of renewable energies by technology over time in a data-based manner. The indicators explicitly cover nature conservation aspects, but also energy management aspects of the expansion of renewable energies. The indicators are assigned to nature conservation target areas that formulate requirements for a nature-friendly energy transition. A nature-compatible energy transition in this sense means meeting society's energy needs in an environmentally compatible and sustainable manner without harming nature and the environment, and reconciling this with the requirements of environmental protection and nature conservation. The aim is to minimize the ecological footprint of energy generation, protect ecosystems and preserve biodiversity through appropriate measures. This includes the efficient use of energy to reduce energy demand and minimize environmental impact. The target areas are mainly based on the 5-point paper (BMU 2017) and the <a
                    href="https://www.bfn.de/publikationen/bfn-report/erneuerbare-energien-report"
                    target="_blank"
                  >Renewable Energies Report of the Federal Agency for Nature Conservation (BfN 2020)</a>.
                </span>
              </v-col>
            </v-row>
          </v-card>

          <!-- ACCORDEON TARGET AREAS -->

          <v-card
            flat
            class="fill-height pa-6 mb-6"
            rounded="0"
          >
            <v-row
              no-gutters
              class="pb-1 flex-column"
              justify="start"
              align="stretch"
            >
              <v-col
                class="text-h4 pb-2"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Zielfelder einer naturverträglichen Energiewende
                </span>
                <span
                  v-else
                >
                  Target areas of nature-friendly energy transition
                </span>
              </v-col>
              <v-col
                class="text-h5 pb-5"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Die folgenden Zielfelder umschreiben die Anforderung an eine naturverträgliche Energiewende:
                </span>
                <span
                  v-else
                >
                  The following target areas describe the requirements for an environmentally compatible energy transition:
                </span>
              </v-col>

              <v-col
                class="text-body-1"
              >
                <v-expansion-panels
                  flat
                  tile
                  class="text-expansion-panels pb-0"
                >
                  <!-- PANEL 1-->

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="background: rgb(225, 232, 238); border: dotted 1px var(--v-secondary-lighten1);"
                    >
                      <template
                        #actions
                      >
                        <v-icon
                          color="var(--v-primary-secondary)"
                          size="24"
                        >
                          {{ mdiChevronDown }}
                        </v-icon>
                      </template>
                      <span
                        class="text-h5"
                      >
                        <span
                          v-if="locale === 'de'"
                        >
                          EE-Ausbau, Energieeffizienz- und -einsparung
                        </span>
                        <span
                          v-else
                        >
                          RE Expansion, energy efficiency and energy saving targets
                        </span>
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      style="border: dotted 1px var(--v-secondary-lighten1);"
                      class="pt-6"
                    >
                      <v-row
                        no-gutters
                        class="pb-1 flex-column"
                        justify="start"
                        align="stretch"
                      >
                        <v-col
                          class="text-body-1"
                        >
                          <span
                            v-if="locale === 'de'"
                          >
                            Der Klimawandel verändert die Lebensräume von Tieren und Pflanzen und bedroht damit die biologische Vielfalt. Die Dekarbonisierung der (Energie-)Wirtschaft und der damit verbundene Ausbau der erneuerbaren Energien reduzieren die Treibhausgasemissionen und tragen damit zum Klimaschutz bei.
                            Der Ausbau erneuerbarer Energien reicht jedoch für eine naturverträgliche Energiewende und den Klimaschutz nicht aus. Die günstigste und sauberste Energie ist die Energie, die gar nicht erst verbraucht wird.erzeugt werden muss.  Aus diesem Grund hat die Europäische Kommission, die „Energieeffizienz an erster Stelle“ im EU-Recht verankert (Richtlinie 2012/27/EU).
                            In diesem Sinne sind die Effizienzsteigerung des gesamten Energieversorgungssystems und Energieeinsparungen zentrale Ziele einer naturverträglichen Energiewende.
                          </span>
                          <span
                            v-else
                          >
                            Climate change is altering animal and plant habitats and thus threatening biodiversity. The decarbonisation of the (energy) economy and the associated expansion of renewable energies reduce greenhouse gas emissions and thus contribute to climate protection.
                            However, the expansion of renewable energies is not sufficient for an energy transition that is compatible with nature and climate protection. The cheapest and cleanest energy is energy that does not need to be produced in the first place.  For this reason, the European Commission has anchored "energy efficiency first" in EU law (Directive 2012/27/EU). In this sense, increasing the efficiency of the entire energy supply system and saving energy are central goals of an environmentally compatible energy transition.

                          </span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- PANEL 2 -->

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="background: rgb(225, 232, 238); border: dotted 1px var(--v-secondary-lighten1);"
                    >
                      <template
                        #actions
                      >
                        <v-icon
                          color="var(--v-primary-secondary)"
                          size="24"
                        >
                          {{ mdiChevronDown }}
                        </v-icon>
                      </template>
                      <span
                        class="text-h5"
                      >
                        <span
                          v-if="locale === 'de'"
                        >
                          Vermeidung von Flächen besonderer Bedeutung für Naturschutz und Landschaftspflege
                        </span>
                        <span
                          v-else
                        >
                          Avoid areas of special importance for nature conservation and landscape management
                        </span>
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      style="border: dotted 1px var(--v-secondary-lighten1);"
                      class="pt-6"
                    >
                      <v-row
                        no-gutters
                        class="pb-1 flex-column"
                        justify="start"
                        align="stretch"
                      >
                        <v-col
                          class="text-body-1"
                        >
                          <span
                            v-if="locale === 'de'"
                          >
                            Naturnahe Landschaften ohne technische Überprägung sind zu erhalten. Geschützte Teile von Natur und Landschaft sind beim Ausbau der erneuerbaren Energien entsprechend den für sie geltenden Vorschriften zu berücksichtigen. Insbesondere sind Flächen mit besonderer Bedeutung für die Erhaltungs- und Entwicklungsziele des Naturschutzes und der Landschaftspflege bei der Standortwahl auszuschließen (z. B. Naturschutzgebiete und Nationalparks).
                          </span>
                          <span
                            v-else
                          >
                            Near-natural landscapes without technical overdevelopment are to be preserved. Protected parts of nature and the landscape shall be taken into account in the expansion of renewable energies in accordance with the regulations applicable to them. In particular, areas of special importance for the conservation and development objectives of nature conservation and landscape management shall be excluded when selecting sites (e.g. nature conservation areas and national parks).
                          </span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- PANEL 3 -->

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="background: rgb(225, 232, 238); border: dotted 1px var(--v-secondary-lighten1);"
                    >
                      <template
                        #actions
                      >
                        <v-icon
                          color="var(--v-primary-secondary)"
                          size="24"
                        >
                          {{ mdiChevronDown }}
                        </v-icon>
                      </template>
                      <span
                        class="text-h5"
                      >
                        <span
                          v-if="locale === 'de'"
                        >
                          Kein weiterer Ausbau von Technologien mit ausgeschöpftem naturverträglichem Nutzungspotenzial
                        </span>
                        <span
                          v-else
                        >
                          No further expansion of technologies with exhausted nature-compatible utilisation potential
                        </span>
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      style="border: dotted 1px var(--v-secondary-lighten1);"
                      class="pt-6"
                    >
                      <v-row
                        no-gutters
                        class="pb-1 flex-column"
                        justify="start"
                        align="stretch"
                      >
                        <v-col
                          class="text-body-1"
                        >
                          <span
                            v-if="locale === 'de'"
                          >
                            Energieumwandlungstechnologien, die Natur und Umwelt im Verhältnis zum Energiegewinn überproportional belasten, sollten nicht weiter ausgebaut und nach Möglichkeit zurückgebaut werden. So hat der Ausbau der Technologiepfade Bioenergie und Wasserkraft aus naturschutzfachlicher Sicht bereits seine naturverträglichen Grenzen erreicht. Biomasse hat insbesondere vor dem Hintergrund von Flächennutzungskonkurrenzen kein weiteres Ausbaupotenzial für die reine Stromerzeugung. Vielmehr sollte Biomasse am Ende ihrer Verwertungskette, also als Rest- und Abfallstoff, energetisch genutzt werden, um einerseits Ressourcen zu schonen und andererseits natürliche Lebensräume zu erhalten. Auch das naturverträgliche Nutzungspotenzial der Wasserkraft ist insbesondere aufgrund der Barrierewirkung in Fließgewässern weitgehend ausgeschöpft und kann nur durch Modernisierung und Ausbau bestehender Wasserkraftanlagen mit einer installierten Leistung von mehr als 1 Megawatt erschlossen werden.
                          </span>
                          <span
                            v-else
                          >
                            Energy conversion technologies that place a disproportionate burden on nature and the environment in relation to the energy gained should not be further expanded and, if possible, should be dismantled. For example, the expansion of bioenergy and hydropower has already reached its limits from a nature conservation perspective. Biomass has no further expansion potential for pure electricity generation, especially against the background of land-use competition. Rather, biomass should be used for energy at the end of its utilisation chain, i.e. as residual and waste material, in order to conserve resources on the one hand and to preserve natural habitats on the other. The nature-compatible utilisation potential of hydropower is also largely exhausted, particularly due to the barrier effect in flowing waters, and can only be tapped by modernising and expanding existing hydropower plants with an installed capacity of more than 1 megawatt.
                          </span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- PANEL 4 -->

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="background: rgb(225, 232, 238); border: dotted 1px var(--v-secondary-lighten1);"
                    >
                      <template
                        #actions
                      >
                        <v-icon
                          color="var(--v-primary-secondary)"
                          size="24"
                        >
                          {{ mdiChevronDown }}
                        </v-icon>
                      </template>
                      <span
                        class="text-h5"
                      >
                        <span
                          v-if="locale === 'de'"
                        >
                          Naturverträgliche Ausgestaltung von EE-Anlagen
                        </span>
                        <span
                          v-else
                        >
                          Nature-compatible design of RE installations
                        </span>
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      style="border: dotted 1px var(--v-secondary-lighten1);"
                      class="pt-6"
                    >
                      <v-row
                        no-gutters
                        class="pb-1 flex-column"
                        justify="start"
                        align="stretch"
                      >
                        <v-col
                          class="text-body-1"
                        >
                          <span
                            v-if="locale === 'de'"
                          >
                            Beim Bau und Betrieb von EE-Anlagen können standortbedingte Konflikte mit Natur und Landschaft durch Vermeidungsmaßnahmen reduzierenreduziert werden. So kann die Gestaltung der Anlage zu einer verbesserten Naturverträglichkeit führen und der Betrieb durch geeignete Maßnahmen an die jeweiligen Gegebenheiten angepasst werden.
                            In diesem Sinne ist die technische Weiterentwicklung von EE-Anlagen im Hinblick auf Naturverträglichkeit und technischen Vermeidungsmaßnahmen (z. B. temporäre Abschaltung von Windenergieanlagen während Vogelzugereignissen, fledermausfreundliche Betriebsalgorithmen zur Reduzierung von Fledermausschlag, technische Erkennungssysteme wie z. B. Kameras) entscheidend.
                          </span>
                          <span
                            v-else
                          >
                            When constructing and operating RE plants, site-related conflicts with nature and the landscape can be reduced through avoidance measures. In this way, the design of the plant can lead to improved nature compatibility and the operation can be adapted to the respective conditions through suitable measures. In this sense, the technical further development of RE plants with regard to nature compatibility and technical avoidance measures (e.g. temporary shutdown of wind turbines during bird migration events, bat-friendly operating algorithms to reduce bat impact, technical detection systems such as cameras) is crucial.
                          </span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- PANEL 5 -->

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="background: rgb(225, 232, 238); border: dotted 1px var(--v-secondary-lighten1);"
                    >
                      <template
                        #actions
                      >
                        <v-icon
                          color="var(--v-primary-secondary)"
                          size="24"
                        >
                          {{ mdiChevronDown }}
                        </v-icon>
                      </template>
                      <span
                        class="text-h5"
                      >
                        <span
                          v-if="locale === 'de'"
                        >
                          Verbrauchsnaher Ausbau
                        </span>
                        <span
                          v-else
                        >
                          Expansion close to consumption
                        </span>
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      style="border: dotted 1px var(--v-secondary-lighten1);"
                      class="pt-6"
                    >
                      <v-row
                        no-gutters
                        class="pb-1 flex-column"
                        justify="start"
                        align="stretch"
                      >
                        <v-col
                          class="text-body-1"
                        >
                          <span
                            v-if="locale === 'de'"
                          >
                            Ein Großteil der erneuerbaren Energien wird fernab der Verbrauchszentren erzeugt. Zur Sicherung der Energieversorgung ist daher ein Ausbau der Übertragungsnetze erforderlich. Der Netzausbau hat aber erhebliche Auswirkungen auf die Schutzgegenstände des Naturschutzes. Je länger insbesondere das Freileitungsnetz wird, desto wahrscheinlicher sind Konflikte mit dem Natur- und Landschaftsschutz.
                            Eine Stärkung des verbrauchsnahen Ausbaus kann dazu beitragen, die  notwendigen Hochspannungsübertragungsnetze zu reduzieren und  die Akzeptanz erneuerbarer Energien in der EEBevölkerung zu erhöhen. Auf diese Weise können auch urbane Räume zu Trägern der  Energiewende werden.
                          </span>
                          <span
                            v-else
                          >
                            A large part of renewable energy is generated far from the centres of consumption. Therefore, an expansion of the transmission grids is necessary to secure the energy supply. However, grid expansion has a considerable impact on the objects of nature conservation protection. The longer the overhead line network in particular becomes, the more likely conflicts with nature conservation and landscape protection are. Strengthening expansion close to consumption can help to reduce the necessary high-voltage transmission grids and increase the acceptance of RE. In this way, urban areas can also become carriers of the energy transition.
                          </span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- PANEL 6 -->

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="background: rgb(225, 232, 238); border: dotted 1px var(--v-secondary-lighten1);"
                    >
                      <template
                        #actions
                      >
                        <v-icon
                          color="var(--v-primary-secondary)"
                          size="24"
                        >
                          {{ mdiChevronDown }}
                        </v-icon>
                      </template>
                      <span
                        class="text-h5"
                      >
                        <span
                          v-if="locale === 'de'"
                        >
                          Minimierte Flächeninanspruchnahme
                        </span>
                        <span
                          v-else
                        >
                          Minimised land use
                        </span>
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      style="border: dotted 1px var(--v-secondary-lighten1);"
                      class="pt-6"
                    >
                      <v-row
                        no-gutters
                        class="pb-1 flex-column"
                        justify="start"
                        align="stretch"
                      >
                        <v-col
                          class="text-body-1"
                        >
                          <span
                            v-if="locale === 'de'"
                          >
                            Aufgrund ihres dezentralen Charakters ist mit dem Ausbau der erneuerbaren Energien eine erhebliche Flächeninanspruchnahme verbunden.
                            EE-Anlagen nehmen oftmals Acker- und Grünlandflächen in Anspruch und versiegeln sie teilweise. Daher ist die Flächeneffizienz ein zentrales Thema und neben den Anlagenzahlen vor allem die Flächeninanspruchnahme von Bedeutung, um deren Wirkung und Präsenz im Raum zu bewerten. Die verschiedenen erneuerbaren Energieträger haben technologiebedingt einen sehr unterschiedlichen Flächenbedarf bzw. Energieertrag pro Flächeneinheit. Dementsprechend sind Technologien mit geringere Flächeninanspruchnahme zu bevorzugen (BfN, 2020).
                          </span>
                          <span
                            v-else
                          >
                            Due to its decentralised character, the expansion of renewable energies is associated with a considerable land use.
                            RE installations often take up arable land and grassland and partially seal it. Therefore, land efficiency is a key issue and, in addition to the number of plants, the land take is of particular importance in order to assess their impact and presence in space. Due to the technology, the various renewable energy sources have very different land requirements or energy yields per unit of area. Accordingly, technologies with low land use are to be preferred (BfN, 2020).
                          </span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- PANEL END -->
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card>

          <v-card
            flat
            class="fill-height pa-6"
            rounded="0"
            style="background: rgb(225, 232, 238)"
          >
            <v-row
              class="flex-column"
            >
              <v-col
                class="pb-3"
              >
                <div
                  class="text-h4 pb-2"
                >
                  <span
                    v-if="locale === 'de'"
                  >
                    Die zwei Perspektiven für den Monitoring-Report
                  </span>
                  <span
                    v-else
                  >
                    The two perspectives for the Monitoring-Report
                  </span>
                </div>

                <div
                  class="text-h5"
                >
                  <span
                    v-if="locale === 'de'"
                  >
                    Die Kennzahlen des Monitoring-Reports können über die Kennzahlenübersicht oder im Regionalbericht für ein bestimmtes Gebiet, z.B. einen Landkreis, aufgerufen werden.
                  </span>
                  <span
                    v-else
                  >
                    The indicators of the Monitoring-Report can be accessed via the indicator overview or in the regional report for a specific area, e.g. a district.
                  </span>
                </div>
              </v-col>

              <v-col>
                <v-row>
                  <v-col
                    v-for="(item, index) in items"
                    :key="index"
                    :xl="12 / items.length"
                    :lg="12 / items.length"
                    :md="12 / items.length"
                    :sm="12"
                    :cols="12"
                  >
                    <v-card
                      elevation="0"
                      class="fill-height"
                      hover
                      rounded="0"
                    >
                      <router-link
                        v-slot="{ href, navigate }"
                        custom
                        :to="{name: item.routeName}"
                        class="d-flex flex-column justify-space-between fill-height"
                      >
                        <span
                          @click="navigate"
                        >

                          <div>
                            <v-img
                              class="white--text grow"
                              gradient="to bottom,rgba(0, 88, 156, 1),rgba(0, 88, 156, 0.3)"
                              :height="$vuetify.breakpoint.smAndUp ? '190px' : '220px'"
                              :src="require(`@/assets/img/${item.image}`)"
                            >
                              <v-container
                                fill-height
                                align-start
                                class="px-6 py-5"
                              >
                                <v-row>
                                  <v-col
                                    xs="12"
                                    cols="12"
                                    fill-height
                                  >
                                    <div
                                      class="text-h4"
                                    >
                                      {{ item[`title_${locale}`] }}
                                    </div>

                                    <div
                                      class="text-h5 mt-2"
                                    >
                                      {{ item[`subtitle_${locale}`] }}
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-img>

                            <v-card-text
                              class="pa-6"
                            >
                              <div
                                class="justify text-body-1 fill-height"
                              >
                                {{ item[`info_text_${locale}`] }}
                              </div>
                            </v-card-text>
                          </div>

                          <div
                            class="align-self-end"
                            style="bottom: 0; width: 100%;"
                          >
                            <v-card-actions>
                              <div
                                class="d-flex justify-end"
                                style="width: 100%;"
                              >
                                <v-btn
                                  color="primary"
                                  text
                                  plain
                                  :block="$vuetify.breakpoint.xsOnly"
                                  style="color: rgb(0, 88, 156); text-transform: none;"
                                  :to="{name: item.routeName}"
                                >
                                  {{ item[`route_button_title_${locale}`] }}
                                </v-btn>
                              </div>
                            </v-card-actions>
                          </div>
                        </span>
                      </router-link>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <EEMonitorPartnerFunding />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BreadcrumbNav from '@/components/main/navigation/BreadcrumbNav.vue'
import EEMonitorPartnerFunding from '@/components/base/info/PartnerFunding.vue'
import HeaderHome from '@/components/main/header/HeaderHome.vue'

import { mdiChevronDown } from '@mdi/js'

export default {
  name: 'EEMonReportAbout',

  components: {
    BreadcrumbNav,
    EEMonitorPartnerFunding,
    HeaderHome
  },

  data () {
    return {
      mdiChevronDown: mdiChevronDown,
      items: [
        {
          image: 'kennzahlenauswahl_screenshot.jpg',
          info_text_de: 'Die Kennzahlenübersicht bietet die Möglichkeit, sich alle 41 Kennzahlen auf einen Überblick anzeigen zu lassen. Die Kennzahlen sind dabei fünf Technologien zugeordnet, nach denen sie sortiert werden können („Technologie“): Windenergie, Solarenergie, Bioenergie, Wasserkraft, Freileitungen und allgemeine Energiedaten. Alternativ kann die Sortierung nach den Zielfeldern einer naturverträglichen Energiewende vorgenommen werden ("Zielfelder").',
          info_text_en: 'The indicator overview offers the possibility of displaying all 41 indicators at a glance. The indicators are assigned to five technologies, according to which they can be sorted ("Technology"): Wind energy, solar energy, bioenergy, hydropower, overhead lines and general energy data. Alternatively, they can be sorted according to the target fields of an nature-friendly energy transition ("Target areas").',
          routeName: 'SelectMain',
          route_button_title_de: 'Zur Kennzahlübersicht',
          route_button_title_en: 'To the indicator overview',
          subtitle_de: 'Die Kennzahlen zum Monitoring für eine naturverträgliche Energiewende',
          subtitle_en: 'The indicators for nature conservation monitoring',
          title_de: 'Kennzahlenübersicht',
          title_en: 'Indicators overview'
        },

        {
          image: 'regionalbericht_screenshot.jpg',
          info_text_de: 'Der Regionalbericht ermöglicht eine regionenspezifische Perspektive auf die 41 Kennzahlen. Die verfügbaren Werte können national, auf Bundesland- und Landkreisebene betrachtet und nach den jeweiligen Technologien ausgewählt werden. Es ist möglich, von den regionenspezifischen Werten direkt zur umfassenden und regionenübergreifenden Ansicht der Kennzahlwerte zu gelangen.',
          info_text_en: 'The regional report provides a region-specific perspective on the 41 indicators. The available values can be viewed nationally, at the state and county level, and selected according to the respective technologies. It is possible to go directly from the region-specific values to the comprehensive and cross-regional view of the indicator values.',
          routeName: 'ReportView',
          route_button_title_de: 'Zum Regionalbericht',
          route_button_title_en: 'To the regional report',
          subtitle_de: 'Monitoring-Kennzahlen für eine naturverträgliche Energiewende auf regionaler Ebene im Überblick',
          subtitle_en: 'Overview of monitoring indicators for a nature-friendly energy transition at regional level',
          title_de: 'Regionalbericht',
          title_en: 'Regional report'
        }
      ]
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  }
}
</script>

<style lang="scss" scoped>

</style>
