<template>
  <div
    class="fill-height"
  >
    <v-sheet
      class="d-flex flex-column fill-height"
    >
      <div
        style="width: 100%; background: rgb(225, 232, 238); z-index: 2;"
        class="align-self-start px-6 py-3"
      >
        <div
          class="mb-1"
          :class="[$vuetify.breakpoint.xsOnly ? 'text-h5' : 'text-h4']"
        >
          Monitoring-Report
        </div>

        <div
          style="line-height: 1rem;"
          :class="[$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h6']"
        >
          <span
            v-if="locale==='de'"
          >
            Kennzahlen-Highlights
          </span>
          <span
            v-else
          >
            Indicator highlights
          </span>
        </div>
      </div>

      <v-carousel
        :value="carouselValue"
        :show-arrows="false"
        hide-delimiter-background
        cycle
        :height="$vuetify.breakpoint.mdAndUp ? '100%' : '400px'"
        :interval="12000"
      >
        <v-carousel-item
          v-for="(item, index) in carouselItems"
          :key="index"
          :src="require(`@/assets/img/slideshow/${item.imgFilename}`)"
        >
          <div
            class="d-flex flex-column fill-height"
          >
            <v-row
              align="end"
              justify="center"
              class="flex-column"
              style="height: 100%;"
              no-gutters
            >
              <!-- <v-col
                style="height: auto;"
              >
                <div
                  style="width: 100%; position: absolute; background: rgba(255,255,255,0.55);"
                  class="align-self-start px-3 py-2"
                >
                  <div
                    class="mb-1"
                    :class="[$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h4']"
                  >
                    Monitoring-Report
                  </div>

                  <div
                    style="line-height: 1rem;"
                    :class="[$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h6']"
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Kennzahlen-Highlights
                    </span>
                    <span
                      v-else
                    >
                      Indicator highlights
                    </span>
                  </div>
                </div>
              </v-col> -->

              <v-col
                class="d-flex align-end align-sm-center align-md-end px-2 pb-6 pa-sm-3 pa-md-6"
                style="width: 100%; margin-bottom: 26px;"
              >
                <div
                  style="height: fit-content; background: rgba(0, 62, 110, 0.85); width: 100%; cursor: pointer;"
                  class="d-flex flex-column justify-start align-center align-md-end pa-4"
                  @click="goTo(item.link)"
                >
                  <div
                    class="white--text mb-3"
                    :class="$vuetify.breakpoint.xsOnly ? 'text-body-2' : 'text-sm-h6 text-md-h5 text-md-h5'"
                  >
                    {{ item[`title_${locale}`] }}
                  </div>

                  <v-btn
                    depressed
                    color="rgba(255,255,255,0.8)"
                    style="text-transform: none;"
                    :small="$vuetify.breakpoint.smOnly"
                    :x-small="$vuetify.breakpoint.xsOnly"
                    @click="goTo(item.link)"
                  >
                    <span>
                      {{ item[`link_title_${locale}`] }}
                    </span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-sheet>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HomeSlideshow',

  data () {
    return {
      carouselValue: 0,
      carouselItems: [
        {
          id: 1,
          imgFilename: '2023-12-Bird-View-Solar-Plant-Motorway.jpg',
          link: '/report/photovoltaik-freiflaechenanlagen-neben-verkehrswegen',
          title_de: '39% der PV-Freiflächenanlagen befinden sich auf dem 500m-Streifen neben Fernverkehrswegen',
          title_en: '39% of ground-mounted PV systems are located on the 500m strip next to long-distance transport routes',
          link_title_de: 'Kennzahl anzeigen',
          link_title_en: 'Show indicator'
        },
        {
          id: 2,
          imgFilename: 'max-bottinger-gRT-IRygEhg-unsplash.jpg',
          link: '/report/region/bundesrepublik-deutschland/',
          title_de: 'Auf einen Hektar Schutzgebiet fallen 1,36 Meter Freileitungen',
          title_en: 'On one hectare of protected area falls 1.36 meters of overhead lines',
          link_title_de: 'Zum Regionalbericht',
          link_title_en: 'Open regional report'
        },
        {
          id: 3,
          imgFilename: 'pexels-skitterphoto-1122164.jpg',
          link: '/report/anzahl-von-windenergieanlagen-in-schutzgebieten',
          title_de: 'In Nordrheinwestfalen befinden sich 72 Windenergieanlagen in Europäischen Vogelschutzgebieten',
          title_en: 'In North Rhine-Westphalia, 72 wind turbines are located in European bird sanctuaries',
          link_title_de: 'Kennzahl anzeigen',
          link_title_en: 'Show indicator'
        }
      ]
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  },

  methods: {
    goTo (link) {
      this.$router.push({
        path: link
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
