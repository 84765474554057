<template>
  <div>
    <v-hover>
      <template
        #default="{ hover }"
      >
        <v-card
          ref="select_item_card"
          class="card-item"
          height="100%"
          elevation="1"
          rounded="5"
          style="min-height: 220px; cursor: pointer; border: 1px dotted var(--v-secondary-lighten1) !important;"
        >
          <v-row
            no-gutters
            class="d-flex flex-column align-stretch fill-height"
          >
            <v-col>
              <v-row
                no-gutters
                class="d-flex align-stretch fill-height"
              >
                <v-col
                  cols="10"
                  class="pr-4 pl-3 py-2"
                >
                  <v-row
                    class="flex-column pb-1"
                    no-gutters
                  >
                    <v-col
                      class="pb-1 break-words"
                    >
                      <div
                        class="text-h5"
                      >
                        {{ item[`title_${locale}`] }}
                      </div>
                    </v-col>

                    <!-- <v-col
                      style="font-style: italic;"
                      class="break-words"
                    >
                      <span>
                        Handlungsfeld:
                      </span>
                    </v-col> -->
                  </v-row>

                  <!-- <v-row no-gutters>
                    <v-col>
                      {{ item.detail_info[0][`info_text_teaser_${locale}`] }}
                    </v-col>
                  </v-row> -->
                </v-col>

                <v-col
                  cols="2"
                  class="text-center pa-3 pt-4"
                  style="border-radius: 5px;"
                >
                  <v-icon
                    color="primary"
                    style="width: auto"
                    size="26"
                  >
                    {{ `icon-technology-0` + getItemTechnology.value_id }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              class="d-flex align-end"
            >
              <v-row
                v-if="item"
                class="no-gutters"
              >
                <v-col
                  class="d-flex align-stretch"
                >
                  <v-row
                    class="no-gutters flex-column"
                  >
                    <v-col
                      class="px-3 py-1 d-flex align-center fill-height"
                      style="min-height: 78px;"
                      :style="setItemBackgroundColor(item)"
                    >
                      <!-- style="min-height: 70px; border-radius: 0 0 5px 5px;" -->
                      <div>
                        <span
                          class="text-h6 font-weight-light"
                        >
                          {{ getFieldOfAction.type[`title_${locale}`] }}:
                        </span>

                        <span
                          class="text-h6 font-weight-regular font-italic"
                        >
                          {{ getFieldOfAction[`title_${locale}`] }}
                        </span>
                      </div>
                    </v-col>

                    <v-col
                      class="d-flex align-center fill-height px-3"
                    >
                      <v-chip-group
                        active-class="primary--text"
                        column
                      >
                        <span
                          v-if="item.has_data"
                        >
                          <v-chip
                            v-for="(spatialValue, index) in item.data_options[0].values"
                            :key="index"
                            color="var(--v-primary-base);"
                            label
                            outlined
                            x-small
                          >
                            {{ spatialValue[`title_${locale}`] }}
                          </v-chip>
                        </span>

                        <span
                          v-else
                        >
                          <v-chip
                            color="var(--v-primary-base);"
                            label
                            outlined
                            x-small
                          >
                            <span
                              v-if="locale==='de'"
                            >
                              keine Daten verfügbar
                            </span>
                            <span
                              v-else
                            >
                              no data available
                            </span>
                          </v-chip>
                        </span>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-fade-transition>
            <v-overlay
              v-if="hover"
              fill-height
              opacity="1"
              absolute
              color="#fff"
              style="height: 100%; z-index: 2;"
              class="d-flex-inline"
              @click="selectItem(item)"
            >
              <v-row
                no-gutters
                class="d-flex flex-column align-stretch justify-space-between fill-height item-hover-overlay-area"
              >
                <v-col
                  class="d-flex self-align-start pt-3"
                >
                  <v-row
                    no-gutters
                    class="d-flex align-stretch fill-height"
                  >
                    <v-col
                      cols="10"
                      class="pr-4 pl-3 "
                    >
                      <v-row
                        class="flex-column pb-1"
                        no-gutters
                      >
                        <v-col
                          class="pb-1 break-words"
                        >
                          <div
                            class="text-body-1"
                            style="color: var(--v-primary-base);"
                          >
                            {{ item.detail_info[0][`info_text_teaser_${locale}`] }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col
                      cols="2"
                      class="text-center pa-3 pt-1"
                      style="border-radius: 5px;"
                    >
                      <v-icon
                        color="primary"
                        style="width: auto"
                        size="26"
                      >
                        {{ `icon-technology-0` + getItemTechnology.value_id }}
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  class="d-flex align-end justify-center"
                >
                  <v-row
                    no-gutters
                  >
                    <v-col
                      class="d-flex flex-row fill-height justify-space-around align-center pa-3"
                    >
                      <v-btn
                        outlined
                        small
                        text
                        elevation="0"
                        color="var(--v-primary-base)"
                        class="text-capitalize"
                        style="border: 1px dotted var(--v-secondary-lighten1); padding: 0 6px;"
                        @click.stop="openIndicatorInfo(item)"
                      >
                        <span
                          v-if="locale === 'de'"
                        >
                          Beschreibung
                        </span>

                        <span
                          v-else
                        >
                          Description
                        </span>
                      </v-btn>

                      <v-btn
                        v-if="item.has_data"
                        outlined
                        small
                        text
                        elevation="0"
                        color="var(--v-primary-base)"
                        style="border: 1px dotted var(--v-secondary-lighten1); padding: 0 6px;"
                        @click.stop="selectItem(item)"
                      >
                        <span
                          v-if="locale === 'de'"
                          style="text-transform: none;"
                        >
                          Kennzahl anzeigen

                        </span>

                        <span
                          v-else
                          style="text-transform: none;"
                        >
                          Show indicator
                        </span>
                      </v-btn>

                      <v-btn
                        v-else
                        small
                        outlined
                        text
                        elevation="0"
                        color="secondary"
                        style="border: 1px dotted var(--v-secondary-lighten1); padding: 0 6px;"
                        class="text-capitalize"
                      >
                        <span
                          v-if="locale === 'de'"
                          style="text-transform: none;"
                        >
                          keine Daten verfügbar
                        </span>

                        <span
                          v-else
                          style="text-transform: none;"
                        >
                          no data available
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'SelectMainItemCardPage',

  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_items', [
      'dashboardComparisonStatus'
    ]),

    getFieldOfAction () {
      // NOTE: Has to get refactored, because Field of Action Title is EEMonReport specific
      return this.item.categories.filter(cat => cat.type.query_key === 'fieldofaction')[0].value
    },

    getItemTechnology () {
      return this.item.categories.filter(cat => cat.type.query_key === 'technology')[0].value
    }
  },

  mounted () {
    this.$nextTick(function () {
      this.$emit('ismounted', true)
    })

    // TODO Build procedure to set same height for card "div zones" depending of heighest value of one of it
    // like https://codepen.io/pwkip/pen/oNvxNYZ?editors=1010
    // -> source https://stackoverflow.com/questions/36721216/equal-height-children-of-flex-items
    // console.log('REF select_item_card ', this.$refs.select_item_card)
  },

  methods: {
    setItemBackgroundColor (item) {
      const fieldofactionColorHex = item.categories.filter((opt) => { return opt.type.query_key === 'fieldofaction' })[0].value.color_hex

      return `background: #${fieldofactionColorHex}`
    },

    selectItem (item) {
      if (item.has_data === true) {
        // console.log(item)
        const nuxtRoute = this.$route

        this.$router.push({
          name: 'Dashboard',
          params: {
            fromRoute: this.$route,
            loadOnlyItem: true,
            slug: item[`slug_${this.locale}`]
          }
        })

        // try {
        //   if (this.dashboardComparisonStatus === true) {

        //     // URL indicator-comparison-"ID"-"ID"

        //     // const indicatorID01 = 1 // TODO
        //     // const indicatorID02 = 14 // TODO

        //     // const indicatorIdParams = []
        //     // this.$nuxt.$router.push({
        //     //   name: 'report-id',
        //     //   params: {
        //     //     id: `indicator-comparison-${indicatorID01}-${indicatorID02}`
        //     //   }
        //     // })
        //   } else {
        //     // URL indicator-"ID"

      //     this.$router.push({
      //       name: 'Dashboard',
      //       params: {
      //         slug: `${item.slug_de}`
      //       }
      //     })
      //   }
      // } catch (e) {
      //   console.log('selectItem catched Error ', e)
      //   // TODO error handling
      // }
      }
    },

    openIndicatorInfo (item) {
      eventBus.$emit('open-dialog-item-description',
        item
      )
    }
  }

}
</script>

<style lang="scss" scoped>

.card-item {
  border: solid thin var(--v-secondary-lighten1);

  &:hover {
    background: var(--v-secondaryLight-base);
    transition: .2s ease-in-out;
  }
}

.v-card {
  transition: .4s ease-in-out;
}

.break-words {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  // word-break: normal; //break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.item-hover-overlay-area::before {
    opacity: 0.04;
    background-color: var(--v-primary-base);
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
