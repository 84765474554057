var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.itemSelectsOptionalDropdown.length !== 0)?_c('div',{staticClass:"d-flex flex-column px-3 py-2",staticStyle:{"background":"rgb(225, 232, 238)"}},_vm._l((_vm.itemSelectsOptionalDropdown),function(select,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-grow-1",staticStyle:{"min-width":"0"}},[_c('v-select',{ref:`ref_select_${select.type.query_key}`,refInFor:true,staticClass:"text-truncate webgis-select webgis-select-options",attrs:{"clearable":select.values.length !== 1,"disabled":_vm.isMenuDisabled || select.values.length === 1,"item-text":select.type.select_item_text_field,"item-value":select.type.query_field,"items":_vm.selectSortValues({
              values: select.values,
              sortValues: select.type.sort_alphabetically
            }),"value":_vm.selectValue[index],"clear-icon":_vm.mdiCloseCircleOutline,"return-object":""},on:{"change":function($event){return _vm.selectUpdated({
              event: $event,
              selectValue: select, index
            })},"click:clear":function($event){return _vm.removeFocus()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(_vm.locale)?_c('div',{staticClass:"d-inline-flex align-end",staticStyle:{"max-width":"95%","overflow":"hidden","white-space":"normal","margin-top":"4px"}},[_c('span',{staticClass:"text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(data.item[`title_${_vm.locale}`])+" ")])]):_vm._e()]}},{key:"item",fn:function(data){return [(_vm.locale)?_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(data.item[`title_${_vm.locale}`])+" ")]):_vm._e()]}},{key:"label",fn:function(){return [_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(select.type[`title_${_vm.locale}`])+" ")])]},proxy:true}],null,true)})],1)]),(_vm.selectValue !== undefined &&
          _vm.selectValue[index] !== null &&
          _vm.selectValue[index] !== undefined &&
          _vm.selectValue[index].data_option_sub_class.length !== 0)?_c('div',{staticClass:"webgis-select"},[_c('div',{key:`key-${_vm.selectValue[index].value_id}`},_vm._l((_vm.selectValue[index].data_option_sub_class),function(select_sc,index_sc){return _c('div',{key:index_sc},[_c('SelectAdditional',{attrs:{"select":select_sc},on:{"update-data":_vm.selectUpdated}})],1)}),0)]):_vm._e()])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }