<template>
  <div
    v-if="user.isAuthenticated"
  >
    <!-- style="width: 100%;" -->
    <!-- <v-container
      fluid
      class="fill-height"
    >
      <HeaderMain
        app
      />
      <v-row
        no-gutters
      >
        <v-col> -->
    <Dashboard />
    <!-- </v-col>
      </v-row>
    </v-container> -->

    <DialogCititation />
  </div>
</template>

<script>
import Dashboard from '@/components/dashboard/Dashboard.vue'
import DialogCititation from '@/components/main/dialog/DialogCititation.vue'

import { mapState } from 'vuex'

export default {
  name: 'DashboardView',

  components: {
    Dashboard,
    DialogCititation
  },

  computed: {
    ...mapState('main', [
      'user'
    ])
  },

  watch: {
    '$vuetify.breakpoint.smAndDown' (newVal, oldVal) {
      if (newVal) {
        document.body.className = ''
      } else {
        document.body.className = 'hide-scrollbar'
        document.documentElement.className = 'hide-scrollbar'
      }
    }
  },

  beforeCreate () {
    document.body.className = 'hide-scrollbar'
    document.documentElement.className = 'hide-scrollbar'
  },

  beforeDestroy () {
    document.body.className = ''
    document.documentElement.className = ''
  },

  mounted () {
    if (!this.user.isAuthenticated) {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
