<template>
  <!-- style="background: yellow; width: 100%; height: auto;" -->
  <div
    style="width: 100%; height: auto;"
  >
    <VChart
      :id="`chart_map`"
      ref="chart_map"
      :autoresize="true"
    />
  </div>
</template>

<script>

import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

// AXIOS
import axios from 'axios'

// LODASH
import _ from 'lodash'

// MAP GEOJSON
import { geojsonLayerItems } from '@/assets/webgis/layeritems/geojsonlayeritems.js'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

// MAP PROJECTION for ECHARTS
import { geoMercator } from 'd3-geo'

import {
  SVGRenderer
  // CanvasRenderer
} from 'echarts/renderers'

import {
  MapChart
} from 'echarts/charts'

import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
  // BrushComponent
  ToolboxComponent
} from 'echarts/components'

use([
  SVGRenderer,
  // CanvasRenderer,
  MapChart,
  VisualMapComponent,
  TitleComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  // BrushComponent
  ToolboxComponent
])

export default {
  name: 'ChartsMapComponentPage',

  components: {
    VChart
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'svg'
    }
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default: 0
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    spatialTypeId: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      agsIdSelected: undefined,
      dataBySpatialType: [],
      dataFiltered: [],
      dataSpatialTypeOne: [],
      dataValueOfSelectedYear: undefined,
      dataValueUnit: '',
      highlightAgsIdSelected: undefined,
      isMapRegionSelected: false,
      isMenuUpdated: false,
      itemDataYears: [],
      mapOverlayTop: 0,
      mapProjection: undefined,
      agsIdSelectedArray: [],
      showLoadingIndicator: false,
      echartsOption: {
        bottom: 'auto',
        series: {},
        title: {},
        toolbox: {},
        tooltip: {},
        top: 'auto',
        visualMap: {}
      },
      valueMax: 0
    }
  },

  computed: {
    ...mapState('main', [
      'baseURL',
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'echartsDefaults',
      'bkgItems'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataQuery',
      'dashboardItemsSelectsBasic',
      'dashboardItemsYearSelected',
      'dashboardItemsDataYears'
    ])
  },

  watch: {
    async locale (newVal, oldVal) {
      const text = await this.setVisualmapText({ valueMax: this.valueMax })
      this.echartsOption.visualMap.text = text
      this.$refs.chart_map.setOption(this.echartsOption)
    },

    isMenuUpdated (newVal, oldVal) {
      if (newVal === true) {
        this.$refs.chart_map.resize()
        this.isMenuUpdated = false
      }
    },

    height (newVal, oldVal) {
      // Resize map canvas if height provided by props changes

      this.$refs.chart_map.resize()
    },

    // spatialTypeSelected (newVal, oldVal) {
    //   this.agsIdSelected = undefined
    // },

    itemData (newVal, oldVal) {
      // Update map chart if data changes
      // this.updateMapChart(this.agsIdSelectedArray[this.spatialTypeId - 1]) // REVIEW
      if (newVal !== oldVal) {
        this.updateMapChart(this.agsIdSelected)
      }
    }
  },

  async mounted () {
    // Set echarts defauls from store
    this.echartsOption.series = _.cloneDeep(this.echartsDefaults.map.series)
    this.echartsOption.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.tooltip = _.cloneDeep(this.echartsDefaults.tooltip)
    this.echartsOption.tooltip.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.visualMap = _.cloneDeep(this.echartsDefaults.map.visualMap)

    // Get value unit title_short (string)
    const dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.dashboardItem
    })
    this.dataValueUnit = dataValueUnitObj.title_short_de

    // GET DISTINCT YEARS (DATA_YEAR) FROM DATASETS (VUEX ACTION)
    // this.itemDataYears = await this.get_datasets_years({
    //   data: this.itemData
    // })

    // GET DISTINCT YEARS (DATA_YEAR) FROM DATASETS (VUEX MAPSTATE)
    this.itemDataYears = this.dashboardItemsDataYears

    // GET & SET MERCATOR MAP PROJECTION
    this.mapProjection = await geoMercator()
    this.echartsOption.series[0].projection = {
      project: (point) => this.mapProjection(point),
      unproject: (point) => this.mapProjection.invert(point)
    }

    // SET MAXIMUM AMOUNT OF SPATIAL_UNIT VALUES
    const dataOptionSpatialType = this.dashboardItemsSelectsBasic.filter((opt) => opt.type.query_key === 'spatial_type')[0]
    this.agsIdSelectedArray = [...Array(dataOptionSpatialType.values.at(-1).value_id)]

    //
    //  EVENT LISTENER ECHARTS
    //

    // EVENT LISTENER ECHARTS -> CLICK
    this.$refs.chart_map.chart.on('click', async (params) => {
      const selectedRegion = this.bkgItems.filter(item => item.ags === params.name)

      const spatialUnitId = selectedRegion[0].ade
      this.agsIdSelectedArray[spatialUnitId - 1] = params.name
      const filteredData = this.dataBySpatialType.filter(ds => ds.ags === params.name)

      const spatialType = filteredData[0].spatial_type

      if (this.agsIdSelected !== params.name) {
        eventBus.$emit('highlight-charts-series', undefined)
        eventBus.$emit('select-charts-series', params.name)

        eventBus.$emit(`set-select-spatial_unit_${spatialType}`, params.name)

        this.agsIdSelected = params.name
        this.agsIdSelectedArray[spatialUnitId - 1] = params.name

        this.isMapRegionSelected = true
      } else {
        eventBus.$emit(`reset-select-spatial_unit_${spatialType}`, {
          update: true
        })

        eventBus.$emit('select-charts-series', undefined)

        this.agsIdSelected = undefined

        this.agsIdSelectedArray[spatialUnitId - 1] = undefined

        this.isMapRegionSelected = false
      }
    })

    // EVENT LISTENER ECHARTS -> MOUSEOVER
    this.$refs.chart_map.chart.on('mouseover', async (params) => {
      if (this.spatialTypeId === 2 &&
      this.isMapRegionSelected === false
      ) {
        eventBus.$emit('highlight-charts-series', params.name)
      } else if (this.spatialTypeId === 4 &&
      this.dashboardItemsDataQuery.filter((opt) => {
        return 'spatial_unit_2' in opt
      })[0].spatial_unit_2 !== null &&
      this.isMapRegionSelected === false
      ) {
        eventBus.$emit('highlight-charts-series', params.name)
      }
    })

    // EVENT LISTENER ECHARTS -> MOUSEOUT
    this.$refs.chart_map.chart.on('mouseout', async (params) => {
      eventBus.$emit('highlight-charts-series', undefined)
    })

    // EVENT LISTENER ECHARTS -> GLOBALOUT
    this.$refs.chart_map.chart.on('globalout', async (params) => {
      eventBus.$emit('highlight-charts-series', undefined)
    })

    //
    //  EVENT lISTENER VUE
    //

    // EVENT lISTENER - RESET REGION SELECTED
    eventBus.$on('reset-region-selected', async ({
      event,
      selectValue
    }) => {
      // console.log('event, selectValue ', event, selectValue)
      this.$refs.chart_map.chart.dispatchAction({
        type: 'unselect',
        name: this.agsIdSelected
      })

      await this.update_data_query({
        query: {
          [`spatial_unit_${this.spatialTypeId}`]: null
        }
      })

      this.agsIdSelected = undefined
      this.isMapRegionSelected = false
      this.agsIdSelectedArray = this.agsIdSelectedArray.map((region) => { return undefined })
    })

    // EVENT lISTENER - SHOW LOADING INDICATOR ANIMATION
    eventBus.$on('loading-indicator-echart-component', () => {
      this.$refs.chart_map.chart.showLoading()

      this.showLoadingIndicator = true
    })

    // EVENT lISTENER - RESIZE ECHART
    eventBus.$on('resize-echarts-map', () => {
      this.$refs.chart_map.chart.resize()
    })

    // EVENT lISTENER - UPDATE MAP BY SELECTED YEAR
    eventBus.$on('update-echarts-map-component', async ({
      year
    }) => {
      this.echartsOption.series[0].data = this.updateMapChartByYear({
        year,
        data: this.dataBySpatialType
      })

      this.$refs.chart_map.setOption(this.echartsOption)
    })

    let stateAgsSelected

    // EVENT lISTENER - UPDATE ECHART BY SPATIAL UNIT
    eventBus.$on('update-echarts-spatial-unit',
      async ({
        event,
        selectValue
      }) => {
        this.agsIdSelected = undefined

        const spatialUnitId = selectValue.type.query_key.slice(-1)

        this.agsIdSelectedArray = this.agsIdSelectedArray.map((
          region,
          idx
        ) => {
          if (idx + 1 > spatialUnitId) {
            return undefined
          } else {
            return region
          }
        })

        // console.log('this.agsIdSelectedArray ', this.agsIdSelectedArray)

        this.isMapRegionSelected = false

        this.agsIdSelectedArray.forEach((regionAgs) => {
          this.$refs.chart_map.chart.dispatchAction({
            type: 'unselect',
            name: regionAgs
          })
        })

        this.$refs.chart_map.chart.dispatchAction({
          type: 'unselect',
          name: stateAgsSelected
        })

        if (event !== null) {
          this.agsIdSelected = event.ags
          stateAgsSelected = event.ags

          this.agsIdSelectedArray[spatialUnitId - 1] = event.ags

          if (event.ade >= this.spatialTypeId) {
            this.isMapRegionSelected = true

            this.$refs.chart_map.chart.dispatchAction({
              type: 'select',
              name: this.agsIdSelected
            })

            stateAgsSelected = this.agsIdSelected

            await this.updateLineChart(this.agsIdSelected)
          } else if (this.spatialTypeId > event.ade) {
            this.updateMapChart(this.agsIdSelected)
          }
        } else {
          this.isMapRegionSelected = false
          this.agsIdSelectedArray[spatialUnitId - 1] = undefined

          if (this.spatialTypeId === 2) {
            this.agsIdSelected = undefined
            stateAgsSelected = undefined
          }

          await this.updateLineChart()

          if (selectValue.type.query_key.slice(-1) < this.spatialTypeId) {
            this.updateMapChart()
          }
        }
      }
    )

    // console.log('updateMapChart 3')
    // await this.updateMapChart()
  },

  beforeDestroy () {
    this.$refs.chart_map.chart.dispose()
    this.$refs.chart_map.chart = null

    eventBus.$off('resize-echarts-map')
    eventBus.$off('load-echarts-map-component')
    eventBus.$off('update-echarts-map-component')
    eventBus.$off('loading-indicator-echart-component')
    eventBus.$off('update-echarts-spatial-unit')
    eventBus.$off('reset-region-selected')
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_ags_gen_title'
    ]),

    ...mapActions('dashboard_items', [
      'get_datasets_value_max',
      'get_datasets_value_min',
      'get_datasets_years',
      'get_item_data_value_unit',
      'get_max_fraction_digits',
      'update_data_query'
    ]),

    ...mapMutations('dashboard_items', [
      'SET_ITEM_YEAR_SELECTED'
    ]),

    updateMapChartByYear ({ year, data }) {
      const datasetsPerYear = data.filter(ds => ds.data_year === year)

      return datasetsPerYear.map((ds) => {
        return {
          name: ds.ags,
          value: ds.data_value
        }
      })
    },

    getAgsGenTitle ({ ags, bkgItems }) {
      const bkgItem = bkgItems.filter(bkgItem => bkgItem.ags === ags)[0]

      if (bkgItem.nbd === false) {
        return bkgItem.gen
      } else {
        if (bkgItem.ade !== 2) {
          return `${bkgItem.bez} ${bkgItem.gen}`
        } else {
          return `${bkgItem.gen}`
        }
      }
    },

    async getGeojsonLayerFileName  ({
      year,
      spatialTypeId,
      geojsonLayerItems
    }) {
      const filterProps = {
        year,
        spatialTypeId
      }

      const geoJsonFileNameFiltered = geojsonLayerItems.filter((item) => {
        for (const key in filterProps) {
          if (
            item[key] === undefined ||
          item[key] !== filterProps[key]
          ) {
            return false
          }
        }
        return true
      })

      return await geoJsonFileNameFiltered[0].filename
    },

    async setVisualmapText ({ valueMax }) {
      if (!this.dataValueUnit.match(/^n$/)) {
        const maximumFractionDigits = await this.get_max_fraction_digits({ minValue: this.valueMax })

        return [
        `${valueMax.toLocaleString(this.locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: maximumFractionDigits // 2
          })} ${this.dataValueUnit}`,
         `0 ${this.dataValueUnit}`
        ]
      } else {
        return [
        `${valueMax.toLocaleString(this.locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })} ${this.dataValueUnit}`,
         `0 ${this.dataValueUnit}`
        ]
      }
    },

    async getDataQueryId () {
      const lastNoNullId = this.agsIdSelectedArray.filter(x => x !== undefined).slice(-2)

      if (lastNoNullId[0] !== this.agsIdSelectedArray.slice(-1)[0]) {
        if (lastNoNullId[0] === undefined && this.agsIdSelectedArray.slice(-1)[0] === undefined) {
          return undefined
        } else {
          return lastNoNullId[0]
        }
      } else {
        return undefined
      }
    },

    async updateMapChart (agsId) {
      // console.log('updateMapChart agsId ', agsId)

      const itemDataCloned = _.cloneDeep(this.itemData)

      // if (agsId === undefined) {
      //   // REVIEW
      //   this.agsIdSelectedArray[this.spatialTypeId - 1] = undefined
      // }

      // // Set array undefined elements by count of used spatial_type values
      // for (let i = 0; i < this.spatialTypeId; i++) {
      //   if (this.agsIdSelectedArray[i] === undefined) {
      //     this.agsIdSelectedArray[i] = undefined

      //     // eventBus.$emit(`reset-select-spatial_unit_${i + 1}`)
      //   }
      // }

      // if (agsId !== undefined) {
      //   const selectedRegion = this.bkgItems.filter(item => item.ags === agsId)
      //   const spatialUnitId = selectedRegion[0].ade
      //   this.agsIdSelectedArray[spatialUnitId - 1] = agsId
      // }
      let dataQueryAgsId

      if (this.spatialTypeId === 2) {
        dataQueryAgsId = undefined
      } else {
        if (this.agsIdSelectedArray[1] !== undefined) {
          dataQueryAgsId = this.agsIdSelectedArray[1]
        } else {
          dataQueryAgsId = undefined
        }
      }

      // const dataQueryAgsId = await this.getDataQueryId()
      // console.log('dataQueryAgsId ', dataQueryAgsId)
      // console.log('this.agsIdSelected ', this.agsIdSelected)
      // console.log('this.agsIdSelectedArray ', this.agsIdSelectedArray)

      this.$refs.chart_map.chart.showLoading()
      this.showLoadingIndicator = true

      // Set item data filtered by selected spatial type
      this.dataBySpatialType = itemDataCloned.filter(ds => ds.spatial_type === this.spatialTypeId)

      // console.log('this.spatialTypeId ', this.spatialTypeId)
      // Filter GeoJson filename by year and spatial type (BKG -> ADE)
      const geoJsonFileName = await this.getGeojsonLayerFileName({
        year: 2016, // NOTE !!! ATM only GeoJson available for the year 2016
        spatialTypeId: this.spatialTypeId,
        geojsonLayerItems
      })

      // Base paths
      const geoJsonFileFolderPath = 'webgis/geojson/'
      const appRootFolder = process.env.VUE_APP_ROOT_FOLDER ? process.env.VUE_APP_ROOT_FOLDER : ''
      const appPort = process.env.VUE_APP_PORT ? process.env.VUE_APP_PORT : ''
      const baseURL = `${process.env.VUE_APP_HOST_URL}${appPort}/${appRootFolder}`

      // Set complete URL for GeoJson file
      const url = `${baseURL}${geoJsonFileFolderPath}${geoJsonFileName}`

      // Retrieve GeoJson file based on query params and register it to the echarts instance
      const { data } = await axios.get(url)

      // Filter geojson data if ags was submitted
      let dataGeoJson = {}
      if (dataQueryAgsId !== undefined) {
        dataGeoJson.features = data.features.filter(ds => ds.properties.ags.startsWith(dataQueryAgsId))
        dataGeoJson.type = data.type
      } else {
        dataGeoJson = data
      }

      // Add map with filtered GeoJsonData to the charts map
      echarts.registerMap('germany', dataGeoJson)

      const selectedYear = Math.max(...this.itemDataYears)
      this.SET_ITEM_YEAR_SELECTED({
        year: selectedYear
      })

      let digits = 0
      if (!this.dataValueUnit.match(/^n$/)) {
        digits = 1
      }

      // Filter data
      if (dataQueryAgsId !== undefined) {
        this.dataFiltered = this.dataBySpatialType.filter((ds) => {
          return ds.ags.startsWith(dataQueryAgsId)
        })
      } else {
        this.dataFiltered = this.dataBySpatialType
      }

      // If region has no datasets set visualmap to white
      const regionHasNoValues = this.dataFiltered.filter(ds => ds.data_value !== null)
      if (regionHasNoValues.length === 0) {
        this.echartsOption.visualMap.inRange = {
          color: ['#ffffff', '#ffffff']
        }
        this.echartsOption.visualMap.show = false
      } else {
        this.echartsOption.visualMap = _.cloneDeep(this.echartsDefaults.map.visualMap)
        this.echartsOption.visualMap.show = true
      }

      // Set min & max values
      this.valueMax = await this.get_datasets_value_max(this.dataFiltered)
      this.echartsOption.visualMap.min = 0 // await this.get_datasets_value_min(filteredData)
      this.echartsOption.visualMap.max = this.valueMax

      // Values array of Germany
      this.dataSpatialTypeOne = itemDataCloned.filter(ds => ds.ags === '00000000')

      this.echartsOption.series[0].data = this.updateMapChartByYear({
        year: this.itemDataYears[this.itemDataYears.length - 1],
        data: this.dataFiltered
      })

      // Tooltip - formating value output
      let minimumFractionDigits = 0
      let maximumFractionDigits = 0

      // Set number of min & max digits for the number of value descendants
      if (!this.dataValueUnit.match(/^n$/)) {
        this.minValue = await this.get_datasets_value_min(this.dataFiltered)
        minimumFractionDigits = 0
        maximumFractionDigits = (this.minValue.toString().replace('0.', '').split('0').length)
      }

      // Set tooltip formatte
      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        // NOTE formatter callback function doesn't work with async to satisfaction as aspected. That's why the local synchronous method for retrieving the ags gen title hast to be used instead of vuex action dashboard_main/get_ags_gen_title

        const bkgAgsTitle = this.getAgsGenTitle({
          ags: params.name,
          bkgItems: this.bkgItems
        })

        const title = `<div class="text-h6">
                      ${bkgAgsTitle} (${this.dashboardItemsYearSelected})
                      </div>`

        if (isNaN(params.value)) {
          return `${title}<div>${this.locale === 'de' ? 'kein Wert' : 'no value'}</div>`
        } else {
          return `${title}
          <div>${params.value.toLocaleString(this.locale, {
            minimumFractionDigits: minimumFractionDigits,
            maximumFractionDigits: maximumFractionDigits
          })}
          ${this.dataValueUnit}</div>`
        }
      }

      // Set values and unit for visualmap min/max (NOTE: method used because value can not set by formatter function if locale is changed)
      this.echartsOption.visualMap.text = await this.setVisualmapText({ valueMax: this.valueMax })

      this.echartsOption.visualMap.formatter = (value) => `${value.toLocaleString(this.locale, { minimumFractionDigits: digits, maximumFractionDigits: digits })} ${this.dataValueUnit}`

      this.$refs.chart_map.chart.hideLoading()
      this.showLoadingIndicator = false

      this.$refs.chart_map.setOption(this.echartsOption)

      await this.updateLineChart(dataQueryAgsId)
    },

    checkIfValueNaN (datasets) {
      const datasetNullValues = datasets.filter(ds => ds.data_value === null)
      return datasetNullValues.length === datasets.length
    },

    async updateLineChart (agsId) {
      let datasets = []
      let isValueNaN = false
      let showLegend = false

      // Clone datasets
      const itemDataCloned = _.cloneDeep(this.itemData)

      this.dataFiltered = itemDataCloned

      // Get item data unit attribute id (density & count -> 1, 2; others 3, 4, 5, 6)
      const dataValueUnitAttributeId = this.dashboardItem.data_options.filter((opt) => {
        return opt.type.query_key === 'data_unit'
      })[0].values[0].attribute.id

      // console.log('this.agsIdSelectedArray', this.agsIdSelectedArray)

      const agsIdSecondBeforeLast = await this.getDataQueryId()

      // console.log('agsIdSecondBeforeLast ', agsIdSecondBeforeLast)

      // Indicate if dataset contains only Null values
      datasets = itemDataCloned.filter(ds => ds.spatial_type === this.spatialTypeId)
      isValueNaN = this.checkIfValueNaN(datasets)

      if (agsId !== undefined &&
      this.agsIdSelectedArray[this.spatialTypeId - 1] !== undefined) {
        showLegend = true

        // Indicate if dataset contains only Null values
        datasets = itemDataCloned.filter(ds => ds.ags === agsId)
        isValueNaN = this.checkIfValueNaN(datasets)
      }

      // eventBus.$emit('echarts-map-click-event', agsId)

      eventBus.$emit('load-echarts-line-1-component', {
        agsIdSelected: this.agsIdSelected,
        isValueNaN,
        agsIdSelectedArray: this.agsIdSelectedArray,
        showLegend,
        spatialTypeSelected: this.spatialTypeId
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
