<template>
  <div
    id="select-slider-date-time"
  >
    <v-slider
      v-model="sliderValue"
      :step="steps"
      thumb-label="always"
      ticks="always"
      tick-size="2"
      :tick-labels="ticksLabels"
      :min="timeSliderValueMin"
      :max="timeSliderValueMax"
      class=""
      @change="setSlider"
    >
      <template
        #thumb-label
      >
        {{ selectedYear }}
      </template>
    </v-slider>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'TimeSliderComponent',

  props: {
    itemData: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      sliderValue: 0,
      steps: 1,
      timeSliderValueMin: 0,
      years: []
    }
  },

  computed: {
    ...mapState('dashboard_items', [
      'dashboardItemsDataYears',
      'dashboardItemsYearSelected'
    ]),

    selectedYear () {
      return this.years[this.sliderValue]
    },
    timeSliderValueMax () {
      return this.years.length - 1
    },
    ticksLabels () {
      return this.removeYears(this.years, 5)
    }
  },

  watch: {
    years (newVal) {
      if (newVal) {
        this.setSliderStateInit()
      }
    },

    dashboardItemsYearSelected (newVal, oldVal) {
      if (newVal) {
        this.sliderValue = this.years.indexOf(newVal)
      }
    }
  },

  mounted () {
    // this.years = await this.get_datasets_years({data: this.itemData})
    this.years = this.dashboardItemsDataYears

    this.setSliderStateInit()
  },

  methods: {
    ...mapMutations('dashboard_items', [
      'SET_ITEM_YEAR_SELECTED'
    ]),

    ...mapActions('dashboard_items', [
      'get_datasets_years'
    ]),

    setSlider (index) {
      const selectedYear = this.years[index]

      this.SET_ITEM_YEAR_SELECTED({
        year: selectedYear
      })

      setTimeout(() => {
        // eventBus.$emit(`update-map-featurelayer-dashboard`,
        //   selectedYear
        // )

        eventBus.$emit('update-echarts-map-component', {
          year: selectedYear
        })
      }, 0)
    },

    setSliderStateInit () {
      this.sliderValue = this.timeSliderValueMax

      if (this.years.length !== 0) {
        const selectedYear = Math.max(...this.years)
        this.SET_ITEM_YEAR_SELECTED({
          year: selectedYear
        })
      }
    },

    removeYears (arr, nth) {
      if (arr.length > 9) {
        // TODO Better solution needed if time range has bigger steps (2020, 2030, 2040)
        // TODO Distinct solution if nth = 2 e.g.
        return arr.map((e, i, arr) => {
          if (i === 0 || i === arr.length - 1) {
            return e
          } else if (e % nth === 0) {
            return e
          } else if (i <= 2 || i >= arr.length - 3) {
            return ''
          } else {
            return ''
          }
        })
      } else {
        return arr
      }
    }
  }
}
</script>

<style lang="scss">
#select-slider-date-time {
  background: rgb(255,255,255,0.7);

  .v-messages {
    display: none;
  }

  .v-slider.v-slider--horizontal {
    margin: 0 17px !important;
  }
  .v-slider__tick-label:first-child {
    left: -16px !important;
  }
  .v-slider__tick-label:last-child {
    right: -16px !important;
  }
  .v-slider__tick-label {
    transform: translateX(0%) !important;
    font-family: inherit;
    font-size: 14px;
  }

  .v-slider--horizontal .v-slider__track-container {
    height: 1.5px;
  }

  .v-slider__tick {
    background-color: var(--v-primary-base);
    width: 1px !important;
    height: 4px !important;
    top: 1px !important;
  }

  .v-slider__track-fill {
    background: var(--v-primary-base) !important;
  }

  .v-slider__ticks-container {
    &>span:nth-child(1) {
      left: calc(0% - 0px) !important;
    }
  }
}
</style>
