<template>
  <div>
    <!-- LOADING INDICATOR FULLSCREEN  -->
    <Transition
      name="fade"
      mode="in-out"
      appear
    >
      <LoadingIndicator
        :show-loading-indicator="showOverlayFullscreen"
        style="width: 100%; height: 100%; background: none; z-index:100; position: absolute; pointer-events:none;"
      />
    </Transition>

    <!-- DIALOG START APP -->

    <DialogStartApp>
      <template
        #default="content"
      >
        <DialogStartReportContent />
      </template>
    </DialogStartApp>

    <v-container
      fluid
      :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'"
    >
      <HeaderMain
        app
        :image-file-name="'solarpark-Bockelwitz-2_schinkel.jpg'"
      />

      <Transition
        name="fade"
        mode="in-out"
        appear
      >
        <v-row
          v-if="!isWelcomeDialogOpen && isReportMainReady"
          v-resize="setElementsHeights"
          class="flex-column"
          no-gutters
        >
          <!-- style="width: calc(100% - 24px); position: fixed; z-index: 3;" -->

          <!-- INFO REGION REPORT -->

          <v-col>
            <v-card
              ref="card_about_area"
              flat
              rounded="0"
              class="mb-3 pa-6"
            >
              <v-row
                align="stretch"
                justify="space-between"
              >
                <v-col>
                  <div
                    v-if="locale==='de'"
                    class="text-h4 font-weight-medium mb-2"
                  >
                    Regionalbericht
                  </div>
                  <div
                    v-else
                    class="text-h4 mb-2"
                  >
                    Regionalbericht
                  </div>

                  <div
                    class="text-body-1 font-weight-normal"
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Der Regionalbericht ermöglicht eine regionenspezifische Perspektive auf die 41 Kennzahlen. Die verfügbaren Werte können national, auf Bundesland- und Landkreisebene betrachtet und nach den jeweiligen Technologien ausgewählt werden.
                      Es ist möglich, von den regionenspezifischen Werten direkt zur umfassenden und regionenübergreifenden  Ansicht der Kennzahlwerte zu gelangen.
                    </span>

                    <span
                      v-else
                    >
                      The regional report provides a region-specific perspective on the 41 metrics. The available values can be viewed nationally, at the state and county level, and selected according to the respective technologies.
                      It is possible to go directly from the region-specific values to the comprehensive and cross-regional view of the indicator values.
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- v-show="!isWelcomeDialogOpen" -->
          <!-- :style="[isMenuDisabled ? {'pointer-events': 'none'} : {}]" -->
          <v-col
            class="d-flex flex-column"
            style="position: sticky;  z-index: 3;"
            :style="{'top': stickyHeaderTop + 'px'}"
          >
            <v-card
              ref="card_select_area"
              style="width: 100%;"
              rounded="0"
              class="d-flex flex-column"
              :class="$vuetify.breakpoint.smAndUp ? 'pa-6' : 'pa-3'"
            >
              <MenuRegionSelect />

              <v-row
                v-if="categoryTechnologyItems.length !== 0"
                no-gutters
                class="flex-wrap mt-3"
              >
                <v-col
                  cols="12"
                >
                  <v-chip-group
                    v-model="btnChipGroup"
                    class="d-flex pa-0 chip-group-technology"
                    :disabled="isMenuDisabled"
                    column
                  >
                    <v-tooltip
                      v-for="(technology, index) in categoryTechnologyItems"
                      v-show="$vuetify.breakpoint.smAndDown"
                      :key="index"
                      :disabled="isMenuDisabled ||$vuetify.breakpoint.xs || $vuetify.breakpoint.lgAndUp"
                      color="rgb(225, 232, 238);"
                      bottom
                      :category-technology-items="categoryTechnologyItems"
                      max-width="300"
                    >
                      <template
                        #activator="{ on, attrs }"
                      >
                        <!-- v-show="!isMenuDisabled"  -->
                        <v-chip
                          class="mx-1 my-0 chips-technology"
                          active-class="chips-technology-active"
                          link
                          small
                          :disabled="isMenuDisabled || !categoryTechnologyIdsSelected.includes(technology.id)"
                          v-on="on"
                          @click="filterItemListByTechnology(technology)"
                        >
                          <div
                            class="d-inline-flex flex-row justify-space-between align-center text-body-2"
                          >
                            <v-icon
                              color="primary"
                              style="width: auto"
                              size="14"
                              :class="[$vuetify.breakpoint.smAndUp ? 'mr-2' : '']"
                            >
                              {{ `icon-technology-0` + technology.value_id }}
                            </v-icon>

                            <div
                              v-if="$vuetify.breakpoint.lgAndUp"
                            >
                              {{ technology[`title_${locale}`] }}
                            </div>

                            <div
                              v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.md"
                            >
                              {{ technology[`title_short_${locale}`] }}
                            </div>
                          </div>
                        </v-chip>
                      </template>

                      <div
                        class="text-body-1 text-center"
                      >
                        {{ technology[`title_${locale}`] }}
                      </div>
                    </v-tooltip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-card>

            <v-slide-y-transition>
              <v-card
                v-if="($vuetify.breakpoint.smAndDown && itemScrolled !== undefined)"
                ref="card_item_title"
                rounded="0"
                elevation="1"
                class="d-flex align-center text-h6 text-sm-h5 font-weight-medium"
                style="padding: 8px 32px !important; min-height: 37px; width: 100%; position: absolute; border-top: 2px solid rgb(225, 232, 238);"
                :style="{'top': cardSelectAreaHeight + 'px'}"
              >
                <div
                  v-if="itemScrolled !== undefined"
                  class="d-inline-flex align-center"
                  style="width: auto;"
                >
                  <v-icon
                    color="primary"
                    style="width: auto"
                    size="30"
                    class="mr-5"
                  >
                    {{ `icon-technology-0` + getItemTechnologyValueId(itemScrolled) }}
                  </v-icon>
                  <div>
                    {{ itemScrolled[`title_${locale}`] }}
                  </div>
                </div>
              </v-card>
            </v-slide-y-transition>
          </v-col>

          <!-- LOADING INDICATOR  -->

          <!-- <div
        class="d-flex"
        > -->
          <!-- :class="classLoadingIndicator" -->
          <v-row
            no-gutters
          >
            <v-col
              style="position: relative;"
            >
              <Transition
                name="fade"
                mode="in-out"
                appear
              >
                <!-- :show-loading-indicator="showOverlay" -->
                <LoadingIndicator
                  :show-loading-indicator="showOverlay || isWelcomeDialogOpen"
                  style="width: 100%; background: none; z-index:100; position: absolute; pointer-events: none;"
                  :style="{'height': reportItemListHeight + 'px'}"
                />
              </Transition>
            </v-col>
          </v-row>

          <!-- </div> -->

          <div
            class="d-flex align-stretch fill-height"
          >
            <v-card
              v-if="!showOverlay && !isWelcomeDialogOpen"
              ref="report_list_container"
              v-scroll="onScrollReportList"
              class="pa-3 fill-height"
              style="width: 100%; background: #e1e8ee;"
              :style="{'min-height': reportItemListHeight + 'px'}"
              flat
              rounded="0"
              elevation="0"
            >
              <v-row
                v-if="itemListSorted.length !== 0 && itemListReportRangeData.length !== 0"
                class="fill-height"
                dense
              >
                <!-- :item-list-report-year-data="itemListReportYearData" -->
                <!-- :key="`${item.id}_${new Date().getTime().toString()}`" -->
                <RegionReportItemCard
                  v-for="item in itemListSorted"
                  :key="item.id"
                  :ref="`report_list_item_${item.id}`"
                  :item="item"
                  :item-list-report-range-data="itemListReportRangeData"
                  :corine-items="corineItems"
                  :ags-id-selected="regionAgsSelected"
                  :item-list-ids-selected="itemListIdsSelected"
                  class="fill-height"
                  @updated="cardUpdated()"
                />
              </v-row>
            </v-card>
          </div>
        </v-row>
      </Transition>

      <!-- DIALOG ITEM DESCRIPTION -->

      <DialogItemDescription
        class="fill-height"
      />
    </v-container>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions, mapMutations } from 'vuex'

// LIBRARIES
import _ from 'lodash'
import axios from 'axios'

// ICONS
import { mdiChevronDown } from '@mdi/js'

// COMPONENTS
import DialogItemDescription from '@/components/main/dialog/DialogItemDescription.vue'
import DialogStartApp from '@/components/main/dialog/DialogStartApp.vue'
import DialogStartReportContent from '@/components/apps/report/region/dialogs/DialogStartReportContent.vue'
import HeaderMain from '@/components/main/header/HeaderMain.vue'
import LoadingIndicator from '@/components/main/overlays/LoadingIndicator.vue'
import MenuRegionSelect from '@/components/apps/report/region/menus/MenuRegionSelect.vue'
import RegionReportItemCard from '@/components/apps/report/region/RegionReportItemCard.vue'

export default {
  name: 'RegionReportMain',

  components: {
    DialogItemDescription,
    DialogStartApp,
    DialogStartReportContent,
    HeaderMain,
    LoadingIndicator,
    MenuRegionSelect,
    RegionReportItemCard
  },

  data () {
    return {
      isReportMainReady: false,
      btnChipGroup: undefined,
      // btnGroupSpatialType: 0,
      cardSelectAreaHeight: 0,
      cardUpdatedCounter: 0,
      categoryTechnologyIds: [],
      categoryTechnologyIdSelected: null,
      categoryTechnologyIdsSelected: [],
      categoryTechnologyItems: [],
      corineItems: [],
      defaultRegion: {},
      // defaultRegion: {
      //   ade: 1,
      //   ags: '00000000',
      //   bez: 'Bundesrepublik',
      //   gen: 'Deutschland',
      //   gen_slug: 'bundesrepublik-deutschland',
      //   nbd: true
      // },
      itemListCategories: [],
      itemListFilteredByTechnology: [],
      itemListIdsSelected: [],
      itemListReportEntries: [],
      itemListReportGrouped: {},
      itemListReportRangeData: [],
      itemListSorted: [],
      itemScrolled: undefined,
      lastScrollTop: 0,
      mdiChevronDown: mdiChevronDown,
      paddingBottom: 12,
      reportItemListHeight: 0,
      reportListPaddingTop: 0,
      routeParams: {
        regionSlug: null,
        technologySlug: null
      },
      selectRegionPlaceholder: '',
      showItemListSorted: false,
      showOverlay: true,
      showOverlayFullscreen: true,
      // itemListReportYearData: [],
      // slideGroup: [],
      spatialTypeItems: [{
        ade: 1,
        title_de: 'Deutschland',
        title_en: 'Germany'
      }, {
        ade: 2,
        title_de: 'Bundesländer',
        title_en: 'Federal States'
      }, {
        ade: 4,
        title_de: 'Landkreise',
        title_en: 'Counties'
      }]
    }
  },

  computed: {
    ...mapState('main', [
      'appRootFolder',
      'application',
      'baseURL',
      'isWelcomeDialogOpen',
      'locale',
      'isMenuDisabled'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems'
    ]),

    ...mapState('report', [
      'itemListReport',
      'regionAgsSelected',
      'spatialTypeSelected'
    ]),

    regionValuesFiltered () {
      return this.bkgItems.filter(item => item.ade === this.spatialTypeSelected)
    },

    classLoadingIndicator () {
      return this.$vuetify.breakpoint.mdAndUp ? 'loading-indicator-card-md-and-up' : 'loading-indicator-card-sm-and-down'
    },

    stickyHeaderTop () {
      return this.$vuetify.application.top
    },

    paddingSubstraction () {
      return this.$vuetify.smAndUp ? 36 : 36
    }
  },

  watch: {
    locale () {
      this.selectRegionPlaceholder = this.getPlaceholderText(this.regionValuesFiltered)
    },

    regionValuesFiltered (newVal, oldVal) {
      this.selectRegionPlaceholder = this.getPlaceholderText(this.regionValuesFiltered)
    },

    categoryTechnologyItems: {
      handler (newVal) {
        if (newVal.length !== 0) {
          this.setElementsHeights()
        }
      },
      immediate: true
    }
  },

  beforeMount () {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'smooth'
    })
  },

  beforeDestroy () {
    eventBus.$off('regionreport-select-spatial-type')
    eventBus.$off('regionreport-select-region')
    this.set_region_ags_selected(null)
    this.set_spatial_type_selected(null)
  },

  async mounted () {
    eventBus.$on('regionreport-select-spatial-type', (spatialType) => {
      this.selectSpatialType(spatialType)
    })

    eventBus.$on('regionreport-select-region', (region) => {
      this.set_dialog_welcome(false)
      this.selectRegion(region)
    })

    this.set_menu_disabled(false)
    this.isReportMainReady = false
    this.showOverlay = true
    this.showOverlayFullscreen = true

    // await this.set_regionreport_defaults()

    this.setElementsHeights()

    this.corineItems = await this.get_corine_items()

    if (this.bkgItems.length === 0) {
      await this.get_bkg_items({
        ade: 4, // FIXME
        gf: 4
      })
    }

    if (this.itemListReport.length === 0) {
      await this.get_item_list_report({
        application: this.application
      })
    }

    if (Object.keys(this.$route.params).length !== 0) {
      this.routeParams = this.$route.params
      this.set_dialog_welcome(false)
    } else {
      this.set_dialog_welcome(true)
      this.showOverlayFullscreen = false
      this.isReportMainReady = true
    }

    // Get distinct list of CategoryType items
    // const categoryTypeItems = itemListCategories.filter((value, index, self) => self.findIndex(value2 => (value2.type.id === value.type.id)) === index).map(item => item.type)

    this.itemListReportGrouped = _.groupBy(this.itemListReport, item => item.report_year)
    this.itemListReportEntries = Object.entries(this.itemListReportGrouped)

    this.setElementsHeights()

    // TODO Set region by routeParams or if empty by defaultRegion
    if (this.routeParams.regionSlug !== null) {
      this.set_menu_disabled(true)

      const rootFolder = this.appRootFolder.length !== 0 ? `/${this.appRootFolder}` : '/'

      // NOTE Workaround
      history.pushState(
        {},
        null,
          `${rootFolder}report/region/${this.routeParams.regionSlug}/`
      )

      // NOTE Workaround
      // window.history.pushState(null, '', `${rootFolder}/report/region/${this.routeParams.regionSlug}/`)

      const region = this.bkgItems.filter(item => item.gen_slug === this.routeParams.regionSlug)[0]

      if (region !== undefined) {
        await this.set_spatial_type_selected(region.ade)
        await this.set_region_ags_selected(region.ags)

        const spatialTypeItem = this.spatialTypeItems.filter(item => item.ade === region.ade)[0]

        // this.btnGroupSpatialType = this.spatialTypeItems.indexOf(spatialTypeItem)
        // this.slideGroup = this.spatialTypeItems.indexOf(spatialTypeItem)

        this.selectRegion(region)
        this.isReportMainReady = true
      } else {
        eventBus.$emit('push-to-page-error-404')
      }
    }
    // else {
    //   this.regionAgsSelected = this.defaultRegion.ags
    //   await this.set_region_ags_selected(this.defaultRegion.ags)

    //   this.selectRegion(this.defaultRegion)
    // }
  },

  methods: {
    ...mapActions('main', [
      'set_dialog_welcome',
      'set_menu_disabled'
    ]),

    ...mapActions('dashboard_main', [
      'get_bkg_items',
      'get_corine_items'
    ]),

    ...mapActions('dashboard_items', [
      'get_item'
    ]),

    ...mapActions('report', [
      'get_item_list_report',
      'set_region_ags_selected',
      'set_regionreport_defaults',
      'set_spatial_type_selected'
    ]),

    getItemTechnologyValueId (item) {
      if (item !== undefined) {
        return item.categories.filter(cat => cat.type.title_de === 'Technologie')[0].value.value_id
      }
    },

    async selectSpatialType (value) {
      this.itemScrolled = undefined

      if (this.regionAgsSelected !== null) {
        await this.set_region_ags_selected(null)
      }

      // this.itemListReportYearData = []
      this.itemListReportRangeData = []
      this.itemListFilteredByTechnology = []
      this.categoryTechnologyIdsSelected = []
      this.itemListIdsSelected = []
      this.categoryTechnologyIdSelected = null

      this.cardUpdatedCounter = 0

      this.showItemListSorted = false

      await this.set_spatial_type_selected(value.ade)

      this.setElementsHeights()

      if (value.ade !== 1) {
        const rootFolder = this.appRootFolder.length !== 0 ? `/${this.appRootFolder}` : '/'
        history.pushState(
          {},
          null,
         `${rootFolder}report/region/`
        )
      }
    },

    async selectRegion (region) {
      // this.itemListReportYearData = []

      this.itemScrolled = undefined
      this.itemListReportRangeData = []
      this.itemListFilteredByTechnology = []
      this.categoryTechnologyIdsSelected = []
      this.itemListIdsSelected = []
      this.categoryTechnologyIdSelected = null

      this.cardUpdatedCounter = 0

      const rootFolder = this.appRootFolder.length !== 0 ? `/${this.appRootFolder}` : '/'

      if (region !== null) {
        this.set_menu_disabled(true)

        await this.set_region_ags_selected(region.ags)

        history.pushState(
          {},
          null,
          `${rootFolder}report/region/${region.gen_slug}/`
        )

        if (this.showOverlayFullscreen) {
          this.showOverlayFullscreen = false
        }

        this.showOverlay = true

        // await this.getReportYearData({
        //   agsId: region.ags,
        //   spatialType: region.ade
        // })

        await this.getReportRangeData({
          agsId: region.ags,
          spatialType: region.ade
        })

        this.showItemListSorted = false

        this.itemListSorted = await this.sortItemListReport()

        // Collect all "categories" arrays from items of itemListFilteredByTechnology
        this.itemListCategories = this.itemListSorted.flatMap(item => item.categories)

        // Get list of category technology items and ids
        this.categoryTechnologyItems = this.getCategoryTechnologyItems(this.itemListCategories)
        this.categoryTechnologyIds = this.categoryTechnologyItems.map(item => item.id)

        // Filter item list by technology with default value -> undefined technologyItem
        this.filterItemListByTechnology()

        this.showItemListSorted = true

        this.set_menu_disabled(false)

        this.showOverlay = false
      } else {
        history.pushState(
          {},
          null,
          '/report/region/'
        )

        // this.itemListReportYearData = []
        this.itemListReportRangeData = []
      }
    },

    async sortItemListReport (data) {
      const items = [...this.itemListReport.filter((item) => {
        return item.show_report === true && item.data_options.some((opt) => {
          return opt.type.query_key === 'spatial_type' &&
          opt.values.some(val => val.value_id === this.spatialTypeSelected)
        })
      })]

      const sortedItems = items.sort((a, b) => a[`title_${this.locale}`].localeCompare(b[`title_${this.locale}`])).sort(this.sortByTechnologyAndDataOptions)
      // .sort(this.sortByTechnology).sort(this.sortByOptions)

      return sortedItems
    },

    sortByTechnologyAndDataOptions (a, b) {
      const technologyA = a.categories.filter(opt => opt.type.query_key === 'technology')[0].value.my_order
      const technologyB = b.categories.filter(opt => opt.type.query_key === 'technology')[0].value.my_order

      const selectsOptionalItemA = a.data_options.filter(opt => opt.type.option_type === 'optional' && opt.type.select_type === 'select-panel-list').length
      const selectsOptionalItemB = b.data_options.filter(opt => opt.type.option_type === 'optional' && opt.type.select_type === 'select-panel-list').length

      // https://stackoverflow.com/questions/6129952/sort-javascript-array-by-two-numeric-fields#comment118302776_6130014
      return selectsOptionalItemA === selectsOptionalItemB ? technologyA - technologyB : selectsOptionalItemA - selectsOptionalItemB
    },

    // sortByTechnology (a, b) {
    //   const technologyA = a.categories.filter(opt => opt.type.query_key === 'technology')[0].value
    //   const technologyB = b.categories.filter(opt => opt.type.query_key === 'technology')[0].value

    //   if (technologyA.my_order < technologyB.my_order) {
    //     return -1
    //   }
    //   if (technologyA.my_order > technologyB.my_order) {
    //     return 1
    //   }
    //   return 0
    // },

    // sortByOptions (a, b) {
    //   let itemA
    //   let itemB

    //   const selectsOptionalItemA = a.data_options.filter(opt => opt.type.option_type === 'optional' && opt.type.select_type === 'select-panel-list')
    //   const selectsOptionalItemB = b.data_options.filter(opt => opt.type.option_type === 'optional' && opt.type.select_type === 'select-panel-list')

    //   if (selectsOptionalItemA.length === 0) {
    //     itemA = -1
    //   }
    //   if (selectsOptionalItemB.length === 0) {
    //     itemB = -1
    //   }

    //   if (selectsOptionalItemA.length !== 0) {
    //     if (selectsOptionalItemA[0].type.query_key === 'landcover' && selectsOptionalItemA[0].values[0].value_id === 10) {
    //       itemA = 5 // FIXME Number of values Corine Level 1 --> atm determined without calc
    //     } else {
    //       itemA = selectsOptionalItemA[0].values.length
    //     }
    //   }
    //   if (selectsOptionalItemB.length !== 0) {
    //     if (selectsOptionalItemB[0].type.query_key === 'landcover' && selectsOptionalItemB[0].values[0].value_id === 10) {
    //       itemB = 5 // FIXME Number of values Corine Level 1 --> atm determined without calc
    //     } else {
    //       itemB = selectsOptionalItemB[0].values.length
    //     }
    //   }

    //   if (itemA < itemB) {
    //     return -1
    //   }
    //   if (itemA > itemB) {
    //     return 1
    //   }
    //   return 0
    // },

    getCategoryTechnologyItems (itemListCategories) {
      const categoryItems = itemListCategories.map(item => item.value).filter((value, index, self) =>
        index === self.findIndex(t => (
          t.type.id === value.type.id && t.id === value.id
        ))
      )

      return categoryItems.filter(item => item.type.query_key === 'technology').sort((a, b) => a.my_order - b.my_order)
    },

    filterItemListByTechnology (technologyItem) {
      if (this.regionAgsSelected !== null) {
        if (technologyItem !== undefined) {
          if (this.categoryTechnologyIdSelected !== technologyItem.id) {
            this.categoryTechnologyIdSelected = technologyItem.id
            this.categoryTechnologyIdsSelected = this.categoryTechnologyIds.filter(id => id === technologyItem.id)

            this.itemListIdsSelected = this.itemListSorted.filter(item => item.categories.some(cat => cat.value.id === technologyItem.id)).map(item => item.id)

            // this.itemListFilteredByTechnology = this.itemListSorted.filter(item => item.categories.some(cat => cat.value.id === technologyItem.id))
          } else {
            this.categoryTechnologyIdSelected = null
            this.categoryTechnologyIdsSelected = this.categoryTechnologyIds

            this.itemListIdsSelected = this.itemListSorted.map(item => item.id)

            // this.itemListFilteredByTechnology = this.itemListSorted
          }
        } else {
          // default init
          if (this.categoryTechnologyIdsSelected.length === 0) {
            this.categoryTechnologyIdsSelected = this.categoryTechnologyIds

            this.itemListIdsSelected = this.itemListSorted.map(item => item.id)

            // this.itemListFilteredByTechnology = this.itemListSorted
          }
        }
      }
    },

    // async getReportYearData ({ agsId, spatialType }) {
    //   this.itemListReportYearData = []
    //   this.itemListReportRangeData = []

    //   await Promise.all(this.itemListReportEntries.map(async (entry) => {
    //     const dataCategories = '3,4'
    //     const dataYear = entry[0]
    //     const itemIds = entry[1].map(item => item.id).join(',')

    //     const response = await axios.get(`${this.baseURL}api/v1/eemonitor/data/?spatial_type=${spatialType}&ags=${agsId}&data_year=${dataYear}&item__in=${itemIds}&data_category__in=${dataCategories}`)
    //       .catch(err => console.log('error get_item_data ', err)) // TODO Error Handling

    //     response.data.forEach(obj => {
    //       this.itemListReportYearData.push(obj)
    //     })
    //   }))
    // },

    async getReportRangeData ({ agsId, spatialType }) {
      this.itemListReportRangeData = []

      const dataCategories = '3,4'
      const itemIds = this.itemListReport.map(item => item.id).join(',')

      const response = await axios.get(`${this.baseURL}api/v1/eemonitor/data/?spatial_type=${spatialType}&ags=${agsId}&item__in=${itemIds}&data_category__in=${dataCategories}`)
        .catch(err => console.log('error get_item_data ', err)) // TODO Error Handling

      this.itemListReportRangeData = response.data
    },

    cardUpdated () {
      this.cardUpdatedCounter = this.cardUpdatedCounter + 1

      if (this.cardUpdatedCounter >= this.itemListSorted.length) {
        this.showOverlay = false
      }
    },

    onScrollReportList () {
      const windowScrollY = window.scrollY

      // TODO https://stackoverflow.com/questions/31223341/detecting-scroll-direction
      // const st = window.pageYOffset || document.documentElement.scrollTop

      Object.entries(this.$refs).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length !== 0) {
            if (key.includes('report_list_item_')) {
              this.cardSelectAreaHeight = this.$refs.card_select_area.$el.clientHeight
              const appBar = this.$vuetify.application.top
              // const appFooter = this.$vuetify.application.bottom
              // const appHeight = this.$vuetify.breakpoint.height

              const itemCardTop = value[0].$el.getBoundingClientRect().top
              const itemCardBottom = value[0].$el.getBoundingClientRect().bottom
              // const itemCardHeight = value[0].$el.clientHeight

              if (itemCardTop < this.cardSelectAreaHeight + appBar && itemCardBottom > this.cardSelectAreaHeight + appBar) {
              // if (appHeight - appBar - appFooter - this.cardSelectAreaHeight < itemCardHeight) {
                const itemId = Number(key.slice(key.lastIndexOf('_') + 1))

                this.itemScrolled = this.itemListReport.filter(item => item.id === itemId)[0]

                if (itemCardBottom < this.cardSelectAreaHeight + appBar + 100) {
                  this.itemScrolled = undefined
                }
              // }
              } else if (windowScrollY < this.cardSelectAreaHeight + appBar) {
                this.itemScrolled = undefined
              }
            }
          // this.lastScrollTop = st <= 0 ? 0 : st;
          }
        }
      })
    },

    setElementsHeights () {
      setTimeout(() => {
        if (this.$refs.card_select_area && this.$refs.card_about_area && this.$refs.card_select_area) {
          this.reportListPaddingTop = this.$refs.card_select_area.$el.clientHeight + this.paddingBottom

          this.reportItemListHeight = this.$vuetify.breakpoint.height - this.$vuetify.application.top - this.$vuetify.application.footer - this.$refs.card_select_area.$el.clientHeight - this.$refs.card_about_area.$el.clientHeight - this.paddingSubstraction
        }
      }, 300)
    },

    getPlaceholderText (regions) {
      if (regions.length !== 0) {
        if (regions[0].ade === 2) {
          if (this.locale === 'de') {
            return 'Bitte wählen Sie ein Bundesland aus'
          } else {
            return 'Please select a federal state'
          }
        } else if (regions[0].ade === 4) {
          if (this.locale === 'de') {
            return 'Bitte wählen Sie einen Landkreis aus'
          } else {
            return 'Please select a county'
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.loading-indicator-card-md-and-up {
  width: calc(100% - 24px);
}

.loading-indicator-card-sm-and-down {
  width: 100%;
}

.v-chip--disabled {
  pointer-events: all !important;
}

.v-chip--disabled:hover{
  opacity: 0.8 !important;
}

.v-chip:focus::before {
    opacity: 0 !important;
}

.v-chip:hover::before {
    opacity: 0.12 !important;
}

.chip-group-technology .v-slide-group__content {
  justify-content: center;
}

.chips-technology {
  background: rgb(225, 232, 238) !important;
  text-transform: initial !important;
  border: 1px dotted var(--v-secondary-lighten1) !important;

  &.v-btn--active {
    // color: var(--v-primary-base) !important;
    border: none !important;
  }
}

.v-select__selections > input {
  width: 1px !important;
}

.region-select {
  .v-select__selections > input {
    min-width: 1px !important;
  }

  >.v-input__control {
    >.v-text-field__details {
        display: none !important;
      }
  }
}
.hide-region-report {
  visibility: hidden;
}

</style>
