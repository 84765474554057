<template>
  <div>
    <!-- v-resize="setItemCardHeights" -->
    <v-card
      class="fill-height pa-3"
      height="100%"
      flat
      rounded="0"
    >
      <!-- :style="[selectItemListHeight !== 0 ? {'min-height': selectItemListHeight + 'px'}: {}]" -->
      <v-row
        no-gutters
        align="stretch"
        justify="space-between"
      >
        <v-col
          cols="12"
          class="px-2 px-sm-3 pt-2"
        >
          <div
            v-if="categoryItem && itemsFiltered[0] && categoryItemSelected[0]"
            class="text-h5"
          >
            {{ itemsFiltered[0].type[`title_plural_${locale}`] }}
            -
            {{ categoryItemSelected[0].type[`title_${locale}`] }}:
            {{ categoryItem[`title_${locale}`] }}
          </div>

          <div
            v-else
            class="text-h5 text-sm-h4"
          >
            <span
              v-if="locale === 'de'"
            >
              Alle
            </span>

            <span
              v-else
            >
              All
            </span>

            <span
              v-if="itemsFiltered[0]"
            >
              {{ itemsFiltered[0].type[`title_plural_${locale}`] }}
            </span>
          </div>
        </v-col>
      </v-row>

      <div
        is="transition-group"
        class="row no-gutters px-0 mx-sm-1"
      >
        <!-- @enter="onEnter" -->
        <!-- @leave="onLeave" -->

        <v-col
          v-for="(item, index) in itemsFiltered"
          :key="index"
          class="pa-2"
          :cols="12"
          :sm="12"
          :md="6"
          :lg="3"
          :xl="3"
        >
          <SelectMainItemCard
            ref="select_main_item_cards"
            class="fill-height"
            :style="{height: itemCardsMaxHeight + 'px'}"
            :item="item"
            @ismounted="cardUpdated()"
          />
        </v-col>
      </div>
    </v-card>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions } from 'vuex'

// import _gsap from 'gsap'
import _ from 'lodash'

import SelectMainItemCard from '@/components/apps/report/selection/SelectMainItemCard.vue'

export default {
  name: 'SelectMainItemListPage',

  components: {
    SelectMainItemCard
  },

  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    selectItemListHeight: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      cardUpdatedCounter: 0,
      itemsFiltered: [],
      itemCardsMaxHeight: '100%'
      // itemsSorted: []
    }
  },

  computed: {
    ...mapState('main', ['locale']),
    ...mapState('select_items', [
      'categoryItemSelected'
    ]),

    itemsSorted () {
      return _.sortBy([...this.items], [`title_${this.locale}`])
      // return _.sortBy([...this.items], 'my_order')
    },

    categoryItem () {
      return this.categoryItemSelected
    }
  },

  watch: {
    locale (newVal, oldVal) {
      this.itemsFiltered = _.sortBy([...this.itemsFiltered], [`title_${this.locale}`])
    }

    // itemsFiltered (newVal, oldVal) {
    //   this.setItemCardHeights()
    // }
  },

  beforeMount () {
    window.scrollTo(0, 0)
  },

  mounted () {
    this.filterItems({
      categoryItem: this.categoryItemSelected
    })

    eventBus.$on('filter-items-list', ({
      categoryItem,
      mode
    }) => {
      if (
        (this.categoryItemSelected === categoryItem &&
         mode !== 'reload') ||
      categoryItem === null ||
      categoryItem === undefined
      ) {
        this.remove_category_item_selected()

        // this.itemsSortedFiltered = this.items
        this.filterItems({
          categoryItem: undefined
        })
      } else {
        this.filterItems({
          categoryItem
        })

        this.set_category_item_selected({
          categoryItem
        })
      }
    })

    // this.setItemCardHeights()
  },

  beforeDestroy () {
    eventBus.$off('filter-items-list')
  },

  methods: {
    ...mapActions('select_items', [
      'remove_category_item_selected',
      'set_category_item_selected'
    ]),

    cardUpdated () {
      this.cardUpdatedCounter = this.cardUpdatedCounter + 1

      if (this.cardUpdatedCounter === this.itemsFiltered.length) {
        this.$emit('showoverlay')
        this.cardUpdatedCounter = 0
      }
    },

    setItemCardHeights () {
      this.$nextTick(() => {
        setTimeout(() => {
          let refItems = []

          for (const [key, value] of Object.entries(this.$refs)) {
            refItems = value
          }

          const itemCardHeights = refItems.map((ref) => {
            return ref.$el.clientHeight
          })

          this.itemCardsMaxHeight = Math.max(...itemCardHeights) - 1
        }, 300)
      })
    },

    // onEnter (el, done) {
    //   _gsap.to(el, {
    //     opacity: 1,
    //     delay: el.dataset.index * 0.15,
    //     onComplete: done,
    //     duration: 1.0
    //   })
    // },

    // onLeave (el, done) {
    //   _gsap.to(el, {
    //     opacity: 0,
    //     height: 0,
    //     delay: el.dataset.index * 0.15,
    //     onComplete: done,
    //     duration: 0.5
    //   })
    // },

    filterItems ({
      categoryItem
    }) {
      if (categoryItem !== null && categoryItem !== undefined) {
        this.itemsFiltered = this.itemsSorted.filter(item => item.categories.some(cat => cat.value.id === categoryItem.id))
      } else {
        this.itemsFiltered = this.itemsSorted
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
