<template>
  <!--  -->
  <div
    id="chart1"
    class="d-flex flex-column fill-height"
  >
    <!-- <div
      class="chart-title-area"
      style="width: 100%; "
    >
      <div
        class="px-6 d-flex flex-row align-center justify-start fill-height text-h6"
        style="height: 30px; width: 100%;"
      >
        {{ chartTitle }}
      </div>
    </div> -->

    <ChartCardHeader
      :title="chartTitle"
    />

    <div
      class="d-flex fill-height pa-3"
    >
      <VChart
        ref="chart1"
        renderer="svg"
        :autoresize="true"
        :option="option"
      />
    </div>
  </div>
</template>

<script>
//  IMPORT
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

import _ from 'lodash'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

// COMPONENTS
import ChartCardHeader from '@/components/dashboard/cardtitle/ChartCardHeader.vue'

import {
  SVGRenderer
} from 'echarts/renderers'
import {
  LineChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
} from 'echarts/components'

use([
  SVGRenderer,
  LineChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
])

export default {
  name: 'ChartsLineLayoutStandard1',

  components: {
    VChart,
    ChartCardHeader
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'svg'
    }
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default: 1
    },
    spatialTypeId: {
      type: Number,
      default: 2
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      bkgItems: [],
      data: [],
      dataAgs: [],
      dataValueUnit: '',
      isValueNaN: false,
      itemDataFiltered: [],
      itemDataYears: [],
      itemDateMinValue: 0,
      option: {},
      series: {
        areaStyle: {
          color: 'rgb(225, 232, 238)'
        },
        connectNulls: true,
        data: [],
        emphasis: {
          focus: 'series',
          itemStyle: {
            // color: '#003E6E',
            borderColor: '#003E6E' // borderStyle: 'solid'
          },
          label: {
            show: false
          },
          lineStyle: {
            color: '#003E6E',
            type: 'solid',
            width: 6
          }
        },
        // lineStyle: {
        //   width: 1,
        //   type: 'solid'
        // },
        sampling: 'average', // itemStyle: {        //   color: (dataItem) => {
        //     if (Number(dataItem.name) < this.targetValueYear) {
        //       return dataItem.color
        //     } else {
        //       return this.targetValueColor
        //     }
        //   }
        // }

        symbol: 'emptyCircle',
        // label: {
        //   show: true,
        //   position: 'top',
        //   distance: 12
        // },
        symbolSize: 8,
        triggerLineEvent: true,
        type: 'line'
      },
      echartsOption: {
        brush: {
          brushLink: 'all',
          outOfBrush: {
            colorAlpha: 0.1
          },
          xAxisIndex: 'all'
        },
        // legend: {
        //   type: 'scroll',
        //   orient: 'vertical',
        //   left: 80,
        //   top: 60,
        //   show: true,
        //   bottom: 20,
        //   backgroundColor: '#ffffff'
        // },
        color: [],
        grid: {
          bottom: '0',
          containLabel: true,
          height: 'auto',
          left: '10',
          right: '20',
          top: '30',
          width: 'auto'
        },
        legend: {
          backgroundColor: '#ffffff',
          icon: 'circle',
          selectedMode: false,
          show: true,
          textStyle: {
            color: '#003e6e',
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: '400'
          }
        },
        selectedMode: 'false',
        series: [],
        textStyle: {
          color: '#003e6e',
          fontFamily: 'Roboto',
          fontSize: 14,
          fontWeight: '400'
        },
        title: {
          left: 'center',
          text: '',
          textStyle: {
            color: '#003e6e',
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: '400'
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: true
            }
          },
          show: false
        },
        tooltip: {},
        xAxis: {
          axisLabel: {},
          axisLine: {
            lineStyle: {
              color: '#003e6e'
            },
            show: true
          },
          axisTick: {
            alignWithLabel: true,
            show: true
          },
          boundaryGap: true,
          splitLine: {
            show: true
          },
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            formatter: '{value}' // axisPointer: {
            //   show: true,
            //   snap: true
            // }

          },
          nameTextStyle: {
            color: '#003e6e',
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: '400'
          },
          type: 'value'
        }
      }
    }
  },
  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'echartsDefaults'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataMinMax',
      'dashboardItemsDataYears'
    ]),

    chartTitle () {
      return this.locale === 'de' ? 'Bundesrepublik Deutschland' : 'Germany'
    }
  },

  watch: {
    height (newVal, oldVal) {
      this.$refs.chart1.resize()
    },

    itemData (newVal, oldVal) {
      this.updateChart()
    }

    // locale (newVal, oldVal) {
    // }
  },

  async mounted () {
    // Get array of distinct years in data
    this.itemDataYears = this.dashboardItemsDataYears

    // Set echarts defauls from store
    // this.echartsOption.series = _.cloneDeep(this.echartsDefaults.map.series)
    this.echartsOption.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    // this.echartsOption.xAxis = _.cloneDeep(this.echartsDefaults.xAxis)
    this.echartsOption.tooltip = _.cloneDeep(this.echartsDefaults.tooltipTriggerAxis)

    // Set x-Axis Interval (index:number, value: string) => boolean
    this.echartsOption.xAxis.axisLabel.interval = await this.get_axis_value_interval({ years: this.itemDataYears })
    this.echartsOption.xAxis.axisTick.interval = await this.get_axis_value_interval({ years: this.itemDataYears, interval: 1 })

    // Get value unit title_short (string)
    const dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.dashboardItem
    })
    this.dataValueUnit = dataValueUnitObj.title_short_de

    this.updateChart()

    // eventBus.$on('set-locale', (locale) => {
    //   this.echartsOption.title.text = this.setTitleText(spatialTypeSelected)

    //   if (showLegend === true) {
    //     this.chartTitle = this.setTitleText(spatialTypeSelected)
    //   }

    //   this.$refs.chart1.setOption(this.echartsOption)
    // })

    eventBus.$on('resize-echarts-chart1', (dimensions) => {
      this.$refs.chart1.chart.resize()
    })

    // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart
    this.$refs.chart1.chart.getZr().on('click', params => {
      this.chartsOnClick(params)
    })

    let agsTitleSelected

    eventBus.$on('highlight-charts-series', async (
      agsId
    ) => {
      // console.log('agsId ', agsId)

      if (agsId === undefined) {
        this.$refs.chart1.chart.dispatchAction({
          type: 'downplay',
          seriesName: agsTitleSelected
        })
      } else {
        if (this.bkgItems.length !== 0 && this.bkgItems !== undefined) {
          const agsTitle = await this.get_ags_gen_title({
            ags: agsId
          })

          this.$refs.chart1.chart.dispatchAction({
            type: 'downplay',
            seriesName: agsTitle
          })

          this.$refs.chart1.chart.dispatchAction({
            type: 'highlight',
            seriesName: agsTitle
          })

          agsTitleSelected = agsTitle
        }
      }

      // console.log('this.data ', this.data)

      // // const datasetsPerYear = this.data.filter((ds) => { return ds.data_year === 2021 })
      // this.echartsOption.yAxis.max = this.dashboardItemsDataMinMax.valueMax

      // const datasetsPerAgs = this.data.filter((ds) => { return ds.ags === attribute.ags })

      // const dataY = datasetsPerAgs.map((ds) => {
      //   if (ds.data_value === null) {
      //     return 0
      //   } else {
      //     return ds.data_value
      //   }
      // })

      // const dataX = datasetsPerAgs.map((ds) => {
      //   return ds.data_year
      // })

      // // console.log('dataX ', dataX)
      // // console.log('dataY ', dataY)

      // // TODO Callback function for number formating
      // // https://echarts.apache.org/en/option.html#yAxis.axisLabel.formatter
      // this.echartsOption.yAxis.axisLabel.formatter = `{value} ${this.dataValueUnit}`

      // this.echartsOption.xAxis.data = dataX
      // this.echartsOption.series[0].data = dataY
    })

    // this.echartsOption.yAxis.max = this.FL_MaxValue[
    //   this.selectIndicatorSelected
    // ]
    // this.echartsOption.series[0].itemStyle.color = this.FL_RendererColor[1]
    // this.$eventHub.on('emit-set-echarts-barchart-data', (AGS) => {
    //   this.echartsData(AGS)
    // })
  },

  beforeDestroy () {
    this.$refs.chart1.chart.dispose()
    this.$refs.chart1.chart = null

    eventBus.$off('highlight-charts-series')
    eventBus.$off('load-echarts-line-1-component')
    eventBus.$off('resize-echarts-chart1')
    eventBus.$off('set-locale')

    // this.$eventHub.off('emit-set-echarts-barchart-data')
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_ags_gen_title'
    ]),

    ...mapActions('dashboard_items', [
      'get_axis_value_interval',
      'get_datasets_value_min',
      'get_datasets_ags',
      'get_datasets_value_max',
      'get_datasets_value_min',
      'get_datasets_years',
      'get_item_data_value_unit',
      'get_max_fraction_digits'
    ]),

    ...mapMutations('dashboard_items', [
      'SET_ITEM_YEAR_SELECTED'
    ]),

    chartsOnClick (params) {
      // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart

      let categoryName

      this.$refs.chart1.chart.dispatchAction({
        type: 'downplay'
      })

      if (params.event.target) {
        const pointInPixel = [params.offsetX, params.offsetY]
        const pointInGrid = this.$refs.chart1.chart.convertFromPixel('grid', pointInPixel)
        categoryName = this.$refs.chart1.chart.getModel().get('xAxis')[0].data[pointInGrid[0]]
      } else {
        categoryName = params.name
      }

      eventBus.$emit('update-echarts-map-component', {
        year: Number(categoryName)
      })

      this.SET_ITEM_YEAR_SELECTED({
        year: Number(categoryName)
      })
    },

    async updateChart () {
      const itemDataCloned = _.cloneDeep(this.itemData)

      // this.isValueNaN = isValueNaN

      this.bkgItems = await this.get_ags_gen_title({
        ags: '00000000'
      })

      // this.echartsOption.legend.show = showLegend

      // if (showLegend === true) {
      //   this.echartsOption.legend.show = showLegend

      //   // this.echartsOption.title.text = ''
      //   this.chartTitle = agsNames.map((obj) => {
      //     return await this.get_ags_gen_title({ ags: obj.ags })
      //   }).join(', ')
      // } else {
      //   this.echartsOption.legend.show = showLegend

      //   // this.echartsOption.title.text = this.setTitleText(spatialTypeSelected)
      //   this.chartTitle = this.setTitleText(spatialTypeSelected)
      // }

      // this.dataAgs = await this.get_datasets_ags(this.data)

      // // Set colors and highlight color at array position of selected dataset
      // this.echartsOption.color = _.cloneDeep(this.echartsDefaults.color)
      // if (this.spatialTypeId === 2) {
      //   this.echartsOption.color[1] = '#ffdf33'
      // } else if (this.spatialTypeId === 4) {
      //   this.echartsOption.color[2] = '#ffdf33'
      // }

      // Create series datasets
      const series = []
      const agsId = '00000000'

      // Filter data
      this.itemDataFiltered = itemDataCloned.filter(ds => ds.ags === agsId)

      this.echartsOption.yAxis.min = 0
      this.echartsOption.yAxis.max = await this.get_datasets_value_max(this.itemDataFiltered)

      const dataValues = this.itemDataFiltered.map((ds) => {
        return ds.data_value
      })

      // NOTE Deep clone is needed for resolving inheritance issues
      const seriesObj = _.cloneDeep(this.series)

      // Set data array of data series
      seriesObj.data = dataValues

      // seriesObj.itemStyle.color = '#333' // NOTE Just for testing

      // Set name of data series (-> BKG (BEZ +) GEN)
      seriesObj.name = await this.get_ags_gen_title({
        ags: agsId
      })

      if (agsId === '00000000') {
        seriesObj.lineStyle = {
          color: '#003e6e',
          width: 4,
          type: 'line'
        }
      }

      seriesObj.itemStyle = {
        color: '#003e6e',
        width: 6,
        type: 'solid'
      }

      series.push(seriesObj)

      // Set created series data
      this.echartsOption.series = series

      // Set number of min & max digits for the number of value descendants
      if (!this.dataValueUnit.match(/^n$/)) {
        this.itemDateMinValue = await this.get_datasets_value_min(this.itemDataFiltered)
        this.maximumFractionDigits = await this.get_max_fraction_digits({ minValue: this.itemDateMinValue })
      }

      if (this.dataValueUnit.match(/^%$/) &&
       this.dashboardItem.id !== 8
      ) {
        this.echartsOption.yAxis.max = 100
      } else if (this.dashboardItem.id === 8) {
      // NOTE workaround fix for item "Endenergieproduktivität"
        this.echartsOption.yAxis.min = this.itemDateMinValue
      }

      this.echartsOption.yAxis.name = this.dataValueUnit
      this.echartsOption.yAxis.axisLabel.formatter = (value) => `${value.toLocaleString(this.locale)}`

      this.echartsOption.yAxis.axisLabel.formatter = (value) => `${value.toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })}`

      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        const title = `<div class="text-h6">
                      ${params[0].seriesName} (${params[0].name})
                      </div>`

        if (isNaN(params[0].value)) {
          return `${title}<div>${this.locale === 'de' ? 'kein Wert' : 'no value'}</div>`
        } else {
          return `${title}
          <div>${params[0].value.toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })}
          ${this.dataValueUnit}</div>`
        }
      }

      // let tooltipDisplay = ''
      // this.$refs.chart1.chart.on('mouseover', (params) => {
      //   console.log(params)
      //   // Check if it's hovering a line
      //   if (params.componentSubType === 'line') {
      //     // get hovered line series name
      //     tooltipDisplay = `${params.seriesName}<br> ${params.value} ${this.dataValueUnit}`
      //   }
      // })

      // // Called when your mouse leaves an object (params : the object you leave)
      // this.$refs.chart1.chart.on('mouseout', (params) => {
      //   tooltipDisplay = ''
      // })
      // function formatter (params) {
      //   // console.log(params)
      //   return tooltipDisplay
      // }
      // this.echartsOption.tooltip.formatter = formatter

      // X-axis -> Set year as category
      this.echartsOption.xAxis.data = this.itemDataYears

      // Set options on the echarts instance
      this.$refs.chart1.setOption(this.echartsOption)
    },

    echartsData (AGS) {
      if (AGS === '' || AGS === undefined) {
        this.echartsOption.series[0].data = []
        this.echartsOption.yAxis.max = 0
      } else {
        this.itemDataFiltered = this.attributesActiveFL.filter((e) => {
          return e.AGS === AGS
        })[0]

        const dataX = this.getDates(
          this.selectTemporalSelected,
          this.dateRange,
          this.dateDefinitions
        )

        // const dataY = dataFiltered[0].value.split(',').map(e => {
        //   if (e === '') {
        //     return '0'
        //   } else if (e === '-1') {
        //     return '0'
        //   } else {
        //     return e
        //   }
        // })

        // const dataY = dataFiltered[0].value

        const dataY = this.itemDataFiltered[this.selectIndicatorSelected.value]

        this.echartsOption.xAxis.data = dataX
        this.echartsOption.yAxis.max = this.FL_MaxValue[
          this.selectIndicatorSelected
        ]
        this.echartsOption.series[0].data = dataY
        this.echartsOption.series[0].itemStyle.color = this.FL_RendererColor[1]
      }
    },

    getDates (temp, dateRange, dateDefinitions) {
      if (Object.keys(dateRange).length !== 0) {
        const def = dateDefinitions[temp.ID - 1]

        const dateRangeDates = Array.from(dateRange.by(def.rangeBy))

        let dates = []
        dates = dateRangeDates.map(d => d.format(def.timeline))

        return dates
      }
    }
  }
}
</script>

<style lang="scss">

</style>
