var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c('DashboardLoadingIndicator'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"xl":_vm.showSelectOptional ? 9 : 12,"lg":_vm.showSelectOptional ? 9 : 12,"md":"12","sm":"12","cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"flex-nowrap flex-grow-0 flex-shrink-0",attrs:{"xl":"5","lg":"5","md":"5","sm":"12","cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"grow d-flex flex-column flex-nowrap",attrs:{"cols":"12"}},[_c('v-card',{ref:"card_charts_line_1",staticClass:"fill-height",style:({'height': _vm.cardHeight + 'px'}),attrs:{"rounded":"0","elevation":_vm.cardElevation}},[_c('div',{staticClass:"d-flex flex-column fill-height"},[(_vm.dashboardItem &&
                      _vm.itemData &&
                      _vm.dashboardItemsDataYears.length !== 0)?_c('ChartsMapWrapper',{attrs:{"dashboard-item":_vm.dashboardItem,"height":_vm.cardHeight,"spatial-type-id":_vm.spatialTypeId,"item-data":_vm.itemData}}):_vm._e()],1)])],1)],1)],1),_c('v-col',{attrs:{"xl":"7","lg":"7","md":"7","sm":"12","cols":"12"}},[_c('v-row',{staticStyle:{"flex-wrap":"nowrap"},attrs:{"dense":""}},[_c('v-col',{staticClass:"flex-grow-0",attrs:{"cols":"12"}},[_c('v-card',{style:({'height': _vm.cardHeight + 'px'}),attrs:{"rounded":"0","elevation":_vm.cardElevation}},[_c('div',{staticClass:"d-flex flex-column fill-height"},[(_vm.dashboardItem &&
                      _vm.itemData &&
                      _vm.dashboardItemsDataYears.length !== 0)?_c('div',{staticClass:"fill-height",staticStyle:{"height":"35%"}},[(_vm.dashboardItemsDataYears.length !==1)?_c('ChartsBarSpatialType1',{attrs:{"dashboard-item":_vm.dashboardItem,"spatial-type-id":_vm.spatialTypeId,"height":_vm.cardHeight,"item-data":_vm.itemData}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"fill-height grow",style:([_vm.dashboardItemsDataYears.length !== 1 ? {height: '65%'} : {height: '100%'}])},[(_vm.dashboardItem &&
                        _vm.itemData &&
                        _vm.dashboardItemsDataYears.length !== 0)?_c('ChartsLineLayoutStandard2',{attrs:{"dashboard-item":_vm.dashboardItem,"spatial-type-id":_vm.spatialTypeId,"height":_vm.dashboardItemsDataYears.length !== 1 ? _vm.cardHeight * 0.65 : _vm.cardHeight,"item-data":_vm.itemData}}):_vm._e()],1)])])],1)],1)],1)],1)],1),(_vm.showSelectOptional && _vm.$vuetify.breakpoint.lgAndUp)?_c('v-col',{attrs:{"xl":"3","lg":"3"}},[_c('v-card',{ref:"card_optional_data_option",staticClass:"section-scroll",style:({'height': _vm.cardHeight + 'px'}),attrs:{"rounded":"0","elevation":_vm.cardElevation}},[(_vm.dashboardItem &&
            _vm.itemData &&
            _vm.dashboardItemsDataYears.length !== 0 &&
            _vm.showSelectOptional)?_c('DashboardItemMenu',{attrs:{"select-query":_vm.selectOptional}}):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }