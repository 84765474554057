<template>
  <v-card
    rounded="6"
    elevation="0"
    class="webgis-region-search pa-3"
    style="pointer-events: all;"
  >
    <!-- :style="[$vuetify.breakpoint.smAndUp ? {'min-width': '350px'} : {}]" -->
    <v-container
      fluid
      class="pa-0"
    >
      <v-row
        class=""
      >
        <v-col
          class="flex-shrink-0 flex-grow-1"
          :cols="$vuetify.breakpoint.smAndUp ? 12 : 9"
        >
          <div
            class="d-flex flex-column "
          >
            <!-- <v-btn-toggle
        v-model="btnGroupSpatialType"
        active-class="btn-select-category-group-active"
        borderless
        dense
        group
        mandatory
        class="webgis-region-spatial-btn-group d-flex justify-space-between mb-3"
      >
        <v-btn
          v-for="spatialType in spatialTypeItems"
          :key="spatialType.ade"
          style="height: 18px; width: auto; border-radius: 6px;"
          class="btn-select align-self-end pa-2 my-0"
          text
          rounded
          @click="selectSpatialType(spatialType)"
        >
          <span
            class="align-self-end"
          >
            <span>
              {{ spatialType[`title_${locale}`] }}
            </span>

          </span>
        </v-btn>
      </v-btn-toggle> -->
            <v-slide-group
              v-model="slideGroup"
              show-arrows
              mandatory
              active-class="btn-select-category-group-active"
              class="d-flex align-center webgis-region-spatial-btn-group mb-3"
            >
              <v-slide-item
                v-for="spatialType in spatialTypeItems"
                :key="spatialType.ade"
                v-slot="{ active, toggle }"
              >
                <v-btn
                  :active-value="active"
                  :disabled="isMenuDisabled"
                  class="btn-select align-self-end pa-2 my-0"
                  rounded
                  style="height: 18px; width: auto; border-radius: 6px;"
                  text
                  @click="toggle && selectSpatialType(spatialType)"
                >
                  <span
                    class="align-self-end"
                  >
                    <span>
                      {{ spatialType[`title_${locale}`] }}
                    </span>

                  </span>
                </v-btn>
              </v-slide-item>
            </v-slide-group>

            <v-autocomplete
              v-if="Object.keys(spatialTypeSelected).length !== 0"
              v-model="regionSelected"
              :append-icon="selectAppendIcon"
              :disabled="isMenuDisabled || bkgItemsFiltered.length === 1"
              :items="bkgItemsFiltered"
              :placeholder="selectRegionPlaceholder"
              auto-select-first
              background-color="rgb(225, 232, 238)"
              class="select-region-search text-truncate"
              clearable
              dense
              filled
              item-text="gen"
              item-value="ags"
              return-object
              rounded
              style="border-radius: 35px; overflow-x: hidden;"
              @keydown.space.prevent
              @change="setRegionSelected"
            >
              <template
                #selection="data"
              >
                <span
                  class="text-right"
                >
                  <span
                    v-if="data.item.nbd"
                  >
                    {{ data.item.bez }}
                  </span>
                  <!-- &nbsp; -->
                  <span>
                    {{ data.item.gen }}
                  </span>
                </span>
              </template>

              <template
                #item="data"
              >
                <div
                  style="width: 100%; border-bottom: 1px dotted var(--v-secondary-lighten1);"
                  class="py-1"
                >
                  <span>
                    {{ data.item.gen }}
                  </span>
                  <!-- &nbsp; -->
                  <span
                    v-if="data.item.nbd"
                  >
                    ({{ data.item.bez }})
                  </span>
                </div>
              </template>
            </v-autocomplete>
          </div>
        </v-col>

        <v-col
          v-if="$vuetify.breakpoint.xsOnly"
          cols="3"
          class="flex-grow-1 d-flex justify-center align-center"
        >
          <OpenMainMenu />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions, mapMutations } from 'vuex'

import { mdiMagnify, mdiMenuOpen } from '@mdi/js'

import OpenMainMenu from '@/components/apps/re_plant_locations/webgis/buttons/OpenMainMenu.vue'

// WEBGIS DEFAULTS
import defaultValues from '@/webgis/default_values.js'

export default {
  name: 'SelectRegionSearch',

  components: {
    OpenMainMenu
  },

  data () {
    return {
      model: [],
      mdiMagnify: mdiMagnify,
      mdiMenuOpen: mdiMenuOpen,
      btnGroupSpatialType: 0,
      slideGroup: [],
      regionSelected: null,
      spatialTypeItems: [
        {
          ade: 1,
          title_de: 'Deutschland',
          title_en: 'Germany'
        },
        {
          ade: 2,
          title_de: 'Bundesländer',
          title_en: 'Federal States'
        },
        {
          ade: 4,
          title_de: 'Landkreise',
          title_en: 'Counties'
        }
      ],
      spatialTypeSelected: {}
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      // 'basemapSelected',
      'isMenuDisabled',
      'itemDataQuery'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems'
    ]),

    selectAppendIcon () {
      return this.spatialTypeSelected.ade === 1 ? null : this.regionSelected === null ? this.mdiMagnify : null
    },

    itemSelectsBasicFiltered () {
      return this.itemSelectsBasic.length !== 0 ? this.itemSelectsBasic.filter(select => select.type.query_key.includes('spatial_unit')) : []
    },

    bkgItemsFiltered () {
      return this.bkgItems.filter(item => item.ade === this.itemDataQuery.spatial_type)
    },

    spatialTypePlaceholder () {
      return this.locale === 'de' ? 'Bitte wählen Sie eine Räumliche Darstellung' : 'Please select a spatial type'
    },

    selectRegionPlaceholder () {
      return this.locale === 'de' ? 'Bitte wählen Sie eine Region' : 'Please select a region'
    }
  },

  watch: {
    bkgItemsFiltered: {
      handler (newVal, oldVal) {
        this.regionSelected = null
        if (newVal.length === 1) {
          this.regionSelected = newVal[0]
          this.update_data_query({
            query: {
              spatial_unit: '00000000'
            }
          })
        }
      },
      immediate: true
    },

    'itemDataQuery.spatial_type': {
      handler (newVal, oldVal) {
        this.slideGroup = this.spatialTypeItems.indexOf(this.spatialTypeItems.filter(item => item.ade === newVal)[0])
      },
      immediate: true
    }
  },

  async mounted () {
    this.spatialTypeSelected = this.spatialTypeItems.filter(item => item.ade === 1)[0]
  },

  methods: {
    ...mapActions('webgis', [
      'itemSelectsBasic',
      'set_layer_statistic_values',
      'update_data_query'
    ]),

    selectSpatialType (value) {
      this.set_layer_statistic_values(null)
      this.spatialTypeSelected = value

      this.update_data_query({
        query: {
          spatial_unit: '00000000'
        }
      })

      this.update_data_query({
        query: {
          spatial_type: value.ade
        }
      })

      eventBus.$emit('webgis-menu-region-update', {
        queryState: this.itemDataQuery
      })
    },

    setRegionSelected (regionObj) {
      let regionAgsId

      if (regionObj !== null) {
        regionAgsId = regionObj.ags
      } else {
        regionAgsId = '00000000'
      }
      this.update_data_query({
        query: {
          spatial_unit: regionAgsId
        }
      })

      eventBus.$emit('webgis-menu-region-update', {
        queryState: this.itemDataQuery
      })
    }
  }
}
</script>

<style lang="scss">
.select-region-search {
  .v-input__slot {
    min-height: 0 !important;
  }
  .v-input__append-inner {
    margin: 3px 0 !important;
  }
}

.webgis-region-search {
  .v-select__selections {
    overflow: hidden !important;
  }
  .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none !important;
  }

  & .v-input {
    &.v-text-field {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  .v-input__slot {
    margin: 0 !important;
  }

  .v-text-field__details {
    display: none !important;
  }

  button .v-icon__svg {
    height: 18px;
    width: 18px;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-bottom: 1px dotted rgb(225, 232, 238);
  }

  .v-select__selections {
    input {
      min-width: 1px !important;
    }
  }
}

.webgis-region-spatial-btn-group{
  .v-slide-group__content{
    button:not(:first-child) {
      margin-left: 4px;
    }
    button:not(:last-child) {
      margin-right: 4px;
    }
  }

 .v-slide-group__next, .v-slide-group__prev {
    min-width: 24px !important;
    flex: 0 1 24px !important;
  }
}

// .webgis-region-spatial-btn-group {
//   div:not(:first-child) {
//     margin-left: 4px;
//   }
//   div:not(:last-child) {
//     margin-right: 4px;
//   }
// }
</style>
