<template>
  <v-container
    fluid
  >
    <!-- :class="$vuetify.breakpoint.mdAndUp ? 'pa-3' : 'pa-0'" -->
    <HeaderMain
      app
    />

    <v-row
      v-if="itemIsLoaded"
      class="fill-height"
      no-gutters
    >
      <v-col
        class="fill-height"
      >
        <DashboardMain
          :key="dashboardItems.id"
          :dashboard-id="1"
          :dashboard-item="dashboardItems"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'

import DashboardMain from '@/components/dashboard/DashboardMain.vue'
import HeaderMain from '@/components/main/header/HeaderMain.vue'

export default {
  name: 'DashboardComponent',

  components: {
    DashboardMain,
    HeaderMain
  },

  data () {
    return {
      itemIsLoaded: false,
      item: {}
    }
  },

  computed: {
    ...mapState('main', [
      'application'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItems'
    ]),

    ...mapState('select_items', [
      'itemList'
    ])
  },

  async activated () {
    await this.loadItem()
  },

  beforeDestroy () {
    // console.log('dashboard beforedestroy')
  },

  async mounted () {
    // console.log('dashboard mounted')

    await this.loadItem()
  },

  methods: {
    ...mapActions('main', [
      'set_locale'
    ]),

    ...mapActions('dashboard_items', [
      'get_item'
    ]),

    ...mapActions('select_items', [
      'get_item_list'
    ]),

    ...mapMutations('dashboard_items', [
      'SET_ITEM',
      'DELETE_DASHBOARD_LAYOUT'
    ]),

    async loadItem () {
      this.itemIsLoaded = false

      if (this.$route.params.loadOnlyItem === undefined) {
        this.item = await this.get_item({
          slug: this.$route.params.slug
        })

        if (this.item !== undefined) {
          // Detect language from slug and set Locale to this language (de/en)
          this.set_locale({
            item: this.item,
            route: this.$route,
            locale: ''
          })

          this.itemIsLoaded = true

          eventBus.$emit('item-is-loaded-dashboard')

          // API call for retrieving all published and to the application assigned (show_in_application) items
          if (this.itemList.length === 0) {
            await this.get_item_list({
              application: this.application
            })
          }
        }
      } else {
        // FIXME In the case of loading also the 2nd item a procedure is needed for adding and removing the item and also for indicating which item is loaded

        // REVIEW Function maybe only called on first mount
        // Retrieve item from API
        await this.get_item({
          slug: this.$route.params.slug
        })

        // await this.get_item({
        //   slug: this.$nuxt.$route.params.slug
        // })
        this.itemIsLoaded = true

        eventBus.$emit('item-is-loaded-dashboard')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
