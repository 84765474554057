<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-resize="setDropDownWidth"
    class="fill-height d-flex flex-row align-center"
  >
    <div
      class="d-flex select-dynamic"
      style="width: 100%"
    >
      <!-- START SELECTS -->

      <!-- SELECT DROPDOWN SINGLE -->

      <div
        v-if="selectValue.type.select_type === 'select-dropdown-single'"
        class="d-flex flex-column fill-height"
        style="width: 100%"
      >
        <!-- TITLE SELECTS -->

        <!-- <div
          class="shrink text-h6 font-weight-light"
          style="white-space:nowrap;"
        >
          {{ selectValue.type[`title_${locale}`] }}
        </div> -->

        <!-- SELECT -->

        <v-select
          v-if="selectValue.values.length !== 1 ||
            selectValue.type.query_key.includes('spatial_unit')"
          ref="select"
          :item-text="selectValue.type.select_item_text_field"
          :item-value="'id'"
          :items="selectValue.values"
          :menu-props="{ maxWidth: dropDownWidth }"
          :placeholder="selectValue.type[`title_plural_${locale}`]"
          :value="selectState[selectValue.type.query_key]"
          class="grow text-h6 font-weight-medium text-truncate"
          return-object
          @change="updateData({event: $event, selectValue: selectValue})"
        >
          <!-- :item-value="selectValue.type.query_field" -->
          <template
            #selection="data"
          >
            <span
              v-if="locale"
              class="text-left"
            >
              {{ data.item[`title_${locale}`] }}
            </span>
          </template>

          <template
            #item="data"
          >
            <span
              v-if="locale"
            >
              {{ data.item[`title_${locale}`] }}
            </span>
          </template>
        <!-- :label="selectValue.type.title_de" -->
        <!-- <template
          #prepend-inner
        >
          <div
            style="width: 100% !important; word-break: keep-all;"
            class="text-h6 font-weight-light"
          >
            {{ selectValue.type[`title_${locale}`] }}:
          </div>
        </template> -->
        </v-select>

        <div
          v-else
          class="d-flex flex-column fill-height justify-space-between align-stretch select-dynamic select-one-value"
        >
          <div
            class="select-one-value-title text-h6 font-weight-medium"
          >
            {{ selectValue.value_default[`title_${locale}`] }}
          </div>
        </div>
      </div>

      <!-- SELECT DROPDOWN AUTOCOMPLETE -->

      <div
        v-if="selectValue.type.select_type === 'select-dropdown-multi'"
        class="d-flex flex-column fill-height"
        style="width: 100%"
      >
        <!-- TITLE SELECTS -->

        <!-- <div
          class="shrink text-h6 font-weight-light"
          style="white-space:nowrap;"
        >
          {{ selectValue.type[`title_${locale}`] }}
        </div> -->

        <!-- SELECT -->

        <v-autocomplete
          v-if="selectValue.values.length !== 1 ||
            selectValue.type.query_key.includes('spatial_unit')"
          ref="select"
          :item-text="selectValue.type.select_item_text_field"
          :item-value="selectValue.type.query_field"
          :items="selectValue.values"
          :menu-props="{ maxWidth: dropDownWidth }"
          :placeholder="selectValue.type[`title_plural_${locale}`]"
          :value="selectState[selectValue.type.query_key]"
          auto-select-first
          class="grow text-h6 font-weight-medium text-truncate"
          clearable
          return-object
          @change="updateData({event: $event, selectValue: selectValue})"
        >
          <template
            #selection="data"
          >
            <span
              class="d-flex text-right"
            >
              <span
                v-if="locale"
              >
                <span
                  v-if="data.item.nbd"
                >
                  {{ data.item.bez }}
                </span>

                {{ data.item.gen }}

                {{ data.item[`title_${locale}`] }}
              </span>
            </span>
          </template>

          <template
            #item="data"
          >
            <span
              v-if="locale"
            >

              {{ data.item.gen }}

              <span
                v-if="data.item.nbd"
              >
                ({{ data.item.bez }})
              </span>

              {{ data.item[`title_${locale}`] }}

            </span>
          </template>

          <!-- :label="selectValue.type.title_de" -->

          <!-- <template
            #prepend-inner
          >
            <span
              style="width: 100% !important; word-break: keep-all;"
              class="text-h6 font-weight-light"
            >
              {{ selectValue.type[`title_${locale}`] }}:
            </span>
          </template> -->
        </v-autocomplete>

        <div
          v-else
          class="d-flex flex-column fill-height justify-space-between align-stretch select-dynamic select-one-value"
        >
          <!-- <div
            class="shrink text-h6 font-weight-light"
            style="white-space:nowrap;"
          >
            {{ selectValue.type[`title_${locale}`] }}
          </div> -->

          <div
            class="select-one-value-title text-h6 font-weight-medium"
          >
            {{ selectValue.value_default[`title_${locale}`] }}
          </div>
        </div>
      </div>

      <!-- SELECT BUTTON Single -->

      <div
        v-if="selectValue.type.select_type === 'select-button-single'"
      >
        <!--  -->
      </div>

      <!-- SELECT BUTTON GROUP -->

      <div
        v-if="selectValue.type.select_type === 'select-button-group'"
        class="d-inline-flex"
      >
        <!-- TITLE SELECTS -->

        <!-- <div
          class="shrink text-h6 font-weight-medium mr-1"
          style="white-space:nowrap; line-height: 2.1rem;"
        >
          {{ selectValue.type[`title_${locale}`] }}:
        </div> -->

        <v-btn-toggle
          ref="btn_toogle"
          v-model="btnToggleValue"
          active-class="btn-select-category-group-active"
          borderless
          dense
          group
          mandatory
        >
          <!-- <div
            v-for="select in selectValue.values"
            :key="select.id"
            class="d-flex"
          > -->
          <v-btn
            v-for="(select, index) in selectValue.values"
            :key="index"
            style="height: 18px; border-radius: 6px;"
            class="btn-select align-self-end pa-2 ma-0 mr-2"
            text
            rounded
            @click="updateData({event: select, selectValue: selectValue})"
          >
            <span
              class="align-self-end"
            >
              <span
                v-if="$vuetify.breakpoint.lgAndUp"
              >
                {{ select[`title_${locale}`] }}
              </span>

              <span
                v-else
              >
                {{ select[`title_short_${locale}`] }}
              </span>

            </span>
          </v-btn>

          <!-- <span
                v-if="categoryType !== orderedCategoryTypeItems[orderedCategoryTypeItems.length - 1]"
                class="text-h4 mx-2 align-self-center"
              > |
              </span> -->
          <!-- </div> -->
        </v-btn-toggle>
      </div>

      <!-- SELECT OPTIONAL SELECTS  -->

      <div
        v-if="selectValue.type.select_type === 'select-panel-list'"
      >
        <div
          class="select-panel-list-title-area"
        >
          <div
            class="text-h6 select-panel-list-title px-6 d-flex align-center justify-start px-5"
            style="height: 30px;"
          >
            <span
              v-if="selectValue.values.length !== 1"
            >
              {{ selectValue.type[`title_plural_${locale}`] }}
            </span>

            <span
              v-else
            >
              {{ selectValue.type[`title_${locale}`] }}
            </span>
          </div>
        </div>

        <div
          v-if="selectValue.type.detail_info[0]"
          class="text-body-1 select-panel-list-border"
          style="padding: 16px 24px;"
        >
          <!-- <span>
            {{ selectValue.type.detail_info[0][`info_text_${locale}`] }}
          </span> -->

          <div
            v-if="selectValue.type.detail_info[0][`info_text_${locale}`].length !== 0"
            class="d-flex flex-column justify-start align-end info-text-panel"
          >
            <!-- REVIEW Alternative https://stackoverflow.com/questions/67624605/show-the-whole-text-when-clicking-read-more-vuejs oder https://github.com/orlyyani/read-more  -->
            <p
              v-show="isSnipped"
              v-snip="vSnipOptions"
              class="ma-0 pa-0"
            >
              {{ selectValue.type.detail_info[0][`info_text_${locale}`] }}
            </p>

            <p
              v-show="!isSnipped"
              class="ma-0 pa-0"
            >
              {{ selectValue.type.detail_info[0][`info_text_${locale}`] }}
            </p>

            <v-btn
              v-show="isSnipped"
              text
              plain
              class="text-lowercase mt-1 ma-0 pa-0"
              style="height: auto;"
              @click="isSnipped = false"
            >
              <span
                v-if="locale==='de'"
              >
                öffnen
              </span>
              <span
                v-else
              >
                read more
              </span>
            </v-btn>

            <v-btn
              v-show="!isSnipped"
              text
              plain
              class="text-lowercase mt-1 ma-0 pa-0"
              style="height: auto;"
              @click="isSnipped = true"
            >
              <span
                v-if="locale==='de'"
              >
                schließen
              </span>
              <span
                v-else
              >
                close
              </span>
            </v-btn>
          </div>
        </div>

        <v-expansion-panels
          v-model="stateExpansionPanels"
          accordion
          active-class="select-panel-active"
          flat
          focusable
          hover
          mandatory
          tile
          :readonly="selectValue.values.length > 1 ? false : true"
        >
          <v-expansion-panel
            v-for="select in selectValue.values"
            :key="select.id"
            class="select-panel"
          >
            <v-expansion-panel-header
              class="pl-6 pr-1 py-1"
              style="min-height: 28px;"
              @click="updateData({event: select, selectValue: selectValue})"
            >
              <template
                #actions
              >
                <v-icon
                  size="20"
                >
                  {{ mdiChevronDown }}
                </v-icon>
              </template>

              <div
                class="text-h6 font-weight-medium"
              >
                {{ select[`title_${locale}`] }}
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div
                v-if="select.detail_info.length !== 0"
                class="text-body-1 pt-3"
              >
                <div
                  v-html="select.detail_info[0][`info_text_${locale}`]"
                >
                  <!-- {{ select.detail_info[0][`info_text_${locale}`] }} -->
                </div>

                <div
                  v-if="select.detail_info[0].info_url"
                  class="mt-1 pb-1"
                >
                  <a
                    :href="select.detail_info[0].info_url"
                    target="_blank"
                  >
                    <v-icon
                      class="pr-1"
                      size="15"
                      color="var(--v-base-primary)"
                    >
                      {{ mdiOpenInNew }}
                    </v-icon>{{ select.detail_info[0][`info_url_title_${locale}`] }}
                  </a>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <!-- END SELECTS -->
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions, mapMutations } from 'vuex'

// LODASH
import _ from 'lodash'

// MATERIAL ICONS
import { mdiChevronDown, mdiOpenInNew } from '@mdi/js'

export default {
  name: 'DynamicSelectComponent',

  props: {
    selectValue: {
      type: Object,
      default () {
        return {}
      }
    },
    selectState: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      btnToggleValue: 0,
      dropDownWidth: 0,
      mdiChevronDown: mdiChevronDown,
      mdiOpenInNew: mdiOpenInNew,
      stateExpansionPanels: 0,
      updateActive: true,
      vSnipOptions: {
        lines: 3,
        mode: 'css'
      },
      isSnipped: true
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataQuery',
      'dashboardItemsSelectsOptional'
    ]),

    selectPlaceholderTitle () {
      return (this.locale === 'de' ? 'Auswahl ' : 'Select ') + this.selectValue.type[`title_plural_${this.locale}`]
    }
  },

  beforeDestroy () {
    eventBus.$off(`reset-select-${this.selectValue.type.query_key}`)
    eventBus.$off(`set-select-${this.selectValue.type.query_key}`)
  },

  mounted () {
    // TODO Extract selects in single components

    // Set state of default value for expansion panels
    const selectStateValue = Object.values(this.selectState)[0]
    this.stateExpansionPanels = this.selectValue.values.findIndex(val => val.value_id === selectStateValue.value_id)

    // TODO Set state of default value for buttons

    // Set size of drop down for the select/autocomplete
    this.setDropDownWidth()

    // Event listener for setting select
    eventBus.$on(
      `set-select-${this.selectValue.type.query_key}`, (ags) => {
        const selectValueFiltered = this.selectValue.values
          .filter((val) => {
            return val.ags === ags
          })

        this.$refs.select.internalValue = selectValueFiltered[0]

        this.updateData({
          event: selectValueFiltered[0],
          selectValue: this.selectValue
        })
      })

    // Event listener for resetting select
    eventBus.$on(`reset-select-${this.selectValue.type.query_key}`, ({ update }) => {
      if (this.$refs.select) {
        this.$refs.select.internalValue = null

        if (update === true) {
          this.updateData({
            event: null,
            selectValue: this.selectValue
          })
        }
      }
    })
  },

  methods: {
    ...mapActions('dashboard_items', [
      'update_data_query'
    ]),

    setDropDownWidth () {
      setTimeout(() => {
        if (this.$refs.select) {
          this.dropDownWidth = this.$refs.select.$el.clientWidth
        }
      }, 500)
    },

    async updateData ({ event, selectValue }) {
      // console.log('updateData ', event, selectValue)

      const queryKey = selectValue.type.query_key
      const optionType = selectValue.type.option_type

      const eventValue = event === null ? null : event[selectValue.type.query_field]

      if (
        queryKey.includes('spatial_type') ||
        queryKey.includes('data_category')
        // optionType !== 'optional'
      // if (optionType !== 'optional' && optionType === 'basic' ||
      //    !queryKey.includes('spatial_unit')
      ) {
        eventBus.$emit('reset-region-selected', {
          event,
          selectValue
        })
      }

      if (
        // optionType === 'basic' &&
        !queryKey.includes('spatial_unit')
      ) {
        if (queryKey === 'spatial_type') {
          let update = false
          const spatialTypeId = this.dashboardItemsDataQuery.filter((opt) => {
            return 'spatial_type' in opt
          })[0].spatial_type
          if (spatialTypeId !== 2) {
            update = true
          }

          selectValue.values.forEach(val => {
            eventBus.$emit(
              `reset-select-spatial_unit_${val.value_id}`,
              {
                update: update
              }
            )
          })
        } else if (optionType !== 'optional') {
          const spatialUnitOptionKeys = this.dashboardItemsDataQuery
            .filter((opt) => {
              return Object.keys(opt)[0]
                .includes('spatial_unit')
            })

          if (spatialUnitOptionKeys.length !== 0) {
            const lastSpatialUnitId = Object.keys(
              spatialUnitOptionKeys.slice(-1)[0]
            )[0].slice(-1)

            eventBus.$emit(
              `reset-select-spatial_unit_${lastSpatialUnitId}`,
              {
                update: false
              }
            )
          }
        }
      }

      if (queryKey.includes('spatial_unit')) {
        const notToQuery = queryKey.slice(-1)

        const spatialUnitOptionKeys = this.dashboardItemsDataQuery
          .filter((opt) => {
            return Object.keys(opt)[0]
              .includes(queryKey.slice(0, -1))
          })

        spatialUnitOptionKeys.forEach((optKey) => {
          if (Object.keys(optKey)[0].slice(-1) > notToQuery) {
            eventBus.$emit(`reset-select-spatial_unit_${Object.keys(optKey)[0]
            .slice(-1)}`, {
              update: true
            })
          }
        })
      }

      // Update data query
      await this.update_data_query({
        query: { [queryKey]: eventValue }
      })

      if (selectValue.type.query_type === 'api') {
        // NOTE API data query

        eventBus.$emit('update-loaded-dashboard',
          this.dashboardItemsDataQuery
        )
      } else {
        // NOTE Local data query either by spatial_unit or corine

        if (eventValue === null) {
          this.value = undefined

          const similarSelects = this.dashboardItemsSelectsOptional.filter((opt) => {
            return opt.type.query_key.includes(String(queryKey).slice(0, -1))
          })

          similarSelects.map(async (select) => {
            const isValueGreater = Number(select.type.query_key.charAt(select.type.query_key.length - 1)) >= Number(queryKey.charAt(queryKey.length - 1))

            if (isValueGreater) {
              // Update data query
              await this.update_data_query({
                query: {
                  [select.type.query_key]: eventValue
                }
              })

              // REVIEW Clear other select values?
            } else {
              return ''
            }
          })
        } else {
          // Update data query
          await this.update_data_query({
            query: {
              [queryKey]: eventValue
            }
          })
        }

        if (queryKey.includes('spatial_unit')) {
          // const spatialTypeOptionValueId = this.dashboardItemsDataQuery
          //   .filter((opt) => {
          //     return Object.keys(opt)[0]
          //       .includes('spatial_type')
          //   })[0].spatial_type

          eventBus.$emit('update-echarts-spatial-unit',
            {
              event,
              selectValue
            }
          )
        } else if (queryKey.includes('corine')) {
          eventBus.$emit('update-echarts-corine',
            {
              event,
              selectValue
            }
          )
        } else if (queryKey.includes('data_statistic')) {
          eventBus.$emit('update-echarts-map-boxplot',
            {
              event,
              selectValue
            }
          )
        }
      }
    }
  }
}
</script>

<style lang="scss" >

.v-input__icon--clear {
  opacity: 1 !important;
}

.v-text-field .v-input__icon--clear {
    opacity: 0.5;
    transition: opacity .3s cubic-bezier(.25,.8,.5,1);
}

.select-dynamic {
  .v-select__slot:hover{
    background: var(--v-secondary-lighten3);
    // transition: background-color 0.3s ease-in-out;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .v-text-field__details {
    display: none !important;
  }

  .v-input.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
  }

  .v-text-field input {
    padding: 2px 0 2px !important;
    line-height: 8px !important;
  }

  .v-input__slot {
    margin-bottom: 4px;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot:before{
    border-color: var(--v-secondary-lighten2);
    border-style: solid;
    border-width: thin 0 0 0;
  }

  .v-input__slot:before {
    border-style: dotted !important;
  }

  .select-one-value-title {
      padding: 0px 0 2px !important;
      // margin: 2px 4px 3px 0 !important;
      border-color: var(--v-secondary-lighten2);
      border-style: dotted;
      border-width: 0 0 thin 0;
  }

  .v-select__selection--comma {
    margin: 2px 4px 2px 0 !important;
  }

  .v-application--is-ltr .v-text-field .v-input__append-inner {
    padding-left: 0 !important;
    padding-top: 0 !important;
  }

  .v-input__prepend-inner {
    width: 100%;
  }

  .v-text-field input {
    // flex: 1 1 auto;
    padding: 0 !important;
    // max-width: auto;
    // min-width: 300px;
    // width: auto;
  }

  .v-input {
    display: flex !important;

    &>.v-input__control {
      height: 100% !important;
      justify-content: end !important;
    }
  }

  .v-input__icon {
    height: 10px;

    .v-input__icon--clear {
      opacity: 1 !important;
    }
  }

  .v-icon.v-icon {
    font-size: 1.5rem;
    color: var(--v-secondary-base);
  }

  // Select Autocomplete
  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 1px;
  }

  .v-select__selections {
    flex-wrap: nowrap !important;
    // display: unset !important;
    // white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;
  }

}

.v-list-item {
  min-height: 24px;
}

.select-dynamic {
  button .v-icon__svg {
    height: 14px;
    width: 14px;
  }

  .v-expansion-panel-content::before,
  .select-panel-list-title::before {
    background-color: var(--v-primary-base);
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .v-expansion-panel-content::before {
    opacity: 0.04;
  }

  .select-panel-list-title-area {
    background: #ffffff;
    position: sticky;
    top: 0px;
    z-index: 2;
  }

  // .select-panel-list-border {
  //   border-bottom: 0.1px solid var(--v-primary-base);
  // }

  .select-panel-list-title::before {
      opacity: 0.12;
  }

  .select-panel-active {
    // .v-expansion-panel-header div {
    //   font-weight: 800 !important;
    // }

    .v-expansion-panel-header {
      min-height: 36px;
    }

    .v-expansion-panel-header--active {
      min-height: 48px;
    }
  }

  // .select-panel:first-child {
  //   border-top: 0.1px solid var(--v-primary-base);
  // }

  .select-panel {
    // box-shadow: inset 0px 0px 0px 0.1px;
    border-top: 0.1px solid var(--v-primary-base);
  }

  .select-panel:last-child {
    border-bottom: 0.1px solid var(--v-primary-base);
  }
}

.v-expansion-panel-header {
  padding-right: 0 !important;
}

</style>
