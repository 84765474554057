import _ from 'lodash'

import axios from 'axios'

import { eventBus } from '@/utils/eventbus.js'

export default {
  async get_item_list ({ dispatch, commit, rootState }, {
    application
  }) {
    const { data } = await axios.get(rootState.main.baseURL + 'api/v1/eemonitor/items/')
      .catch((err) => {
        console.log('error get_item_list ', err)
        eventBus.$emit('push-to-page-error-404')
      }) // TODO Error Handling

    const filteredItemsByApplication = data.filter((item) => {
      return item.show_in_applications.some((app) => {
        return app.id === application.id
      })
    })

    commit('SET_ITEM_LIST', filteredItemsByApplication)
  },
  set_category_item_selected ({ commit }, payload) {
    commit('SET_CATEGORY_ITEM_SELECTED', payload)
  },
  remove_category_item_selected ({ commit }, payload) {
    commit('REMOVE_CATEGORY_ITEM_SELECTED', payload)
  },
  set_category_type_selected ({ commit }, payload) {
    commit('SET_CATEGORY_TYPE_SELECTED', payload)
  }
}
