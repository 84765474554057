<template>
  <v-dialog
    v-model="isDialogOpenState"
    scrollable
    transition="dialog-bottom-transition"
    max-width="800"
    style="background: #ffffff"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :style="[$vuetify.breakpoint.smAndDown ? {'z-index': 2000} : {}]"
  >
    <v-card
      rounded="0"
      elevation="0"
    >
      <v-card-title
        class="pa-0 ma-0"
      >
        <v-toolbar
          dark
          dense
          flat
          color="primary"
          height="36"
        >
          <v-toolbar-title
            class="text-h6 ml-2"
            style="line-height: 2.3;"
          >
            <span
              v-if="locale === 'de'"
            >
              Zitationshinweis
            </span>
            <span
              v-else
            >
              Citation notes
            </span>
          </v-toolbar-title>

          <v-spacer />

          <v-toolbar-items>
            <v-btn
              dark
              icon
              @click="isDialogOpenState = false"
            >
              <v-icon>
                {{ mdiClose }}
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <CitiationContent
        class="pa-6"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { eventBus } from '@/utils/eventbus.js'

import { mdiClose } from '@mdi/js'

import CitiationContent from '@/components/base/cititation/CitiationContent.vue'

export default {
  name: 'DialogCitiationContent',

  components: {
    CitiationContent
  },

  data () {
    return {
      isDialogOpenState: false,
      itemObject: {},
      mdiClose: mdiClose
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  },

  async mounted () {
    eventBus.$on('open-dialog-cititation-info', () => {
      this.isDialogOpenState = true
    })
  },

  beforeDestroy () {
    eventBus.$off('open-dialog-cititation-info')
  }

}
</script>

<style lang="scss" scoped>

</style>
