<template>
  <div>
    <v-card
      flat
      rounded="0"
      class="box-light-blue"
    >
      <v-row
        no-gutters
        class="fill-height grow"
      >
        <!-- PARTNER -->

        <v-col
          class="d-flex flex-column flex-nowrap pa-6 grow"
          cols="12"
          md="6"
        >
          <v-row
            class="shrink"
          >
            <v-col
              class="text-h4"
            >
              <span
                v-if="locale === 'de'"
              >
                Projektpartner
              </span>
              <span
                v-else
              >
                Project partners
              </span>
            </v-col>
          </v-row>

          <v-row
            class="grow"
          >
            <v-col
              v-for="partner in projectPartner"
              :key="partner.id"
              :md="partner.md"
              cols="12"
            >
              <a
                :href="partner.url"
                target="_blank"
              >
                <v-card
                  class="d-flex align-center justify-center fill-height pa-6"
                  flat
                  hover
                  rounded="0"
                >
                  <v-img
                    :alt="partner.alt"
                    :src="require(`@/assets/logos/${partner[`logo_${locale}`]}`)"
                    aspect-ratio="1"
                    contain
                    max-height="80px"
                  >
                    <template
                      #placeholder
                    >
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          color="grey lighten-5"
                          indeterminate
                          style="width: 100px; height: 100px;"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </a>
            </v-col>
          </v-row>
        </v-col>

        <!-- FUNDING -->

        <v-col
          class="d-flex flex-column flex-nowrap pa-6 grow"
          cols="12"
          md="6"
        >
          <v-row
            class="shrink"
          >
            <v-col
              class="text-h4"
            >
              <span
                v-if="locale === 'de'"
              >
                Förderung
              </span>
              <span
                v-else
              >
                Funding
              </span>
            </v-col>
          </v-row>

          <v-row
            class="grow"
          >
            <v-col
              cols="12"
              md="6"
            >
              <a
                :href="projectFunding.url"
                target="_blank"
              >
                <v-card
                  class="d-flex align-center justify-center fill-height pa-6"
                  flat
                  hover
                  rounded="0"
                >
                  <v-img
                    :alt="projectFunding.alt"
                    :src="require(`@/assets/logos/${projectFunding[`logo_${locale}`]}`)"
                    aspect-ratio="1"
                    contain
                    max-height="80px"
                  >
                    <template
                      #placeholder
                    >
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          color="grey lighten-5"
                          indeterminate
                          style="width: 100px; height: 100px;"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </a>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-card
                flat
                rounded="0"
                class="text-body-2 fill-height pa-3"
              >
                <span
                  v-if="locale==='de'"
                >
                  Das Forschungsprojekt wird gefördert durch das <a
                    href="https://www.bfn.de"
                    target="_blank"
                  >Bundesamt für Naturschutz</a> mit Mitteln des <a
                    href="https://www.bmuv.de"
                    target="_blank"
                  >Bundesministeriums für Umwelt, Naturschutz, nukleare Sicherheit und Verbraucherschutz</a>.
                </span>

                <span
                  v-else
                >
                  The research project is supported by the german <a
                    href="https://www.bfn.de/en"
                    target="_blank"
                  >Federal Agency for Nature Conservation</a> with funds from the german <a
                    href="https://www.bmuv.de/en/"
                    target="_blank"
                  >Federal Ministry for the Environment, Nature Conservation, Nuclear Safety and Consumer Protection</a>.
                </span>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EEMonitorPartner',

  data () {
    return {
      projectFunding:
        {
          id: 1,
          url: 'https://www.bfn.de',
          logo_de: 'logo_bfn_2022_de.svg',
          logo_en: 'logo_bfn_2022_en.svg',
          alt: 'Bundesamt für Naturschutz BfN'
        },
      projectPartner: [
        {
          id: 1,
          md: 8,
          url: 'https://www.ufz.de',
          logo_de: 'logo_ufz_de.svg',
          logo_en: 'logo_ufz_en.svg',
          alt: 'Helmholtz-Zentrum für Umweltforschung GmbH - UFZ'
        },
        {
          id: 2,
          md: 4,
          url: 'https://www.dbfz.de',
          logo_de: 'logo_dbfz_de.svg',
          logo_en: 'logo_dbfz_de.svg',
          alt: 'Deutsches Biomasseforschungszentrum DBFZ gemeinnützige GmbH'
        }
      ]
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
