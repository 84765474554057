<template>
  <div>
    <v-card
      v-if="userHasConsent !== null"
      elevation="0"
      rounded="0"
      class="pa-6"
    >
      <div
        class="text-h5 mb-1"
      >
        <div
          v-if="userHasConsent"
        >
          <span
            v-if="locale === 'de'"
          >
            Opt-Out Matomo Webanalyse
          </span>

          <span
            v-else
          >
            Opt-out Matomo webanalysis
          </span>
        </div>

        <div
          v-else
        >
          <span
            v-if="locale === 'de'"
          >
            Opt-In Matomo Webanalyse
          </span>

          <span
            v-else
          >
            Opt-in Matomo webanalysis
          </span>
        </div>
      </div>

      <div
        class="text-body-1 mb-3"
      >
        <div
          v-if="userHasConsent"
        >
          <span
            v-if="locale === 'de'"
          >
            Sie haben die Möglichkeit zu verhindern, dass von Ihnen hier getätigte Aktionen analysiert und verknüpft werden. Dies wird Ihre Privatsphäre schützen, aber wird auch den Besitzer daran hindern, aus Ihren Aktionen zu lernen und die Bedienbarkeit für Sie und andere Benutzer:innen zu verbessern.
          </span>

          <span
            v-else
          >
            You have the option to prevent actions you take here from being analysed and linked. This will protect your privacy, but will also prevent the owner from learning from your actions and improving usability for you and other users.
          </span>
        </div>

        <div
          v-else
        >
          <span
            v-if="locale === 'de'"
          >
            Ihre Besuche auf dieser Webseite werden vom Webanalysetool nicht erfasst. Bitte beachten Sie, dass auch der Matomo-Deaktivierungs-Cookie dieser Webseite gelöscht wird, wenn Sie die in Ihrem Browser abgelegten Cookies entfernen. Außerdem müssen Sie, wenn Sie einen anderen Computer oder einen anderen Webbrowser verwenden, die Deaktivierungsprozedur nochmals absolvieren.
          </span>

          <span
            v-else
          >
            Your visits to this website will not be collected by the web analytics tool. Please note that the Matomo deactivation cookie of this website will also be deleted if you remove the cookies stored in your browser. In addition, if you use a different computer or web browser, you will need to complete the deactivation procedure again.
          </span>
        </div>
      </div>

      <v-alert
        v-model="showSuccessOptOut"
        type="success"
      >
        <span
          v-if="locale === 'de'"
        >
          Opt-out abgeschlossen.
        </span>
        <span
          v-else
        >
          Opt-out completed
        </span>
      </v-alert>

      <v-alert
        v-model="showSuccessOptIn"
        type="success"
      >
        <span
          v-if="locale === 'de'"
        >
          Opt-in abgeschlossen.
        </span>
        <span
          v-else
        >
          Opt-in completed
        </span>
      </v-alert>

      <div
        class="mb-3"
      >
        <div
          v-if="userHasConsent"
        >
          <span
            v-if="locale === 'de'"
          >
            Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse erfasst. Mit folgendem Button können Sie den Opt-Out bestätigen.
          </span>

          <span
            v-else
          >
            Your visit to this website is currently collected by Matomo web analytics. You can confirm your opt-out by clicking the following button.
          </span>
        </div>

        <div
          v-else
        >
          <span
            v-if="locale === 'de'"
          >
            Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse nicht erfasst. Mit folgendem Button können Sie den Opt-In bestätigen.
          </span>

          <span
            v-else
          >
            Your visit to this website is currently not recorded by Matomo web analytics. You can confirm the opt-in with the following button.
          </span>
        </div>
      </div>

      <div
        class="d-flex justify-start"
      >
        <v-btn
          v-if="userHasConsent && !showSuccessOptOut"
          outlined
          text
          elevation="0"
          color="var(--v-primary-base)"
          style="border: 1px dotted var(--v-secondary-lighten1); padding: 0 6px;"
          class="mr-6"
          @click="optOut()"
        >
          <span>
            Opt-out
          </span>
        </v-btn>

        <v-btn
          v-if="!userHasConsent && !showSuccessOptIn"
          outlined
          text
          elevation="0"
          color="var(--v-primary-base)"
          style="border: 1px dotted var(--v-secondary-lighten1); padding: 0 6px;"
          @click="optIn()"
        >
          <span>
            Opt-in
          </span>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PrivacyPolicyOptout',

  data () {
    return {
      showSuccessOptIn: false,
      showSuccessOptOut: false,
      userHasConsent: null
    }
  },

  computed: {
    ...mapState('main', [
      'isPrivacyConsentOpen',
      'locale'
    ])
  },

  async mounted () {
    setTimeout(() => {
      this.userHasConsent = this.$matomo.hasConsent()
    }, 1000)
  },

  methods: {
    ...mapActions('main', [
      'set_privacy_consent'
    ]),

    optOut () {
      this.showSuccessOptIn = false

      this.$matomo.forgetConsentGiven()
      this.$matomo.optUserOut()
      this.$matomo.forgetUserOptOut()

      this.set_privacy_consent(false)

      this.userHasConsent = false

      this.showSuccessOptOut = true
    },

    optIn () {
      this.showSuccessOptOut = false

      this.$matomo.forgetUserOptOut()
      this.$matomo.rememberConsentGiven(720)
      this.$matomo.rememberCookieConsentGiven()

      this.set_privacy_consent(true)

      this.userHasConsent = true

      this.showSuccessOptIn = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
