<template>
  <div
    v-if="user.isAuthenticated"
    class="fill-height"
    style="width: 100%;"
  >
    <!-- mode="out-in" -->
    <transition
      name="fade"
      style="width: 100%;"
    >
      <router-view />
    </transition>

    <DialogCititation />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import DialogCititation from '@/components/main/dialog/DialogCititation.vue'

export default {
  name: 'RePlantLocationView',

  components: {
    DialogCititation
  },

  data () {
    return {
      applicatonName: 'eelocation'
    }
  },

  computed: {
    ...mapState('main', [
      'user'
    ])
  },

  beforeDestroy () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },

  async mounted () {
    if (!this.user.isAuthenticated) {
      this.$router.push('/')
    }

    await this.get_application_item({
      applicatonName: this.applicatonName
    })

    // window.location.href = 'https://www.ufz.de/ee-monitor-app/webgis'
  },

  methods: {
    ...mapActions('main', [
      'get_application_item'
    ])
  }
}
</script>

<style lang="scss">
</style>
