<template>
  <div>
    <v-container
      id="scroll-target"
      class="fill-height"
      style="max-width: 1200px; width: auto;"
    >
      <div>
        <BreadcrumbNav />
      </div>

      <v-row
        align="start"
      >
        <v-col>
          <!-- ABOUT CARD -->

          <v-card
            flat
            class="fill-height pa-6 mb-6"
            rounded="0"
          >
            <v-row
              no-gutters
              class="pb-1 flex-column"
              justify="start"
              align="stretch"
            >
              <v-col
                class="text-h4 font-weight-medium pb-2"
              >
                <div
                  class="text-h4 font-weight-medium"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    Über den EE-Monitor
                  </span>
                  <span
                    v-else
                  >
                    About the EE Monitor
                  </span>
                </div>
              </v-col>

              <v-col
                class="text-body-1"
              >
                <span
                  v-if="locale === 'de'"
                >
                  <p>
                    Der EE-Monitor informiert aus Umweltsicht über den Ausbau der Erneuerbaren Energien in Deutschland. Er stellt auf der Grundlage von Forschungsergebnissen aus dem Bereich der Energie- und Umweltforschung umfangreiche Informationen zum naturverträglichen Ausbau der Erneuerbaren Energien, zu Standorten von Erneuerbare-Energien-Anlagen und zu energiebezogenen Aspekten zur Verfügung.
                  </p>
                  <p>
                    Aufgabe des EE-Monitors ist es, auf wissenschaftlich fundierter Grundlage positive Trends, aber auch Fehlentwicklungen beim naturverträglichen Ausbau der Erneuerbaren Energien aufzuzeigen. Er soll als Informationsbasis für die gesellschaftliche und politische Diskussion über den naturverträglichen Ausbau der Erneuerbaren Energien in Deutschland dienen.
                  </p>
                </span>
                <span
                  v-else
                >
                  <p>
                    The EE-Monitor provides information on the expansion of renewable energies in Germany from an environmental perspective. Based on research results from the field of energy and environmental research, it provides comprehensive information on the nature-friendly expansion of renewable energies, on the locations of renewable energy plants or on energy-related aspects.
                  </p>
                  <p>
                    The task of the EE-Monitor is to identify positive trends, but also undesirable developments in the nature-friendly expansion of renewable energies on a scientifically sound basis. It is intended to serve as a basis of information for the social and political discussion on the nature-friendly expansion of renewable energies in Germany.
                  </p>
                </span>
              </v-col>
            </v-row>
          </v-card>

          <!-- OCCASSION AND BACKGROUND CARD -->

          <v-card
            flat
            class="fill-height pa-6 mb-6"
            rounded="0"
          >
            <v-row
              no-gutters
              class="pb-1 flex-column"
              justify="start"
              align="stretch"
            >
              <v-col
                class="text-h4 pb-2"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Anlass und Hintergrund
                </span>
                <span
                  v-else
                >
                  Occasion and background
                </span>
              </v-col>

              <v-col
                class="text-body-1"
              >
                <div
                  class="mb-3"
                >
                  <div
                    class="text-body-1"
                  >
                    <span
                      v-if="locale === 'de'"
                    >
                      Seit Inkrafttreten des Stromeinspeisungsgesetzes (StromEinspG) im Jahr 1991 und des Erneuerbare-Energien-Gesetzes (EEG) im Jahr 2000 haben sich die erneuerbaren Energien in Deutschland insbesondere im Strombereich rasant entwickelt. Wurden 1990 noch rund 19 TWh Strom aus erneuerbaren Energien erzeugt, waren es 2021 bereits 234 TWh. Damit liegt ihr Anteil am Bruttostromverbrauch im Jahr 2021 bereits bei rund 41 %. Um die nationalen Klimaschutzziele zu erreichen, soll der Anteil von Strom aus erneuerbaren Energien am Bruttostromverbrauch bis 2030 auf 80 % steigen. Klimaschutz ist in diesem Nexus eine wichtige Voraussetzung für den Erhalt von Natur und Landschaft und trägt dazu bei, das Überleben von Arten langfristig zu sichern. Auch deshalb ist ein weiterer starker Ausbau der erneuerbaren Energien vorgesehen.
                    </span>
                    <span
                      v-else
                    >
                      Since the Electricity Feed Act (StromEinspG) came into force in 1991 and the Renewable Energy Sources Act (EEG) in 2000, renewable energies have developed rapidly in Germany, especially in the electricity sector. In 1990, around 19 TWh of electricity was generated from renewable energy sources; by 2021, this figure had already risen to 234 TWh. This means that they will already account for around 41% of gross electricity consumption in 2021. In order to achieve the national climate protection targets, the share of electricity from renewable energy sources in gross electricity consumption should increase to 80% by 2030. In this context, climate protection is also an important prerequisite for nature and landscape conservation and helps to ensure the long-term survival of species. For this reason, a further strong expansion of renewable energies is also planned.
                    </span>
                  </div>
                </div>

                <div>
                  <div>
                    <span
                      v-if="locale === 'de'"
                    >
                      Mit dem Ausbau der Erneuerbaren Energien sind jedoch auch zunehmende Auswirkungen auf Natur und Umwelt verbunden, die zu (Ziel-)Konflikten mit den Belangen des Naturschutzes führen. Beispiele hierfür sind Vogel- und Fledermausschlag an Windenergieanlagen, der Verlust von Brachflächen und Biodiversität durch Monokulturen von Energiepflanzen oder die Beeinträchtigung des Landschaftsbildes durch Hochspannungsleitungen. Hinzu kommen Ressourcen- und Lebenszyklusfragen bei der Herstellung und dem Betrieb von Anlagen zur Nutzung erneuerbarer Energien. Der EE-Monitor soll helfen, diese Auswirkungen systematisch zu erfassen, um positive Trends, aber auch Fehlentwicklungen im Sinne eines naturverträglichen Ausbaus der erneuerbaren Energien aufzuzeigen.
                    </span>
                    <span
                      v-else
                    >
                      However, the expansion of renewable energies is also accompanied by increasing impacts on nature and the environment, which can lead to conflicts with nature conservation. Examples include bird and bat strikes on wind turbines, the loss of fallow land and biodiversity due to monocultures of energy crops, or the disruption of the landscape by high-voltage power lines. There are also resource and lifecycle issues in the production and operation of renewable energy facilities. The RE Monitor aims to systematically record these impacts in order to highlight positive trends as well as undesirable developments in the context of an environmentally sustainable expansion of renewable energy.
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <!-- RESEARCH CARD-->

          <v-card
            flat
            class="fill-height pa-6 mb-6"
            rounded="0"
          >
            <v-row
              no-gutters
              class="pb-1 flex-column"
              justify="start"
              align="stretch"
            >
              <v-col
                class="text-h4 pb-2"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Forschungsarbeiten
                </span>
                <span
                  v-else
                >
                  Research
                </span>
              </v-col>
              <v-col
                class="text-h5 pb-5"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Die Inhalte des EE-Monitors basieren im Wesentlichen auf den Forschungsergebnissen der folgenden Projekte:
                </span>
                <span
                  v-else
                >
                  The contents of the EE-Monitor are essentially based on the research results of the following projects:
                </span>
              </v-col>

              <v-col
                class="text-body-1"
              >
                <v-expansion-panels
                  flat
                  tile
                  class="pb-0"
                >
                  <!-- PANEL 1-->

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="background: rgb(225, 232, 238); border: dotted 1px var(--v-secondary-lighten1);"
                    >
                      <template
                        #actions
                      >
                        <v-icon
                          color="var(--v-primary-secondary)"
                          size="24"
                        >
                          {{ mdiChevronDown }}
                        </v-icon>
                      </template>
                      <span
                        class="text-h5"
                      >
                        <span
                          v-if="locale === 'de'"
                        >
                          Umsetzungsmöglichkeiten eines Monitorings zur Berücksichtigung der Anforderungen von Natur und Landschaft beim Ausbau der erneuerbaren Energien und Netze im Strombereich (Kurztitel: EEMonReport)
                        </span>
                        <span
                          v-else
                        >
                          Implementation possibilities of a monitoring to consider the requirements of nature and landscape in the expansion of renewable energies and grids in the electricity sector (short title: EEMonReport)
                        </span>
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      style="border: dotted 1px var(--v-secondary-lighten1);"
                      class="pt-6"
                    >
                      <v-row
                        no-gutters
                        class="pb-1 flex-column"
                        justify="start"
                        align="stretch"
                      >
                        <v-col
                          class="text-body-1"
                        >
                          <div
                            class="mb-3"
                          >
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Beschreibung:
                              </span>
                              <span
                                v-else
                              >
                                Description:
                              </span>
                            </div>
                            <div>
                              <span
                                v-if="locale === 'de'"
                              >
                                Da neben dem Klimaschutz auch die Erhaltung der biologischen Vielfalt ein wichtiges Ziel der Bundesregierung ist, sind die Ausbauziele der erneuerbaren Energien naturverträglich umzusetzen. Im Vorhaben „Umsetzungsmöglichkeiten eines Monitorings zur Berücksichtigung der Anforderungen von Natur und Landschaft beim Ausbau der erneuerbaren Energien und Netze im Strombereich“ (EEMonReport) soll auf Basis des abgeschlossenen EE-Monitor Vorhabens (FKZ: 3515 82 2700) ein webbasiertes Berichtsformat (Web-Applikation) entwickelt werden, das mithilfe von im Vorhaben festzulegenden Kennzahlen über den erreichten Fortschritt beim naturverträglichen Ausbau der Erneuerbaren in Deutschland im Strombereich informieren kann und so eine Wissensbasis für politisches Handeln und für eine öffentliche Auseinandersetzung darüber schafft. So können einerseits Potenziale und Fehlentwicklungen aufgezeigt und andererseits die öffentliche Wahrnehmung für Naturschutzthemen der Energiewende gefördert werden.
                              </span>
                              <span
                                v-else
                              >
                                Since, in addition to climate protection, the preservation of biodiversity is also an important goal of the Federal Government, the expansion targets for renewable energies must be implemented in a manner that is compatible with nature. In the project "Implementation options for monitoring the requirements of nature and landscape in the expansion of renewable energies and grids in the electricity sector" (EEMonReport), a web-based reporting format (web application) is to be developed on the basis of the completed EE-Monitor project. - This will provide a knowledge base for political action and for a public debate on the subject. In this way, potentials and undesirable developments can be highlighted on the one hand, and public awareness of nature conservation issues of the energy transition can be promoted on the other.
                              </span>
                            </div>
                          </div>

                          <div
                            class="mb-3"
                          >
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Förderkennzeichen:
                              </span>
                              <span
                                v-else
                              >
                                Funding code:
                              </span>
                            </div>
                            <div>
                              <span>
                                (FKZ) 3520 86 0500
                              </span>
                            </div>
                          </div>

                          <div
                            class="mb-3"
                          >
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Laufzeit:
                              </span>
                              <span
                                v-else
                              >
                                Duration:
                              </span>
                            </div>
                            <div>
                              <span>
                                01.11.2020 – 30.06.2023
                              </span>
                            </div>
                          </div>

                          <div
                            class="mb-3"
                          >
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Projektergebnisse:
                              </span>
                              <span
                                v-else
                              >
                                Project results:
                              </span>
                            </div>
                            <div>
                              <span
                                v-if="locale === 'de'"
                              >
                                <ul>
                                  <li>
                                    WebTool Monioringreport
                                  </li>
                                  <li>
                                    Anpassung des Webbasierten Geoinformationssystem (Web-GIS)
                                  </li>
                                  <li>
                                    Ausstehendes BfN-Skript (Sommer 2023)
                                  </li>
                                </ul>
                              </span>
                              <span
                                v-else
                              >
                                <ul>
                                  <li>
                                    WebTool Monioringreport
                                  </li>
                                  <li>
                                    Adaptation of web-based geo-information system (Web-GIS) site data
                                  </li>
                                  <li>
                                    Pending BfN script (summer 2023)
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </div>

                          <div>
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Publikationen:
                              </span>
                              <span
                                v-else
                              >
                                Publications:
                              </span>
                            </div>
                            <div>
                              <span>
                                Manske, David; Grosch, Lukas; Schmiedt, Julius; Mittelstädt, Nora; Thrän, Daniela. Geo-Locations and System Data of Renewable Energy Installations in Germany. Data 2022, 7(9), 128; <a
                                  href="https://doi.org/10.3390/data7090128"
                                  target="_blank"
                                >Link</a>.
                              </span>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <!-- PANEL 2 -->

                  <v-expansion-panel>
                    <v-expansion-panel-header
                      style="background: rgb(225, 232, 238); border: dotted 1px var(--v-secondary-lighten1);"
                    >
                      <template
                        #actions
                      >
                        <v-icon
                          color="var(--v-primary-secondary)"
                          size="24"
                        >
                          {{ mdiChevronDown }}
                        </v-icon>
                      </template>
                      <span
                        class="text-h5"
                      >
                        <span
                          v-if="locale === 'de'"
                        >
                          Naturschutzfachliches Monitoring des Ausbaus der erneuerbaren Energien im Strombereich und Entwicklung von Instrumenten zur Verminderung der Beeinträchtigung von Natur und Landschaft (Kurztitel: EE-Monitor)
                        </span>
                        <span
                          v-else
                        >
                          Nature conservation monitoring of the expansion of renewable energies in the electricity sector and development of instruments to reduce the impact on nature and landscape (short title: EE-Monitor)
                        </span>
                      </span>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      style="border: dotted 1px var(--v-secondary-lighten1);"
                      class="pt-6"
                    >
                      <v-row
                        no-gutters
                        class="pb-1 flex-column"
                        justify="start"
                        align="stretch"
                      >
                        <v-col
                          class="text-body-1"
                        >
                          <div
                            class="mb-3"
                          >
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Beschreibung:
                              </span>
                              <span
                                v-else
                              >
                                Description:
                              </span>
                            </div>
                            <div>
                              <span
                                v-if="locale === 'de'"
                              >
                                Im Forschungsvorhaben wurde ein systematisches, mehrteiliges Monitoringkonzept entwickelt, das es erstmals ermöglicht, deutschlandweit bestimmte Auswirkungen des Ausbaus der erneuerbaren Energien zur Stromerzeugung (Windenergie, Photovoltaik, Bioenergie und Wasserkraft) sowie des zum Energietransport benötigten Stromnetzes auf Natur und Landschaft abzubilden. Das entwickelte Monitoringsystem könnte unter anderem sowohl die nationale Berichterstattung zum Ausbau der erneuerbaren Energien um die Effekte auf Natur und Landschaft erweitern als auch mittelfristig das Wirkungswissen schrittweise verbessern. Problematische Entwicklungen könnten damit frühzeitig erkannt und vermieden werden.
                              </span>
                              <span
                                v-else
                              >
                                In the research project, a systematic, multi-part monitoring concept was developed that makes it possible for the first time to map certain impacts of the expansion of renewable energies for electricity generation (wind energy, photovoltaics, bioenergy and hydropower) and the electricity grid required for energy transport on nature and landscape throughout Germany. The monitoring system developed could, among other things, both expand national reporting on the expansion of renewable energies to include the effects on nature and landscape and gradually improve knowledge of impacts in the medium term. Problematic developments could thus be recognised and avoided at an early stage.
                              </span>
                            </div>
                          </div>

                          <div
                            class="mb-3"
                          >
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Förderkennzeichen:
                              </span>
                              <span
                                v-else
                              >
                                Funding code:
                              </span>
                            </div>
                            <div>
                              <span>
                                (FKZ) 3515 82 2700
                              </span>
                            </div>
                          </div>

                          <div
                            class="mb-3"
                          >
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Laufzeit:
                              </span>
                              <span
                                v-else
                              >
                                Duration:
                              </span>
                            </div>
                            <div>
                              <span>
                                01.08.2015 - 31.12.2018
                              </span>
                            </div>
                          </div>

                          <div
                            class="mb-3"
                          >
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Projektergebnisse:
                              </span>
                              <span
                                v-else
                              >
                                Project results:
                              </span>
                            </div>
                            <div>
                              <span
                                v-if="locale === 'de'"
                              >
                                <ul>
                                  <li>
                                    Webbasiertes Geoinformationssystem (Web-GIS; Standortdaten und Anlagenparameter)
                                  </li>
                                </ul>
                              </span>
                              <span
                                v-else
                              >
                                <ul>
                                  <li>
                                    Web-based geo-information system (Web-GIS; site data and plant parameters)
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </div>

                          <div>
                            <div
                              class="font-weight-bold"
                            >
                              <span
                                v-if="locale === 'de'"
                              >
                                Publikationen:
                              </span>
                              <span
                                v-else
                              >
                                Publications:
                              </span>
                            </div>
                            <div>
                              <div
                                class="mb-2"
                              >
                                Thrän, Daniela; Bunzel, Katja; Bovet, Jana; Eichhorn, Marcus; Hennig, Christiane; Keuneke, Rita ; Kinast, Pascal; Klenke, Reinhard; Koblenz, Barbara; Lorenz, Christian; Majer, Stefan; Manske, David; Massmann, Edith; Oehmichen, Gunnar; Peters, Wolfgang; Reichmuth, Matthias (2020) Naturschutzfachliches Monitoring des Ausbaus der erneuerbaren Energien im Strombereich und Entwicklung von Instrumenten zur Verminderung der Beeinträchtigung von Natur und Landschaft („EE-Monitor“). BfN Skript 562. Bundesamt für Naturschutz (BfN), Bonn, 318 S. <a
                                  href="https://www.bfn.de/sites/default/files/BfN/service/Dokumente/skripten/skript562.pdf"
                                  target="_blank"
                                >Link (PDF)</a>.
                              </div>
                              <div>
                                Eichhorn, Marcus; Scheftelowitz, Mattes; Reichmuth, Matthias; Lorenz, Christian; Louca, Kyriakos; Schiffler, Alexander; Keuneke, Rita; Bauschmann,  Martin; Ponitka, Jens; Manske, David; Thrän, Daniela (2019): Spatial distribution of wind turbines, photovoltaic field systems, bioenergy, and river hydro power plants in Germany. Data 2019, 4, 29. <a
                                  href="https://doi.org/10.3390/data4010029"
                                  target="_blank"
                                >Link</a>.
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card>

          <!-- CONTACT CARD -->

          <v-card
            flat
            class="fill-height pa-6 mb-6"
            rounded="0"
          >
            <v-row
              no-gutters
            >
              <v-col
                class="text-h4 pb-2"
              >
                <span
                  v-if="locale==='de'"
                >
                  Team
                </span>
                <span
                  v-else
                >
                  Team
                </span>
              </v-col>
            </v-row>

            <v-row
              no-gutters
              class="flex-column"
            >
              <v-col
                cols="12"
              >
                <v-row>
                  <!-- THRAEN -->

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <div
                      class="text-h6"
                    >
                      Prof. Dr. Daniela Thrän
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Leiterin des Departments Bioenergie (BEN)
                      </span>
                      <span
                        v-else
                      >
                        Head of the Department of Bioenergy (BEN)
                      </span>
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Helmholtz-Zentrum für Umweltforschung GmbH - UFZ
                      </span>
                      <span
                        v-else
                      >
                        Helmholtz Centre for Environmental Research GmbH - UFZ
                      </span>
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <a
                        href="mailto:daniela.thraen@ufz.de"
                      >
                        daniela.thraen@ufz.de
                      </a>
                    </div>
                  </v-col>

                  <!-- MITTELSTAEDT -->

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <div
                      class="text-h6"
                    >
                      Nora Mittelstädt
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Wissenschaftliche Mitarbeiterin
                      </span>
                      <span
                        v-else
                      >
                        Research associate
                      </span>
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Helmholtz-Zentrum für Umweltforschung GmbH - UFZ
                      </span>
                      <span
                        v-else
                      >
                        Helmholtz Centre for Environmental Research GmbH - UFZ
                      </span>
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <a
                        href="mailto:nora.mittelstaedt@ufz.de"
                      >
                        nora.mittelstaedt@ufz.de
                      </a>
                    </div>
                  </v-col>

                  <!-- MANSKE -->

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <div
                      class="text-h6"
                    >
                      David Manske
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Wissenschaftlicher Mitarbeiter
                      </span>
                      <span
                        v-else
                      >
                        Research associate
                      </span>
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Helmholtz-Zentrum für Umweltforschung GmbH - UFZ
                      </span>
                      <span
                        v-else
                      >
                        Helmholtz Centre for Environmental Research GmbH - UFZ
                      </span>
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <a
                        href="mailto:david.manske@ufz.de"
                      >
                        david.manske@ufz.de
                      </a>
                    </div>
                  </v-col>

                  <!-- SCHINKEL -->

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <div
                      class="text-h6"
                    >
                      Björn Schinkel
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Wissenschaftlich-Technischer Mitarbeiter
                      </span>
                      <span
                        v-else
                      >
                        Scientific-technical employee
                      </span>
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Helmholtz-Zentrum für Umweltforschung GmbH - UFZ
                      </span>
                      <span
                        v-else
                      >
                        Helmholtz Centre for Environmental Research GmbH - UFZ
                      </span>
                    </div>
                    <div
                      class="text-body-1"
                    >
                      <a
                        href="mailto:bjoern.schinkel@ufz.de"
                      >
                        bjoern.schinkel@ufz.de
                      </a>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <!-- CITITATION CARD -->

          <v-card
            id="div-cititation"
            flat
            class="fill-height pa-6"
            rounded="0"
          >
            <v-row
              no-gutters
              class="pb-1 flex-column"
              justify="start"
              align="stretch"
            >
              <v-col
                class="text-h4 pb-2"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Zitationshinweis
                </span>
                <span
                  v-else
                >
                  Citation notes
                </span>
              </v-col>

              <v-col
                class="text-body-1"
              >
                <CitiationContent />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <EEMonitorPartnerFunding />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BreadcrumbNav from '@/components/main/navigation/BreadcrumbNav.vue'
import CitiationContent from '@/components/base/cititation/CitiationContent.vue'
import EEMonitorPartnerFunding from '@/components/base/info/PartnerFunding.vue'

import { mdiChevronDown } from '@mdi/js'

export default {
  name: 'EEMonitorAbout',

  components: {
    BreadcrumbNav,
    CitiationContent,
    EEMonitorPartnerFunding
  },
  data () {
    return {
      mdiChevronDown: mdiChevronDown,
      items: [
        {
          image: 'kennzahlenauswahl_screenshot.jpg',
          routeName: 'SelectMain',
          route_button_title_de: 'Zu den Kennzahlen',
          title_de: '"Kennzahlenübersicht"',
          subtitle_de: '"Die Kennzahlen zum naturschutzfachlichen Monitoring"',
          info_text_de: 'Die 41 Kennzahlen wurden im EE-Monitor-Vorhaben entwickelt und ausgewählt. Sie umfassen fünf Technologien, nach denen sie sortiert werden können: Windkraft, Solarenergie, Bioenergie, Wasserkraft, Freileitungen und allgemeine Energiedaten. Die Sortierung kann zusätzlich nach naturschutzfachlichen Zielsetzungen ("Zielfelder") vorgenommen werden.'
        },

        {
          image: 'regionalbericht_screenshot.jpg',
          routeName: 'ReportView',
          route_button_title_de: 'Zum Regionalbericht',
          title_de: 'Regionalbericht',
          subtitle_de: '"Die Kennzahlen zum regionenbezogenen naturschutzfachlichen Monitoring auf einen Blick"',
          info_text_de: 'Der Regionalbericht ermöglicht eine regionenspezifische Perspektive auf die 41 Kennzahlen. Die verfügbaren Werte können national, auf Bundesland- und Landkreisebene betrachtet und nach den jeweiligen Technologien ausgewählt werden. Es ist möglich, von den regionenspezifischen Werten direkt zur umfassenden und regionenübergreifenden Ansicht der Kennzahlwerte zu gelangen.'
        }
      ]
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  }
}
</script>

<style lang="scss">
p:last-child {
  margin-bottom: 0;
}

// .v-icon {
//     font-size: 34px !important;
//   }
// .text-expansion-panels {
//   .v-icon.v-icon {
//     font-size: 34px !important;
//   }
// }
</style>
