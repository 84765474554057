<template>
  <div
    v-resize="resizeViewDiv"
    class="fill-height"
    style="width: 100%;"
  >
    <v-card
      id="webgis_div"
      ref="webgis_div"
      style="width: 100%; height: 100%; background:none;"
      elevation="0"
      rounded="0"
    />
    <!-- :style="{'height': viewDivHeight + 'px'}" -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { eventBus } from '@/utils/eventbus.js'

// LODASH
import _ from 'lodash'

// Tweezer https://github.com/jaxgeller/tweezer.js/
import Tweezer from 'tweezer.js'

// ARCGIS
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils'
import Query from '@arcgis/core/rest/support/Query'
import FeatureFilter from '@arcgis/core/layers/support/FeatureFilter'

// import LabelClass from '@arcgis/core/layers/support/LabelClass'
// import Field from '@arcgis/core/layers/support/Field'

// WEBGIS DEFAULTS
import defaultValues from '@/webgis/default_values.js'

// WEBGIS METHODS
import {
  createLayer,
  getBaseLayer,
  getGeojsonPointData,
  getGeojsonPolygonData,
  initWebGis
} from '@/webgis/base_methods.js'

// COMPONENTS

export default {
  name: 'WebGisMap',

  data () {
    return {
      // baseLayerViews: [],
      // baseLayerViewsWatchHandler: [],
      // queryYearsRange: [],
      baseLayerItems: [],
      esriMap: null,
      esriMapView: null,
      esriMapViewCenterHandler: null,
      esriMapViewWatchHandler: null,
      esriMapViewZoomHandler: null,
      featuresHighlighted: [],
      featureIDsByQuery: [],
      geojsonLayer: [],
      geojsonLayerPolygon: [],
      geojsonLayerViews: [],
      // geojsonLayerViewsWatchHandler: [],
      highlightedFeatures: [],
      isLayerViewFiltered: false,
      isLayerViewFilteredCounter: 0,
      isMapViewLoaded: false,
      lastIDArrSorted: null,
      mapViewHandlerClick: [],
      mapViewHandlerPointerMove: [],
      mapViewZoom: 0,
      menuSelectUpdatedTimeout: undefined,
      polygonLayerRenderer: null,
      popupViewHandler: null,
      queriesJoined: [],
      queryCapacityInstalled: [],
      areaIDsByQuery: [],
      pointObjectIDsByQuery: [],
      queryRegion: [],
      querySelectValues: [],
      queryYearCommissioning: [],
      root: null,
      showPolygonLayer: false,
      viewDivHeight: 0
    }
  },

  computed: {
    ...mapState('main', [
      'baseURL', // TODO Rename baseURL to Django URL
      'locale'
    ]),

    ...mapState('webgis', [
      'basemapSelected',
      'isMainMenuOpen',
      'isPopupVisible',
      'itemDataQuery',
      'itemSelected',
      'items',
      'layerAttributesPointerMove',
      'layerStatisticValues',
      'popupData',
      'selectsOptionalQueryKeys',
      'showMapViewUpdatingIndicator',
      'viewDivDimensions'
      // 'itemYearRange',
      // 'itemYearRangeDefault'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems'
    ])

  },

  watch: {
    isMainMenuOpen: {
      handler () {
        if (this.$vuetify.breakpoint.mdAndUp) {
          this.setMapViewPadding()
        }
      },
      immediate: true
    },

    basemapSelected: {
      async handler  (newBasemap) {
        if (newBasemap !== null && newBasemap !== undefined) {
          if (this.root !== null) {
            this.root.style.setProperty('--esri-attribution-color', newBasemap.design === 'dark' ? '#ffffff' : '#003E6E')
          }

          // if (!this.showPolygonLayer) {
          if (this.baseLayerItems.length !== 0) {
            this.baseLayerItems.forEach((layer) => {
              layer.renderer = defaultValues.renderer[newBasemap.design].baseLayer
            })
          }
          // }

          // await this.setRenderer({ basemap: newBasemap })
          // if (this.geojsonLayer.length !== 0) {
          //   this.geojsonLayer[0].featureReduction = defaultValues.clusterConfig[newBasemap.design]

          this.geojsonLayer.forEach((layer) => {
            // NOTE: store only data of geojson with geometry point
            if (layer.featureReduction !== null) {
              layer.renderer = defaultValues.renderer[newBasemap.design].point_1
            } else {
              layer.renderer = defaultValues.renderer[newBasemap.design].point_2
            }
            layer.featureReduction = defaultValues.clusterConfig[newBasemap.design]
          })

          if (this.geojsonLayerPolygon.length !== 0) {
            this.geojsonLayerPolygon.forEach((layer) => {
              if (newBasemap.value === 'satellite') {
                layer.renderer = defaultValues.renderer[newBasemap.design].polygon_1
              } else {
                layer.renderer = defaultValues.renderer[newBasemap.design].polygon_2
              }
            })
          }
          // }
        }
      },
      immediate: true
    }

    // showPolygonLayer: {
    //   handler (bool) {
    //     if (bool) {
    //       this.geojsonLayer.forEach(layer => { layer.visible = false })

    //       const baselayerSelected = this.baseLayerItems.filter(item => item.title.includes(`ade-${this.itemDataQuery.spatial_type}`))[0]

    //       if (this.polygonLayerRenderer !== null) {
    //         baselayerSelected.renderer = this.polygonLayerRenderer
    //       }

    //       if (this.baseLayerViewsWatchHandler.length === 0) {
    //         this.baseLayerViews.forEach((layerView) => {
    //         })
    //       }
    //     } else {
    //       if (this.baseLayerItems.length !== 0) {
    //         this.baseLayerItems.forEach((layer) => {
    //           layer.renderer = defaultValues.renderer[this.basemapSelected.design].baseLayer
    //         })
    //       }

    //       if (this.baseLayerViewsWatchHandler.length !== 0) {
    //         this.baseLayerViewsWatchHandler.forEach((layerView) => {
    //           layerView.remove()
    //         })
    //       }
    //       this.baseLayerViewsWatchHandler = []
    //     }
    //   },
    //   immediate: true
    // }

    // queriesJoined: {
    //   handler (newQuery, oldQuery) {
    //     if (newQuery.length !== 0 && oldQuery !== 0) {
    //       console.log('newQuery, oldQuery', newQuery, oldQuery)
    //       console.log('newQuery[0] === oldQuery[0] ', newQuery[0] === oldQuery[0])
    //       if (newQuery[0] === oldQuery[0]) {
    //         this.isLayerViewFilteredCounter = 0
    //         this.show_mapview_updating_indicator(false)
    //       }
    //     }
    //   },
    //   immediate: true
    // }

    // '$refs.webgis_div': {
    //   handler (ref) {
    //     if (ref !== undefined) {
    //       console.log('ref webgis_div ', ref)
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },

  async mounted () {
    this.root = document.documentElement

    //
    // >>> EVENTBUS
    //

    eventBus.$on('webgis-reset-highlight', () => {
      this.lastIDArrSorted = null
      this.mapViewHighlightRemove()
    })

    eventBus.$on('webgis-zoom-in', () => {
      this.esriMapView.zoom = this.esriMapView.zoom + 1
    })

    eventBus.$on('webgis-zoom-out', () => {
      this.esriMapView.zoom = this.esriMapView.zoom - 1
    })

    eventBus.$on('set-map-basemap', (basemapObj) => {
      this.setBasemap(basemapObj)
    })

    eventBus.$on('webgis-item-selected', async (itemId) => {
      this.set_layer_statistic_values(null)
      await this.loadItem(itemId)
    })

    eventBus.$on('webgis-reset-zoom', async () => {
      await this.zoomToRegion({ ags: '00000000' })
    })

    eventBus.$on('webgis-menu-select-update', async (queryState) => {
      this.set_layer_statistic_values(null)
      await this.updateSelects(queryState)
    })

    eventBus.$on('webgis-menu-slider-capacity-update', async (queryState) => {
      this.set_layer_statistic_values(null)
      await this.updateCapacity(queryState)
    })

    eventBus.$on('webgis-menu-slider-year-commissioning-update', async (queryState) => {
      this.set_layer_statistic_values(null)
      await this.updateYear(queryState)
    })

    eventBus.$on('webgis-menu-region-update', async (obj) => {
      this.set_layer_statistic_values(null)
      await this.updateRegion(obj)
    })

    eventBus.$on('webgis-highlight-features', async (graphic) => {
      await this.mapViewHighlightFeaturesPopUp(graphic)
    })

    // eventBus.$on('webgis-menu-slider-range-year-update', (queryState) => {
    //   this.menuRangeYearUpdated(queryState)
    // })

    //
    // >>> INIT Web-GIS
    //

    await this.loadMap()

    this.esriMapViewWatchHandler = this.watchMapViewUpdating(this.esriMapView)
    this.esriMapViewCenterHandler = this.watchMapViewCenter(this.esriMapView)
    this.esriMapViewZoomHandler = this.watchMapViewZoom(this.esriMapView)

    this.resizeViewDiv()

    await this.loadBaseLayer()

    // console.log('esriMap ', this.esriMap)
    // console.log('esriMapView ', this.esriMapView)
  },

  async beforeDestroy () {
    // console.log('beforeDestroy')

    eventBus.$off('webgis-reset-highlight')
    eventBus.$off('webgis-zoom-in')
    eventBus.$off('webgis-zoom-out')
    eventBus.$off('webgis-reset-zoom')
    eventBus.$off('set-map-basemap')
    eventBus.$off('webgis-item-selected')
    eventBus.$off('webgis-menu-select-update')
    eventBus.$off('webgis-menu-slider-capacity-update')
    eventBus.$off('webgis-menu-slider-year-commissioning-update')
    eventBus.$off('webgis-menu-region-update')
    eventBus.$off('webgis-highlight-features')
    // eventBus.$off('webgis-menu-slider-range-year-update')

    if (this.mapViewHandlerClick.length !== 0) {
      this.removeHandler(this.mapViewHandlerClick)
    }

    if (this.mapViewHandlerPointerMove.length !== 0) {
      this.removeHandler(this.mapViewHandlerPointerMove)
    }

    if (this.popupViewHandler !== null) {
      this.popupViewHandler.remove()
      this.popupViewHandle = null
    }

    if (this.esriMapViewZoomHandler !== null) {
      this.esriMapViewZoomHandler.remove()
      this.esriMapViewZoomHandler = null
    }
    if (this.esriMapViewWatchHandler !== null) {
      this.esriMapViewWatchHandler.remove()
      this.esriMapViewWatchHandler = null
    }
    if (this.esriMapViewCenterHandler !== null) {
      this.esriMapViewCenterHandler.remove()
      this.esriMapViewCenterHandler = null
    }

    // this.removeHandler(this.geojsonLayerViewsWatchHandler)

    if (this.geojsonLayer.length !== 0) {
      this.esriMap.removeMany(this.geojsonLayer)

      this.geojsonLayer.forEach((layer) => {
        layer.destroy()
        layer = null
      })
      this.geojsonLayer = []

      if (this.geojsonLayerPolygon.length !== 0) {
        this.geojsonLayerPolygon.forEach((layer) => {
          layer.destroy()
          layer = null
        })
        this.geojsonLayerPolygon = []
      }
    }

    if (this.baseLayerItems.length !== 0) {
      this.esriMap.removeMany(this.baseLayerItems)

      this.baseLayerItems.forEach((layer) => {
        layer.destroy()
        layer = null
      })
      this.baseLayerItems = []
    }

    if (this.esriMapView !== null) {
      this.esriMapView.destroy()
      this.esriMapView = null
    }

    if (this.esriMap !== null) {
      this.esriMap.destroy()
      this.esriMap = null
    }

    this.geojsonLayerViews = null
    this.geojsonLayerViews = []
    this.geojsonLayer = null
    this.geojsonLayer = []
  },

  methods: {
    ...mapActions('webgis', [
      'set_item_year_range_min_max',
      'set_layer_statistic_values',
      'set_layer_values_min_max',
      'set_mapview_center',
      'set_mapview_screenpointer',
      'set_menu_disabled',
      'set_attributes_pointer_move',
      'set_popup_data',
      'set_popup_visibility',
      'set_viewdiv_dimensions',
      'show_mapview_updating_indicator',
      'update_data_query'
    ]),

    resetLayerQueries () {
      this.queryCapacityInstalled = []
      this.querySelectValues = []
      this.queryYearCommissioning = []
      this.queryRegion = []
    },

    setBasemap (basemapObj) {
      if (this.esriMap !== null) {
        this.esriMap.basemap = basemapObj.value
        setTimeout(() => {
          if (this.esriMap.basemap.referenceLayers.items.length !== 0) {
            this.esriMap.basemap.referenceLayers.items[0].visible = false
          }
        }, 100)
      }
    },

    // NOTE: not used atm // async menuRangeYearUpdated (queryState) {
    //   const queryValues = queryState.year_range

    //   this.queryYearsRange = `year_commissioning >= ${queryValues[0]}` // AND year_decommissioning <= ${queryValues[1]}
    //   console.log('queryYearsRange ', this.queryYearsRange)

    //   this.geojsonLayer.forEach((layer) => {
    //     layer.definitionExpression = [...this.querySelectValues, ...this.queryCapacityInstalled, this.queryYearsRange].join(' AND ')

    //     console.log('layer.definitionExpression ', layer.definitionExpression)
    //   })
    // },

    async updateCapacity (queryState) {
      // console.log('updateCapacity()')

      this.queryCapacityInstalled = []

      if (queryState !== undefined) {
        this.queryCapacityInstalled = [`capacity_installed >= ${queryState.capacity_installed[0]} AND capacity_installed <= ${queryState.capacity_installed[1]}`]

        if (!this.showMapViewUpdatingIndicator) {
          this.show_mapview_updating_indicator(true)
        }

        this.set_popup_visibility(false)
        this.set_popup_data([])

        await this.setAreaIDsByQuery()

        // console.time('layerupdating')
        await this.setLayerViewFeatureFilter()

        this.queryLayerStatistics()

        // await this.updateBaseLayerValues() // NOTE: Not used atm
      }
    },

    async updateYear (queryState) {
      // console.log('updateYear()')

      if (queryState !== undefined) {
        // this.queryYearCommissioning = [`year_commissioning ${queryState.time_format_operand} ${queryState.year_commissioning}`]
        this.queryYearCommissioning = [`year_commissioning ${queryState.time_format_operand} ${queryState.year_commissioning} AND CASE WHEN year_decommissioning IS NULL THEN year_decommissioning IS NULL ELSE year_decommissioning >= ${queryState.year_commissioning} END`]

        if (!this.showMapViewUpdatingIndicator) {
          this.show_mapview_updating_indicator(true)
        }

        this.set_popup_visibility(false)
        this.set_popup_data([])

        await this.setAreaIDsByQuery()

        // console.time('layerupdating')
        await this.setLayerViewFeatureFilter()

        this.queryLayerStatistics()

        // await this.updateBaseLayerValues() // NOTE: Not used atm
      }
    },

    async updateSelects ({ queryState, event }) {
      // console.log('updateSelects()')

      this.querySelectValues = []

      if (queryState !== undefined) {
        if (this.menuSelectUpdatedTimeout !== undefined) {
          clearTimeout(this.menuSelectUpdatedTimeout)
          this.menuSelectUpdatedTimeout = undefined
        }

        const queryFiltered = Object.fromEntries(Object.entries(queryState).filter(([key, value]) => this.selectsOptionalQueryKeys.includes(key) && !Array.isArray(value)))

        for (const [key, value] of Object.entries(queryFiltered)) {
          if (value !== null) {
            if (!Array.isArray(value)) {
              const queryPart = `${key} = ${value}`
              this.querySelectValues.push(queryPart)
            }
          }
        }

        this.menuSelectUpdatedTimeout = setTimeout(async () => {
          clearTimeout(this.menuSelectUpdatedTimeout)

          if (this.geojsonLayer.length !== 0) {
            await this.setLayerQueryMinMax()

            this.queryYearCommissioning = [`year_commissioning ${queryState.time_format_operand} ${queryState.year_commissioning} AND CASE WHEN year_decommissioning IS NULL THEN year_decommissioning IS NULL ELSE year_decommissioning >= ${queryState.year_commissioning} END`]

            this.queryCapacityInstalled = [`capacity_installed >= ${queryState.capacity_installed[0]} AND capacity_installed <= ${queryState.capacity_installed[1]}`]

            if (!this.showMapViewUpdatingIndicator) {
              this.show_mapview_updating_indicator(true)
            }

            this.set_popup_visibility(false)
            this.set_popup_data([])

            await this.setAreaIDsByQuery()

            // console.time('layerupdating')
            await this.setLayerViewFeatureFilter()

            this.queryLayerStatistics()

            // await this.updateBaseLayerValues() // NOTE: Not used atm
          }
        }, 100)
      }
    },

    async updateRegion ({ queryState }) {
      // console.log('updateRegion()')

      if (queryState !== undefined) {
        const regionAgs = queryState.spatial_unit

        if (regionAgs !== '00000000') {
          // NOTE: plant data field for AGS is "bkg_ags"
          this.queryRegion = [`bkg_ags LIKE '${regionAgs}%'`]
        } else {
          // NOTE: if region "BRD/Germany" is selected (bkg_ags = '00000000') all features will be returned --> important for offshore windenergy & hydropower plants who are outside of german borders
          this.queryRegion = []
        }

        await this.setLayerQueryMinMax()

        if (!this.showMapViewUpdatingIndicator) {
          this.show_mapview_updating_indicator(true)
        }

        this.set_popup_visibility(false)
        this.set_popup_data([])

        await this.setAreaIDsByQuery()

        // console.time('layerupdating')
        await this.setLayerViewFeatureFilter()

        this.queryLayerStatistics()

        // this.isLayerViewFilteredCounter = 0 // REVIEW if this should be commented out, if yes remove

        this.show_mapview_updating_indicator(false)

        this.zoomToRegion({ ags: regionAgs })

        // if (queryState.spatial_type !== 1 && regionAgs === '00000000') {
        //   this.geojsonLayer.forEach(layer => { layer.visible = false })
        //   await this.updateBaseLayerValues() // NOTE: Not used atm
        // } else {
        //   this.showPolygonLayer = false
        // }
      }
    },

    // async updateBaseLayerValues () {
    //   if (this.itemDataQuery.spatial_type !== 1 && this.showPolygonLayer === true) {
    //     const baseLayerSelected = this.baseLayerItems.filter(layer => layer.title.includes(`ade-${this.itemDataQuery.spatial_type}`))[0]

    //     baseLayerSelected.visible = false

    //     const statisticDefinition = [{
    //       onStatisticField: 'capacity_installed',
    //       outStatisticFieldName: 'capacity_installed_sum',
    //       statisticType: 'sum'
    //     }, {
    //       onStatisticField: 'id',
    //       outStatisticFieldName: 'id_count',
    //       statisticType: 'count'
    //     }]

    //     const query = this.geojsonLayer[0].createQuery()

    //     query.where = [
    //       ...this.queryRegion,
    //       ...this.querySelectValues,
    //       ...this.queryCapacityInstalled,
    //       ...this.queryYearCommissioning,
    //       'bkg_ags IS NOT NULL'
    //     ].join(' AND ')

    //     query.outStatistics = statisticDefinition

    //     query.groupByFieldsForStatistics = [`bkg_ags_ade_${this.itemDataQuery.spatial_type}`]

    //     const result = await this.geojsonLayer[0].queryFeatures(query)

    //     // Get the max value of the plant count for the highest stop on the renderer visualVariables
    //     const resultValueMax = Math.max(...result.features.map(feature => feature.attributes.id_count))

    //     this.polygonLayerRenderer = {
    //       type: 'simple',
    //       symbol: { type: 'simple-fill' },
    //       visualVariables: [{
    //         type: 'color',
    //         field: 'plant_count',
    //         stops: [{ value: 0, color: '#ffffff' },
    //           { value: resultValueMax, color: '#003E6E' }]
    //       }]
    //     }

    //     const objectIds = await baseLayerSelected.queryObjectIds()

    //     const queryFeatureResults = await baseLayerSelected.queryFeatures({
    //       objectIds,
    //       outFields: ['*'],
    //       returnGeometry: true
    //     })

    //     // console.log('queryFeatureResults ', queryFeatureResults)

    //     const featuresEdited = queryFeatureResults.features.map((feature) => {
    //       const filteredResult = result.features.filter(feat => feat.attributes[`bkg_ags_ade_${this.itemDataQuery.spatial_type}`] === feature.attributes.AGS)

    //       feature.attributes.plant_count = 0
    //       feature.attributes.capacity_installed = 0

    //       if (filteredResult.length !== 0) {
    //         feature.attributes.plant_count = filteredResult[0].attributes.id_count
    //         feature.attributes.capacity_installed = filteredResult[0].attributes.capacity_installed_sum
    //         return feature
    //       } else {
    //         feature.attributes.plant_count = 0
    //         feature.attributes.capacity_installed = 0
    //         return feature
    //       }
    //     })

    //     // console.log('featuresEdited ', featuresEdited)

    //     await baseLayerSelected.applyEdits({
    //       updateFeatures: featuresEdited
    //     })

    //     baseLayerSelected.renderer = this.polygonLayerRenderer

    //     baseLayerSelected.visible = true

    //     const statesLabelClass = new LabelClass({
    //       labelExpressionInfo: { expression: '$feature.plant_count' },
    //       symbol: {
    //         type: 'text',
    //         color: '#003E6E',
    //         haloSize: 5,
    //         haloColor: 'white'
    //       }
    //     })
    //     baseLayerSelected.labelingInfo = [statesLabelClass]

    //     //  // First create a point geometry (this is the location of the Titanic)
    //     // const point = {
    //     //   type: "point", // autocasts as new Point()
    //     //   longitude: -49.97,
    //     //   latitude: 41.73
    //     // };

    //     // // Create a symbol for drawing the point
    //     // const markerSymbol = {
    //     //   type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    //     //   color: [226, 119, 40],
    //     //   outline: {
    //     //     // autocasts as new SimpleLineSymbol()
    //     //     color: [255, 255, 255],
    //     //     width: 2
    //     //   }
    //     // };

    //     // // Create a graphic and add the geometry and symbol to it
    //     // const pointGraphic = new Graphic({
    //     //   geometry: point,
    //     //   symbol: markerSymbol
    //     // });
    //   } else {
    //     this.showPolygonLayer = false
    //   }
    // },

    async setLayerQueryMinMax () {
      if (this.geojsonLayer.length !== 0) {
        const statisticDefinition = [{
          onStatisticField: 'capacity_installed',
          outStatisticFieldName: 'capacity_installed_min',
          statisticType: 'min'
        }, {
          onStatisticField: 'capacity_installed',
          outStatisticFieldName: 'capacity_installed_max',
          statisticType: 'max'
        }, {
          onStatisticField: 'year_commissioning',
          outStatisticFieldName: 'year_commissioning_min',
          statisticType: 'min'
        }, {
          onStatisticField: 'year_commissioning',
          outStatisticFieldName: 'year_commissioning_max',
          statisticType: 'max'
        }, {
          onStatisticField: 'year_decommissioning',
          outStatisticFieldName: 'year_decommissioning_min',
          statisticType: 'min'
        }, {
          onStatisticField: 'year_decommissioning',
          outStatisticFieldName: 'year_decommissioning_max',
          statisticType: 'max'
        }, {
          onStatisticField: 'capacity_installed',
          outStatisticFieldName: 'capacity_installed_sum',
          statisticType: 'sum'
        }, {
          onStatisticField: 'id',
          outStatisticFieldName: 'id_count',
          statisticType: 'count'
        }]

        const query = this.geojsonLayer[0].createQuery()
        query.where = [
          ...this.queryRegion,
          ...this.querySelectValues
        ].join(' AND ')
        query.outStatistics = statisticDefinition
        if (this.geojsonLayerPolygon.length !== 0) {
          query.groupByFieldsForStatistics = ['area_id']
        }

        // Query on first geojsonLayer (the 2nd one would be the polygon layer of solar energy)
        const geojsonLayerGeometryPoint = this.geojsonLayer.filter(layer => layer.geometryType === 'point')
        const result = await geojsonLayerGeometryPoint[0].queryFeatures(query)

        let capacityInstalledMin
        let capacityInstalledMax
        let yearCommissioningMin
        let yearCommissioningMax
        let yearDecommissioningMax

        let layerValuesMinMax

        if (result.features.length !== 0) {
          const attributes = result.features[0].attributes

          if (this.geojsonLayerPolygon.length !== 0) {
            const idCountSum = result.features.map(feature => feature.attributes.id_count).reduce((a, b) => a + b, 0)

            capacityInstalledMin = Math.min(...result.features.map(feature => feature.attributes.capacity_installed_min))
            capacityInstalledMax = Math.max(...result.features.map(feature => feature.attributes.capacity_installed_sum)) // Must be the sum of all area_id's
            yearCommissioningMin = Math.min(...result.features.map(feature => feature.attributes.year_commissioning_min))
            yearCommissioningMax = Math.max(...result.features.map(feature => feature.attributes.year_commissioning_max))
            yearDecommissioningMax = Math.max(...result.features.map(feature => feature.attributes.year_decommissioning_max))

            layerValuesMinMax = {
              id_count: idCountSum,
              capacity_installed_min: capacityInstalledMin,
              capacity_installed_max: capacityInstalledMax,
              year_commissioning_min: yearCommissioningMin,
              year_commissioning_max: yearCommissioningMax
            }
          } else {
            capacityInstalledMin = attributes.capacity_installed_min
            capacityInstalledMax = attributes.capacity_installed_max
            yearCommissioningMin = attributes.year_commissioning_min
            yearCommissioningMax = attributes.year_commissioning_max

            layerValuesMinMax = {
              id_count: attributes.id_count,
              capacity_installed_min: capacityInstalledMin,
              capacity_installed_max: capacityInstalledMax,
              year_commissioning_min: yearCommissioningMin,
              year_commissioning_max: yearCommissioningMax
            }
          }

          await this.set_layer_values_min_max(layerValuesMinMax)
          // await this.set_layer_values_min_max(attributes)
          // console.log('attributes', attributes)

          await this.update_data_query({
            query: {
              capacity_installed: [
                capacityInstalledMin,
                capacityInstalledMax
              ],
              year_commissioning: yearCommissioningMax,
              year_decommissioning: yearDecommissioningMax
            }
          })

          // REVIEW
          if (capacityInstalledMin !== null &&
          capacityInstalledMax !== null) {
            this.queryCapacityInstalled = [
              `capacity_installed >= ${capacityInstalledMin} AND capacity_installed <= ${capacityInstalledMax}`
            ]
          }

          // TODO set query for time range
          // operand has to be received by store maybe??
          // this.queryYearCommissioning = [`year_commissioning ${operand} ${queryState.year_commissioning}`]
        } else {
          this.queryCapacityInstalled = []

          this.update_data_query({
            query: {
              capacity_installed: [null, null],
              year_commissioning: null,
              year_decommissioning: null
            }
          })
        }
      }
    },

    async zoomToRegion ({ ags }) {
      this.set_menu_disabled(true)

      const regionSelected = this.bkgItems.filter(region => region.ags === ags)[0]

      const baseLayerSelected = this.baseLayerItems.filter(layer => layer.title.includes(`ade-${regionSelected.ade}`))[0]

      const queryRegion = new Query()
      // NOTE: baseLayer field for AGS is "AGS"
      queryRegion.where = `AGS = '${ags}'`
      queryRegion.returnGeometry = true

      const results = await baseLayerSelected.queryExtent(queryRegion)
      await this.esriMapView.goTo(results.extent, {
        duration: 1200,
        easing: 'ease-in-out'
      })

      this.set_menu_disabled(false)

      await this.baseLayerItems.forEach((layer) => {
        if (layer.title.includes(`ade-${this.itemDataQuery.spatial_type}`)) {
          // TODO layerView Visible
          layer.visible = true
        } else {
          layer.visible = false
        }
      })
    },

    setLayerViewFeatureFilter () {
      // console.log('setLayerViewFeatureFilter()')

      return new Promise((resolve, reject) => {
        this.isLayerViewFiltered = true

        this.queriesJoined = [
          ...this.queryCapacityInstalled,
          ...this.queryRegion,
          ...this.querySelectValues,
          ...this.queryYearCommissioning
        ].join(' AND ')

        if (this.geojsonLayerPolygon.length !== 0) {
        // Update point layer(s)
          this.geojsonLayer.forEach((layer) => {
            this.esriMapView.whenLayerView(layer).then((layerView) => {
              layerView.filter = new FeatureFilter({
                // where: String(`id IN (${this.featureIDsByQuery.join(', ')})`)
                objectIds: this.pointObjectIDsByQuery
              })
            })
          })

          // Update polygon layer(s)
          this.geojsonLayerPolygon.forEach((layer) => {
            this.esriMapView.whenLayerView(layer).then((layerView) => {
              layerView.filter = new FeatureFilter({
                where: String(`area_id IN (${this.areaIDsByQuery.join(', ')})`)
              })
            })
          })
        } else {
        // Update point layer(s)
          this.geojsonLayer.forEach((layer) => {
            this.esriMapView.whenLayerView(layer).then((layerView) => {
              layerView.filter = new FeatureFilter({
                where: this.queriesJoined
              })
            })
          })
        }

        resolve()
      })
    },

    async setAreaIDsByQuery () {
      // console.time('setAreaIDsByQuery')
      if (this.geojsonLayerPolygon.length !== 0) {
        // Query statistic for capacity installed grouped by area_id
        const queryGroupCI = this.geojsonLayer[0].createQuery()

        queryGroupCI.where = [
          ...this.queryRegion,
          ...this.querySelectValues,
          ...this.queryYearCommissioning
        ].join(' AND ')

        queryGroupCI.outStatistics = [{
          onStatisticField: 'capacity_installed',
          outStatisticFieldName: 'capacity_installed_sum',
          statisticType: 'sum'
        }]

        queryGroupCI.groupByFieldsForStatistics = ['area_id']

        // Filter point features grouped by their area_id's based on filter values of installed capacity
        // queryGroupCI.having = String(`SUM(capacity_installed) >= ${this.itemDataQuery.capacity_installed[0]} AND SUM(capacity_installed) <= ${this.itemDataQuery.capacity_installed[1]}`)
        queryGroupCI.having = String(`SUM(capacity_installed) >= ${this.itemDataQuery.capacity_installed[0]} AND SUM(capacity_installed) <= ${this.itemDataQuery.capacity_installed[1]}`)

        // Retrieve all area_id's of the point features where the sum of installed capacity matches the query
        const { features } = await this.geojsonLayer[0].queryFeatures(queryGroupCI)

        this.areaIDsByQuery = features.map(feature => feature.attributes.area_id)

        // Query for ObjectIDs from point attributes who match the queried area_id's
        const queryObjectIDs = this.geojsonLayer[0].createQuery()

        queryObjectIDs.where = [
          String(`area_id IN (${this.areaIDsByQuery.join(', ')})`),
          ...this.queryYearCommissioning
        ].join(' AND ')

        const objectIDArr = await this.geojsonLayer[0].queryObjectIds(queryObjectIDs)

        // With the case that no ObjectID was retrieved an empty array would be returned and set on the FeatureFilter. That would lead to "no filter" meaning all features would be visible in the MapView. To avoid that a "non existing" ObjectID (99999999999999) will be returned instead, that does not lead to an visible feature but also not the problem mentioned before
        if (objectIDArr.length !== 0) {
          this.pointObjectIDsByQuery = objectIDArr
        } else {
          this.pointObjectIDsByQuery = [99999999999999]
        }

        // this.featureIDsByQuery = response.features.map(feature => feature.attributes.id)

        // Not needed atm because Query.having was set. Needs performance REVIEW
        // // Filter area_id's based on filter values of installed capacity
        // const areaIDsFiltered = attributes.filter((attr) => {
        //   return attr.capacity_installed_sum >= this.itemDataQuery.capacity_installed[0] &&
        //   attr.capacity_installed_sum <= this.itemDataQuery.capacity_installed[1]
        // }).map(attrFiltered => attrFiltered.area_id)

        // NOTE: Experimental for testing
        // const results = await this.geojsonLayer[0].queryFeatures()
        // console.log('results', results)
        // const attributesTest = results.features.map((feature) => {
        //   return feature.attributes
        //   // const obj = {
        //   //   area_id: feature.attributes.area_id,
        //   //   capacity_installed: feature.attributes.capacity_installed
        //   // }
        //   // return obj
        // })
        // // console.log('test attributesTest', attributesTest)
        // const attributesTestGrouped = _.groupBy(attributesTest, 'area_id')
        // // console.log('test attributesTestGrouped', attributesTestGrouped)
        // const testArr = []
        // for (const [key, value] of Object.entries(attributesTestGrouped)) {
        //   const newObj = value
        //   newObj.capacity_installed = _.sumBy(value, 'capacity_installed')
        //   // const newObj = {
        //   //   area_id: key,
        //   //   capacity_installed: _.sumBy(value, 'capacity_installed')
        //   // }
        //   testArr.push(newObj)
        // }
        // console.log('test testArr', testArr)
      } else {
        this.areaIDsByQuery = []
      }
      // console.timeEnd('setAreaIDsByQuery')
    },

    queryLayerStatistics () {
      let queryString

      const queryLayerStatisticsDefinition = [{
        onStatisticField: 'capacity_installed',
        outStatisticFieldName: 'capacity_installed_sum',
        statisticType: 'sum'
      }, {
        onStatisticField: 'id',
        outStatisticFieldName: 'id_count',
        statisticType: 'count'
      }, {
        onStatisticField: 'year_commissioning',
        outStatisticFieldName: 'year_commissioning_min',
        statisticType: 'min'
      }, {
        onStatisticField: 'year_commissioning',
        outStatisticFieldName: 'year_commissioning_max',
        statisticType: 'max'
      }]

      const query = this.geojsonLayer[0].createQuery()

      const queryDefinitions = [
        ...this.queryCapacityInstalled,
        ...this.queryRegion,
        ...this.querySelectValues,
        ...this.queryYearCommissioning
      ].join(' AND ')

      const queryDefinitionAreaID = [
        String(`area_id IN (${this.areaIDsByQuery.join(', ')})`),
        ...this.queryYearCommissioning
      ].join(' AND ')

      if (this.geojsonLayerPolygon.length !== 0) {
        queryString = String(`(${queryDefinitions}) OR (${queryDefinitionAreaID})`)
      } else {
        queryString = queryDefinitions
      }

      query.where = String(`${queryString}`)
      query.outStatistics = queryLayerStatisticsDefinition

      // const geojsonLayerGeometryPoint = this.geojsonLayer.filter(layer => layer.geometryType === 'point')
      this.geojsonLayer[0].queryFeatures(query).then((response) => {
        const attributes = response.features[0].attributes
        // console.log('attributes', attributes)

        // NOTE: If the newly setted DefinitionExpression does not change the layerView / the shown features than the updating property of the LayerView will not change. As a fallback the loading indicator will be stopped by comparing the state of the new and old layerStatisticValues.
        if (this.layerStatisticValues !== null) {
          if (attributes.capacity_installed_sum !== this.layerStatisticValues.capacity_installed_sum &&
        attributes.id_count !== this.layerStatisticValues.id_count) {
            this.set_layer_statistic_values(attributes)
          } else {
            setTimeout(() => {
              if (this.showMapViewUpdatingIndicator) {
                this.show_mapview_updating_indicator(false)
                this.set_menu_disabled(false)
              }
            }, 750)
          }
        } else {
          this.set_layer_statistic_values(attributes)
        }
      })
    },

    watchMapViewZoom (view) {
      return reactiveUtils.watch(
        () => view.zoom,
        async () => {
          // console.log('view.zoom ', view.zoom)

          this.set_attributes_pointer_move(null)
          this.lastIDArrSorted = null

          if (view.zoom < this.mapViewZoom) {
            // Close PopUp if zooming out
            this.set_popup_visibility(false)
            this.set_popup_data([])
          }

          if (!this.isPopupVisible) {
            // If PopUp is not visible unset highlighted features in MapView
            this.mapViewHighlightRemove()
          }

          this.mapViewZoom = view.zoom

          // if (this.itemDataQuery.spatial_type !== 1 ||
          // this.itemDataQuery.spatial_type !== undefined) {
          //   if (view.zoom <= 6) {
          //     this.geojsonLayer.forEach(layer => { layer.visible = false })
          //     this.showPolygonLayer = true
          //   } else {
          //     this.showPolygonLayer = false
          //     this.geojsonLayer.forEach(layer => { layer.visible = true })
          //   }
          // } else {
          //   this.showPolygonLayer = false
          // }

          await this.setRenderer({ basemap: this.basemapSelected })

          // }
        },
        {
          initial: true
        })
    },

    watchMapViewCenter (view) {
      return reactiveUtils.watch(
        () => view.center,
        async (center) => {
          // console.log('watchMapViewCenter ', center)
          if (center !== null) {
            this.set_mapview_center({
              latitude: center.latitude,
              longitude: center.longitude,
              wkid: center.spatialReference.wkid,
              x: center.x,
              y: center.y
            })
          }
        },
        {
          initial: true
        })
    },

    watchMapViewUpdating (mapView) {
      let timeout

      return reactiveUtils.watch(
        () => mapView.updating,
        async () => {
          // console.log('mapView updating', mapView)

          if (this.isLayerViewFiltered) {
            this.isLayerViewFilteredCounter = this.isLayerViewFilteredCounter + 1

            if (this.isLayerViewFilteredCounter >= 4) {
              this.isLayerViewFilteredCounter = 0
              this.isLayerViewFiltered = false

              this.show_mapview_updating_indicator(false)
              this.set_menu_disabled(false)

              // console.timeEnd('layerupdating')

              if (timeout) {
                clearTimeout(timeout)
                timeout = null
              }
            } else {
              // Timeout set for safety reasons if the updating event was not fired in the needed count (edge case)

              if (!timeout) {
                timeout = setTimeout(() => {
                  // console.log('watchMapViewUpdating clearTimeout fallback')

                  this.isLayerViewFilteredCounter = 0
                  this.isLayerViewFiltered = false

                  this.show_mapview_updating_indicator(false)
                  this.set_menu_disabled(false)
                }, 5000)
              }
            }
          }
        },
        {
          initial: true
        })
    },

    // setRendererPointSize ({ basmapID, zoomLevel }) {
    //   // 'satellite', id: 1 (dark) && 'topo-vector', id: 3 (light)
    //   // 'dark-gray-vector', id: 4 (dark) && 'streets-vector', id: 2 (light)

    //   if (basmapID === 1 || basmapID === 3) {
    //     if (zoomLevel <= 11) {
    //       // cluster
    //       return 7
    //     } else if (zoomLevel === 12) {
    //       return 7
    //     } else if (zoomLevel === 13) {
    //       return 10
    //     } else if (zoomLevel >= 14) {
    //       return 14
    //     }
    //   } else if (basmapID === 2 || basmapID === 4) {
    //     if (zoomLevel <= 10) {
    //       // cluster
    //       return 7
    //     } else if (zoomLevel === 11) {
    //       return 7
    //     } else if (zoomLevel === 12) {
    //       return 10
    //     } else if (zoomLevel >= 13) {
    //       return 14
    //     }
    //   }
    // },

    async setRenderer ({ basemap }) {
      // NOTE: Zoom levels in the mapView differ between the basemap items and lead to different appearances of clustering and zoom levels where the point/polygon layers switch. As workaround the zoom level threshold for setting the point clustering and polygon appearance has to be set based on the selected basemap id (satellite & topo-vector +1 -> 11 / 13 | streets-vector & dark-gray-vector +0 -> 10 / 12)

      const zoomLevelThresholdCluster = [1, 3].includes(basemap.id) ? 11 : 10
      const zoomLevelThresholdPolygon = [1, 3].includes(basemap.id) ? 13 : 12
      // console.log('zoomLevelThresholdCluster ', zoomLevelThresholdCluster)
      // console.log('zoomLevelThresholdPolygon ', zoomLevelThresholdPolygon)

      // if (this.mapViewZoom >= 8) {
      //   if (this.esriMap.basemap.referenceLayers.items.length !== 0) {
      //     this.esriMap.basemap.referenceLayers.items[0].visible = true
      //   }
      // } else {
      //   if (this.esriMap.basemap.referenceLayers.items.length !== 0) {
      //     this.esriMap.basemap.referenceLayers.items[0].visible = false
      //   }
      // }

      if (this.geojsonLayer.length !== 0) {
        if (this.mapViewZoom > 0) {
          // console.log('this.mapViewZoom ', this.mapViewZoom)

          // TODO detect item id 44 by other generalized attribute information (polygon)

          if (this.itemSelected.id !== 44) {
            if (this.mapViewZoom <= zoomLevelThresholdCluster) {
            // this.set_popup_visibility(false)
            // this.set_popup_data([])

              if (this.geojsonLayer[0].featureReduction === null) {
                this.geojsonLayer[0].renderer = defaultValues.renderer[basemap.design].point_1
                // this.geojsonLayer[0].renderer.symbol.size = this.setRendererPointSize({ basmapID: basemap.id, zoomLevel: this.mapViewZoom })
                this.geojsonLayer[0].featureReduction = defaultValues.clusterConfig[basemap.design]

                setTimeout(() => {
                // NOTE: The Zoom-out has to set 1 level lower to fix the ArcGIS API BUG that the layerView is not updating correctly if aggregateIds are used with the query --> queryAggregatedAttributes()
                  this.esriMapView.zoom = zoomLevelThresholdCluster - 1
                }, 150)
              }
            } else {
            // this.set_popup_visibility(false)

              this.geojsonLayer[0].featureReduction = null
              this.geojsonLayer[0].renderer = defaultValues.renderer[basemap.design].point_2
              // this.geojsonLayer[0].renderer.symbol.size = this.setRendererPointSize({ basmapID: basemap.id, zoomLevel: this.mapViewZoom })
            }
          } else {
            if (this.mapViewZoom <= zoomLevelThresholdCluster) {
              if (this.geojsonLayer[0].featureReduction === null) {
                this.set_popup_visibility(false)
                this.set_popup_data([])

                this.geojsonLayer[0].featureReduction = defaultValues.clusterConfig[basemap.design]
                this.geojsonLayer[0].renderer = defaultValues.renderer[basemap.design].point_1
                // this.geojsonLayer[0].renderer.symbol.size = this.setRendererPointSize({ basmapID: basemap.id, zoomLevel: this.mapViewZoom })

                this.setLayerViewVisibility({
                  layerArr: this.geojsonLayer,
                  state: true
                })
                this.setLayerViewVisibility({
                  layerArr: this.geojsonLayerPolygon,
                  state: false
                })

                setTimeout(() => {
                  // NOTE: The Zoom out has to be set 1 level lower to fix the ArcGis API BUG that the layerView is not updating correctly for using the query with aggregateIds --> queryAggregatedAttributes()

                  this.esriMapView.zoom = zoomLevelThresholdCluster - 1
                }, 150)
              }
            } else {
              this.geojsonLayer[0].featureReduction = null
              this.geojsonLayer[0].renderer = defaultValues.renderer[basemap.design].point_2
              // this.geojsonLayer[0].renderer.symbol.size = this.setRendererPointSize({ basmapID: basemap.id, zoomLevel: this.mapViewZoom })

              // NOTE: Layers with additional polygon datasets will show the polygon layer instead of the point layer if the zoom level is above the zoomLevelThresholdPolygon

              if (this.mapViewZoom <= zoomLevelThresholdPolygon) {
                this.setLayerViewVisibility({
                  layerArr: this.geojsonLayer,
                  state: true
                })
                this.setLayerViewVisibility({
                  layerArr: this.geojsonLayerPolygon,
                  state: false
                })
              } else if (this.mapViewZoom > zoomLevelThresholdPolygon) {
                this.setLayerViewVisibility({
                  layerArr: this.geojsonLayer,
                  state: false
                })
                this.setLayerViewVisibility({
                  layerArr: this.geojsonLayerPolygon,
                  state: true
                })
              }

              // NOTE: Event handler have to be set/removed for each layer geometry type (point/polygon) based on the layers visibility state set by the zoomLevelThresholdPolygon, because both handler react to user input events (click/pointer-move) regardless if the layer is visible in the MapView or not

              if (this.mapViewZoom === zoomLevelThresholdPolygon) {
                // Point layer is/are visible

                this.removeHandler(this.mapViewHandlerClick)
                this.removeHandler(this.mapViewHandlerPointerMove)

                this.mapViewHandlerClick.push(
                  this.setMapViewHandlerClick({
                    layerArr: this.geojsonLayer
                  }
                  )
                )

                this.mapViewHandlerPointerMove.push(
                  this.setMapViewHandlerPointerMove(
                    this.geojsonLayer
                  )
                )
              } else if (this.mapViewZoom - 1 === zoomLevelThresholdPolygon) {
                // Polygon layer is/are visible

                this.removeHandler(this.mapViewHandlerClick)
                this.removeHandler(this.mapViewHandlerPointerMove)

                this.mapViewHandlerClick.push(
                  this.setMapViewHandlerClick({
                    layerArr: this.geojsonLayerPolygon
                  }
                  )
                )

                this.mapViewHandlerPointerMove.push(
                  this.setMapViewHandlerPointerMove(
                    this.geojsonLayerPolygon
                  )
                )
              }
            }
          }
        }
      }
    },

    setMapViewHandlerClick ({ layerArr }) {
      // TODO: Create aggregation of values for polygon graphics (cumulative or string-aggregated point features)
      // TODO: Add aggregated values to first slide of polygon (and maybe also cluster-point) PopUpDialog

      // NOTE: This click event handler handles all click events in the MapView. Point/Polygon graphics of registered layer(s) [layerArr] will be processed for the PopUp component. Point-Cluster graphics will only get zoomed to.
      return this.esriMapView.on('click', (event) => {
        let areaIdRelatedPointGraphics
        let areaIdRelatedPointIDs

        this.set_popup_visibility(false)

        this.esriMapView.hitTest(event).then(async ({ results }) => {
          // console.log('hitTestResults results ', results)

          // Filter HitTest results for the distinct for the event handler registered layers
          const hitTestResults = layerArr.map((layer) => {
            return results?.filter(
              hitResult => hitResult.graphic.layer === layer
            )
          }).flat()

          if (hitTestResults?.length > 0) {
            // Set interface state
            this.set_popup_visibility(false)
            this.set_menu_disabled(true)
            this.mapViewHighlightRemove()

            // Get geometry type of uppermost feature from the hittest
            const layerGeomType = hitTestResults[0].layer.geometryType

            // Filter out graphics by geometry type of uppermost feature from the hittest
            const hitTestResultsFiltered = hitTestResults.filter(hit => hit.layer.geometryType === layerGeomType)
            // console.log('hitTestResultsFiltered', hitTestResultsFiltered)

            // Get mapPoint of the feature graphic based on layer geometryType
            const pointX = layerGeomType === 'point' ? hitTestResultsFiltered[0].graphic.geometry.x : hitTestResultsFiltered[0].mapPoint.x
            const pointY = layerGeomType === 'point' ? hitTestResultsFiltered[0].graphic.geometry.y : hitTestResultsFiltered[0].mapPoint.y

            // Create MapPoint object
            const mapPoint = {
              x: pointX,
              y: pointY,
              spatialReference: {
                wkid: 3857
              }
            }

            // Retrieve data only if a none aggregate point cluster graphic was clicked in the MapView
            if (!hitTestResultsFiltered[0].graphic.isAggregate) {
              // Data collecting for polygon layer with area_id
              if (layerGeomType === 'polygon') {
                // Create query object for retrieving all polygon related point feature(s) queried by area_id and all other query definitions
                // areaIdRelatedPointGraphics = await this.getFeaturesByAreaId({
                //   layer: this.geojsonLayer,
                //   results: hitTestResultsFiltered
                // })
                areaIdRelatedPointGraphics = await this.getPointFeaturesByAreaId({
                  layer: this.geojsonLayer,
                  results: hitTestResultsFiltered
                })
                // console.log('areaIdRelatedPointGraphics', areaIdRelatedPointGraphics)
              }

              // // Set handler for watching the position of the selected feature relative to the mapView
              // if (this.popupViewHandler !== null) {
              //   this.popupViewHandler.remove()
              //   this.popupViewHandler = null
              // }
              // this.popupViewHandler = reactiveUtils.watch(
              //   () => this.esriMapView.center,
              //   () => {
              //     this.setPopupScreenPoint(hitTestResults)
              //   })

              // Retrieve all feature graphics attribute objects from the hittest
              let graphicAttributes
              let graphics

              if (layerGeomType === 'point') {
                graphicAttributes = hitTestResultsFiltered.map(hit => hit.graphic.attributes)
                graphics = hitTestResultsFiltered.map(hit => hit.graphic)
                // console.log('graphics', graphics)

                // Get PolygonLayer
                const polygonLayer = this.geojsonLayerPolygon.filter(layer => layer.geometryType === 'polygon')

                if (polygonLayer.length !== 0) {
                  const query = new Query()
                  query.where = String(`area_id = ${graphics[0].attributes.area_id}`)
                  query.returnGeometry = false
                  query.outFields = ['area_size']
                  const { features } = await polygonLayer[0].queryFeatures(query)
                  // console.log('features', features)
                  graphics.forEach(graphic => { graphic.attributes.area_size = features[0].attributes.area_size })
                }
              } else if (layerGeomType === 'polygon') {
                graphicAttributes = areaIdRelatedPointGraphics.map(feature => feature.attributes)
                graphics = areaIdRelatedPointGraphics.map(graphic => graphic) // REVIEW

                // Add attribute of the area_size from the selected polygon to every single point feature
                graphicAttributes.forEach(attribute => { attribute.area_size = hitTestResultsFiltered[0].graphic.attributes.area_size })
                graphics.forEach(graphic => { graphic.attributes.area_size = hitTestResultsFiltered[0].graphic.attributes.area_size })

                // graphicAttributes = areaIdRelatedPointIDs
              }

              // Reset & close the pointermove tooltip
              this.set_attributes_pointer_move(null)

              // Emit graphics and initial screenpoint for the popup
              const screenPoint = this.esriMapView.toScreen(mapPoint)

              // Emit open-popup event to the PopUpContainer component
              eventBus.$emit('open-popup', {
                mapView: this.esriMapView,
                graphics: graphics,
                // graphicAttributes: graphicAttributes,
                screenPoint: {
                  x: screenPoint.x,
                  y: screenPoint.y
                }
              })

              // Store data
              this.set_popup_data(graphicAttributes) // TODO Rename function and vuex variable
            }

            // Set zoom level based on mapView zoom level state and if it is a featureReductionCluster (isAggregate) or an single point/polygon
            const zoomLevel = this.esriMapView.zoom >= 13 ? this.esriMapView.zoom : !hitTestResultsFiltered[0].graphic.isAggregate ? 13 : this.esriMapView.zoom >= 9 ? 13 : 9

            // Zoom to clicked feature - set x/y values base on geometry type of feature
            const centerX = layerGeomType === 'point' ? hitTestResultsFiltered[0].graphic.geometry.longitude : hitTestResultsFiltered[0].mapPoint.longitude
            const centerY = layerGeomType === 'point' ? hitTestResultsFiltered[0].graphic.geometry.latitude : hitTestResultsFiltered[0].mapPoint.latitude

            // Zoom to center of point feature or distinct clicked point on polygon feature
            this.esriMapView.goTo({
              center: [centerX, centerY],
              zoom: zoomLevel
            }, {
              duration: 1200,
              easing: 'ease-in-out'
            })
              .then(() => {
                this.set_menu_disabled(false)
              })

            if (!hitTestResultsFiltered[0].graphic.isAggregate) {
              this.set_popup_visibility(true)

              // let highlightFeatures

              if (layerGeomType === 'point') {
                let polygonFeature

                if (this.geojsonLayerPolygon.length !== 0) {
                  // Get feature of polygon
                  const polygonAreaId = results[0].graphic.attributes.area_id
                  const query = this.geojsonLayerPolygon[0].createQuery()
                  query.where = String(`area_id = (${polygonAreaId})`)
                  query.returnGeometry = true
                  query.outFields = ['*']

                  // Query point layer for feature(s)
                  const response = await this.geojsonLayerPolygon[0].queryFeatures(query)
                  polygonFeature = response.features[0]
                }

                this.highlightedFeatures = [polygonFeature, ...hitTestResultsFiltered.map(hit => hit.graphic)]
              } else if (layerGeomType === 'polygon') {
                this.highlightedFeatures = [hitTestResultsFiltered[0].graphic]
              }

              await this.mapViewHighlightFeatures({
                layerArr: [
                  ...this.geojsonLayerPolygon,
                  ...this.geojsonLayer
                ],
                graphics: this.highlightedFeatures
              })
            }
          } else {
            // If no result was retrieved reset interface, popup data state and remove highlight from highlighted features
            this.set_popup_visibility(false)
            this.set_popup_data([])
            this.mapViewHighlightRemove()
            this.lastIDArrSorted = null
          }
        })
      })
    },

    async getPointFeaturesByAreaId ({ layer, results }) {
      // Identify area_id of related polygon
      const polygonAreaId = results[0].graphic.attributes.area_id

      // Create query object for retrieving all polygon layer related point features queried by area_id
      const query = layer[0].createQuery()
      query.where = [
        String(`area_id = (${polygonAreaId})`),
        ...this.queryYearCommissioning
      ].join(' AND ')
      query.returnGeometry = true
      query.outFields = ['*']

      // Query point layer for feature(s)
      const queryResponse = await this.geojsonLayer[0].queryFeatures(query)
      return queryResponse.features
    },

    async getPointFeatureIDsByAreaId ({ layer, results }) {
      // Create query object for retrieving all polygon layer related point features queried by area_id and year
      const areaIDs = results.map(res => res.graphic.attributes.area_id)

      const query = layer[0].createQuery()
      query.where = [
        String(`area_id IN (${areaIDs.join(', ')})`),
        ...this.queryYearCommissioning
      ].join(' AND ')
      query.returnGeometry = false
      query.outFields = ['id']

      // Query point layer for feature(s)
      const queryResponse = await this.geojsonLayer[0].queryFeatures(query)
      return queryResponse.features.map(feature => feature.attributes.id)
    },

    async getPolygonGraphicByAreaId (graphic) {
      // Identify area_id of related polygon
      const polygonAreaId = graphic.attributes.area_id

      const result = await Promise.all(this.geojsonLayerPolygon.map(async (layer) => {
        // Create query object for retrieving all polygon layer related point features queried by area_id
        const query = layer.createQuery()
        query.where = String(`area_id = (${polygonAreaId})`)
        query.returnGeometry = true
        query.outFields = ['*']

        // Query point layer for feature(s)
        const queryResponse = await layer.queryFeatures(query)
        return queryResponse
      }))

      return result[0]?.features[0]
    },

    setPopupScreenPoint (graphicHits) {
      // console.log('setPopupScreenPoint graphicHits', graphicHits)
      const graphicHit = graphicHits[0]

      let pointX
      let pointY
      if (graphicHit.layer.geometryType === 'point') {
        pointX = graphicHit.graphic.geometry.x
        pointY = graphicHit.graphic.geometry.y
      } else {
        // NOTE: if polygon the center has to be retrieved from the geometry extent
        // pointX = graphicHit.graphic.geometry.extent.center.x
        // pointY = graphicHit.graphic.geometry.extent.center.y
        pointX = graphicHit.mapPoint.x
        pointY = graphicHit.mapPoint.y
      }

      const mapPoint = {
        x: pointX,
        y: pointY,
        spatialReference: {
          wkid: 3857
        }
      }

      // console.log('setPopupScreenPoint mapPoint', mapPoint)

      const screenPoint = this.esriMapView.toScreen(mapPoint)
      if (screenPoint !== null && screenPoint !== undefined) {
        eventBus.$emit('popup-position', {
          x: Math.round(screenPoint.x),
          y: Math.round(screenPoint.y)
        })
      }
    },

    async mapViewHighlightFeatures ({ layerArr, graphics }) {
      // If graphic(s) is/are already highlighted, then remove the highlight
      this.mapViewHighlightRemove()

      Promise.all(layerArr.map(async (layer) => {
        // console.log('layer', layer)
        // Get LayerView of distinct layer
        const layerView = await this.esriMapView.whenLayerView(layer)

        // Set the highlight on every graphic returned by the query
        graphics.forEach((graphic) => {
          if (graphic !== undefined) {
            if (graphic.layer.geometryType === layer.geometryType) {
              const highlight = layerView.highlight(graphic)
              this.featuresHighlighted.push(highlight)
            }
          }
        })
      }))
    },

    async mapViewHighlightFeaturesPopUp (pointGraphic) {
      // If graphic(s) is/are already highlighted, then remove the highlight
      this.mapViewHighlightRemove()

      // Get polygon graphic related to point graphic
      const polygonGraphic = await this.getPolygonGraphicByAreaId(pointGraphic)

      const graphics = [pointGraphic, polygonGraphic]

      // Set Highlight of distinct layerView
      Promise.all([...this.geojsonLayer, ...this.geojsonLayerPolygon].map(async (layer) => {
        // Get LayerView of distinct layer
        const layerView = await this.esriMapView.whenLayerView(layer)

        graphics.forEach((graphic) => {
          // Set the highlight on every graphic returned by the query
          if (graphic !== undefined) {
            if (graphic.layer.geometryType === layer.geometryType) {
              const highlight = layerView.highlight(graphic)
              this.featuresHighlighted.push(highlight)
            }
          }
        })
      }))
    },

    mapViewHighlightRemove () {
      if (this.featuresHighlighted.length !== 0) {
        this.featuresHighlighted.forEach((featureHighlighted) => {
          if (!this.highlightedFeatures.includes(featureHighlighted)) {
            featureHighlighted.remove()

            // TODO Element has to be removed by key -> e.g. https://codepen.io/static_K/pen/bGBdLzE?editors=1011
            this.featuresHighlighted = []
          }
        })
      }
    },

    setMapViewHandlerPointerMove (layerArr) {
      // TODO: Change behavi11or of cursor symbol if graphics are hoovered to "pointer".
      // TODO: Change behavior of cursor symbol if none graphics are hoovered to "grab".
      // TODO: e.g. https://community.esri.com/t5/arcgis-javascript-maps-sdk-questions/change-mouse-cursor-on-hover-over-feature-only/td-p/646945

      let timeout
      let timeout2
      // let lastAttrAreaID
      // let lastIDArrSorted
      let lastObjectID
      let lastAttrID
      let lastPointDatasetIDs

      return this.esriMapView.on('pointer-move', (event) => {
        if (timeout !== undefined) {
          clearTimeout(timeout)
        }

        timeout = setTimeout(async () => {
          if (layerArr.length !== 0 && this.isPopupVisible === false) {
          // if (layerArr.length !== 0) {
            const { results } = await this.esriMapView.hitTest(event, { include: layerArr })

            if (results.length !== 0) {
              // Filter HitTest results for the distinct for the event handler registered layers
              const hitTestResults = layerArr.map((layer) => {
                return results?.filter(
                  hitResult => hitResult.graphic.layer === layer
                )
              }).flat()

              const result = hitTestResults[0]

              // Get geometry type of uppermost feature from the hittest
              const layerGeomType = hitTestResults[0].layer.geometryType

              if (hitTestResults.length !== 0 &&
              timeout2 !== undefined) {
                clearTimeout(timeout2)

                this.set_mapview_screenpointer({
                  x: Number(event.x.toFixed(0)),
                  y: Number(event.y.toFixed(0))
                })
              }

              const idArrSorted = hitTestResults.map(result => result.graphic.attributes.id).sort()

              if (layerGeomType === 'point') {
                if (result.graphic.isAggregate === false) {
                  // NOTE: For single graphic feature
                  if (
                    this.lastIDArrSorted !== idArrSorted ||
                      this.layerAttributesPointerMove === null
                  ) {
                    this.lastIDArrSorted = idArrSorted

                    if (hitTestResults.length === 1) {
                      if (lastAttrID !== result.graphic.attributes.id ||
                      this.layerAttributesPointerMove === null) {
                        lastAttrID = result.graphic.attributes.id
                        lastPointDatasetIDs = null

                        this.set_attributes_pointer_move(result.graphic.attributes)

                        this.mapViewHighlightFeatures({
                          layerArr: this.geojsonLayer,
                          graphics: [result.graphic]
                        })
                      }
                    } else if (hitTestResults.length >= 2) {
                      lastAttrID = null

                      const pointDatasetIDs = hitTestResults.map(result => result.graphic.attributes.id).sort()

                      if (JSON.stringify(lastPointDatasetIDs) !==
                      JSON.stringify(pointDatasetIDs)) {
                        lastPointDatasetIDs = pointDatasetIDs

                        const statisticDefinition = [{
                          onStatisticField: 'capacity_installed',
                          outStatisticFieldName: 'capacity_installed_min',
                          statisticType: 'min'
                        }, {
                          onStatisticField: 'capacity_installed',
                          outStatisticFieldName: 'capacity_installed_sum',
                          statisticType: 'sum'
                        }, {
                          onStatisticField: 'year_commissioning',
                          outStatisticFieldName: 'year_commissioning_min',
                          statisticType: 'min'
                        }, {
                          onStatisticField: 'year_commissioning',
                          outStatisticFieldName: 'year_commissioning_max',
                          statisticType: 'max'
                        }, {
                          onStatisticField: 'id',
                          outStatisticFieldName: 'id_count',
                          statisticType: 'count'
                        }]

                        //
                        const query = this.geojsonLayer[0].createQuery()
                        query.where = String(`id IN (${pointDatasetIDs.join(', ')})`)
                        query.outStatistics = statisticDefinition
                        query.returnGeometry = false
                        query.outFields = ['*']

                        // Query point layer for feature(s)
                        const queryResponse = await this.geojsonLayer[0].queryFeatures(query)
                        const attributes = queryResponse.features[0].attributes

                        //
                        const yearCommissioning = attributes.year_commissioning_min === attributes.year_commissioning_max ? attributes.year_commissioning_min : null
                        const yearCommissioningRange = attributes.year_commissioning_min !== attributes.year_commissioning_max ? `${attributes.year_commissioning_min} - ${attributes.year_commissioning_max}` : null
                        const capacityInstalled = pointDatasetIDs.length === 1 ? attributes.capacity_installed_min : null
                        const capacityInstalledSum = pointDatasetIDs.length !== 1 ? attributes.capacity_installed_sum : null
                        //
                        const pointerMoveAttributes = {
                          capacity_installed_sum: capacityInstalledSum,
                          capacity_installed: capacityInstalled,
                          year_commissioning_range: yearCommissioningRange,
                          year_commissioning: yearCommissioning,
                          id_count: attributes.id_count,
                          id: result.graphic.attributes.id
                        }

                        //
                        this.set_attributes_pointer_move(pointerMoveAttributes)

                        // Query for features for highlight
                        const queryHighlight = this.geojsonLayer[0].createQuery()
                        queryHighlight.where = String(`id IN (${pointDatasetIDs.join(', ')})`)
                        queryHighlight.returnGeometry = false
                        queryHighlight.outFields = null // ['*']
                        const queryHighlightResponse = await this.geojsonLayer[0].queryFeatures(queryHighlight)

                        //
                        await this.mapViewHighlightFeatures({
                          layerArr: this.geojsonLayer,
                          graphics: queryHighlightResponse.features
                        })
                      }
                    }
                  }
                } else {
                  // NOTE: For clustered graphic features

                  // For testing
                  // this.processParams(result.graphic, this.geojsonLayerViews)

                  const objectID = result.graphic.getObjectId()

                  if (lastObjectID !== objectID ||
                      this.layerAttributesPointerMove === null) {
                    lastObjectID = objectID

                    const attributes = await this.queryAggregatedAttributes({
                      objectID,
                      result
                    })

                    this.set_attributes_pointer_move(attributes)

                    await this.mapViewHighlightFeatures({
                      layerArr: this.geojsonLayer,
                      graphics: [result.graphic]
                    })
                  }
                }
              } else if (layerGeomType === 'polygon') {
                // NOTE: For polygon layers with area_id

                // if (result.graphic.attributes.area_id !== lastAttrAreaID ||
                //       this.layerAttributesPointerMove === null) {
                //   lastAttrAreaID = result.graphic.attributes.area_id

                if (
                  JSON.stringify(this.lastIDArrSorted) !==
                  JSON.stringify(idArrSorted)
                  // ||
                  //     this.layerAttributesPointerMove === null
                ) {
                  this.lastIDArrSorted = idArrSorted

                  // Data collecting for polygon layer with area_id
                  // Create query object for retrieving all polygon related point feature(s) queried by area_id and all other query definitions
                  // const areaIdRelatedPointFeatures = await this.getFeaturesByAreaId({
                  //   layer: this.geojsonLayer,
                  //   results: results
                  // })

                  const areaIdRelatedPointIDs = await this.getPointFeatureIDsByAreaId({
                    layer: this.geojsonLayer,
                    results: results
                  })

                  // console.log('areaIdRelatedPointIDs', areaIdRelatedPointIDs)

                  const statisticDefinition = [{
                    onStatisticField: 'capacity_installed',
                    outStatisticFieldName: 'capacity_installed_min',
                    statisticType: 'min'
                  }, {
                    onStatisticField: 'capacity_installed',
                    outStatisticFieldName: 'capacity_installed_sum',
                    statisticType: 'sum'
                  }, {
                    onStatisticField: 'year_commissioning',
                    outStatisticFieldName: 'year_commissioning_min',
                    statisticType: 'min'
                  }, {
                    onStatisticField: 'year_commissioning',
                    outStatisticFieldName: 'year_commissioning_max',
                    statisticType: 'max'
                  }, {
                    onStatisticField: 'id',
                    outStatisticFieldName: 'id_count',
                    statisticType: 'count'
                  }]

                  // const areaIdRelatedPointIDs = areaIdRelatedPointIDs.map(feature => feature.attributes.id)

                  const query = this.geojsonLayer[0].createQuery()
                  query.where = String(`id IN (${areaIdRelatedPointIDs.join(', ')}) AND area_id IN (${this.areaIDsByQuery.join(', ')})`)
                  // query.where = String(`id IN (${areaIdRelatedPointIDs.join(', ')})`)

                  // Expand the extent of the MapView to be sure to retrieve all point features related to polygons that are visible in the MapView and outside of the current MapView -> Point features who are outside of the current MapView can not be retrieved even if the polygon feature is partly visible
                  const mapViewExtentClone = this.esriMapView.extent.clone()
                  const expandValue = this.mapViewZoom >= 17 ? 40 : this.mapViewZoom >= 13 ? 15 : 2
                  mapViewExtentClone.expand(expandValue)
                  query.geometry = mapViewExtentClone
                  query.outStatistics = statisticDefinition
                  query.outFields = ['*']

                  // Query point layer for feature(s)
                  const layerView = await this.esriMapView.whenLayerView(this.geojsonLayer[0])
                  // console.log('layerView', layerView)
                  // const queryResponse = await this.geojsonLayer[0].queryFeatures(query) // To slow if all features are queried
                  const queryResponse = await layerView.queryFeatures(query)
                  // console.log('queryResponse', queryResponse)

                  // const queryTestDef = this.geojsonLayer[0].createQuery()
                  // queryTestDef.outFields = ['*']
                  // queryTestDef.geometry = this.esriMapView.extent
                  // const queryTest = await layerView.queryFeatures(queryTestDef)
                  // console.log('queryTest', queryTest)

                  const attributes = queryResponse.features[0].attributes

                  const yearCommissioning = attributes.year_commissioning_min === attributes.year_commissioning_max ? attributes.year_commissioning_min : null

                  const yearCommissioningRange = attributes.year_commissioning_min !== attributes.year_commissioning_max ? `${attributes.year_commissioning_min} - ${attributes.year_commissioning_max}` : null

                  const capacityInstalled = areaIdRelatedPointIDs.length === 1 ? attributes.capacity_installed_min : null

                  const capacityInstalledSum = areaIdRelatedPointIDs.length !== 1 ? attributes.capacity_installed_sum : null

                  const pointerMoveAttributes = {
                    capacity_installed_sum: capacityInstalledSum,
                    capacity_installed: capacityInstalled,
                    year_commissioning_range: yearCommissioningRange,
                    year_commissioning: yearCommissioning,
                    id_count: attributes.id_count,
                    id: result.graphic.attributes.area_id
                  }

                  this.set_attributes_pointer_move(pointerMoveAttributes)

                  await this.mapViewHighlightFeatures({
                    layerArr: this.geojsonLayerPolygon,
                    graphics: results.map(result => result.graphic)
                  })
                }
              }
            } else {
              timeout2 = setTimeout(() => {
                if (this.layerAttributesPointerMove !== null) {
                  this.set_attributes_pointer_move(null)
                  this.mapViewHighlightRemove()

                  // lastAttrAreaID = null
                  this.lastIDArrSorted = null // TODO Must be reset to null if zooming
                  lastObjectID = null
                  lastAttrID = null
                  lastPointDatasetIDs = null
                }
              }, 20)
            }
          }
        }, 5)
      })
    },

    // processParams (graphic, layerView) {
    //   if (!graphic || !layerView) {
    //     throw new Error('Graphic or layerView not provided.')
    //   }

    //   if (!graphic.isAggregate) {
    //     throw new Error('Graphic must represent a cluster.')
    //   }
    // },

    async queryAggregatedAttributes ({ objectID }) {
      // console.log('objectID', objectID)

      let yearRange
      let yearSingle

      const query = this.geojsonLayerViews[0].createQuery()
      query.aggregateIds = [objectID]
      // FIXME BUG For some unknown reason the retrieved feature IDs contain also the IDs of features outside of the selected year range atm. As a temperary fix a query.where is implemented. The shown clustered point feature count is still wrong. This could have to do with the way the features are counted on the backside by the clusterConfig "expression: "Text($feature.cluster_count, '#,###')"" <-- default_values.js
      // FIXME Solution could be to set the count by a feature count of the queried features and to update the clusterconfig each time from here

      query.where = [
        ...this.queryRegion,
        ...this.querySelectValues,
        // ...this.queryCapacityInstalled,
        ...this.queryYearCommissioning
      ].join(' AND ')

      // const statisticDefinition = [{
      //   onStatisticField: 'capacity_installed',
      //   outStatisticFieldName: 'capacity_installed_sum',
      //   statisticType: 'sum'
      // }, {
      //   onStatisticField: 'year_commissioning',
      //   outStatisticFieldName: 'year_commissioning_min',
      //   statisticType: 'min'
      // }, {
      //   onStatisticField: 'year_commissioning',
      //   outStatisticFieldName: 'year_commissioning_max',
      //   statisticType: 'max'
      // }]
      // query.outStatistics = statisticDefinition

      // TODO rewrite query with statistic method

      const { features } = await this.geojsonLayerViews[0].queryFeatures(query)
      // console.log('features', features)
      // const testFilter = features.filter(feature => feature.attributes.year_commissioning >= 2015)
      // console.log('testFilter >= 2015', testFilter)

      const attributesCapacityInstalled = features.map(feature => feature.attributes.capacity_installed)
      const capacitySum = attributesCapacityInstalled.reduce((pv, cv) => pv + cv, 0)

      const yearsCommissioning = features.map(feature => feature.attributes.year_commissioning)
      const yearsUnique = [...new Set(yearsCommissioning)]

      if (yearsUnique.length !== 1) {
        yearRange = String(Math.min(...yearsCommissioning)) + ' - ' + Math.max(...yearsCommissioning)
        yearSingle = null
      } else {
        yearRange = null
        yearSingle = String(yearsUnique[0])
      }

      const attributes = {
        id: objectID,
        year_commissioning: yearSingle,
        year_commissioning_range: yearRange,
        capacity_installed_sum: Math.round(Number(capacitySum))
      }
      return attributes
    },

    removeHandler (handlerArray) {
      if (handlerArray.length !== 0) {
        const handlersRemoved = handlerArray.map(handler => {
          handler.remove()
          return handler
        })
        handlersRemoved.forEach((handlerRemoved) => {
          const index = handlerArray.indexOf(handlerRemoved)
          handlerArray.splice(index, 1)
        })
      }
    },

    async loadItem (itemId) {
      // console.log('loadItem()')
      // console.time('itemloading')

      this.set_layer_statistic_values(null)
      this.set_attributes_pointer_move(null)
      this.set_popup_visibility(false)
      this.set_menu_disabled(true)
      this.set_popup_data([])
      this.mapViewHighlightRemove()
      eventBus.$emit('webgis-reset-local-state')

      this.removeHandler(this.mapViewHandlerClick)
      this.removeHandler(this.mapViewHandlerPointerMove)

      this.resetLayerQueries()

      // this.removeHandler(this.geojsonLayerViewsWatchHandler)

      // Remove old layer if exist - NOTE: Does not work inside of reusable method
      if (this.geojsonLayer.length !== 0) {
        this.esriMap.removeMany(this.geojsonLayer)
        this.geojsonLayer.forEach((layer) => {
          layer.destroy()
          layer = null
        })
        this.geojsonLayer = null
        this.geojsonLayer = []
      }
      if (this.geojsonLayerPolygon.length !== 0) {
        this.esriMap.removeMany(this.geojsonLayerPolygon)
        this.geojsonLayerPolygon.forEach((layer) => {
          layer.destroy()
          layer = null
        })
        this.geojsonLayerPolygon = null
        this.geojsonLayerPolygon = []
      }

      await this.loadLayer(itemId)

      // Set initial time filter (!! important for filtering out all installations who are dismantled up to the default / first selected year)
      this.updateYear(this.itemDataQuery)

      // Create click handler for point and polygon feature/geojson layer with re plant installations
      this.mapViewHandlerClick.push(this.setMapViewHandlerClick({
        layerArr: [
          ...this.geojsonLayer,
          ...this.geojsonLayerPolygon
        ]
      }))

      this.mapViewHandlerPointerMove.push(this.setMapViewHandlerPointerMove(this.geojsonLayer))

      await this.setLayerQueryMinMax()

      if (!this.showMapViewUpdatingIndicator) {
        this.show_mapview_updating_indicator(true)
      }

      await this.setAreaIDsByQuery()

      await this.setLayerViewFeatureFilter()

      this.queryLayerStatistics()

      this.show_mapview_updating_indicator(false)
      this.set_menu_disabled(false)
      this.isLayerViewFilteredCounter = 0
      this.isLayerViewFiltered = false

      await this.zoomToRegion({ ags: '00000000' })

      // console.timeEnd('itemloading')

      return true
    },

    async loadMap () {
      const { map, mapView } = await initWebGis({
        basemap: this.basemapSelected.value,
        constraints: defaultValues.mapView.constraints,
        container: 'webgis_div',
        highlightOptions: defaultValues.mapView.highlightOptions,
        navigation: defaultValues.mapView.navigation,
        uiComponents: defaultValues.mapView.uiComponents
      })

      this.esriMap = map
      this.esriMapView = mapView

      if (this.isMainMenuOpen && this.$vuetify.breakpoint.mdAndUp) {
        this.esriMapView.padding = {
          left: 0,
          top: 0, // 106,
          right: 442,
          bottom: 0
        }
      } else {
        this.esriMapView.padding = {
          left: 0,
          top: 0, // 106,
          right: 0,
          bottom: 0
        }
      }

      this.isMapViewLoaded = true
    },

    async loadLayer (itemId) {
      this.geojsonLayer = null
      this.geojsonLayer = []
      this.geojsonLayerViews = null
      this.geojsonLayerViews = []

      eventBus.$emit('webgis-show-overlay', true)

      // Reset all queries
      this.querySelectValues = []
      this.queryCapacityInstalled = []
      this.queryYearCommissioning = []

      // Set geojson geometry types for loading point&polygon for solarenergy (id = 44) layer only
      const geojsonSpatialFields = itemId === 44 ? ['point', 'polygon'] : ['point']

      await Promise.all(geojsonSpatialFields.map(async (spatialField) => {
        // NOTE: spatialField='' -> "point" | spatialField = 'polygon' -> "geometry_multipolygon"
        let geojsonData = []

        if (spatialField === 'point') {
          geojsonData = await getGeojsonPointData({
            baseURL: this.baseURL,
            itemId: itemId,
            spatialField: spatialField,
            yearCommissioningMax: 2022 // NOTE: default value in view is also 2022
          })
        } else {
          geojsonData = await getGeojsonPolygonData({
            baseURL: this.baseURL,
            itemId: itemId,
            spatialField: spatialField
          })
        }

        // NOTE: Atm field types must not be set because geojson values are serialized by their django datatype. NOTE: This has the side effect that if all values of a field/database-column that was received from the Axios API call have Null as value, that field will be automatically dropped from the layers fields set in the moment of the layers creation (new GeoJSONLayer).
        // const geojsonFields = Object.keys(geojsonData.features[0].properties).map((key) => {
        //   const fieldDefinition = defaultValues.layerFields.filter((layerField) => {
        //     return layerField.name === key
        //   })
        //   return fieldDefinition[0]
        // }).filter(field => field)

        const layer = await createLayer({
          copyright: defaultValues.copyright.manske,
          data: geojsonData,
          // fields: geojsonFields,
          title: `layer-item-${this.itemSelected.id}-${spatialField}`
        })

        geojsonData = null

        if (spatialField === 'point') {
          layer.renderer = defaultValues.renderer[this.basemapSelected.design].point_1
          layer.featureReduction = defaultValues.clusterConfig[this.basemapSelected.design]
          this.geojsonLayer.push(layer)
        } else if (spatialField === 'polygon') {
          layer.renderer = defaultValues.renderer[this.basemapSelected.design].polygon_1
          this.geojsonLayerPolygon.push(layer)
        }

        this.esriMap.add(layer)

        if (spatialField === 'point') {
        // Get lowest and highest value of capacity installed and store it
          const statisticDefinition = [{
            onStatisticField: 'capacity_installed',
            outStatisticFieldName: 'capacity_installed_min',
            statisticType: 'min'
          }, {
            onStatisticField: 'capacity_installed',
            outStatisticFieldName: 'capacity_installed_max',
            statisticType: 'max'
          }]
          const query = layer.createQuery()
          query.outStatistics = statisticDefinition
          const result = await layer.queryFeatures(query)
          if (result.features.length !== 0) {
            const attributes = result.features[0].attributes
            // NOTE: capacity min/max values get round down/up to nearest value below/higher divisible by 100 to avoid filtering out values lower or higher by 99 -> this is because the step property on v-slider is set to 100 and rounds the range for filtering
            // REVIEW needed. rangeSliderSteps vary in number
            const minValue = Math.floor(Math.min(attributes.capacity_installed_min) / 100) * 100
            const maxValue = Math.ceil(Math.max(attributes.capacity_installed_max) / 100) * 100
            this.update_data_query({ query: { capacity_installed: [minValue, maxValue] } })
          }
        }

        // Create LayerView of distinct layer
        this.esriMapView.whenLayerView(layer).then((layerView) => {
          if (spatialField === 'point') {
            layerView.visible = true
          } else if (spatialField === 'polygon') {
            layerView.visible = false
          }

          this.geojsonLayerViews.push(layerView)
        })
      }))

      eventBus.$emit('webgis-show-overlay', false)
    },

    setLayerViewVisibility ({ layerArr, state }) {
      layerArr.forEach((layer) => {
        const layerView = this.geojsonLayerViews.filter(view => view.layer.title === layer.title)
        layerView[0].visible = state
      })
    },

    async loadBaseLayer () {
      this.baseLayerItems = await Promise.all(defaultValues.baseLayerItems.map(async (baselayer) => {
        const baseLayerData = await getBaseLayer({
          geoJsonFileName: baselayer.filename
        })

        const baseLayer = createLayer({
          data: baseLayerData,
          copyright: defaultValues.copyright.bkg,
          title: baselayer.title
        })

        return baseLayer
      }))

      this.baseLayerItems.forEach((layer) => {
        layer.renderer = defaultValues.renderer[this.basemapSelected.design].baseLayer

        layer.visible = false

        this.esriMap.add(layer)

        // this.esriMapView.whenLayerView(layer).then((layerView) => {
        //   this.baseLayerViews.push(layerView)
        // })
      })

      // return true
    },

    setMapViewPadding () {
      if (this.esriMapView !== null) {
        this.set_menu_disabled(true)

        if (this.$vuetify.breakpoint.xsOnly) {
          this.esriMapView.padding = {
            left: 0,
            top: 106,
            right: 0,
            bottom: 0
          }
        } else {
          const start = this.isMainMenuOpen ? 1 : 440
          const end = this.isMainMenuOpen ? 440 : 1

          new Tweezer({
            start: start,
            end: end,
            duration: 1200,
            easing: (t, b, c, d) => {
              if ((t /= d / 2) < 1) return c / 2 * t * t + b
              return -c / 2 * ((--t) * (t - 2) - 1) + b
            }
          })
            .on('tick', value => {
              this.esriMapView.padding = {
                left: 0,
                top: 106,
                right: value,
                bottom: 0
              }
            }).begin()
            .on('done', () => {
              // all done
              this.set_menu_disabled(false)
            })
          // .stop() // this stops the tweening
          // .begin() // this fires the tweening
        }
      }
    },

    resizeViewDiv () {
      setTimeout(() => {
        this.viewDivHeight = this.$vuetify.breakpoint.height - this.$vuetify.application.top - this.$vuetify.application.footer

        this.set_viewdiv_dimensions({
          width: this.$vuetify.breakpoint.width,
          height: this.$vuetify.breakpoint.height
        })
      }, 200)
    }
  }
}
</script>

<style lang="scss">
:root {
  --esri-attribution-color: #ffffff;
}

#webgis_div {
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }
  overscroll-behavior-x: none;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
    display: none !important;
    outline: none !important;
}

// .esri-overlay-surface,
.esri-ui-inner-container .esri-component,
// .esri-ui.calcite-theme-light .esri-ui-corner-container,
.esri-view .esri-view-surface--inset-outline:focus::after {
  display: none !important;
}

.esri-ui {
  inset: 0 !important;
}
.esri-ui-inner-container.esri-ui-manual-container {
  height: 100%;
}
.esri-component.esri-attribution.esri-widget {
  bottom: 0px !important;
  width: 100% !important;
  position: absolute !important;
  background: none !important;
  padding: 0 12px !important;
  display: inline-flex !important;
  justify-content: flex-end !important;
  white-space: break-spaces !important;
}

.esri-component.esri-attribution.esri-widget,
.esri-component.esri-attribution.esri-widget a {
  font-size: 0.8rem;
  color:  var(--esri-attribution-color); // #ffffff; // var(--v-primary-base);
}
// .esri-attribution::before {
//   color: var(--v-primary-base) !important;
//   content: "BKG, ";
// }
// .esri-attribution__powered-by::before {
//   content: " ";
//   white-space: break-spaces;
// }
</style>
