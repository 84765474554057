import _ from 'lodash'

export default {
  REMOVE_CATEGORY_ITEM_SELECTED (state) {
    state.categoryItemSelected = null
  },
  SET_CATEGORY_ITEM_SELECTED (state, {
    categoryItem
  }) {
    state.categoryItemSelected = categoryItem
  },
  REMOVE_CATEGORY_TYPE_SELECTED (state) {
    state.categoryTypeSelected = null
  },
  SET_CATEGORY_TYPE_SELECTED (state, {
    categoryItem
  }) {
    state.categoryTypeSelected = categoryItem
  },
  SET_CATEGORY_TYPE_SELECTED_BTN_ID (state, {
    btnId
  }) {
    state.categoryTypeSelectedBtnId = btnId
  },
  SET_ITEM_LIST (state, itemlist) {
    state.itemList = itemlist
  }
}
