// import AggregateField from '@arcgis/core/layers/support/AggregateField.js'

export default {
  baseLayerItems: [
    {
      filename: '2020-DE-NUTS0-VG250.geojson',
      ade: 1,
      title: 'ade-1'
    },
    {
      filename: '2020-DE-NUTS1-VG250_.geojson',
      ade: 2,
      title: 'ade-2'
    },
    {
      filename: '2020-DE-NUTS3-VG250_.geojson',
      ade: 4,
      title: 'ade-4'
    }
  ],
  basemapDefault: {
    id: 1,
    value: 'satellite',
    title_de: 'Satellit',
    title_en: 'Satellite',
    design: 'dark'
  },
  basemapItems: [
    {
      id: 1,
      value: 'satellite',
      title_de: 'Satellit',
      title_en: 'Satellite',
      design: 'dark'
    },
    {
      id: 2,
      value: 'streets-vector',
      title_de: 'Straßenkarte',
      title_en: 'Streets',
      design: 'light'
    },
    {
      id: 3,
      value: 'topo-vector',
      title_de: 'Topographie',
      title_en: 'Topography',
      design: 'light'
    },
    {
      id: 4,
      value: 'dark-gray-vector',
      title_de: 'Dunkelgrau',
      title_en: 'Dark gray',
      design: 'dark'
    }
  ], // https://developers.arcgis.com/javascript/latest/api-reference/esri-Map.html#basemap
  copyright: {
    bkg: '© GeoBasis-DE / BKG 2020',
    manske: '<a href="https://doi.org/10.5281/zenodo.8188601" target="_blank">RE-plant-data: Manske et al.</a>',
    ufz: ''
  },
  // map: {
  //   basemap: 'satellite' // 'gray-vector', // 'streets-vector',
  // },
  mapView: {
    constraints: {
      rotationEnabled: false,
      geometry: {
        type: 'extent',
        xmax: 15.3788,
        xmin: 4.5552,
        ymax: 55.7456,
        ymin: 46.2992
      },
      maxScale: 1000,
      minScale: 19000000
    },
    highlightOptions: {
      color: [255, 241, 58],
      fillOpacity: 0.4,
      haloOpacity: 0.2
    },
    navigation: {
      browserTouchPanEnabled: true,
      gamepad: {
        enabled: false
      },
      momentumEnabled: true,
      mouseWheelZoomEnabled: true
    },
    uiComponents: ['attribution']
  },
  // https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-support-FeatureReductionCluster.html#properties-summary
  clusterConfig: {
    light: {
      clusterMaxSize: '60px',
      clusterMinSize: '24px',
      clusterRadius: '100px',
      labelingInfo: [{
        deconflictionStrategy: 'none',
        labelExpressionInfo: {
          expression: "Text($feature.cluster_count, '#,###')"
          // expression: 'Text($feature.aggregateCount, \'#,###\')'
        },
        labelPlacement: 'center-center',
        symbol: {
          color: '#ffffff',
          font: {
            family: 'Noto Sans',
            size: '14px',
            weight: 'normal'
          },
          type: 'text'
        }
      }],
      popupEnabled: false,
      symbol: {
        color: 'rgb(0, 62, 110)',
        outline: {
          color: 'rgb(0, 62, 110)',
          width: 0.5
        },
        type: 'simple-marker'
      },
      type: 'cluster'
      // fields: [
      //   new AggregateField({
      //     name: 'aggregateCount',
      //     statisticType: 'count'
      //   })
      // ]
    },
    dark: {
      clusterMaxSize: '60px',
      clusterMinSize: '24px',
      clusterRadius: '100px',
      labelingInfo: [{
        deconflictionStrategy: 'none',
        labelExpressionInfo: {
          expression: "Text($feature.cluster_count, '#,###')"
          // expression: 'Text($feature.aggregateCount, \'#,###\')'
        },
        labelPlacement: 'center-center',
        symbol: {
          color: 'rgb(0, 62, 110)',
          font: {
            family: 'Noto Sans',
            size: '14px',
            weight: 'normal'
          },
          type: 'text'
        }
      }],
      popupEnabled: false,
      symbol: {
        color: 'rgb(225, 232, 238)',
        outline: {
          // color: 'rgb(225, 232, 238)',
          color: 'rgb(0, 62, 110)',
          width: 0.5
        },
        type: 'simple-marker'
      },
      type: 'cluster'
      // fields: [
      //   new AggregateField({
      //     name: 'aggregateCount',
      //     statisticType: 'count'
      //   })
      // ]
    }
  },
  renderer: {
    light: {
      point_1: {
        type: 'simple',
        symbol: {
          type: 'simple-marker',
          size: 14,
          color: 'rgb(0, 62, 110)',
          outline: {
            color: '#ffffff',
            width: 1
          }
        }
      },
      point_2: {
        type: 'simple',
        symbol: {
          type: 'simple-marker',
          size: 14,
          color: 'rgb(0, 62, 110)',
          outline: {
            color: '#ffffff',
            width: 1
          }
        }
      },
      polygon_1: {
        type: 'simple',
        symbol: {
          type: 'simple-fill',
          size: 14,
          color: null,
          outline: {
            color: 'rgb(0, 62, 110)',
            width: 3
          }
        }
      },
      polygon_2: {
        type: 'simple',
        symbol: {
          type: 'simple-fill',
          size: 14,
          color: 'rgba(255, 255, 25, 0.2)',
          outline: {
            color: 'rgb(0, 62, 110)',
            width: 3
          }
        }
      },
      baseLayer: {
        type: 'simple',
        symbol: {
          type: 'simple-fill',
          size: 14,
          color: null,
          outline: {
            color: 'rgba(0, 62, 110, 0.4)',
            width: 1
          }
        }
      }
    },
    dark: {
      point_1: {
        type: 'simple',
        symbol: {
          type: 'simple-marker',
          size: 14,
          color: 'rgb(225, 232, 238)',
          outline: {
            color: 'rgb(0, 62, 110)',
            width: 1
          }
        }
      },
      point_2: {
        type: 'simple',
        symbol: {
          type: 'simple-marker',
          size: 14,
          // NOTE https://coolors.co/003e6e-edde59
          color: 'rgb(237, 222, 89)', // 'rgb(225, 232, 238)',
          outline: {
            color: 'rgb(0, 62, 110)',
            width: 1
          }
        }
      },
      polygon_1: {
        type: 'simple',
        symbol: {
          type: 'simple-fill',
          size: 14,
          color: null,
          outline: {
            color: 'rgb(237, 222, 89)', // 'rgb(225, 232, 238)',
            width: 3
          }
        }
      },
      polygon_2: {
        type: 'simple',
        symbol: {
          type: 'simple-fill',
          size: 14,
          color: 'rgba(255, 255, 25, 0.2)',
          outline: {
            color: 'rgb(225, 232, 238)',
            width: 3
          }
        }
      },
      baseLayer: {
        type: 'simple',
        symbol: {
          type: 'simple-fill',
          size: 14,
          color: null,
          outline: {
            color: 'rgba(225, 232, 238, 0.4)',
            width: 1
          }
        }
      }
    }
  }
  // layerFields: [
  //   {
  //     name: 'id',
  //     alias: 'id',
  //     type: 'oid'
  //   },
  //   {
  //     name: 'item',
  //     alias: 'item',
  //     type: 'integer'
  //   },
  //   {
  //     name: 'technology',
  //     alias: 'technology',
  //     type: 'integer'
  //   },
  //   {
  //     name: 'technology_type',
  //     alias: 'technology_type',
  //     type: 'integer'
  //   },
  //   {
  //     name: 'biomass_type',
  //     alias: 'biomass_type',
  //     type: 'integer'
  //   },
  //   {
  //     name: 'hydropower_type',
  //     alias: 'hydropower_type',
  //     type: 'integer'
  //   },
  //   {
  //     name: 'photovoltaic_type',
  //     alias: 'photovoltaic_type',
  //     type: 'integer'
  //   },
  //   {
  //     name: 'photovoltaic_alignment',
  //     alias: 'photovoltaic_alignment',
  //     type: 'integer'
  //   },
  //   {
  //     name: 'photovoltaic_inclination',
  //     alias: 'photovoltaic_inclination',
  //     type: 'integer'
  //   },
  //   {
  //     name: 'manufacturer',
  //     alias: 'manufacturer',
  //     type: 'string'
  //   },
  //   {
  //     name: 'manufacturer_model',
  //     alias: 'manufacturer_model',
  //     type: 'string'
  //   },
  //   {
  //     name: 'date_commissioning',
  //     alias: 'date_commissioning',
  //     type: 'string'
  //   },
  //   {
  //     name: 'date_decommissioning',
  //     alias: 'date_decommissioning',
  //     type: 'string'
  //   },
  //   {
  //     name: 'year_commissioning',
  //     alias: 'year_commissioning',
  //     type: 'integer'
  //   },
  //   {
  //     name: 'year_decommissioning',
  //     alias: 'year_decommissioning',
  //     type: 'integer'
  //   },
  //   // {
  //   //   name: 'system_type',
  //   //   alias: 'system_type',
  //   //   type: 'string'
  //   // },
  //   // {
  //   //   name: 'system_subtype',
  //   //   alias: 'system_subtype',
  //   //   type: 'string'
  //   // },
  //   {
  //     name: 'capacity_installed',
  //     alias: 'capacity_installed',
  //     type: 'double'
  //   },
  //   // {
  //   //   name: 'bio_productionsite_id',
  //   //   alias: 'bio_productionsite_id',
  //   //   type: 'integer'
  //   // },
  //   // {
  //   //   name: 'pvs_alignment',
  //   //   alias: 'pvs_alignment',
  //   //   type: 'string'
  //   // },
  //   // {
  //   //   name: 'pvs_inclination',
  //   //   alias: 'pvs_inclination',
  //   //   type: 'string'
  //   // }
  //   {
  //     name: 'windenergy_hub_height',
  //     alias: 'windenergy_hub_height',
  //     type: 'double'
  //   },
  //   {
  //     name: 'windenergy_rotor_dia',
  //     alias: 'windenergy_rotor_dia',
  //     type: 'double'
  //   },
  //   // {
  //   //   name: "source_record",
  //   //   alias: "source_record",
  //   //   type: 'string'
  //   // },
  //   // {
  //   //   name: "source_id",
  //   //   alias: "source_id",
  //   //   type: 'string'
  //   // },
  //   // {
  //   //   name: "source_actuality",
  //   //   alias: "source_actuality",
  //   //   type: 'date'
  //   // },
  //   // {
  //   //   name: "info_note",
  //   //   alias: "info_note",
  //   //   type: 'string'
  //   // },
  //   {
  //     name: 'location_lat',
  //     alias: 'location_lat',
  //     type: 'double'
  //   },
  //   {
  //     name: 'location_lon',
  //     alias: 'location_lon',
  //     type: 'double'
  //   },
  //   // {
  //   //   name: 'bkg_debkg_id',
  //   //   alias: 'bkg_debkg_id',
  //   //   type: 'string'
  //   // },
  //   {
  //     name: 'bkg_ags',
  //     alias: 'bkg_ags',
  //     type: 'string'
  //   }
  //   // {
  //   //   name: 'bkg_ars',
  //   //   alias: 'bkg_ars',
  //   //   type: 'string'
  //   // },
  //   // {
  //   //   name: 'bkg_ags_0',
  //   //   alias: 'bkg_ags_0',
  //   //   type: 'string'
  //   // },
  //   // {
  //   //   name: 'bkg_ars_0',
  //   //   alias: 'bkg_ars_0',
  //   //   type: 'string'
  //   // },
  //   // {
  //   //   name: 'bkg_gf',
  //   //   alias: 'bkg_gf',
  //   //   type: 'integer'
  //   // },
  //   // {
  //   //   name: 'bkg_source',
  //   //   alias: 'bkg_source',
  //   //   type: 'string'
  //   // },
  //   // {
  //   //   name: 'bkg_source_date',
  //   //   alias: 'bkg_source_date',
  //   //   type: 'date'
  //   // },
  //   // {
  //   //   name: 'bkg_source_ade',
  //   //   alias: 'bkg_source_ade',
  //   //   type: 'integer'
  //   // }
  // ]
}
