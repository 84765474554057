<template>
  <div
    id="select_main_category"
  >
    <v-card
      v-if="itemList.length !== 0"
      class="fill-height"
      height="100%"
      flat
      rounded="0"
    >
      <v-row
        no-gutters
        align="center"
        justify="space-between"
        class="px-3 pb-2 flex-row"
      >
        <v-col>
          <v-btn-toggle
            v-model="categoryTypeSelectedBtnId"
            borderless
            dense
            group
            mandatory
            active-class="btn-select-category-group-active"
            class="d-flex"
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'justify-center'"
            style="width: 100%;"
          >
            <div
              class="d-flex align-center"
              :class="$vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column justify-center'"
            >
              <div
                :class="$vuetify.breakpoint.mdAndUp ? 'mr-1' : ''"
                class="d-flex align-center text-h5 text-sm-h4"
              >
                <span
                  v-if="locale === 'de'"
                  class="d-flex align-center"
                >
                  Kennzahlenauswahl nach:
                </span>

                <span
                  v-else
                  class="d-flex align-center"
                >
                  Indicator selection by
                </span>
              </div>

              <div
                class="d-flex align-center"
              >
                <div
                  v-for="categoryType in orderedCategoryTypeItems"
                  :key="categoryType.id"
                >
                  <v-btn
                    text
                    plain
                    class="btn-title align-self-end"
                    @click="selectCategoryType({ categoryType })"
                  >
                    <span
                      class="text-h5 text-sm-h4 align-self-start"
                    >
                      {{ categoryType[`title_plural_${locale}`] }}
                    </span>
                  </v-btn>

                  <span
                    v-if="categoryType !== orderedCategoryTypeItems[orderedCategoryTypeItems.length - 1]"
                    class="text-h5 mx-2"
                    style="line-height: 1.5rem; height: 100%; opacity: 0.6;"
                  > |
                  </span>
                </div>
              </div>
            </div>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <!-- <v-row
        no-gutters
        align="stretch"
        justify="space-between"
        class="px-3 pb-4 flex-row"
      >
        <v-col>
          <v-card
            flat
            rounded="0"
            class="category-text-area pa-3"
          >
            <div
              v-if="categoryTypeSelected[0]"
              class="text-h6"
            >
              {{ categoryTypeSelected[0].detail_info[0][`info_text_${locale}`] }}
            </div>
          </v-card>
        </v-col>
      </v-row> -->

      <v-row
        v-show="$vuetify.breakpoint.xsOnly"
        no-gutters
        class="px-3 pt-1 pb-1 flex-row"
      >
        <v-col
          cols="12"
          class="category-select"
        >
          <v-select
            v-model="categoryListItemMobileSelected"
            return-object
            clearable
            filled
            rounded
            background-color="rgb(225, 232, 238)"
            dense
            :class="[$vuetify.breakpoint.mdAndUp ? 'text-h5 font-weight-medium':'px-1 text-h6 font-weight-medium']"
            :item-text="selectItemText"
            :items="categoryItemsFiltered"
            @change="selectCategoryItem({
              categoryItem: categoryListItemMobileSelected,
              selectElement: 'dropdown',
            })"
          >
            <template
              #selection="data"
            >
              <div
                class="text-truncate"
              >
                <span
                  v-if="locale"
                >
                  {{ data.item[`title_${locale}`] }}
                </span>
              </div>
            </template>

            <template
              #item="data"
            >
              <div
                style="width: 100%; border-bottom: 1px dotted var(--v-secondary-lighten1);"
                class="py-1"
              >
                <span
                  v-if="locale"
                >
                  {{ data.item[`title_${locale}`] }}
                </span>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row
        v-show="$vuetify.breakpoint.smAndUp"
        no-gutters
      >
        <v-col
          cols="12"
        >
          <v-list
            class="pa-0"
          >
            <v-list-item-group
              v-model="categoryListItemSelected"
              class="d-flex justify-space-between flex-nowrap mx-3 mb-3"
              :class="{'word-break': $vuetify.breakpoint.mdOnly}"
              active-class="category_active"
            >
              <v-list-item
                v-for="(categoryItem, index) in categoryItemsFiltered"
                :key="index"
                class="pa-0 d-flex flex-nowrap float-left list_item"
                :class="{
                  'category-item-not-active': isCategoryItemSeleced,
                }"
                @click="selectCategoryItem({
                  categoryItem,
                  selectElement: 'list',
                })"
              >
                <SelectMainCategoryItem
                  class="fill-height"
                  :item="categoryItem"
                  :is-category-item-seleced="isCategoryItemSeleced"
                  :category-item-selected="categoryListItemMobileSelected"
                />
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions, mapMutations } from 'vuex'

// import _gsap from 'gsap'
import _ from 'lodash'

import SelectMainCategoryItem from '@/components/apps/report/selection/SelectMainCategoryItem.vue'
// import DashboardSelectButton from '@/components/main/button/BtnDashboardSelect.vue'

export default {
  name: 'SelectMainCategoryPage',

  components: {
    SelectMainCategoryItem
    // DashboardSelectButton
  },

  data () {
    return {
      categoryItems: [],
      categoryItemSelectedId: 1,
      categoryListItemMobileSelected: undefined,
      categoryListItemSelected: undefined,
      categoryTypeItems: [],
      isCategoryItemSeleced: false
      // showSkeleton: false,
      // skeletonAttributes: {
      //   class: 'mb-6',
      //   elevation: 2,
      //   height: '100%'
      // }
    }
  },

  computed: {
    ...mapState('main', [
      'locale',
      'application'
    ]),

    ...mapState('select_items', [
      'categoryItemSelected',
      'categoryTypeSelected',
      'itemList'
    ]),

    orderedCategoryTypeItems () {
      return _.orderBy(this.categoryTypeItems, 'my_order')
    },

    categoryTypeSelectedBtnId: {
      get () {
        return this.$store.state.select_items.categoryTypeSelectedBtnId
      },
      set (btnId) {
        this.SET_CATEGORY_TYPE_SELECTED_BTN_ID({
          btnId
        })
      }
    },

    categoryItemsFiltered () {
      return _.sortBy(this.categoryItems.filter(item => item.type.my_order === this.categoryItemSelectedId), ['my_order'])
    },

    selectItemText () {
      return `title_${this.locale}`
    }
  },

  watch: {
    categoryListItemMobileSelected (newVal, oldVal) {
      this.categoryListItemSelected = this.categoryItemsFiltered.indexOf(newVal)
    },

    categoryListItemSelected (newVal, oldVal) {
      this.categoryListItemMobileSelected = this.categoryItemsFiltered[newVal]
    }
  },

  async mounted () {
    // API call for retrieving all published and to the application assigned (show_in_application) items
    if (this.itemList.length === 0) {
      await this.get_item_list({
        application: this.application
      })
    }

    // Collect all "categories" arrays from items of itemList
    const itemListCategories = this.itemList.flatMap(item => item.categories)
    // Get distinct list of CategoryType items
    this.categoryTypeItems = itemListCategories.filter((value, index, self) => self.findIndex(value2 => (value2.type.id === value.type.id)) === index).map(item => item.type)

    // REVIEW Sort category items by order in django
    this.categoryTypeItems.sort((a, b) => a.my_order - b.my_order)

    // Get list of Category value items
    this.categoryItems = itemListCategories.map(item => item.value).filter((value, index, self) =>
      index === self.findIndex(t => (
        t.type.id === value.type.id && t.id === value.id
      ))
    )

    if (this.categoryTypeSelected) {
      // Reset previous selected category type
      this.selectCategoryType({
        categoryType: this.categoryTypeSelected
      })
    } else {
      // Set init selected category type (-> first element in sorted category type list)
      this.set_category_type_selected({
        categoryItem: this.categoryTypeItems[0]
      })
    }

    // Reselect category item if it was saved to the state
    const stateCategoryItem = this.categoryItemSelected

    if (stateCategoryItem !== undefined) {
      this.selectCategoryItem({
        categoryItem: stateCategoryItem,
        mode: 'reload'
      })

      this.categoryListItemSelected = this.categoryItemsFiltered.indexOf(stateCategoryItem)

      this.categoryListItemMobileSelected = this.categoryItemsFiltered[stateCategoryItem]
    }
  },

  methods: {
    ...mapActions('select_items', [
      'get_item_list',
      'set_category_type_selected'
    ]),

    ...mapMutations('select_items', [
      'SET_CATEGORY_TYPE_SELECTED_BTN_ID'
    ]),

    selectCategoryType ({ categoryType }) {
      // Reset state of selected categoryItem
      this.isCategoryItemSeleced = false
      this.categoryListItemSelected = undefined

      this.set_category_type_selected({
        categoryItem: categoryType
      })

      this.categoryItemSelectedId = categoryType.my_order

      eventBus.$emit('filter-items-list', {
        categoryItem: undefined,
        mode: ''
      })

      eventBus.$emit('resize-select-main-item-list')
    },

    selectCategoryItem ({
      categoryItem,
      mode
    }) {
      eventBus.$emit('filter-items-list', {
        categoryItem,
        mode
      })

      if (this.categoryItemSelected !== null) {
        this.isCategoryItemSeleced = true
      } else {
        this.isCategoryItemSeleced = false
      }

      eventBus.$emit('resize-select-main-item-list')
    }
  }
}
</script>

<style lang="scss">
.v-tab {
  border-bottom: var(--v-primary-base) 1.5px solid;
}

.word-break {
  word-break: break-word;
}

.overflow-y-scroll {
  overflow-y: scroll;
  transition: background-color 0.8s;
}

.category-select {
  .v-text-field {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }

  .v-text-field__details {
    display: none !important;
  }
}

button {
  // background-color: var(--button-background-color);
  opacity: var(--button-background-opacity);
}

// .v-btn::before{
//   // background: none !important;
//   // opacity: 0.2 !important;
// }

// .v-btn--active {
//   border-bottom: 2px solid var(--v-primary-base) !important;
//   border-radius: 0 !important;
// }

.list_item {
  margin: 0 8px;
  &:first-child {
    margin: 0 8px 0 0;
  }

  &:last-child {
    margin: 0 0 0 8px;
  }

  .v-ripple__container {
    border-radius: 5px;
  }
}

.v-list-item:hover::before {
    border-radius: 5px;
}

#select_main_category {
  .theme--light.v-list-item--active:before,
  .theme--light.v-list-item--active:hover:before,
  .theme--light.v-list-item:focus:before {
    opacity: 0;
  }

  .theme--light.v-btn--active:hover::before,
  .theme--light.v-btn--active::before {
    opacity: 0 !important;
  }
}

.category_active > div {
  -webkit-box-shadow: inset 0px 0px 0px 2px var(--v-primary-base);
  -moz-box-shadow: inset 0px 0px 0px 2px var(--v-primary-base);
  box-shadow: inset 0px 0px 0px 2px var(--v-primary-base);
}

.category-item-not-active {
  &.list_item:not(.category_active) {
    background: var(--v-backgroundColor-base);
  }
}

.category-text-area::before {
    opacity: 0.12;
    background-color: var(--v-primary-base);
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
