<template>
  <div
    class="d-flex flex-column px-3 py-1"
    style="background: rgb(225, 232, 238);"
  >
    <v-row
      no-gutters
      justify="space-between"
    >
      <v-col
        class="flex-grow-0"
      >
        <div
          v-if="idCountLocal > 1 || idCountLocal === 0"
        >
          <v-select
            v-model="timeFormatSelected"
            :items="timeFormatItems"
            :disabled="isMenuDisabled || sliderYearMax - sliderYearMin === 0 || idCountLocal === 0"
            return-object
            :item-text="`title_${locale}`"
            class="webgis-select webgis-select-timeformat"
            @change="setTimeFormat({value: itemYearSelected, format: timeFormatSelected})"
          >
            <template
              #selection="data"
            >
              <span
                v-if="locale"
                :style="idCountLocal === 0 ? {'color': 'rgba(0, 0, 0, 0.38)'}: {}"
                class="text-body-1"
              >
                {{ data.item[`title_${locale}`] }}
              </span>
            </template>

            <template
              #item="data"
            >
              <span
                v-if="locale"
                class="text-body-1"
              >
                {{ data.item[`title_${locale}`] }}
              </span>
            </template>
          </v-select>
        </div>

        <div
          v-else-if="idCountLocal === 1"
          class="text-body-1"
        >
          <span
            v-if="locale === 'de'"
          >
            Inbetriebnahme:
          </span>
          <span
            v-else
          >
            Date commissioning:
          </span>
        </div>
      </v-col>

      <v-col
        v-if="timeFormatSelected !== null"
        cols="auto"
        class="d-flex justify-space-between align-center offset-1"
      >
        <div
          v-if="sliderYearMax - sliderYearMin !== 0 && idCountLocal !== 0"
        >
          <span
            v-if="timeFormatSelected.id === 1"
            class="text-body-1"
          >
            <span>
              <!-- ({{ sliderYearMin }} -->
              {{ sliderYearMin }}
            </span>
            <span>
              <!-- &nbsp;-&nbsp;)&nbsp; -->
              &nbsp;-&nbsp;
            </span>
          </span>

          <span
            class="text-body-1 font-weight-medium"
          >
            {{ itemYearSelected }}
          </span>
        </div>
        <div
          v-else-if="idCountLocal !== 0"
          class="text-body-1 font-weight-medium"
        >
          <span>
            {{ sliderYearMin }}
          </span>
        </div>

        <!-- <div
          v-else
        >
          <span
            class="text-body-1 font-weight-medium"
          >
            -
          </span>
        </div> -->
      </v-col>
    </v-row>

    <div>
      <v-slider
        v-model="itemYearSelected"
        :disabled="isMenuDisabled || sliderYearMax - sliderYearMin === 0 || idCountLocal === 0"
        :min="sliderYearMin"
        :max="sliderYearMax"
        :thumb-label="true"
        ticks="always"
        tick-size="2"
        hide-details
        step="1"
        class="align-center"
        @change="setTimeYear({value: itemYearSelected, format: timeFormatSelected})"
      />
    </div>

    <!-- TIME - YEAR RANGE -->

    <!-- <v-range-slider
                  v-model="itemYearRangeValue"
                  :min="itemYearRange.yearStart"
                  :max="itemYearRange.yearEnd"
                  hide-details
                  step="1"
                  class="align-center"
                  @change="setYearRange"
                >
                  <template
                    #prepend
                  >
                    <div>
                      {{ itemYearRangeValue[0].toLocaleString(locale) }}
                    </div>
                  </template>
                  <template
                    #append
                  >
                    <div>
                      {{ itemYearRangeValue[1].toLocaleString(locale) }}
                    </div>
                  </template>
                </v-range-slider> -->
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SliderTime',

  data () {
    return {
      idCountLocal: 0,
      itemYearRangeValue: [this.sliderYearMin, this.sliderYearMax],
      itemYearSelected: 2022,
      sliderYearMax: 2022,
      sliderYearMin: 1990,
      timeFormatItems: [{
        id: 1,
        title_de: 'Zubau (kumuliert)',
        title_en: 'Expansion (accumulated)',
        value: '<='
      }, {
        id: 2,
        title_de: 'Zubau (pro Jahr)',
        title_en: 'Expansion (per year)',
        value: '='
      }],
      timeFormatSelected: null,
      timeFormatSelectedOld: null,
      yearCommissioningOld: null
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'itemDataQuery',
      'isMenuDisabled',
      'layerQueryValuesMinMax',
      'layerStatisticValues'
    ])
  },

  watch: {
    'layerQueryValuesMinMax.id_count': {
      handler (newVal, oldVal) {
        if (newVal !== null) {
          this.idCountLocal = newVal
        }
      },
      immediate: true
    },

    'layerQueryValuesMinMax.year_commissioning_min': {
      handler (newVal, oldVal) {
        if (newVal !== null) {
          this.sliderYearMin = newVal
        }
      },
      immediate: true
    },

    'layerQueryValuesMinMax.year_commissioning_max': {
      handler (newVal, oldVal) {
        if (newVal !== null) {
          this.sliderYearMax = newVal
          this.itemYearSelected = newVal
          this.yearCommissioningOld = newVal
        }
      },
      immediate: true
    }

    // layerQueryValuesMinMax: {
    //   handler (newVal) {
    //     if (newVal !== null && newVal !== undefined) {
    //       this.sliderYearMin = newVal.year_commissioning_min
    //       this.sliderYearMax = newVal.year_commissioning_max
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // }
    // 'layerQueryValuesMinMax.year_commissioning_min': {
    //   handler (newVal, oldVal) {
    //     if (newVal !== null) {
    //       this.sliderYearMax = newVal
    //     }
    //   },
    //   immediate: true
    // }
    // itemYearRange (newVal, oldVal) {
    //   if (newVal !== undefined) {
    //     this.itemYearRangeValue = [newVal.yearStart, newVal.yearEnd]
    //     console.log('itemYearRangeValue newVal ', JSON.stringify(newVal))
    //   }
    // },
  },

  updated () {
    if (this.itemDataQuery.year_commissioning !== null && this.yearCommissioningOld === null) {
      this.yearCommissioningOld = this.itemDataQuery.year_commissioning
    }
  },

  mounted () {
    this.timeFormatSelected = this.timeFormatItems.filter(item => item.id === 1)[0]
  },

  methods: {
    ...mapActions('webgis', [
      'set_menu_disabled',
      'update_data_query'
    ]),

    setTimeYear ({ value, format }) {
      // console.log('setTimeYear')

      if (this.yearCommissioningOld !== value) {
        this.set_menu_disabled(true)

        this.update_data_query({
          query: {
            year_commissioning: value
          }
        })

        eventBus.$emit('webgis-menu-slider-year-commissioning-update', this.itemDataQuery)

        this.timeFormatSelectedOld = format.value
        this.yearCommissioningOld = value
      }
    },

    setTimeFormat ({ value, format }) {
      // console.log('setTimeFormat')

      if (this.timeFormatSelectedOld !== format.value) {
        // this.itemYearSelected = this.itemDataQuery.year_commissioning

        this.set_menu_disabled(true)

        this.update_data_query({
          query: {
            time_format_operand: format.value,
            year_commissioning: this.itemYearSelected
          }
        })

        eventBus.$emit('webgis-menu-slider-year-commissioning-update', this.itemDataQuery)

        this.timeFormatSelectedOld = format.value
        this.yearCommissioningOld = value
      }
    }

    // setYearRange (value) {
    //   this.update_data_query({
    //     query: {
    //       year_range: value
    //     }
    //   })
    //   eventBus.$emit('webgis-menu-slider-range-year-update', this.itemDataQuery)
    // },
  }

}
</script>

<style lang="scss">

.webgis-select-timeformat {
  .v-select__selections {
    white-space: nowrap;
  }

  &.v-input {
    &.v-text-field {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
</style>
