<template>
  <div>
    <transition
      name="fade"
    >
      <v-card
        v-if="isAlertOpen"
        elevation="0"
        rounded="0"
        style="border: 1px dotted var(--v-secondary-lighten1); padding: 0 6px; background: rgb(225, 232, 238);"
        class="px-6 py-3"
      >
        <div
          class="text-h5 mb-1"
        >
          <span
            v-if="locale === 'de'"
          >
            Einstellungen zum Datenschutz
          </span>

          <span
            v-else
          >
            Privacy Settings
          </span>
        </div>

        <div
          class="text-body-1 mb-3"
        >
          <span
            v-if="locale === 'de'"
          >
            Wir verwenden Cookies, die für die grundlegende Funktionalität unserer Webseite erforderlich sind, damit diese stetig für Sie optimiert und deren Nutzerfreundlichkeit verbessert werden kann. Darüber hinaus setzen wir das Webanalysetool Matomo ein, welches Daten anonymisiert trackt. Dadurch ist es uns möglich die Nutzung unserer Webseite statistisch auswerten. Ihre Einwilligung zur Nutzung von Matomo kann jederzeit über die <router-link
              v-slot="{ navigate }"
              custom
              :to="{name: 'PrivacyPolicy'}"
            >
              <span
                class="font-weight-medium"
                style="text-decoration: underline; cursor: pointer;"
                @click="navigate"
              >Datenschutzerklärung</span></router-link> widerrufen werden.
          </span>

          <span
            v-else
          >
            We use cookies, which are necessary for the basic functionality of our website, so that it can be continuously optimised for you and its user-friendliness improved. In addition, we use the web analysis tool Matomo, which tracks data anonymously. This enables us to statistically evaluate the use of our website. Your consent to the use of Matomo can be revoked at any time via the <router-link
              v-slot="{ navigate }"
              custom
              :to="{name: 'PrivacyPolicy'}"
            >
              <span
                class="font-weight-medium"
                style="text-decoration: underline; cursor: pointer;"
                @click="navigate"
              >privacy policy</span></router-link>.
          </span>
        </div>

        <div
          class="d-flex justify-start"
        >
          <v-btn
            elevation="0"
            color="success"
            style="border: 1px dotted var(--v-secondary-lighten1); padding: 0 6px;"
            class="mr-6"
            @click="allowCookies(true)"
          >
            <span
              v-if="locale === 'de'"
            >
              Zustimmen
            </span>

            <span
              v-else
            >
              Accept
            </span>
          </v-btn>

          <v-btn
            outlined
            text
            elevation="0"
            color="var(--v-primary-base)"
            style="border: 1px dotted var(--v-secondary-lighten1); padding: 0 6px;"
            @click="allowCookies(false)"
          >
            <span
              v-if="locale === 'de'"
            >
              Nicht zustimmen
            </span>

            <span
              v-else
            >
              Refuse
            </span>
          </v-btn>
        </div>
      </v-card>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PrivacyPolicyConsent',

  data () {
    return {
      isAlertOpen: false
    }
  },

  computed: {
    ...mapState('main', [
      'isPrivacyConsentOpen',
      'locale'
    ])
  },

  async mounted () {
    setTimeout(() => {
      if (this.$matomo !== undefined) {
        if (this.$matomo.hasConsent() === false) {
          // console.log('this.$matomo ', this.$matomo)
        // console.log('this.$matomo.isUserOptedOut() ', this.$matomo.isUserOptedOut())
        // console.log('this.$matomo.hasConsent() ', this.$matomo.hasConsent())

          // if (!this.$matomo.isUserOptedOut()) {
          this.isAlertOpen = true
        // }
        }
      }
    }, 3000)
  },

  methods: {
    ...mapActions('main', [
      'set_privacy_consent'
    ]),

    allowCookies (bool) {
      if (this.$matomo !== undefined) {
        if (bool) {
          this.$matomo.rememberConsentGiven(720)
          this.$matomo.rememberCookieConsentGiven()
        } else {
          this.$matomo.optUserOut()
        }

        this.set_privacy_consent(true)

        this.isAlertOpen = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
