<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="200"
    close-delay="200"
    :disabled="$vuetify.breakpoint.xsOnly"
  >
    <v-btn
      class="btn_menu pl-0"
      depressed
      elevation0
      color="white"
      plain
      elevation="0"
      @click="goBack()"
    >
      <v-icon
        size="20"
        class="mr-2"
      >
        {{ mdiArrowLeft }}
      </v-icon>

      <span
        v-if="hover === false"
      >
        <span
          v-if="$route.params.fromRoute"
        >
          <span
            v-if="locale === 'de'"
          >
            Zurück
          </span>

          <span
            v-else
          >
            Back
          </span>
        </span>

        <span
          v-else
        >
          <span
            v-if="locale === 'de'"
          >
            Kennzahlenauswahl
          </span>

          <span
            v-else
          >
            Indicators
          </span>

        </span>
      </span>

      <v-expand-x-transition>
        <span
          v-if="hover"
        >
          <span
            v-if="$route.params.fromRoute"
          >
            <span
              v-if="locale === 'de'"
            >
              Zurück ({{ $route.params.fromRoute.meta[`title_${locale}`] }})
            </span>

            <span
              v-else
            >
              Back ({{ $route.params.fromRoute.meta[`title_${locale}`] }})
            </span>
          </span>

          <span
            v-else
          >
            <span
              v-if="locale === 'de'"
            >
              Zurück zur Kennzahlenauswahl
            </span>

            <span
              v-else
            >
              Back to indicator selection
            </span>
            <!-- <span
              v-if="locale === 'de'"
            >
              Zur Startseite
            </span>

            <span
              v-else
            >
              To the hompage
            </span> -->
          </span>
        </span>
      </v-expand-x-transition>
    </v-btn>
  </v-hover>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'BtnDashboardBack',

  data () {
    return {
      mdiArrowLeft: mdiArrowLeft
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),
    mailtoTitle () {
      return this.locale === 'de' ? 'Anfrage' : 'Request'
    }
  },

  mounted () {
  },

  methods: {
    goBack () {
      if (this.$route.params.fromRoute) {
        this.$router.go(-1)
      } else {
        this.$router.push({
          // name: 'EEMonitorHome'
          name: 'SelectMain'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
