<template>
  <div
    class="fill-height"
  >
    <v-card
      ref="card_map"
      flat
      class="d-flex flex-column fill-height"
      rounded="0"
      style="background: none;"
    >
      <div
        class="d-flex flex-shrink-1 order-0"
      >
        <v-card
          ref="card_map_menu"
          flat
          rounded="0"
          class="d-flex px-6"
          style="background: #e1e8ee; width: 100%; padding-top: 2px; height: 30px;"
        >
          <DashboardItemMenu
            v-if="bkgItems.length !== 0"
            :select-query="['data_statistic', 'spatial_unit_2', 'spatial_unit_4']"
            :select-additional-values="additionalValues(['spatial_unit_2', 'spatial_unit_4'])"
            @isUpdated="isMenuUpdated = true"
          />
        </v-card>
      </div>

      <div
        class="d-flex flex-column fill-height px-3 pt-3"
      >
        <div
          class="d-flex flex-column fill-height"
        >
          <div
            class="flex-grow-1 order-1"
          >
            <v-card
              ref="sheet_map_wrapper"
              rounded="0"
              text
              elevation="0"
              class="d-flex fill-height pa-0 ma-0"
            >
              <v-overlay
                opacity="0"
                color="#fff"
                absolute
                style="pointer-events: none; z-index: 2;"
              >
                <div
                  class="d-flex flex-row align-start justify-start fill-height font-weight-light text-md-h4 text-lg-h3  ma-lg-5 ma-md-3"
                  style="color: var(--v-primary-base)"
                >
                  {{ dashboardItemsYearSelected }}
                </div>
              </v-overlay>

              <VChart
                :id="`chart_map`"
                ref="chart_map"
                :autoresize="true"
              />
            </v-card>
          </div>
        </div>

        <div
          v-if="itemDataYears.length > 1"
          class="flex-shrink-1 order-0"
        >
          <v-card
            ref="card_map_timeslider"
            flat
            rounded="0"
            class="mb-3 pa-1"
          >
            <TimeSlider
              :years="itemDataYears"
            />
          </v-card>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>

import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

// AXIOS
import axios from 'axios'

// LODASH
import _ from 'lodash'

// MAP GEOJSON
import { geojsonLayerItems } from '@/assets/webgis/layeritems/geojsonlayeritems.js'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

// MAP PROJECTION for ECHARTS
import { geoMercator } from 'd3-geo'

// COMPONENTS
import DashboardItemMenu from '@/components/dashboard/inputs/DashboardItemMenu.vue'
import TimeSlider from '@/components/dashboard/slider/TimeSlider.vue'

import {
  SVGRenderer
} from 'echarts/renderers'

import {
  MapChart
} from 'echarts/charts'

import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
  ToolboxComponent
} from 'echarts/components'

use([
  SVGRenderer,
  MapChart,
  VisualMapComponent,
  TitleComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent
])

export default {
  name: 'ChartMapBoxplot',

  components: {
    DashboardItemMenu,
    TimeSlider,
    VChart
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'svg'
    }
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default: 0
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    spatialTypeId: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      agsIdSelected: undefined,
      dataStatisticObj: {},
      dataStatisticId: null,
      statisticValueTitle: '',
      dataValueUnit: '',
      echartsOption: {
        bottom: 'auto',
        series: [],
        title: {},
        toolbox: {},
        tooltip: {},
        top: 'auto',
        visualMap: {}
      },
      isMenuUpdated: false,
      itemDataFiltered: [],
      itemDataFilteredDataStatistic: [],
      itemDataYears: [],
      mapProjection: undefined,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      regionsAgsSelected: [],
      showLoadingIndicator: false,
      valueMax: 0
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems',
      'echartsDefaults'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataQuery',
      'dashboardItemsDataYears',
      'dashboardItemsSelectsBasic',
      'dashboardItemsYearSelected'
    ])
  },

  watch: {
    locale (newVal, oldVal) {
      this.setVisualmapText({ valueMax: this.valueMax }).then((text) => {
        this.echartsOption.visualMap.text = text
        this.$refs.chart_map.setOption(this.echartsOption)
      })
    },

    isMenuUpdated (newVal, oldVal) {
      if (newVal === true) {
        this.$refs.chart_map.resize()
        this.isMenuUpdated = false
      }
    },

    height (newVal, oldVal) {
      // Resize map canvas if height provided by props changes

      this.$refs.chart_map.resize()
    },

    itemData () {
      this.updateMapChart(this.agsIdSelected)
    }
  },

  async mounted () {
    // Set echarts defauls from store
    this.echartsOption.series = this.echartsDefaults.map.series
    // this.echartsOption.textStyle = this.echartsDefaults.textStyle
    this.echartsOption.tooltip = this.echartsDefaults.map.tooltip
    this.echartsOption.tooltip.textStyle = this.echartsDefaults.textStyle
    this.echartsOption.visualMap = this.echartsDefaults.map.visualMap

    // Set initial data_statistic value by default value
    this.dataStatisticObj = await this.getItemDataStatisticValue({ item: this.dashboardItem })
    this.dataStatisticId = this.dataStatisticObj.value_default.value_id

    // Set initial data_statistic title by default value
    this.statisticValueTitle = await this.getItemDataStatisticTitle()

    // Get dataset distinct years
    this.itemDataYears = this.dashboardItemsDataYears

    // Get value unit title_short (string)
    const dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.dashboardItem
    })
    this.dataValueUnit = dataValueUnitObj.title_short_de

    // Get mercator map projection
    this.mapProjection = await geoMercator()

    // Set maximum amount of spatial_unit values
    const dataOptionSpatialType = this.dashboardItemsSelectsBasic.filter((opt) => opt.type.query_key === 'spatial_type')[0]
    const lastSpatialTypeId = dataOptionSpatialType.values.at(-1).value_id
    this.regionsAgsSelected = [...Array(lastSpatialTypeId)]

    // Set map projection (mercator)
    this.echartsOption.series[0].projection = {
      project: (point) => this.mapProjection(point),
      unproject: (point) => this.mapProjection.invert(point)
    }

    //
    //  Event Listener ECHARTS
    //

    this.$refs.chart_map.chart.on('click', async (params) => {
      const selectedRegion = this.bkgItems.filter(item => item.ags === params.name)
      const spatialUnitId = selectedRegion[0].ade

      const filteredData = this.itemDataFilteredDataStatistic.filter(ds => ds.ags === params.name)
      const spatialType = filteredData[0].spatial_type

      if (this.agsIdSelected !== params.name) {
        eventBus.$emit(`set-select-spatial_unit_${spatialType}`, params.name)

        this.agsIdSelected = params.name
        this.regionsAgsSelected[spatialUnitId - 1] = params.name
      } else {
        eventBus.$emit(`reset-select-spatial_unit_${spatialType}`, {
          update: true
        })

        this.agsIdSelected = undefined
        this.regionsAgsSelected[spatialUnitId - 1] = undefined
      }
    })

    //
    //  Event Listener VUE
    //

    eventBus.$on('reset-region-selected', ({
      event,
      selectValue
    }) => {
      this.$refs.chart_map.chart.dispatchAction({
        type: 'unselect',
        name: this.agsIdSelected
      })

      this.agsIdSelected = undefined

      this.regionsAgsSelected.forEach((region) => { return undefined })
    })

    eventBus.$on('loading-indicator-echart-component', () => {
      if (this.$refs.chart_map) {
        this.$refs.chart_map.chart.showLoading()
      }

      this.showLoadingIndicator = true
    })

    eventBus.$on('resize-echarts-map', () => {
      this.$refs.chart_map.chart.resize()
    })

    eventBus.$on('update-echarts-map-boxplot', async ({
      event,
      selectValue
    }) => {
      this.dataStatisticId = event.value_id

      // Set data_statistic title by default value
      this.statisticValueTitle = await this.getItemDataStatisticTitle()

      this.itemDataFilteredDataStatistic = this.itemDataFiltered.filter((ds) => {
        return ds.data_statistic === this.dataStatisticId &&
         ds.spatial_type !== 1
      })

      // Set number of min & max digits for the number of value descendants
      await this.setMaxFractionDigits({ data: this.itemDataFilteredDataStatistic })

      // Set min & max values
      this.valueMax = await this.get_datasets_value_max(this.itemDataFilteredDataStatistic)
      this.echartsOption.visualMap.max = this.valueMax
      this.echartsOption.visualMap.text = await this.setVisualmapText({ valueMax: this.valueMax })

      this.echartsOption.series[0].data = this.updateMapChartByYear({
        year: this.itemDataYears[this.itemDataYears.length - 1],
        data: this.itemDataFilteredDataStatistic
      })

      this.$refs.chart_map.setOption(this.echartsOption)
    })

    // TODO use general update event or by checking itemData.length
    eventBus.$on('update-echarts-map-component', async ({
      year
    }) => {
      this.itemDataFilteredDataStatistic = this.itemDataFiltered.filter((ds) => {
        return ds.data_statistic === this.dataStatisticId &&
         ds.spatial_type !== 1
      })

      this.echartsOption.series[0].data = this.updateMapChartByYear({
        year,
        data: this.itemDataFilteredDataStatistic
      })

      this.$refs.chart_map.setOption(this.echartsOption)
    })

    eventBus.$on('update-boxplot-map-component', async ({
      year
    }) => {
      this.itemDataFilteredDataStatistic = this.itemDataFiltered.filter((ds) => {
        return ds.data_statistic === this.dataStatisticId &&
         ds.spatial_type !== 1
      })

      this.echartsOption.series[0].data = this.updateMapChartByYear({
        year,
        data: this.itemDataFilteredDataStatistic
      })

      this.$refs.chart_map.setOption(this.echartsOption)
    })

    let stateAgsSelected

    eventBus.$on('update-echarts-spatial-unit',
      async ({
        event,
        selectValue
      }) => {
        const spatialUnitId = selectValue.type.query_key.slice(-1)

        this.regionsAgsSelected = this.regionsAgsSelected.map((
          region,
          idx
        ) => {
          if (idx + 1 > spatialUnitId) {
            return undefined
          } else {
            return region
          }
        })

        this.regionsAgsSelected.forEach((regionAgs) => {
          this.$refs.chart_map.chart.dispatchAction({
            type: 'unselect',
            name: regionAgs
          })
        })

        this.$refs.chart_map.chart.dispatchAction({
          type: 'unselect',
          name: stateAgsSelected
        })

        if (event !== null) {
          this.agsIdSelected = event.ags
          stateAgsSelected = event.ags

          this.regionsAgsSelected[spatialUnitId - 1] = event.ags

          if (event.ade >= this.spatialTypeId) {
            this.$refs.chart_map.chart.dispatchAction({
              type: 'select',
              name: this.agsIdSelected
            })

            stateAgsSelected = this.agsIdSelected

            this.updateChartBoxplot(this.agsIdSelected)
          } else if (this.spatialTypeId > event.ade) {
            this.updateMapChart(this.agsIdSelected)
          }
        } else {
          this.regionsAgsSelected[spatialUnitId - 1] = undefined

          if (this.spatialTypeId === 2) {
            this.agsIdSelected = undefined

            stateAgsSelected = undefined
          }

          this.updateChartBoxplot(this.agsIdSelected)

          if (selectValue.type.query_key.slice(-1) < this.spatialTypeId) {
            this.updateMapChart()
          }
        }
      }
    )

    await this.updateMapChart()
  },

  beforeDestroy () {
    this.$refs.chart_map.chart.dispose()
    this.$refs.chart_map.chart = null

    eventBus.$off('loading-indicator-echart-component')
    eventBus.$off('reset-region-selected')
    eventBus.$off('resize-echarts-map')
    eventBus.$off('update-echarts-map-boxplot')
    eventBus.$off('update-echarts-map-component')
    eventBus.$off('update-echarts-spatial-unit')
    eventBus.$off('update-boxplot-map-component')
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_ags_gen_title'
    ]),

    ...mapActions('dashboard_items', [
      'get_max_fraction_digits',
      'get_item_data_value_unit',
      'get_datasets_value_min',
      'get_datasets_value_max'
    ]),

    ...mapMutations('dashboard_items', [
      'SET_ITEM_YEAR_SELECTED'
    ]),

    async getItemDataStatisticValue ({ item }) {
      const valueUnitObj = item.data_options.filter((opt) => {
        return opt.type.query_key === 'data_statistic'
      })[0]

      return valueUnitObj
    },

    async getItemDataStatisticTitle () {
      const statisticValueObj = this.dataStatisticObj.values.filter(value => value.value_id === this.dataStatisticId)
      return statisticValueObj[0][`title_${this.locale}`]
    },

    additionalValues (valueQueryKeys) {
      const addionalValuesNumbers = valueQueryKeys.map((qk) => {
        return qk.substring(qk.length - 1)
      })

      const selects = valueQueryKeys.map((qk, idx, arr) => {
        let bkgValues

        const spatialTypeId = qk.substring(qk.length - 1)

        if (idx === 0) {
          bkgValues = this.bkgItems.filter((item) => {
            return item.ade === Number(spatialTypeId)
          })
        } else {
          const previousOption = this.dashboardItemsDataQuery.filter((dq) => {
            return Object.keys(dq).some((k) => {
              return ~k.indexOf(addionalValuesNumbers[idx - 1])
            })
          })

          if (previousOption.length !== 0) {
            const previousOptionValue = previousOption[0][Object.keys(previousOption[0])[0]]

            if (previousOptionValue === undefined || previousOptionValue === null) {
              bkgValues = this.bkgItems.filter(item =>
                item.ade === Number(spatialTypeId)
              )
            } else {
              bkgValues = this.bkgItems.filter(item =>
                item.ade === Number(spatialTypeId) &&
            item.ags.startsWith(previousOptionValue)
              )
            }
          }
        }

        return {
          queryKey: qk,
          values: bkgValues
        }
      })

      return selects
    },

    updateMapChartByYear ({ year, data }) {
      const datasetsPerYear = data.filter((ds) => {
        return ds.data_year === year
      })

      return datasetsPerYear.map((ds) => {
        return {
          name: ds.ags,
          value: ds.data_value
        }
      })
    },

    // TODO to store action
    getAgsGenTitle ({ ags, bkgItems }) {
      const bkgItem = bkgItems.filter(bkgItem => bkgItem.ags === ags)[0]

      if (bkgItem.nbd === false) {
        return bkgItem.gen
      } else {
        if (bkgItem.ade !== 2) {
          return `${bkgItem.bez} ${bkgItem.gen}`
        } else {
          return `${bkgItem.gen}`
        }
      }
    },

    // TODO to store action
    async getGeojsonLayerFileName  ({
      year,
      spatialTypeId,
      geojsonLayerItems
    }) {
      const filterProps = {
        year,
        spatialTypeId
      }

      const geoJsonFileNameFiltered = geojsonLayerItems.filter((item) => {
        for (const key in filterProps) {
          if (
            item[key] === undefined ||
          item[key] !== filterProps[key]
          ) {
            return false
          }
        }
        return true
      })

      return await geoJsonFileNameFiltered[0].filename
    },

    async setVisualmapText ({ valueMax }) {
      let value
      if (!this.dataValueUnit.match(/^n$/)) {
        value = valueMax.toFixed(2)
      } else {
        value = valueMax
      }
      return [
        `${value.toLocaleString(this.locale)} ${this.dataValueUnit}`,
         `0 ${this.dataValueUnit}`
      ]
      // return [
      //   `${valueMax.toLocaleString(this.locale, {
      //       minimumFractionDigits: this.minimumFractionDigits,
      //       maximumFractionDigits: this.maximumFractionDigits
      //     })} ${this.dataValueUnit}`,
      //    `0 ${this.dataValueUnit}`
      // ]
    },

    async setMaxFractionDigits ({ data }) {
      if (!this.dataValueUnit.match(/^n$/)) {
        const minValue = await this.get_datasets_value_min(data)

        this.maximumFractionDigits = await this.get_max_fraction_digits({ minValue })
      }
    },

    async updateMapChart (agsId) {
      const itemDataCloned = _.cloneDeep(this.itemData)

      // this.$refs.chart_map.chart.showLoading()
      this.showLoadingIndicator = true

      // Set item data filtered by selected spatial type
      // NOTE There is no filtering by spatial_type atm --> it was removed becuase the boxplot-layout was only for showing spatial_type = 2 (federal states)
      this.itemDataFiltered = itemDataCloned.filter((ds) => {
        return ds.spatial_type === this.spatialTypeId
      })

      // Filter GeoJson filename by year and spatial type (BKG -> ADE)
      const geoJsonFileName = await this.getGeojsonLayerFileName({
        year: 2016, // NOTE !!! ATM GeoJson file only available for the year 2016
        spatialTypeId: this.spatialTypeId,
        geojsonLayerItems
      })

      // TODO move to state and share it with the other map chart component
      // Base paths
      const geoJsonFileFolderPath = 'webgis/geojson/'
      const appRootFolder = process.env.VUE_APP_ROOT_FOLDER ? process.env.VUE_APP_ROOT_FOLDER : ''
      const appPort = process.env.VUE_APP_PORT ? process.env.VUE_APP_PORT : ''
      const baseURL = `${process.env.VUE_APP_HOST_URL}${appPort}/${appRootFolder}`

      // Set complete URL for GeoJson file
      const url = `${baseURL}${geoJsonFileFolderPath}${geoJsonFileName}`

      // Retrieve GeoJson file based on query params and register it to the echarts instance
      const { data } = await axios.get(url)

      // Add map with filtered GeoJsonData to the charts map
      echarts.registerMap('germany', data)

      const selectedYear = Math.max(...this.itemDataYears)
      this.SET_ITEM_YEAR_SELECTED({
        year: selectedYear
      })

      // Filter median (value_id = 3)
      this.itemDataFilteredDataStatistic = this.itemDataFiltered.filter((ds) => {
        return ds.data_statistic === this.dataStatisticId &&
         ds.spatial_type !== 1
      })

      this.echartsOption.series[0].data = this.updateMapChartByYear({
        year: this.itemDataYears[this.itemDataYears.length - 1],
        data: this.itemDataFilteredDataStatistic
      })

      // Set number of min & max digits for the number of value descendants
      await this.setMaxFractionDigits({ data: this.itemDataFilteredDataStatistic })

      // Set min & max values
      this.valueMax = await this.get_datasets_value_max(this.itemDataFilteredDataStatistic)
      this.echartsOption.visualMap.min = 0
      this.echartsOption.visualMap.max = this.valueMax

      // Set values and unit for visualmap min/max (NOTE: method used because value can not set by formatter function if locale is changed)
      this.echartsOption.visualMap.text = await this.setVisualmapText({ valueMax: this.valueMax })

      // Set tooltip formatte
      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        // NOTE formatter callback function doesn't work with async to satisfaction as aspected. That's why the local synchronous method for retrieving the ags gen title hast to be used instead of vuex action dashboard_main/get_ags_gen_title

        const bkgAgsTitle = this.getAgsGenTitle({
          ags: params.name,
          bkgItems: this.bkgItems
        })

        const title = `<div class="text-h6">
                      ${bkgAgsTitle} (${this.dashboardItemsYearSelected})
                      </div>`

        if (isNaN(params.value)) {
          return `${title}<div>${this.locale === 'de' ? 'kein Wert' : 'no value'}</div>`
        } else {
          return `${title}
          <div><table style="width: 100%; border-collapse: collapse;"><tbody>
            <tr style="padding:0; margin:0;">
              <td style="padding: 0 24px 0 0;">${this.statisticValueTitle}</td>
              <td style="text-align: right;">${params.value.toLocaleString(this.locale, {
                  minimumFractionDigits: this.minimumFractionDigits,
                  maximumFractionDigits: this.maximumFractionDigits
                })} ${this.dataValueUnit}</td>
            </tr>
          </tbody></table></div>`
        }
      }

      this.$refs.chart_map.chart.hideLoading()
      this.showLoadingIndicator = false

      this.$refs.chart_map.setOption(this.echartsOption)

      await this.updateChartBoxplot(agsId)
    },

    async updateChartBoxplot (agsId) {
      const spatialTypeSelected = 2

      eventBus.$emit('echarts-map-click-event', agsId)

      eventBus.$emit('load-echarts-boxplot-component', {
        spatialTypeSelected,
        agsIdSelected: agsId
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
