<template>
  <v-card
    id="chart1"
    ref="chart_card"
    elevation="0"
    rounded="0"
    class="d-flex flex-column fill-height"
    style="width: 100%;"
  >
    <VChart
      ref="chart_boxplot"
      style="width: 100%;"
      renderer="canvas"
      :autoresize="true"
    />
  </v-card>
</template>

<script>
import _ from 'lodash'

//  IMPORT
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BoxplotChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  BoxplotChart,
  GridComponent,
  TooltipComponent
])

export default {
  name: 'RegionReportChartsBoxplot',

  components: {
    VChart
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'canvas'
    }
  },

  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    itemDataValueMax: {
      type: Number,
      default: 0
    },
    dataValueUnitObj: {
      type: Object,
      default () {
        return {}
      }
    },
    maximumFractionDigits: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      itemDataFiltered: [],
      minimumFractionDigits: 0,
      dataFiltered: [],
      itemDataYears: [],
      isMouseOver: false,
      chartTitle: '',
      data: [],
      dataAgs: [],
      dataValueUnit: '',
      isValueNaN: false,
      showLegend: false,
      spatialTypeSelected: undefined,
      series: {
        data: [],
        itemStyle: {
          color: 'rgb(216,225,233)'
        },
        name: '',
        select: {
          disabled: true
        },
        type: 'boxplot'
      },
      echartsOption: {
        color: '#003e6e',
        grid: {
          bottom: '5%',
          containLabel: true,
          height: '95%',
          left: 'auto',
          right: '12px',
          top: '5%',
          width: 'auto'
        },
        itemStyle: {
          borderColor: 'rgb(0, 62, 110, 1)'
        },
        series: [],
        emphasis: {
          disabled: true
        },
        tooltip: {
          className: 'echarts-tooltip',
          alwaysShowContent: false,
          axisPointer: {
            type: 'line',
            snap: true
          },
          trigger: 'axis'
        },
        xAxis: {
          offset: 2,
          axisLabel: {},
          axisLine: {
            lineStyle: {
              color: '#003e6e'
            },
            show: true
          },
          axisPointer: {
            show: true,
            label: {
              show: false
            }
          },
          axisTick: {
            alignWithLabel: true,
            show: true
          },
          data: [],
          nameGap: 30,
          splitLine: {
            show: false
          },
          type: 'category'
        },
        yAxis: {
          axisLabel: {},
          nameGap: 0,
          splitLine: {
            show: true
          },
          type: 'value'
        }
      }
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'echartsDefaults'
    ])
  },

  watch: {
    locale (newVal, oldVal) {
      if (this.$refs.chart_boxplot !== undefined) {
        this.$refs.chart_boxplot.setOption(this.echartsOption)
      }
    }
  },

  async mounted () {
    if (this.$refs.chart_boxplot !== undefined) {
      // Set only the year of reporting
      this.itemDataYears = [1990, 1995, 2000, 2005, 2010, 2015, this.item.report_year]

      this.itemDataFiltered = this.itemData.filter(ds => this.itemDataYears.some(year => year === ds.data_year)) // this.item.report_year

      // Set echarts defaults from state
      this.echartsOption.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
      this.echartsOption.tooltip.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)

      this.dataValueUnit = this.dataValueUnitObj.title_short_de

      // Get highest value in dataset
      const valueMax = await this.get_datasets_value_max(this.itemDataFiltered)

      // Set min & max value of y-Axis
      this.echartsOption.yAxis.min = 0
      this.echartsOption.yAxis.max = valueMax

      this.echartsOption.yAxis.minInterval = valueMax / 4

      // Create series datasets
      const seriesDatasets = []
      for (const itemDataYear of this.itemDataYears) {
        const datasets = this.itemDataFiltered.filter((ds) => {
          return ds.data_year === itemDataYear
        })

        // Sort datasets ascending by foreign key of data_statistic
        // NOTE data_statistic values --> Minimum = 1 | Quartile 1 = 2 | Median = 3 | Quartile 3 = 4 | Maximum = 5 | Arithmetic mean = 6
        datasets.sort((a, b) => a.data_statistic - b.data_statistic)

        // Remove data_statistic "arithmetic mean" value (data_statistic === 6, index === 5 in dataset array)
        datasets.splice(5, 1)

        const dataValues = datasets.map((ds, idx, arr) => {
        // TODO number of digits should be set by lowest value in dataset
          if (ds.data_value === null) {
            return 0
          } else {
            return ds.data_value
          }
        })

        seriesDatasets.push(dataValues)
      }

      // NOTE Deep cloning is necessary for resolving inheritance issues
      const seriesObj = _.cloneDeep(this.series)

      // Set data array of data series
      seriesObj.data = seriesDatasets

      // Set created series data
      this.echartsOption.series = seriesObj

      // NOTE min/max digits set to 0 because yAxis was jumping left/right based on different fraction digits values
      this.echartsOption.yAxis.axisLabel.formatter = (value) => `${value.toLocaleString(this.locale, {
            minimumFractionDigits: 0, // this.minimumFractionDigits,
            maximumFractionDigits: 1 // this.maximumFractionDigits
          })}`

      const dataOptionsStatistic = this.item.data_options.filter((opt) => {
        return opt.type.query_key === 'data_statistic'
      })[0].values

      const dataOptionsStatisticSliced = dataOptionsStatistic.slice(0, 5).reverse()

      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        const year = params[0].name

        const statisticTable = dataOptionsStatisticSliced.map((opt, idx, arr) => {
          let textClass = 'text-body-1'
          if (idx === 2) {
            textClass = 'text-h6 font-weight-bold'
          }
          // NOTE data arr ["year-index", min, q1, median, q2, max]
          // Cut off index of category ("year" as index) and reverse (min -> max to max -> min)
          const paramsDataSliced = params[0].data.slice(1, 6).reverse()

          const value = paramsDataSliced[idx]
          const title = opt[`title_${this.locale}`]

          const htmlTableRowStatisticValue = `
                    <tr class="${textClass}" style="padding:0; margin:0;">
                      <td style="padding: 0 24px 0 0;">
                        ${title}
                      </td>
                      <td style="text-align: right;">
                        ${value.toLocaleString(this.locale, {
              minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })} ${this.dataValueUnit}
                      </td>
                    </tr>`

          return htmlTableRowStatisticValue
        }).join('')

        const html = `<div><table style="width: 100%; border-collapse: collapse;"><tbody>${statisticTable}</tbody></table></div><div class="text-center text-body-1 mt-1">(${year})</div>`

        return html
      }

      // X-Axis -> Set year as category
      this.echartsOption.xAxis.data = this.itemDataYears

      // Set options on the echarts instance
      if (this.$refs.chart_boxplot !== undefined) {
        this.$refs.chart_boxplot.setOption(this.echartsOption)
      }

      this.$refs.chart_boxplot.chart.hideLoading()
    }
  },

  beforeDestroy () {
    this.$refs.chart_boxplot.chart.dispose()
    this.$refs.chart_boxplot.chart = null
  },

  methods: {
    ...mapActions('dashboard_items', [
      'get_datasets_value_max'
    ])
  }
}
</script>

<style lang="scss">
.echarts-tooltip {
  left: 120px !important;
}
</style>
