<template>
  <div>
    <!-- <v-card
      v-if="Object.keys(item).length !== 0"
      flat
      rounded="6"
      class="px-6 py-3 ma-6 text-h4"
      :style="[basemapSelected.design === 'dark' ?
        {'background': 'rgba(0, 62, 110, 0.8)'} :
        {'background': 'rgba(255, 255, 255, 0.8)'}]"
      :class="[basemapSelected.design === 'dark' ? 'white--text' : 'primary--text']"
    > -->
    <div
      class="d-flex justify-center align-center"
      style="background: rgb(225, 232, 238); border-bottom: dotted 1px var(--v-secondary-lighten1);"
    >
      <span
        class="text-h5 font-weight-medium"
      >
        {{ itemSelected[`title_plural_${locale}`] }}
      </span>
    </div>
    <!-- </v-card> -->
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LayerTitleMenu',

  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'basemapSelected',
      'itemSelected'
    ])

    // itemTechnology () {
    //   return this.item.categories.filter(cat => cat.type.query_key === 'technology')[0].value
    // }
  }
}
</script>

<style lang="scss" scoped>

</style>
