<template>
  <v-row
    id="dashboard-header-content"
    class="px-3"
  >
    <!-- ITEM TECHNOLOGY ICON -->

    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      cols="auto"
      class="flex-grow-0 flex-shrink-0"
    >
      <v-row
        class=" flex-grow-0 flex-shrink-1 fill-height"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          class="d-flex felx-column justify-center text-center pa-0 pt-0 pa-1"
          style="border-right: 1px dotted var(--v-secondary-lighten1);"
        >
          <v-tooltip
            v-if="getItemTechnology !== null"
            bottom
          >
            <template
              #activator="{ on, attrs }"
            >
              <span
                class="text-h6"
                v-on="on"
              >
                <div>
                  <v-icon
                    class="icon-technology pr-3"
                    color="primary"
                    style="width: 100%; height: 100%; color: #003e6e;"
                    :style="[$vuetify.breakpoint.mdAndUp ? {'font-size': '3rem'} : {'font-size': '2.2rem'}]"
                  >
                    {{ `icon-technology-0` + getItemTechnology.value_id }}
                  </v-icon>
                </div>
              </span>
            </template>

            {{ getItemTechnology[`title_${locale}`] }}
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>

    <!-- ITEM TITLE & INPUTS -->
    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 8 : $vuetify.breakpoint.smOnly ? '' : 12"
      class="flex-grow-1 flex-shrink-1"
    >
      <v-row
        class="fill-height justify-start align-center"
        style="width: 100%;"
        no-gutters
      >
        <!-- style="height: auto; flex-basis: auto;" -->
        <!-- MAIN LAYOUT COLUMN -->

        <v-col
          class="d-flex flex-column justify-center align-start"
          cols="12"
        >
          <!-- ITEM TITLE -->

          <v-row
            class="ustify-space-between align-center"
            style="width: 100%;"
            :style="[$vuetify.breakpoint.smAndDown ? {'border-bottom': '1px dotted var(--v-secondary-lighten1)'} : {}]"
            :class="[$vuetify.breakpoint.xsOnly ? 'mb-3 pb-1' : '']"
            no-gutters
          >
            <v-col
              class="flex-grow-1 flex-shrink-0"
            >
              <span
                class="text-xl-h4 text-lg-h5 text-h6 font-weight-medium"
              >
                {{ dashboardItem[`title_${locale}`] }}
              </span>
            </v-col>

            <v-col
              v-if="$vuetify.breakpoint.smAndDown"
              class="ml-3 flex-grow-0 flex-shrink-1"
              style="max-width: 100%;"
            >
              <BtnOpenItemDescription
                :dashboard-item="dashboardItem"
                @open-dialog="openDialogItemDesc(dashboardItem)"
              />
            </v-col>
          </v-row>

          <!-- BUTTON MENU ROW -->

          <!-- <div
            class="d-flex flex-row flex-wrap justify-start align-center header-basic-menu mt-2"
            style="width: auto;"
          > -->
          <v-row
            no-gutters
            class="mt-2 mb-1"
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'flex-row'"
          >
            <!-- BUTTON GROUP DATA CATEGORY -->

            <v-col
              sm="12"
              md="auto"
              class="pa-0"
            >
              <DashboardItemMenu
                ref="btn_group_data_category"
                :select-query="['data_category']"
              />
            </v-col>

            <!-- DIVIDER VERTICAL DOTTED LINE -->

            <v-col
              v-if="btnGroupDataCategoryWidth > 30 && btnGroupSpatialTypeWidth > 30 && $vuetify.breakpoint.mdAndUp"
              md="auto"
            >
              <div
                class="align-self-stretch mr-5 ml-3"
                style="height: 100%; border-left: 1px dotted var(--v-secondary-lighten1);"
              />
            </v-col>

            <!-- BUTTON GROUP SPATIAL TYPE -->

            <v-col
              sm="12"
              md="auto"
              class="pa-0"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''"
            >
              <DashboardItemMenu
                ref="btn_group_spatial_type"
                :select-query="['spatial_type']"
                class="mt-sm-0"
              />
            </v-col>
          </v-row>

          <!-- </div> -->

          <!-- OPTIONAL SELECT TEXT FIELD on lower Screensize -->

          <OptionalSelectTextField
            v-if="$vuetify.breakpoint.mdAndDown &&
              showSelectOptional"
            class="d-flex mt-1"
            :dashboard-item="dashboardItem"
            style="max-width: 100%;"
          />
        </v-col>
      </v-row>
    </v-col>

    <!-- BUTTON ITEM DESCRIPTION -->

    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      :cols="$vuetify.breakpoint.smAndUp ? 'auto' : 3"
      class="d-flex justify-end flex-grow-1 flex-shrink-0"
    >
      <div
        class="d-flex flex-row justify-end align-center fill-height"
      >
        <BtnOpenItemDescription
          id="btn-item-description"
          :dashboard-item="dashboardItem"
          @open-dialog="openDialogItemDesc(dashboardItem)"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { eventBus } from '@/utils/eventbus.js'

import DashboardItemMenu from '@/components/dashboard/inputs/DashboardItemMenu.vue'
import OptionalSelectTextField from '@/components/dashboard/header/OptionalSelectTextField.vue'
import BtnOpenItemDescription from '@/components/dashboard/button/BtnOpenItemDescription.vue'

import { mdiArrowRight, mdiInformationOutline, mdiTable, mdiFileChartOutline } from '@mdi/js'

export default {
  name: 'DashboardHeaderContent',

  components: {
    DashboardItemMenu,
    OptionalSelectTextField,
    BtnOpenItemDescription
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      btnGroupDataCategoryWidth: 0,
      btnGroupSpatialTypeWidth: 0,
      btnToggleValue: undefined,
      mdiArrowRight: mdiArrowRight,
      mdiFileChartOutline: mdiFileChartOutline,
      mdiInformationOutline: mdiInformationOutline,
      mdiTable: mdiTable,
      showDialog: false
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_items', [
      'showSelectOptional'
    ]),

    getItemTechnology () {
      return Object.keys(this.dashboardItem) !== 0 ? this.dashboardItem.categories.filter(cat => cat.type.query_key === 'technology')[0].value : null
    }
  },

  mounted () {
    setTimeout(() => {
      if (this.$refs.btn_group_data_category && this.$refs.btn_group_spatial_type) {
        this.btnGroupDataCategoryWidth = this.$refs.btn_group_data_category.$el.clientWidth
        this.btnGroupSpatialTypeWidth = this.$refs.btn_group_spatial_type.$el.clientWidth
      }
    }, 20)
  },

  methods: {
    openDialogItemDesc (dashboardItem) {
      eventBus.$emit('open-dialog-item-description', dashboardItem)
    },

    openDialogItemDataTable (dashboardItem) {
      // eventBus.$emit('open-dialog-item-data-table', dashboardItem)
    },

    openDialogItemReport (dashboardItem) {
      eventBus.$emit('open-dialog-item-report', dashboardItem)
    },

    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    }
  }
}
</script>

<style lang="scss">
#dashboard-header-content {
  #btn-item-description {
    .v-icon__svg {
      height: 14px;
      width: 14px;
    }
  }

  .icon-technology {
    // border: solid thin rgba(0, 0, 0, 0.54); //var(--v-secondary-lighten2);
    border-radius: 6px;
    padding: 8px;
  }

  .bounce {
    -webkit-animation: bounce 2s;
            animation: bounce 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  @-webkit-keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
    }
    60% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }

  @keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
    }
    60% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }

  // .item-desc-button::before {
  //    background-color: var(--v-primary-base);
  //     opacity: 0.12;
  //     border-radius: inherit;
  //     bottom: 0;
  //     content: "";
  //     left: 0;
  //     pointer-events: none;
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //     transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
  // }

  // .item-text-area::before {
  //   background-color: var(--v-primary-base);
  //   opacity: 0.04;
  //   border-radius: inherit;
  //   bottom: 0;
  //   content: "";
  //   left: 0;
  //   pointer-events: none;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
  // }
}

</style>
