<template>
  <div
    style="width: 100%; height: 100%;"
  >
    <HeaderHome
      app
      :image-file-name="'thomas-richter-B09tL5bSQJk-unsplash_2.jpg'"
    />

    <v-container
      class="fill-height"
      fluid
      style="background: rgb(225, 232, 238); height: 100%"
    >
      <v-row
        align="center"
        justify="center"
        class="fill-height"
      >
        <v-col
          cols="auto"
          class="text-center"
        >
          <div
            class="text-h4"
          >
            <span
              v-if="locale === 'de'"
            >
              Die von Ihnen aufgerufene Seite konnte nicht gefunden werden.<br>
              Sie werden in {{ counter }} Sekunden auf die Startseite weitergeleitet ...

            </span>

            <span
              v-else
            >
              The page you requested could not be found.<br>
              You will be redirected to {{ counter }} seconds to be redirected to the home page ...

            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import HeaderHome from '@/components/main/header/HeaderHome.vue'

export default {
  name: 'ErrorPage404',

  components: {
    HeaderHome
  },

  data () {
    return {
      counter: 8
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  },

  watch: {
    counter: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.counter--
          }, 1000)
        }
      },
      immediate: true
    }
  },

  mounted () {
    setTimeout(() => {
      this.$router.push({
        name: 'EEMonitorHome'
      })
    }, 8000)
  }
}
</script>

<style lang="scss" scoped>

</style>
