<template>
  <v-sheet
    class="fill-height pa-6"
  >
    <v-row
      no-gutters
      class="mb-6 flex-column"
      justify="start"
      align="stretch"
    >
      <v-col
        class=""
      >
        <!-- {{ application.detail_info[0][`info_title_${locale}`] }} -->
        <div
          class="text-h4 font-weight-medium"
        >
          <span
            v-if="locale === 'de'"
          >
            <!-- Willkommen auf der Webseite des EE-Monitors! -->
            Willkommen beim EE-Monitor!
          </span>
          <span
            v-else
          >
            <!-- Welcome to the website of the EE Monitor! -->
            Welcome to the EE Monitor!
          </span>
        </div>
        <div
          class="text-h5"
        >
          <span
            v-if="locale === 'de'"
          >
            <!-- Diese Webseite bietet hochauflösende Informationen zur Naturverträglichkeit der Energiewende in Deutschland. -->
            Diese Webseite bietet hochauflösende Informationen zur Naturverträglichkeit der Energiewende in Deutschland.
          </span>
          <span
            v-else
          >
            This website offers high-resolution information on the nature compatibility of the energy transition in Germany.
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      class="pb-1 flex-column"
      justify="start"
      align="stretch"
    >
      <v-col
        class=""
      >
        <div>
          <div
            class="text-body-1"
          >
            <span
              v-if="locale === 'de'"
            >
              <span
                class="font-weight-medium"
              >
                Findet der Ausbau der erneuerbaren Energien naturverträglich
                statt?
              </span>
              Der Ausbau der erneuerbaren Energien wirkt sich auf Arten, Lebensräume und Landschaften aus: Stromtrassen, Windenergieanlagen, Photovoltaikanlagen und andere Technologien zur Nutzung erneuerbarer Energien, benötigen Platz und ihr Ausbau stellt einen Eingriff in die gewachsenen Lebensräume von Tier- und Pflanzenarten und in das Landschaftsbild dar. Ob ein naturverträglicher Ausbau der erneuerbaren Energien gelingt, hängt von vielen Faktoren ab. Vor diesem Hintergrund liefert der EE-Monitor eine wissenschaftlich fundierte Grundlage für die gesellschaftliche Auseinandersetzung über den naturverträglichen Ausbau der erneuerbaren Energien in Deutschland. Der EE-Monitor unterstützt dabei, sowohl Fehlentwicklungen als auch positive Tendenzen beim naturverträglichen Ausbau der erneuerbaren Energien aufzuzeigen.
            </span>

            <span
              v-else
            >
              <span
                class="font-weight-medium"
              >
                Is the expansion of renewable energies taking place in a way
                that is compatible with nature?
              </span>
              The expansion of renewable energies affects species, habitats and landscapes: Power lines, wind energy plants, photovoltaic systems and other technologies for the use of renewable energies require space and their expansion interferes with the habitats of animal and plant species and the landscape. Whether the expansion of renewable energies is compatible with nature depends on many factors. Against this background, the EE-Monitor provides a scientifically sound basis for a social debate on the nature-friendly expansion of renewable energies in Germany. The EE-Monitor supports the identification of undesirable developments and positive trends in the expansion of renewable energy.
            </span>
          </div>
          <div
            class="d-flex justify-end mt-6"
            style="width: 100%"
          >
            <div>
              <v-btn
                color="primary"
                text
                plain
                style="color: rgb(0, 88, 156); text-transform: none"
                :to="{ name: 'EEMonitorAbout' }"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Zum Hintergrund des EE-Monitors
                </span>
                <span
                  v-else
                > Background of the EE Monitor </span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>

      <!-- <v-col
          class="text-body-1"
        >
          {{ application.detail_info[0][`info_text_${locale}`] }}
        </v-col> -->
    </v-row>
  </v-sheet>
</template>

<script>
import { mdiArrowRight } from '@mdi/js'

import { mapState } from 'vuex'

export default {
  name: 'HomeProjectComponent',

  data () {
    return {
      mdiArrowRight: mdiArrowRight
    }
  },

  computed: {
    ...mapState('main', ['locale', 'application'])
  },

  mounted () {
    // console.log(this.$vuetify.breakpoint)
    // console.log('application ', this.application)
  }
}
</script>

<style lang="scss" scoped>
</style>
