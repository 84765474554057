<template>
  <div
    v-if="user.isAuthenticated"
    id="scrolling"
    class="fill-height"
    style="width: 100%;"
  >
    <HeaderHome
      app
      :image-file-name="'thomas-richter-B09tL5bSQJk-unsplash_2.jpg'"
    />

    <HomeMain />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import HomeMain from '@/components/base/home/HomeMain.vue'
import HeaderHome from '@/components/main/header/HeaderHome.vue'

export default {
  name: 'HomePageView',

  components: {
    HomeMain,
    HeaderHome
  },

  computed: {
    ...mapState('main', [
      'user'
    ])
  },

  beforeDestroy () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },

  mounted () {
    if (!this.user.isAuthenticated) {
      this.$router.push('/')
    }

    this.SET_APPLICATION_ITEM_SELECTED(undefined)
  },

  methods: {
    ...mapMutations('main', [
      'SET_APPLICATION_ITEM_SELECTED'
    ])
  }
}
</script>
