<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="fill-height"
  >
    <v-sheet
      class="fill-height pa-6"
    >
      <v-row
        no-gutters
        class="flex-column fill-height"
        justify="start"
        align="stretch"
      >
        <v-col
          class="shrink pb-1"
        >
          <div
            class="text-h4 font-weight-medium"
          >
            News
          </div>
        </v-col>

        <v-col
          class="grow"
        >
          <v-row
            v-if="application !== undefined"
            class="d-flex flex-column justify-space-between fill-height"
            no-gutters
          >
            <v-col
              class="fill-height"
            >
              <v-carousel
                :value="carouselValue"
                :show-arrows="false"
                hide-delimiters
                height="auto"
              >
                <v-carousel-item
                  v-for="(newsItemGroup, index) in newsItemsGrouped"
                  :key="index"
                >
                  <div
                    v-for="item in newsItemGroup"
                    :key="item.id"
                  >
                    <v-row
                      class="flex-column mb-4"
                      no-gutters
                    >
                      <v-col
                        class="text-body-2 pb-0"
                        style="opacity: 0.62;"
                      >
                        {{ new Date(item.date).toLocaleDateString(locale) }}
                      </v-col>

                      <v-col
                        class="text-h6"
                        style="cursor: pointer;"
                        @click="openDialog(item)"
                      >
                        {{ item[`title_${locale}`] }}
                      </v-col>

                      <v-col
                        style="line-height: 1rem;"
                      >
                        <v-btn
                          color="primary"
                          text
                          plain
                          style="color: rgb(0, 88, 156); text-transform: none; height: auto;"
                          class="px-0"
                          @click="openDialog(item)"
                        >
                          <v-icon
                            color="var(--v-primary-secondary)"
                            size="12"
                            class="mr-1"
                          >
                            {{ mdiArrowRight }}
                          </v-icon>
                          <span
                            v-if="locale==='de'"
                          >
                            weiterlesen
                          </span>
                          <span
                            v-else
                          >
                            read more
                          </span>
                        </v-btn>
                      </v-col>

                      <v-col
                        class="text-h6"
                      />
                    </v-row>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </v-col>

            <!-- FOOTER ACTIONS -->

            <v-col
              class="align-end d-flex"
            >
              <div
                class="d-flex justify-space-between align-center"
                style="width: 100%;"
              >
                <div
                  class="d-flex grow justify-center align-center"
                >
                  <v-btn
                    :disabled="carouselValue === 0"
                    icon
                    @click="carouselValue--"
                  >
                    <v-icon
                      size="18"
                      style="color: var(--v-primary-base);"
                    >
                      {{ mdiArrowLeft }}
                    </v-icon>
                  </v-btn>

                  <div
                    class="mx-3 font-weight-bold text-body-2"
                    style="user-select: none;"
                    :style="[newsItemsGrouped.length === 1 ? {'color': '#567f9f'} : {}]"
                  >
                    {{ carouselValue + 1 }} / {{ newsItemsGrouped.length }}
                  </div>

                  <v-btn
                    :disabled="newsItemsGrouped.length === carouselValue + 1"
                    icon
                    @click="carouselValue++"
                  >
                    <v-icon
                      size="18"
                      style="color: var(--v-primary-base);"
                    >
                      {{ mdiArrowRight }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-dialog
      v-model="isDialogOpenState"
      scrollable
      transition="dialog-bottom-transition"
      max-width="800"
      style="background: #ffffff"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :style="[$vuetify.breakpoint.smAndDown ? {'z-index': 2000} : {}]"
    >
      <v-card
        v-if="Object.keys(dialogItem).length !== 0"
        rounded="0"
        elevation="0"
      >
        <v-card-title
          class="pa-0 ma-0"
        >
          <v-toolbar
            dark
            dense
            flat
            color="primary"
            height="36"
          >
            <v-toolbar-title
              class="text-h6 ml-2"
              style="line-height: 2.3;"
            >
              News
            </v-toolbar-title>

            <v-spacer />

            <v-toolbar-items>
              <v-btn
                dark
                icon
                @click="isDialogOpenState = false"
              >
                <v-icon>
                  {{ mdiClose }}
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-row
          no-gutters
          class="pa-6 flex-column flex-nowrap justify-start"
          :style="[$vuetify.breakpoint.xsOnly ? {'overflow-y': 'scroll'} : {}]"
        >
          <v-col
            cols="12"
            class="mb-3 shrink"
          >
            <v-row>
              <v-col
                cols="12"
                order="1"
              >
                <v-row
                  class="flex-column"
                  no-gutters
                >
                  <v-col>
                    <span
                      style="opacity: 0.62;"
                    >
                      {{ new Date(dialogItem.date).toLocaleDateString(locale) }}
                    </span>
                  </v-col>
                  <v-col>
                    <span
                      class="text-h6 text-sm-h5"
                    >
                      {{ dialogItem[`title_${locale}`] }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                v-if="$vuetify.breakpoint.xsOnly"
                cols="12"
                sm="4"
                order="2"
                order-sm="3"
              >
                <div
                  style="border: 1px solid rgb(225, 232, 238);"
                >
                  <v-img
                    class="white--text grow"
                    :src="require(`@/assets/img/news/${dialogItem.image_filename}`)"
                  />
                </div>
              </v-col>

              <v-col
                cols="12"
                order="3"
                order-sm="2"
                class="text-body-1"
              >
                <span
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  <v-img
                    class="white--text grow float-right ml-3 mb-3"
                    width="40%"
                    contain
                    style="border: 1px solid rgb(225, 232, 238);"
                    :src="require(`@/assets/img/news/${dialogItem.image_filename}`)"
                  />
                </span>

                <!-- {{ dialogItem[`text_${locale}`] }} -->
                <div
                  v-html="dialogItem[`text_${locale}`]"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-if="dialogItem.link_path.length !== 0"
            cols="12"
            class="shrink align-start justify-start"
          >
            <v-btn
              :disabled="!dialogItem.link_active"
              color="primary"
              text
              style="color: rgb(0, 88, 156); text-transform: none;"
              class="px-0"
              @click="goTo(dialogItem)"
            >
              <div
                class="d-flex align-center"
              >
                <v-icon
                  color="var(--v-primary-secondary)"
                  size="14"
                  class="mr-2"
                >
                  {{ mdiArrowRight }}
                </v-icon>

                {{ dialogItem[`link_title_${locale}`] }}
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import _ from 'lodash'

import { mdiChevronDown, mdiArrowRight, mdiArrowLeft, mdiClose } from '@mdi/js'

export default {
  name: 'NewsList',

  data () {
    return {
      isDialogOpenState: false,
      dialogItem: {},
      newsItemsChunkValue: 3,
      carouselValue: 0,
      mdiChevronDown: mdiChevronDown,
      mdiArrowRight: mdiArrowRight,
      mdiArrowLeft: mdiArrowLeft,
      mdiClose: mdiClose,
      newsListValue: 0
    }
  },

  computed: {
    ...mapState('main', [
      'application',
      'locale'
    ]),

    newsItemsGrouped () {
      return _.chunk(this.application !== undefined ? _.orderBy(this.application.news_items, ['date'], ['desc']) : [], this.newsItemsChunkValue)
    }
  },

  methods: {
    openDialog (item) {
      this.dialogItem = item
      this.isDialogOpenState = true
    },

    goTo (dialogItem) {
      if (dialogItem.link_type === 'int') {
        this.$router.push({
          path: dialogItem.link_path
        })
      } else {
        window.open(dialogItem.link_path, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
