<template>
  <v-row
    v-if="active"
    :class="{
      'no-gutters': selectValuesFiltered.length === 1,
      'd-flex': selectValuesFiltered.length === 1,
      'd-flex': selectValuesFiltered.length !== 1
    }"
    style="pointer-events: all !important;"
  >
    <!-- class="d-inline-flex" -->
    <!-- :class="selectValuesFiltered.length !== 1 ? '' : 'no-gutters'" -->
    <v-col
      v-for="(selectValue, index) in selectValuesFiltered"
      :key="index"

      :style="[$vuetify.breakpoint.xlOnly ? {
        'width': 100 / selectValuesFiltered.length +'%' } :
        ($vuetify.breakpoint.lgOnly && selectValuesFiltered.length === 4) ?
          {'width': 100 / 4 +'%'} :
          ($vuetify.breakpoint.mdAndUp && selectValuesFiltered.length < 4) ?
            {'width': 100 / selectValuesFiltered.length +'%'} :
            ($vuetify.breakpoint.mdAndDown && selectValuesFiltered.length === 4) ?
              {'width': 100 / 2 +'%'} :
              {'width': 100 / 3 +'%'}
      ]"
    >
      <DynamicSelect
        v-if="setSelectStateValue(selectValue)"
        :select-value="selectValue"
        :select-state="selectState"
      />

      <!-- <v-col
            v-if="select.type.query_key === 'spatial_type'"
          >
            <v-autocomplete />
          </v-col> -->
    </v-col>
  </v-row>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'

// COMPONENTS
import DynamicSelect from '@/components/dashboard/inputs/DynamicSelect.vue'

export default {
  name: 'DashboarItemMenuComponent',

  components: {
    DynamicSelect
  },

  props: {
    selectQuery: {
      type: Array,
      default () {
        return []
      }
    },
    selectAdditionalValues: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      dropDownWidth: 0,
      value: {},
      selectValues: [],
      selectState: {},
      active: true
    }
  },

  computed: {
    ...mapState('dashboard_items', [
      'dashboardItemsSelectsBasic',
      'dashboardItemsSelectsOptional',
      'dashboardItemsDataQuery'
    ]),

    selectValuesFiltered () {
      return this.selectValues.filter(val => val.values.length !== 0)
    }
  },

  watch: {
    // selectState (newVal, oldVal) {
    //   console.log('selectState ', newVal)
    // },

    dashboardItemsSelectsBasic (newVal, oldVal) {
      // console.log('dashboardItemsSelectsBasic (newVal, oldVal) ', newVal, oldVal)

      this.selectArrayMerged()
    },

    selectAdditionalValues (newVal, oldVal) {
      this.selectArrayMerged()
    }

    // dashboardItemsDataQuery (newVal, oldVal) {
    // }
  },

  mounted () {
    this.selectArrayMerged()

    // console.log('this.selectValues', this.selectValues)
  },

  updated () {
    this.$nextTick(() => {
      this.$emit('isUpdated')
    })
  },

  methods: {
    setSelectStateValue (select) {
      this.selectState[select.type.query_key] = select.value_default

      return true
    },

    selectArrayMerged () {
      if (this.dashboardItemsSelectsBasic !== undefined &&
      this.dashboardItemsSelectsOptional !== undefined) {
        if (this.dashboardItemsSelectsBasic.length !== 0) {
          if (this.selectQuery.length === 0) {
            const optionsBasic = this.dashboardItemsSelectsBasic.filter(opt => opt.values.length !== 1)
            const optionsOptional = this.dashboardItemsSelectsOptional.filter(opt => opt.values.length !== 1)

            this.selectValues = [
              ...optionsBasic,
              ...optionsOptional
            ]
          } else {
            const options = [
              ...this.dashboardItemsSelectsBasic,
              ...this.dashboardItemsSelectsOptional
            ]

            this.selectValues = this.selectQuery.map((query) => {
              const select = options.filter((opt) => {
                return opt.type.query_key === query
              })

              if (select.length !== 0) {
                // FIXME Clears the values array of dataOption spatial_type from dashboardItem
                // Atm bugfix with cloneDeep in store action set_select_values
                // Filter out spatial_type.value_id === 1 (Germany)
                if (select[0].type.query_key === 'spatial_type') {
                  select[0].values = select[0].values.filter(val => val.value_id !== 1)
                }

                if (this.selectAdditionalValues.length !== 0) {
                  this.selectAdditionalValues.forEach((sva) => {
                    if (sva.queryKey === query) {
                      select[0].values = sva.values
                    }
                  })
                }
                return select[0]
              } else {
                return [] // FIXME
              }
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" >
// .webgis-select-menu {
//   background: rgba(255,255,255,0.8) !important;
// }
</style>
