<template>
  <div
    class="fill-height"
  >
    <v-container
      id="scroll-target"
      style="max-width: 1200px; width: auto;"
    >
      <BreadcrumbNav />

      <v-row>
        <!-- class="mb-3" -->
        <v-col
          cols="12"
          md="8"
        >
          <HomeProject />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <NewsList />
        </v-col>
      </v-row>

      <v-row
        class="mb-3"
      >
        <v-col
          sm="12"
          md="6"
        >
          <HomeSlideshow />
        </v-col>

        <v-col
          sm="12"
          md="6"
        >
          <MapAllRenewablePlants />
        </v-col>
      </v-row>

      <HomeOverviewApplications
        class="mb-6"
      />

      <EEMonitorPartnerFunding />
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import BreadcrumbNav from '@/components/main/navigation/BreadcrumbNav.vue'
import EEMonitorPartnerFunding from '@/components/base/info/PartnerFunding.vue'
import HomeOverviewApplications from '@/components/base/home/HomeOverviewApplications'
import HomeProject from '@/components/base/home/HomeProject'
import HomeSlideshow from '@/components/base/home/HomeSlideshow.vue'
import MapAllRenewablePlants from '@/components/base/charts/MapAllRenewablePlants.vue'
import NewsList from '@/components/base/news/NewsList.vue'

export default {
  name: 'HomeMainComponent',

  components: {
    BreadcrumbNav,
    EEMonitorPartnerFunding,
    HomeOverviewApplications,
    HomeProject,
    HomeSlideshow,
    MapAllRenewablePlants,
    NewsList
  },

  computed: {
    ...mapState('main', [
      'application',
      'applicationsItemSelected',
      'locale'
    ]),

    containerHeight () {
      return this.$vuetify.breakpoint.height - this.$vuetify.application.top - this.$vuetify.application.footer
    }
  },

  methods: {
    ...mapActions('main', [
    ]),

    ...mapActions('main', [
      'get_application_item'
    ])

  }
}
</script>
