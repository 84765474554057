<template>
  <v-container
    v-scroll="onScroll"
    v-resize="setElementsHeights"
    fluid
    :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'"
  >
    <HeaderMain
      app
    />

    <div
      v-resize="resizeSelectMainItemList"
      class="ma-0 pa-0"
    >
      <!-- INFO ITEM SELECT -->
      <v-card
        ref="item_info_area_card"
        class="pb-3"
        rounded="0"
        flat
        style="background: none;"
      >
        <v-row
          no-gutters
          align="stretch"
          justify="space-between"
          class=""
        >
          <v-col>
            <v-card
              flat
              rounded="0"
              class="pa-6"
            >
              <div
                class="text-h4 font-weight-medium mb-2"
              >
                <span
                  v-if="locale==='de'"
                >
                  Kennzahlen des Monitoring-Reports
                </span>
                <span
                  v-else
                >
                  Indicators of the  Monitoring-Report
                </span>
              </div>

              <div
                class="text-body-1 font-weight-normal"
              >
                <span
                  v-if="locale==='de'"
                >
                  Die Kennzahlenübersicht bietet die Möglichkeit, sich alle 41 Kennzahlen auf einen Überblick anzeigen zu lassen. Die Kennzahlen sind dabei fünf Technologien zugeordnet, nach denen sie sortiert werden können („Technologie“): Windenergie, Solarenergie, Bioenergie, Wasserkraft, Freileitungen und allgemeine Energiedaten. Alternativ kann die Sortierung nach den Zielfeldern einer naturverträglichen Energiewende vorgenommen werden ("Zielfelder").
                </span>

                <span
                  v-else
                >
                  The indicator overview offers the possibility of displaying all 41 indicators at a glance. The indicators are assigned to five technologies, according to which they can be sorted ("Technology"): Wind energy, solar energy, bioenergy, hydropower, overhead lines and general energy data. Alternatively, they can be sorted according to the target fields of an nature-friendly energy transition ("Target areas").
                </span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <!-- SELECT INDICATOR -->

      <v-card
        ref="item_select_area_card"
        rounded="0"
        elevation="0"
        style="background: none;"
      >
        <v-row
          v-if="btnSelectMain === 0"
          no-gutters
          class="flex-column"
        >
          <v-col>
            <Transition
              name="fade"
              mode="in-out"
              appear
            >
              <LoadingIndicator
                :show-loading-indicator="showOverlay"
                style="width: 100%; z-index:100; position: absolute; pointer-events:none;"
                :style="{'height': selectMainHeight + 'px'}"
              />
            </Transition>
            <!-- :class="classLoadingIndicator" -->
          </v-col>

          <v-col
            cols="12"
            class="pb-3"
            style="position: sticky;  z-index: 5;"
            :style="{'top': stickyHeaderTop + 'px'}"
          >
            <v-card
              ref="card_select_main_category"
              elevation="1"
              rounded="0"
              class="pa-3"
              :class="[isHeaderSticky ? 'card-sticky' : '']"
            >
              <SelectMainCategory
                ref="selectmaincategory"
              />
            </v-card>
          </v-col>

          <v-col
            v-if="itemList.length !== 0"
            cols="12"
            class="fill-height"
          >
            <!-- :style="{'min-height': selectItemListHeight + 'px'}" -->
            <SelectMainItemList
              ref="selectmainitemlist"
              v-resize="resizeSelectMainItemList"
              :items="itemList"
              class="fill-height"
              :select-item-list-height="selectItemListHeight"
              @showoverlay="showOverlay = false"
            />
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- LOADING INDICATOR  -->
    <div />

    <!-- DIALOG ITEM DESCRIPTION -->

    <DialogItemDescription
      class="fill-height"
    />
  </v-container>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions, mapMutations } from 'vuex'

import SelectMainCategory from '@/components/apps/report/selection/SelectMainCategory.vue'
import SelectMainItemList from '@/components/apps/report/selection/SelectMainItemList.vue'
import DialogItemDescription from '@/components/main/dialog/DialogItemDescription.vue'
import HeaderMain from '@/components/main/header/HeaderMain.vue'

import LoadingIndicator from '@/components/main/overlays/LoadingIndicator.vue'

export default {
  name: 'SelectMainPage',

  components: {
    SelectMainCategory,
    SelectMainItemList,
    DialogItemDescription,
    HeaderMain,
    LoadingIndicator
  },

  props: {
    applicationItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      selectMainHeight: 0,
      showOverlay: true,
      headerMenu: [
        {
          id: 1,
          title_de: 'Auswahl der Kennzahlen',
          title_en: 'Selection of the indicators'
        },
        {
          id: 2,
          title_de: 'Regionen-Report (Auswahl nach Regionen)',
          title_en: 'Regions Report (selection by regions)'
        }
      ],
      btnSelectMain: 0,
      selectItemListHeight: 0,
      // stickyHeaderTop: 0,
      stickyHeaderTopInit: 0,
      isHeaderSticky: false
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('select_items', [
      'itemList'
    ]),

    ...mapState('dashboard_items', [
      // 'dashboardComparisonStatus'
    ]),

    stickyHeaderTop () {
      return this.$vuetify.application.top
    }

    // classLoadingIndicator () {
    //   return this.$vuetify.breakpoint.mdAndUp ? 'loading-indicator-card-md-and-up' : 'loading-indicator-card-sm-and-down'
    // }
  },

  // watch: {
  //   vuetify () {
  //     console.log(this.$vuetify)
  //   }
  // },

  mounted () {
    this.setElementsHeights()

    eventBus.$on('resize-select-main-item-list', () => {
      this.resizeSelectMainItemList()
    })

    this.resizeSelectMainItemList()

    setTimeout(() => {
      if (this.$refs.card_select_main_category) {
        this.stickyHeaderTopInit = this.$refs.card_select_main_category.$el.offsetParent.offsetTop
      }
    }, 200)
  },

  beforeDestroy () {
    eventBus.$off('resize-select-main-item-list')
  },

  methods: {

    resizeSelectMainItemList () {
      setTimeout(() => {
        if (this.$refs.selectmaincategory) {
          this.selectItemListHeight =
        this.$vuetify.breakpoint.height -
        this.$refs.selectmaincategory.$el.clientHeight -
        this.$vuetify.application.top -
        this.$vuetify.application.footer
        }

        // console.log('this.$vuetify ', this.$vuetify)

        // TODO https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
      }, 200)
    },

    onScroll () {
      // console.log('card_select_main_category ', this.$refs.card_select_main_category.$el.offsetParent.offsetTop)
      if (this.$refs.card_select_main_category) {
        if (this.$refs.card_select_main_category.$el.offsetParent.offsetTop > this.stickyHeaderTopInit) {
        // console.log(this.$refs.card_select_main_category)
          this.isHeaderSticky = true
        } else {
          this.isHeaderSticky = false
        }
      }
    },

    setElementsHeights () {
      setTimeout(() => {
        if (this.$refs.item_select_area_card && this.$refs.item_info_area_card) {
          this.selectMainHeight = this.$vuetify.breakpoint.height - this.$vuetify.application.top - this.$vuetify.application.footer - this.$refs.item_info_area_card.$el.clientHeight - this.$refs.item_select_area_card.$el.clientHeight + 12
        }
      }, 300)
    }

  }
}
</script>

<style lang="scss" scoped>
.card-sticky {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

.loading-indicator-card-md-and-up {
  width: calc(100% - 24px);
}

.loading-indicator-card-sm-and-down {
  width: 100%;
}
</style>
