import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueMatomo from 'vue-matomo'

import VueMeta from 'vue-meta'

import VueSnip from 'vue-snip'

// import Print2Pdf from './plugins/print2pdf' // NOTE Own adaption of the original

Vue.use(VueMatomo, {
  host: 'https://www.ufz.de/stats/',
  siteId: 48,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: true,
  requireCookieConsent: true,
  trackInitialView: false,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
})

// Set baseURL for axios api based on env
// axios.defaults.baseURL = `${process.env.VUE_APP_HOST_URL}${process.env.VUE_APP_DJANGO_PORT}/${process.env.VUE_APP_ROOT_FOLDER}`

// https://github.com/mycurelabs/vue-html-to-paper
// const options = {
//   name: '_blank',
//   specs: [
//     'fullscreen=yes',
//     'titlebar=yes',
//     'scrollbars=yes'
//   ],
//   styles: [
//     'https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.0.96/css/materialdesignicons.min.css',
//     'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900',
//     'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css'
//   ],
//   timeout: 1000,
//   autoClose: true,
//   windowTitle: 'test'
// }
// Vue.use(Print2Pdf, options)

Vue.use(VueMeta)
// {
//   keyName: 'metaInfo'
//   // attribute: 'data-vue-meta',
//   // ssrAttribute: 'data-vue-meta-server-rendered',
//   // tagIDKeyName: 'vmid',
//   // refreshOnceOnNavigation: true
// }

Vue.use(VueAxios, axios)
Vue.use(VueSnip)

Vue.config.productionTip = false

export const eventBus = new Vue()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
