<template>
  <div>
    <v-fade-transition>
      <v-card
        v-if="layerAttributesPointerMove !== null &&
          popupData[0]?.id !== layerAttributesPointerMove.id"
        id="pointerinfo"
        color="rgb(225, 232, 238)"
        :style="[
          $vuetify.breakpoint.xsOnly
            ? { visibility: 'hidden' }
            : { left: esriMapViewScreenpointer.x + 10 + 'px', top: esriMapViewScreenpointer.y + 15 + 'px' },
        ]"
        style="pointer-events: none; position: absolute; z-index: 12; color: var(--primary-base);"
        class="pa-2"
      >
        <v-row
          no-gutters
          class="flex-column text-center"
        >
          <!-- INSTALLATION UNITS -->

          <v-col
            v-if="'id_count' in layerAttributesPointerMove &&
              layerAttributesPointerMove.id_count !== null"
          >
            <div
              v-if="layerAttributesPointerMove.id_count !== null"
            >
              <span
                v-if="locale === 'de'"
              >
                Anlageneinheiten:
              </span>
              <span
                v-else
              >
                Installation units:
              </span>

              <span
                class="font-weight-medium"
              >
                {{ layerAttributesPointerMove.id_count }}
              </span>
            </div>

            <div
              v-else
            >
              <span>
                <span
                  v-if="locale === 'de'"
                >
                  unbekannt
                </span>
                <span
                  v-else
                >
                  unknown
                </span>
              </span>
            </div>
          </v-col>

          <!-- YEAR COMMISSIONING -->

          <v-col>
            <div
              v-if="layerAttributesPointerMove.year_commissioning !== null"
            >
              <span
                v-if="locale === 'de'"
              >
                Inbetriebnahme:
              </span>
              <span
                v-else
              >
                Year commissioning:
              </span>

              <span
                v-if="layerAttributesPointerMove.year_commissioning !== null"
                class="font-weight-medium"
              >
                {{ layerAttributesPointerMove.year_commissioning }}
              </span>
            </div>

            <div
              v-else-if="layerAttributesPointerMove.year_commissioning_range !== null"
            >
              <span
                v-if="locale === 'de'"
              >
                Inbetriebnahme (Zeitraum):
              </span>
              <span
                v-else
              >
                Year range of build:
              </span>

              <span
                class="font-weight-medium"
              >
                {{ layerAttributesPointerMove.year_commissioning_range }}
              </span>
            </div>

            <div
              v-else
            >
              <span>
                <span
                  v-if="locale === 'de'"
                >
                  unbekannt
                </span>
                <span
                  v-else
                >
                  unknown
                </span>
              </span>
            </div>
          </v-col>

          <v-col>
            <!-- CAPACITY INSTALLED -->

            <div
              v-if="layerAttributesPointerMove.capacity_installed !== null &&
                'capacity_installed' in layerAttributesPointerMove "
            >
              <span
                v-if="locale === 'de'"
              >
                Installierte Leistung:
              </span>

              <span
                v-else
              >
                Installed capacity:
              </span>

              <span
                v-if="layerAttributesPointerMove.capacity_installed !== null"
                class="font-weight-medium"
              >
                {{ getCapacityValueString(layerAttributesPointerMove.capacity_installed, true) }}
              </span>
            </div>

            <div
              v-else-if="layerAttributesPointerMove.capacity_installed_sum !== null &&
                'capacity_installed_sum' in layerAttributesPointerMove"
            >
              <span
                v-if="locale === 'de'"
              >
                Installierte Leistung (∑):
              </span>
              <span
                v-else
              >
                Installed capacity (∑):
              </span>

              <span
                v-if="layerAttributesPointerMove.capacity_installed_sum !== null"
                class="font-weight-medium"
              >
                {{ getCapacityValueString(layerAttributesPointerMove.capacity_installed_sum, true) }}
              </span>
              <span
                v-else
              >
                <span
                  v-if="locale === 'de'"
                >
                  unbekannt
                </span>
                <span
                  v-else
                >
                  unknown
                </span>
              </span>
            </div>

            <div
              v-else
            >
              <span>
                <span
                  v-if="locale === 'de'"
                >
                  unbekannt
                </span>
                <span
                  v-else
                >
                  unknown
                </span>
              </span>
            </div>
          </v-col>

          <!-- CLICK INFO -->

          <!-- <v-col
            class="text-body-2"
            style="width: 200px;"
          >
            <span
              v-if="locale === 'de'"
            >
              Für weitere Informationen klicken Sie bitte auf den Anlagenstandort.
            </span>
            <span
              v-else
            >
              For more information, please click on the plant location.
            </span>
          </v-col> -->
        </v-row>
      </v-card>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// MIXINS
import mixinWebgis from '@/mixins/webgis.js'

export default {
  name: 'MousemoveTooltip',

  mixins: [
    mixinWebgis
  ],

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'popupData',
      'esriMapViewScreenpointer',
      'layerAttributesPointerMove'
    ])
  }
}
</script>

<style lang="scss">
#pointerinfo {
  position: absolute;
}
</style>
