import _ from 'lodash'
export default {
  SET_DATA_QUERY (state, {
    query
  }) {
    state.itemDataQuery = query
  },

  SET_ITEM_SELECTED (state, payload) {
    state.itemSelected = payload
  },

  SET_ITEMS (state, payload) {
    state.items = payload
  },

  SET_LAYER_STATISTIC_VALUES (state, payload) {
    state.layerStatisticValues = payload
  },

  SET_LAYER_VALUES_MIN_MAX (state, payload) {
    state.layerQueryValuesMinMax = payload
  },

  SET_MAIN_MENU_OPEN_STATE (state, payload) {
    state.isMainMenuOpen = payload
  },

  SET_MAIN_MENU_SELECTED_TAB (state, payload) {
    state.mainMenuSelectedTab = payload
  },

  SET_MAP_BASEMAP (state, basemapObj) {
    state.basemapSelected = basemapObj
  },

  SET_MAP_BASEMAP_ITEMS (state, payload) {
    state.basemapItems = payload
  },

  SET_MAPVIEW_CENTER (state, payload) {
    state.mapViewCenter = payload
  },

  SET_MAPVIEW_SCREENPOINTER (state, payload) {
    state.esriMapViewScreenpointer = payload
  },

  SET_MENU_DISABLED (state, payload) {
    state.isMenuDisabled = payload
  },

  SET_ATTRIBUTES_POINTER_MOVE (state, payload) {
    state.layerAttributesPointerMove = payload
  },

  SET_POPUP_DATA (state, payload) {
    state.popupData = payload
  },

  SET_POPUP_VISIBILITY (state, payload) {
    state.isPopupVisible = payload
  },

  SET_SELECTS_BASIC (state, {
    selectsBasic
  }) {
    const selectsBasicSorted = _.orderBy(selectsBasic, 'type.my_order')

    state.itemSelectsBasic = selectsBasicSorted
  },

  SET_SELECTS_OPTIONAL (state, {
    selectsOptional
  }) {
    const selectsOptionalSorted = _.orderBy(selectsOptional, 'type.my_order')

    state.itemSelectsOptional = selectsOptionalSorted
  },

  SET_SELECTS_OPTIONAL_QUERY_KEYS (state, {
    selectsOptionalQueryKeys
  }) {
    state.selectsOptionalQueryKeys = selectsOptionalQueryKeys
  },

  SET_VIEWDIV_DIMENSIONS (state, payload) {
    state.viewDivDimensions = payload
  },

  SHOW_MAPVIEW_UPDATING_INDICATOR (state, payload) {
    state.showMapViewUpdatingIndicator = payload
  }

}
