<template>
  <v-card
    rounded="0"
    elevation="0"
    width="200"
    style="height: 100%;"
  >
    <div
      class="d-flex"
      style="padding: 50px 16px 12px 12px; height: 100%;"
    >
      <div
        v-if="legendItems.length !== 0"
        class="d-flex flex-column align-end section-scroll pa-3"
        style="height: 100%; overflow-y: auto;"
      >
        <!-- :style="[$vuetify.breakpoint.smAndUp ? {'height': `${height * 0.50}px`} : {'height': `${height * 0.44}px`}]" -->
        <div
          v-for="legendItem in legendItems"
          :key="legendItem.seriesIndex"
          class="d-flex justify-end align-start mb-2"
        >
          <div
            style="height: 100%; text-align: right; word-break: keep-all; line-height: 1.1rem;"
            class="d-flex align-start text-body-2"
          >
            {{ legendItem.name }}
          </div>
          <div
            class="d-flex align-start pl-2 fill-height"
            style="width: auto; margin-top: 3px;"
          >
            <div
              style="height: 14px; width: 14px; border-radius: 50%;"
              :style="`background: ${legendItem.seriesColor}`"
            />
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { eventBus } from '@/utils/eventbus.js'

import { mdiClose } from '@mdi/js'

export default {
  name: 'ChartsLegendDialog',

  props: {
    refChart: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      isDialogOpenState: false,
      itemObject: {},
      mdiClose: mdiClose,
      legendItems: []
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    dialogHeight () {
      return this.$vuetify.breakpoint.height * 0.8
    }
  },

  watch: {
    isDialogOpenState (newValue, oldValue) {
      if (newValue === false) {
        setTimeout(() => {
          this.itemObject = {}
        }, 600)
      }
    }
  },

  async mounted () {
    eventBus.$on('open-charts-legend-dialog', (refChart) => {
      // console.log('chart ', refChart)

      this.legendItems = refChart.chart.getModel().getSeries().map(s => {
        return {
          seriesIndex: s.seriesIndex,
          name: s.name,
          seriesColor: refChart.chart.getVisual({
            seriesIndex: s.seriesIndex
          }, 'color')
        }
      })

      // console.log(this.legendItems)

      this.isDialogOpenState = true
    //
    })
  },

  beforeDestroy () {
    eventBus.$off('open-charts-legend-dialog')
  },

  methods: {
    ...mapActions('dashboard_items', [
      // 'get_item'
    ])
  }
}
</script>

<style lang="scss">

</style>
