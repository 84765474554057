<template>
  <div
    style="height: fit-content;"
  >
    <v-card
      rounded="0"
      elevation="0"
      class="d-flex flex-column justify-space-between pa-3 fill-height"
      style="z-index: 2;"
    >
      <v-container
        fluid
      >
        <v-row
          class="flex-column justify-start align-center text-center pa-3"
        >
          <v-col>
            <div
              v-if="locale==='de'"
              class="text-h4 font-weight-medium"
            >
              Monitoring-Report - Regionalbericht
            </div>
            <div
              v-else
              class="text-h4"
            >
              Monitoring-Report -  Regionalbericht
            </div>
          </v-col>

          <v-col
            class="text-h6 text-sm-h5 font-weight-normal"
          >
            <span
              v-if="locale==='de'"
            >
              Der Regionalbericht ermöglicht eine regionenspezifische Perspektive auf die 41 Kennzahlen. Die verfügbaren Werte können national, auf Bundesland- und Landkreisebene betrachtet und nach den jeweiligen Technologien ausgewählt werden.
              Es ist möglich, von den regionenspezifischen Werten direkt zur umfassenden und regionenübergreifenden  Ansicht der Kennzahlwerte zu gelangen.
            </span>

            <span
              v-else
            >
              The regional report provides a region-specific perspective on the 41 metrics. The available values can be viewed nationally, at the state and county level, and selected according to the respective technologies.
              It is possible to go directly from the region-specific values to the comprehensive and cross-regional view of the indicator values.
            </span>
          </v-col>
        </v-row>

        <v-row
          class="flex-column justify-start align-center fill-height mx-3"
          :class="[$vuetify.breakpoint.mdAndUp ? 'my-6 justify-space-around' : 'justify-space-between']"
        >
          <v-col
            class=""
          >
            <div
              class="d-flex justify-center align-center text-h5 text-sm-h4 text-center"
            >
              <span
                v-if="locale==='de'"
              >
                Bitte wählen Sie eine Region nach der räumlichen Auflösung
              </span>
              <span
                v-else
              >
                Please select a region according to the spatial resolution
              </span>
            </div>
          </v-col>

          <v-col
            class=""
          >
            <MenuRegionSelect />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

import MenuRegionSelect from '@/components/apps/report/region/menus/MenuRegionSelect.vue'

export default {
  name: 'DialogStartReportContent',

  components: {
    MenuRegionSelect
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems'
    ])
  },

  watch: {

  },

  methods: {
    ...mapActions('main', [
      'set_dialog_welcome'
    ]),

    ...mapActions('webgis', [
      'set_main_menu_open_state'
    ]),

    getItemTechnologyID (item) {
      return item.categories.filter(cat => cat.type.query_key === 'technology')[0].value.value_id
    },

    selectItem (item) {
      eventBus.$emit('select-webgis-item', item)

      this.set_dialog_welcome(false)

      if (this.$vuetify.breakpoint.mdAndUp !== undefined) {
        if (this.$vuetify.breakpoint.mdAndUp) {
          this.$nextTick(() => {
            this.set_main_menu_open_state(true)
          })
        } else {
          this.set_main_menu_open_state(false)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.btn-item-select-dialog {
   .v-btn__content {
     width: 100% !important;
  }
}

.webgis-dialog {
  z-index: 2 !important;
}
:root {
  --dialog-top: 0px;
  --dialog-bottom: 0px;
  --dialog-height: auto;
}

.v-dialog--fullscreen {
  top: var(--dialog-top);
  bottom: var(--dialog-bottom);
  height: var(--dialog-height);
}

</style>
