<template>
  <div
    class="fill-height"
  >
    <HeaderMain
      app
    />

    <!-- LOADING INDICATOR  -->
    <Transition
      name="fade"
      mode="in-out"
      appear
    >
      <LoadingIndicator
        :show-loading-indicator="showOverlay"
        style="width: 100%; background: none; z-index:100; position: absolute; pointer-events:none;"
        class="fill-height"
      />
    </Transition>

    <!-- DIALOG START APP -->

    <DialogStartApp
      v-if="items.length !== 0"
    >
      <!-- #default="content" -->
      <template
        #default
      >
        <DialogStartWebGisContent />
      </template>
    </DialogStartApp>

    <!-- LOADING INDICATOR FULL SCREEN -->

    <IndicatorMapViewUpdating
      :show-loading-indicator="showMapViewUpdatingIndicator"
    />

    <!-- POP-UP TOOLTIP -->

    <MousemoveTooltip />

    <PopUpContainer />

    <!-- BUTTONs -->

    <v-container
      v-show="!isWelcomeDialogOpen"
      fluid
      class="fill-height pb-6"
      style="position: absolute; pointer-events: none; z-index: 10;"
    >
      <v-row
        style="width: 100%;"
        align="end"
        justify="space-between"
        class="fill-height"
        no-gutters
      >
        <v-col
          cols="6"
          class="d-flex justify-start align-end"
        >
          <SelectBasemapCard />
        </v-col>
        <v-col
          cols="6"
          class="d-flex flex-column align-end justify-space-between fill-height"
        >
          <OpenMainMenu
            v-if="$vuetify.breakpoint.smAndUp && !isWelcomeDialogOpen && (isMainMenuOpen || !drawerState)"
          />

          <div
            class="d-flex flex-column align-self-end ma-4"
            style="position: absolute; bottom: 0; right: 0;"
          >
            <BtnZoomIn
              v-if="$vuetify.breakpoint.smAndUp"
            />

            <BtnZoomOut
              v-if="$vuetify.breakpoint.smAndUp"
            />

            <BtnResetZoom />
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- SEARCH, SELECT, BASEMAP -->

    <div
      v-show="!isWelcomeDialogOpen"
      class="fill-height pa-3"
      style="width: 100%; position: absolute; pointer-events: none; z-index: 10;"
    >
      <v-row
        align="start"
        justify="space-between"
        class="fill-height"
      >
        <v-col
          cols="auto"
          class="d-flex flex-column justify-space-between fill-height"
          :style="[$vuetify.breakpoint.smAndUp ? {'width': 'auto'} : {'width': '100%'}]"
        >
          <SelectRegionSearch />
        </v-col>

        <v-col
          cols="auto"
        >
          <Transition
            name="fade"
            mode="in-out"
            appear
          >
            <WebGisMenuMain
              v-show="isMainMenuOpen && $vuetify.breakpoint.mdAndUp"
              style="pointer-events: all; width: 430px;"
              @select-item="setSelectedItem"
            />
          </Transition>
        </v-col>
      </v-row>
    </div>

    <!-- MAIN MENU DRAWER -->

    <v-navigation-drawer
      v-show="!isWelcomeDialogOpen && $vuetify.breakpoint.smAndDown"
      v-model="drawerState"
      style="z-index: 249; pointer-events: none;"
      :style="`top: ${$vuetify.application.top}px`"
      :height="`calc(100vh - ${$vuetify.application.top}px)`"
      right
      hide-overlay
      temporary
      disable-resize-watcher
      stateless
      app
      :width="$vuetify.breakpoint.smAndUp ? 'auto' : '100%'"
      @input="closeDrawer"
    >
      <div
        class="d-flex flex-column justify-space-between fill-height"
      >
        <WebGisMenuMain
          style="pointer-events: all;"
          :style="[$vuetify.breakpoint.smAndUp ? {'width': '430px'} : {'width': '100%'}]"
          @select-item="setSelectedItem"
        />

        <div
          v-if="$vuetify.breakpoint.xsOnly"
          class="d-flex pa-3"
          style="width: 100%;"
        >
          <v-btn
            block
            depressed
            color="var(--v-base-primary)"
            @input="closeDrawer"
          >
            <span
              v-if="locale === 'de'"
            >
              Zurück zur Karte
            </span>
            <span
              v-else
            >
              Back to the map
            </span>
          </v-btn>
        </div>
      </div>
    </v-navigation-drawer>

    <!-- WEB-MAP -->

    <v-container
      v-if="items.length !== 0"
      id="webgis_container"
      fluid
      class="pa-0 fill-height"
      @click="closeDrawer(false)"
    >
      <WebGisMap
        class="fill-height"
        :item-selected="itemSelected"
      />
    </v-container>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

// LIBRARIES
import _ from 'lodash'

// COMPONENTS
import DialogStartApp from '@/components/main/dialog/DialogStartApp.vue'
import DialogStartWebGisContent from '@/components/apps/re_plant_locations/webgis/dialogs/DialogStartWebGisContent'
import HeaderMain from '@/components/main/header/HeaderMain.vue'
import IndicatorMapViewUpdating from '@/components/apps/re_plant_locations/webgis/indicator/IndicatorMapViewUpdating.vue'
import LoadingIndicator from '@/components/main/overlays/LoadingIndicator.vue'
import MousemoveTooltip from '@/components/apps/re_plant_locations/webgis/popup/MousemoveTooltip.vue'
import OpenMainMenu from '@/components/apps/re_plant_locations/webgis/buttons/OpenMainMenu.vue'
import PopUpContainer from '@/components/apps/re_plant_locations/webgis/popup/PopUpContainer.vue'
import BtnResetZoom from '@/components/apps/re_plant_locations/webgis/buttons/BtnResetZoom.vue'
import SelectBasemapCard from '@/components/apps/re_plant_locations/webgis/menus/SelectBasemapCard.vue'
import SelectRegionSearch from '@/components/apps/re_plant_locations/webgis/menus/SelectRegionSearch.vue'
import WebGisMap from '@/components/apps/re_plant_locations/webgis/WebGisMap.vue'
import WebGisMenuMain from '@/components/apps/re_plant_locations/webgis/menus/WebGisMenuMain.vue'
import BtnZoomIn from '@/components/apps/re_plant_locations/webgis/buttons/BtnZoomIn.vue'
import BtnZoomOut from '@/components/apps/re_plant_locations/webgis/buttons/BtnZoomOut.vue'

export default {
  name: 'WebGisMain',

  components: {
    DialogStartApp,
    DialogStartWebGisContent,
    HeaderMain,
    IndicatorMapViewUpdating,
    LoadingIndicator,
    MousemoveTooltip,
    OpenMainMenu,
    PopUpContainer,
    BtnResetZoom,
    SelectBasemapCard,
    SelectRegionSearch,
    WebGisMap,
    WebGisMenuMain,
    BtnZoomIn,
    BtnZoomOut
  },

  data () {
    return {
      drawerState: false,
      itemIDs: [],
      itemSelected: {},
      showOverlay: false
    }
  },

  computed: {
    ...mapState('main', [
      'applicationsItemSelected',
      'baseURL', // TODO Rename baseURL to Django URL
      'isWelcomeDialogOpen',
      'locale'
    ]),

    ...mapState('webgis', [
      'basemapSelected',
      'default',
      'isMainMenuOpen',
      'itemDataQuery',
      'items',
      'layerStatisticValues',
      'showMapViewUpdatingIndicator'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems'
    ])
  },

  watch: {
    applicationsItemSelected: {
      handler: 'getApplicationItems',
      immediate: true
    },

    '$vuetify.breakpoint.mdAndUp': {
      handler (mdAndUp) {
        if (mdAndUp !== undefined) {
          if (mdAndUp) {
            eventBus.$emit('set-webgis-main-menu', false)
          } else {
            if (this.isMainMenuOpen) {
              eventBus.$emit('set-webgis-main-menu', true)
            }
          }
        }
      },
      immediate: true
    }
  },

  beforeCreate () {
    document.body.className = 'hide-scrollbar'
    document.documentElement.className = 'hide-scrollbar'
  },

  beforeDestroy () {
    document.body.className = ''
    document.documentElement.className = ''

    this.set_dialog_welcome(false)

    eventBus.$off('webgis-show-overlay')
    eventBus.$off('set-webgis-main-menu')
    eventBus.$off('select-webgis-item')
  },

  async mounted () {
    // FIXME should fire every time user scrolls
    if (this.$vuetify.breakpoint.xsOnly) {
      window.addEventListener('load', () => {
        setTimeout(() => {
          // Hide the address bar:
          window.scrollTo(0, 1)
        }, 0)
      })
    }

    await this.set_webgis_defaults()

    if (this.bkgItems.length === 0) {
      await this.get_bkg_items({
        ade: 4, // FIXME
        gf: 4
      })
    }

    if (this.applicationsItemSelected !== undefined) {
      this.getApplicationItems()
    }

    eventBus.$on('select-webgis-item', (item) => {
      this.selectItem(item)
    })

    eventBus.$on('webgis-show-overlay', (bool) => {
      this.showOverlay = bool
    })

    eventBus.$on('set-webgis-main-menu', (bool) => {
      this.drawerState = bool
    })
  },

  methods: {
    ...mapActions('main', [
      'set_dialog_welcome'
    ]),

    ...mapActions('webgis', [
      'get_webgis_items',
      'set_data_query',
      'set_item_selected',
      'set_items',
      'set_main_menu_open_state',
      'set_main_menu_selected_tab',
      'set_select_values',
      'set_webgis_defaults'
    ]),

    ...mapActions('dashboard_main', [
      'get_bkg_items'
    ]),

    selectItem (item) {
      const queryDefault = item.data_options.map((option) => {
        if (option.type.query_type === 'api' && option.value_default !== null) {
          return {
            [option.type.query_key]: option.value_default.value_id
          }
        } else if (option.type.query_type === 'local' || option.value_default === null) {
        // NOTE If corine all selects are local and will be set to null by default!!!!
          return {
            [option.type.query_key]: null
          }
        } else {
          return ''
        }
      })

      // Add additional keys
      queryDefault.push({
        spatial_unit: null,
        time_format_operand: '<=', // TODO Set from state default
        year_selected: 2022,
        year_commissioning: 2022, // TODO Set from state default
        year_decommissioning: 1990 // TODO Set from state default
      })

      const objAssigned = Object.assign({}, ...queryDefault)

      this.set_data_query({
        query: objAssigned
      })

      const selectsBasic = item.data_options.filter(opt => opt.type.option_type === 'basic')
      const selectsOptional = item.data_options.filter(opt => opt.type.option_type === 'optional')

      this.set_select_values({
        selectsBasic,
        selectsOptional
      })

      this.set_item_selected({ item })

      this.$emit('select-item', item)
      eventBus.$emit('webgis-item-selected', item.id)

      this.set_main_menu_selected_tab(`tab-${item.id}`)
    },

    closeDrawer (value) {
      if (this.$vuetify.breakpoint.smAndDown) {
        if (value === false) {
          this.set_main_menu_open_state(false)
          this.drawerState = false
        }
      }
    },

    setSelectedItem (item) {
      this.itemSelected = item
    },

    async getApplicationItems () {
      // this.itemIDs = this.applicationsItemSelected.items.map(item => item.id)

      const items = await this.get_webgis_items({
        itemIDs: [42, 44, 45, 46] // FIXME BUG if coming from other app all items will be loaded this.itemIDs // NOTE -> [42, 44, 45, 46]
      })
      await this.set_items(items)
    }
  }
}
</script>

<style lang="scss">

</style>
