<template>
  <v-speed-dial
    v-if="basemapSelected !== null && basemapSelected !== undefined"
    v-model="speedDial"
    :direction="$vuetify.breakpoint.smAndUp ? 'right' : 'top'"
    :open-on-hover="$vuetify.breakpoint.smAndUp"
    transition="slide-y-reverse-transition"
    class=""
    style="width: fit-content; pointer-events: all;"
  >
    <template
      #activator
    >
      <v-card
        v-if="$vuetify.breakpoint.smAndUp"
        class="d-flex flex-column justify-space-between align-stretch text-body-2 pa-1"
        style="width: 100px; height: 100px;"
      >
        <div
          class="text-center font-weight-medium"
        >
          <span
            v-if="locale === 'de'"
          >
            Basiskarte:
          </span>
          <span
            v-else
          >
            Basemap:
          </span>
        </div>
        <v-card
          elevation="0"
          rounded="0"
          style="overflow: hidden; height: auto;"
          class="d-flex grow"
        >
          <div
            class="d-flex fill-height"
            style="width: 100%; border-radius: 0 0 6px 6px;"
            :style="`background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,rgba(0, 88, 156, 0.2) 60%, rgba(0, 88, 156, 1) 100%), url(${require(`@/assets/img/basemap/${basemapSelected.value}.png`)}); background-size: cover;`"
          >
            <div
              class="text-center white--text align-self-end"
              style="width: 100%;"
            >
              {{ basemapSelected[`title_${locale}`] }}
            </div>
          </div>
        </v-card>
      </v-card>

      <v-btn
        v-else
        fab
        dark
        small
        bottom
        right
        color="rgb(225, 232, 238)"
        class="mx-3 mb-0 ma-t-4"
      >
        <v-icon
          size="24"
          style="color: rgb(0, 62, 110);"
        >
          {{ mdiLayersOutline }}
        </v-icon>
      </v-btn>
    </template>

    <v-card
      v-if="$vuetify.breakpoint.xsOnly"
      class="mt-3 mt-sm-0 mr-sm-3 pa-1 select-basemap-item"
      style="width: 120px; cursor: pointer;"
      :style="[$vuetify.breakpoint.smAndUp ? {'height': '100px', 'font-size': '0.875rem'}:{'height': '60px', 'font-size': '0.75rem'}]"
    >
      <div
        class="d-flex fill-height"
        style="width: 100%; border-radius: 0 0 6px 6px;"
        :style="`background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,rgba(0, 88, 156, 0.2) 60%, rgba(0, 88, 156, 1) 100%), url(${require(`@/assets/img/basemap/${basemapSelected.value}.png`)}); background-size: cover;`"
      >
        <div
          class="text-center white--text align-self-end"
          style="width: 100%;"
        >
          {{ basemapSelected[`title_${locale}`] }}
        </div>
      </div>
    </v-card>

    <v-card
      v-for="basemapItem in basemapItemsFiltered"
      :key="basemapItem.id"
      class="mt-3 mt-sm-0 mr-sm-3 pa-1 select-basemap-item"
      style="width: 100px; cursor: pointer;"
      :style="[$vuetify.breakpoint.smAndUp ? {'height': '100px', 'font-size': '0.875rem'}:{'height': '60px', 'font-size': '0.75rem'}]"
    >
      <div
        class="d-flex justify-center align-stretch fill-height "
        style="background: green;"
        @click="setBasemap(basemapItem)"
      >
        <div
          class="d-flex fill-height"
          style="width: 100%; border-radius: 6px;"
          :style="`background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,rgba(0, 88, 156, 0.2) 60%, rgba(0, 88, 156, 1) 100%), url(${require(`@/assets/img/basemap/${basemapItem.value}.png`)}); background-size: cover;`"
        >
          <div
            class="text-center white--text align-self-end"
            style="width: 100%;"
          >
            {{ basemapItem[`title_${locale}`] }}
          </div>
        </div>
      </div>
    </v-card>
  </v-speed-dial>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions, mapMutations } from 'vuex'

import { mdiLayersOutline } from '@mdi/js'

// WEBGIS DEFAULTS
// import defaultValues from '@/webgis/default_values.js'

export default {
  name: 'SelectBasemapCard',

  data () {
    return {
      basemapItemsFiltered: [],
      mdiLayersOutline: mdiLayersOutline,
      speedDial: false
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'basemapItems',
      'basemapSelected'
    ])
  },

  watch: {
    basemapSelected: {
      handler (basemapSelected) {
        if (basemapSelected !== null && basemapSelected !== undefined) {
          setTimeout(() => {
            this.basemapItemsFiltered = this.basemapItems.length !== undefined ? this.basemapItems.filter(item => item.value !== basemapSelected.value) : []
          }, 250)
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('webgis', [
      'set_map_basemap'
    ]),

    setBasemap (basemapObj) {
      this.set_map_basemap(basemapObj)
      eventBus.$emit('set-map-basemap', basemapObj)
    }
  }
}
</script>

<style lang="scss">
.select-basemap-item:hover{
  background: rgba(0, 88, 156, 1);
  transition: 0.3s;
}
</style>
