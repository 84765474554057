<template>
  <!--  -->
  <div
    class="d-flex flex-column fill-height"
  >
    <ChartCardHeader
      :title="chartTitle"
    />

    <div
      style="position: relative;"
      class="d-flex flex-column fill-height"
    >
      <div
        class="d-flex fill-height pa-3"
      >
        <VChart
          ref="chart2"
          renderer="svg"
          :autoresize="true"
          :option="option"
        />

        <!-- <div
          v-show="isLegendOpen"
          style="width: auto;"
          class="fill-height"
        >
          <LegendDialog
            :ref-chart="$refs.chart2"
            :height="500"
          />
        </div> -->
      </div>

      <!-- <BtnOpenLegend
        :ref-echarts="$refs.chart2"
        :is-legend-open="isLegendOpen"
        @legend-state="isLegendOpen = !isLegendOpen"
      /> -->
    </div>
  </div>
</template>

<script>
//  IMPORT
import { eventBus } from '@/utils/eventbus.js'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

import _ from 'lodash'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import * as echarts from 'echarts/index.blank'

import {
// CanvasRenderer
  SVGRenderer
} from 'echarts/renderers'
import {
  BarChart
  // LineChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
} from 'echarts/components'

// import Moment from 'moment'
// import { extendMoment } from 'moment-range'
//  EXTEND Moment.js with Moment-Range
// const moment = extendMoment(Moment)

// COMPONENTS
import ChartCardHeader from '@/components/dashboard/cardtitle/ChartCardHeader.vue'
// import LegendDialog from '@/components/dashboard/legend/LegendDialog.vue'
// import BtnOpenLegend from '@/components/dashboard/button/BtnOpenLegend.vue'

use([
  // CanvasRenderer,
  SVGRenderer,
  BarChart,
  // LineChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TitleComponent
])

export default {
  name: 'ChartsLineComponentPage',
  // mixins: [globalFunctions],

  components: {
    VChart,
    ChartCardHeader
    // LegendDialog,
    // BtnOpenLegend
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'svg'
    }
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    height: {
      type: Number,
      default: 1
    },
    spatialTypeId: {
      type: Number,
      default: 2
    },
    chartIndex: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      isLegendOpen: false,
      chartTitle: '',
      dataAgs: [],
      dashboardItemsCollection: [],
      dataArray: [],
      dataFiltered: {},
      dataValueUnit: '',
      dataYearLimit: 2020,
      item: {},
      itemDataYears: [],
      contextItems: [],
      contextItemsData: [],
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      option: {},
      renderChartComponent: false,
      targetValueColor: 'red', // TODO Needs to be defined
      targetValueYear: 2022,
      series: {
        data: [],
        emphasis: {
          focus: 'series',
          itemStyle: {
            // color: '#003E6E',
            borderColor: '#003E6E' // borderStyle: 'solid'

          },
          label: {
            show: false
          },
          lineStyle: {
            color: '#ffdf33',
            type: 'solid',
            width: 3
          }
        },
        itemStyle: {
          color: 'auto',
          opacity: 0.8,
          borderColor: '#003e6e',
          borderWidth: 1
        },
        lineStyle: {
          type: 'solid',
          width: 1
        },
        sampling: 'average',
        select: {
          selectMode: true,
          disabled: true
        },
        stack: 'stacked',
        symbolSize: 4,
        triggerLineEvent: true,
        type: 'bar'
      },
      echartsOption: {
        brush: {
          brushLink: 'all',
          outOfBrush: {
            colorAlpha: 0.1
          },
          xAxisIndex: 'all'
        },
        color: [],
        grid: {
          bottom: '8px',
          containLabel: true,
          height: 'auto',
          left: '10',
          right: '10',
          top: 'auto',
          // height: '50%',
          width: 'auto'
        },
        // grid: {
        //   bottom: '8px',
        //   containLabel: true,
        //   height: 'auto',
        //   left: '10',
        //   right: '10', // '230',
        //   top: '42px',
        //   width: 'auto' // width: 'auto'
        // },
        legend: {
          // align: 'right',
          align: 'auto',
          animation: true,
          animationDurationUpdate: 800,
          backgroundColor: '#ffffff',
          bottom: 10,
          icon: 'circle',
          lineStyle: {
            type: 'solid'
          },
          // orient: 'vertical',
          orient: 'horizontal',
          pageIconColor: 'rgb(0, 62, 110, 1)',
          pageIconSize: 10,
          right: 10,
          selected: {},
          show: true,
          textStyle: {
            fontSize: 14,
            fontWeight: 400,
            overflow: 'break',
            width: 210
          },
          top: '30px',
          // type: 'scroll'
          type: 'plain'
        },
        series: [],
        textStyle: {
          color: '#003e6e',
          fontFamily: 'Roboto',
          fontSize: 14,
          fontWeight: '400'
        },
        title: {
          left: 'center',
          text: '',
          textStyle: {
            color: '#003e6e',
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: '400'
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: true
            }
          },
          show: false
        },
        tooltip: {},
        xAxis: {
          boundaryGap: true,
          // axisPointer: {          //   show: true,
          //   snap: true
          // }
          data: [],
          splitLine: {
            show: true
          },
          type: 'category'
        },
        yAxis: {
          axisLabel: {
            formatter: '{value}' // axisPointer: {
            //   show: true,
            //   snap: true
            // }
          },
          nameTextStyle: {
            color: '#003e6e',
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: '400'
          },
          type: 'value'
        }
      }
    }
  },
  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'echartsDefaults',
      'bkgItems'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataMinMax',
      'dashboardItemsDataYears'
    ]),

    tooltipFontSize () {
      return this.$vuetify.breakpoint.mdAndDown ? '1rem' : '1.4rem'
    },

    tooltipListMargin () {
      return this.$vuetify.breakpoint.mdAndDown ? '2px' : '3px'
    },

    tooltipTitleFontSize () {
      return this.$vuetify.breakpoint.mdAndDown ? '1.2rem' : '1.5rem'
    }
  },

  watch: {
    height (newVal, oldVal) {
      this.$refs.chart2.resize()
      // console.log('resize')
    },

    locale: {
      handler (newVal) {
        this.updateChart()
        this.chartTitle = newVal === 'de' ? 'Bundesrepublik Deutschland' : 'Germany'
      },
      immediate: true
    },

    '$vuetify.breakpoint': {
      handler (breakpoint) {
        if (breakpoint.mdAndUp) {
          this.echartsOption.grid.height = '80%'
        } else if (breakpoint.smOnly) {
          this.echartsOption.grid.height = '70%%'
        } else {
          this.echartsOption.grid.height = '60%%'
        }
        this.updateChart()
      },
      immediate: true,
      deep: true
    }
  },

  async mounted () {
    this.contextItems = this.dashboardItem.context_items.map((ci) => { return ci.item })
    this.dashboardItemsCollection = [this.dashboardItem, ...this.contextItems]

    // Get array of distinct years in data
    this.itemDataYears = this.dashboardItemsDataYears

    // Set echarts defauls from store
    // this.echartsOption.series = this.echartsDefaults.map.series
    this.echartsOption.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)
    this.echartsOption.xAxis = _.cloneDeep(this.echartsDefaults.xAxis)
    this.echartsOption.tooltip = _.cloneDeep(this.echartsDefaults.tooltip)
    this.echartsOption.tooltip.textStyle = _.cloneDeep(this.echartsDefaults.textStyle)

    // Set x-Axis Interval (index:number, value: string) => boolean
    this.echartsOption.xAxis.axisLabel.interval = await this.get_axis_value_interval({ years: this.itemDataYears })
    this.echartsOption.xAxis.axisTick.interval = await this.get_axis_value_interval({ years: this.itemDataYears, interval: 1 })

    // Get value unit title_short (string)
    const dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.dashboardItem
    })
    this.dataValueUnit = dataValueUnitObj.title_short_de

    const item = await this.get_item({
      slug: this.dashboardItem.slug_de
    })
    this.item = _.cloneDeep(item)

    this.renderChartComponent = true

    eventBus.$on('resize-echarts-chart2', (dimensions) => {
      this.$refs.chart2.chart.resize()
    })

    // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart
    // FIXME chart not mounted when event listener is set
    // this.$refs.chart2.chart.getZr().on('click', params => {
    //   this.chartsOnClick(params)
    // })

    await this.updateChart()
  },

  beforeDestroy () {
    this.$refs.chart2.chart.dispose()
    this.$refs.chart2.chart = null

    eventBus.$off('highlight-charts-series')
    eventBus.$off('load-echarts-line-1-component')
    eventBus.$off('resize-echarts-chart2')
    eventBus.$off('set-locale')

    // this.$eventHub.off('emit-set-echarts-barchart-data')
  },

  methods: {
    // ...mapActions('dashboard_main', [
    // ]),

    ...mapActions('dashboard_items', [
      'get_axis_value_interval',
      'get_item',
      'get_datasets_ags',
      'get_datasets_value_max',
      'get_datasets_value_min',
      'get_datasets_years',
      'get_item_data',
      'get_item_data_value_unit',
      'get_max_fraction_digits'
    ]),

    ...mapMutations('dashboard_items', [
      'SET_ITEM_YEAR_SELECTED'
    ]),

    async updateChart () {
      this.echartsOption.legend.show = true

      this.echartsOption.yAxis.min = 0 // await this.get_datasets_value_min(this.itemData)
      // this.echartsOption.yAxis.max = await this.get_datasets_value_max(this.itemData)
      const series = []

      for (const item of this.dashboardItemsCollection) {
        let categoryAttributeTitle = ''
        let categoryTitle = ''

        const categoryObj = item.categories.filter(cat => cat.type.query_key === 'technology')[0]
        const categoryId = categoryObj.value.value_id

        if (categoryId !== 2) {
          categoryTitle = categoryObj.value.title_de
        } else {
          if (this.locale === 'de') {
            categoryTitle = 'Photovoltaik'
          } else {
            categoryTitle = 'Photovoltaic'
          }
        }

        if (categoryObj.attribute !== null) {
          categoryAttributeTitle = `- ${categoryObj.attribute.title_de} `
        }

        const dataStatus = this.itemData.filter(ds => ds.item === item.id && ds.data_year <= this.dataYearLimit)
        const dataExpansionPath = this.itemData.filter(ds => ds.item === item.id && ds.data_year > this.dataYearLimit)

        const dataArray = [dataStatus, dataExpansionPath]

        // Create series datasets
        for (const dataArea of dataArray) {
          const dataValues = this.itemDataYears.map((year) => {
            const ds = dataArea.filter(ds => ds.data_year === year)
            if (ds.length !== 0) {
              return ds[0].data_value
            } else {
              return null
            }
          })

          // NOTE Deep clone is needed for resolving inheritance issues
          const seriesObj = _.cloneDeep(this.series)

          // Set data array of data series
          seriesObj.data = dataValues

          // TODO Translation and move to django/db
          const titleSeries = [
            {
              title_de: 'Status',
              title_en: 'Status'
            },
            {
              title_de: 'Zielwert',
              title_en: 'Target'
            }
          ]

          // Set name of data series (-> BKG (BEZ +) GEN)
          seriesObj.name = `${categoryTitle} ${categoryAttributeTitle}(${titleSeries[dataArray.indexOf(dataArea)][`title_${this.locale}`]})`

          // Disable if
          if (this.dashboardItemsCollection.indexOf(item) !== 0) {
            this.echartsOption.legend.selected[`${categoryTitle} ${categoryAttributeTitle}(${titleSeries[dataArray.indexOf(dataArea)][`title_${this.locale}`]})`] = false
          }

          // Colors for expansion status items 1,2,3,4
          // Source https://coolors.co/4ee7ff-5c64ff-ffc525-92ff0e
          const colors = ['#4EE7FF', '#5C64FF', '#FFC525', '#92FF0E']

          seriesObj.itemStyle.color = colors[item.id - 1]
          // this.echartsDefaults.color[this.dashboardItemsCollection.indexOf(item)]

          if (dataArray.indexOf(dataArea) === 1) {
            seriesObj.itemStyle.opacity = 0.4
          }

          series.push(seriesObj)
        }
      }

      // Set created series data
      this.echartsOption.series = series

      this.echartsOption.yAxis.name = this.dataValueUnit
      this.echartsOption.yAxis.axisLabel.formatter = (value) => `${value.toLocaleString(this.locale)}`

      // Set number of min & max digits for the number of value descendants
      if (!this.dataValueUnit.match(/^n$/)) {
        const minValue = await this.get_datasets_value_min(this.itemData)
        this.maximumFractionDigits = await this.get_max_fraction_digits({ minValue })
      }

      this.echartsOption.tooltip.formatter = (params, ticket, callback) => {
        const title = `<div class="text-h6">
                      ${params.seriesName} (${params.name})
                      </div>`

        if (isNaN(params.value)) {
          return `${title}<div>${this.locale === 'de' ? 'kein Wert' : 'no value'}</div>`
        } else {
          return `${title}
          <div>${params.value.toLocaleString(this.locale, {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits
          })}
          ${this.dataValueUnit}</div>`
        }
      }

      // X-axis -> Set year as category
      this.echartsOption.xAxis.data = this.itemDataYears

      // Set options on the echarts instance
      this.$refs.chart2.setOption(this.echartsOption, true)
      this.$refs.chart2.resize()

      eventBus.$emit('open-charts-legend-dialog', this.$refs.chart2)
    },

    chartsOnClick (params) {
      // https://stackoverflow.com/questions/64643683/how-to-make-space-around-bar-clickable-in-echarts-bar-chart

      let categoryName

      this.$refs.chart2.chart.dispatchAction({
        type: 'downplay'
      })

      if (params.event.target) {
        const pointInPixel = [params.offsetX, params.offsetY]
        const pointInGrid = this.$refs.chart2.chart.convertFromPixel('grid', pointInPixel)
        categoryName = this.$refs.chart2.chart.getModel().get('xAxis')[0].data[pointInGrid[0]]
      } else {
        categoryName = params.name
      }

      eventBus.$emit('update-echarts-map-component', {
        year: Number(categoryName)
      })

      this.SET_ITEM_YEAR_SELECTED({
        year: Number(categoryName)
      })
    },

    echartsData (AGS) {
      if (AGS === '' || AGS === undefined) {
        this.echartsOption.series[0].data = []
        this.echartsOption.yAxis.max = 0
      } else {
        const dataFiltered = this.attributesActiveFL.filter((e) => {
          return e.AGS === AGS
        })

        this.dataFiltered = dataFiltered[0]

        const dataX = this.getDates(
          this.selectTemporalSelected,
          this.dateRange,
          this.dateDefinitions
        )

        // const dataY = dataFiltered[0].value.split(',').map(e => {
        //   if (e === '') {
        //     return '0'
        //   } else if (e === '-1') {
        //     return '0'
        //   } else {
        //     return e
        //   }
        // })

        // const dataY = dataFiltered[0].value
        const dataY = dataFiltered[0][this.selectIndicatorSelected.value]

        this.echartsOption.xAxis.data = dataX
        this.echartsOption.yAxis.max = this.FL_MaxValue[
          this.selectIndicatorSelected
        ]
        this.echartsOption.series[0].data = dataY
        this.echartsOption.series[0].itemStyle.color = this.FL_RendererColor[1]
      }
    },

    getDates (temp, dateRange, dateDefinitions) {
      if (Object.keys(dateRange).length !== 0) {
        const def = dateDefinitions[temp.ID - 1]

        const dateRangeDates = Array.from(dateRange.by(def.rangeBy))

        let dates = []
        dates = dateRangeDates.map(d => d.format(def.timeline))

        return dates
      }
    }
  }
}
</script>

<style lang="scss">

</style>
