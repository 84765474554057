export default () => ({
  basemapItems: [],
  basemapSelected: null,
  esriMapViewScreenpointer: { x: 0, y: 0 },
  isMainMenuOpen: true,
  isMenuDisabled: false,
  isPopupVisible: false,
  itemDataQuery: {},
  itemSelected: {},
  itemSelectsBasic: [],
  itemSelectsOptional: [],
  items: [],
  layerAttributesPointerMove: null,
  layerQueryValuesMinMax: null,
  layerStatisticValues: null,
  mainMenuSelectedTab: '',
  mapViewCenter: null,
  popupData: [],
  selectsOptionalQueryKeys: [],
  showMapViewUpdatingIndicator: false,
  viewDivDimensions: { height: 0, width: 0 }
})
