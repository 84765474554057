<template>
  <div>
    <div
      class="mb-3"
    >
      <span
        v-if="locale === 'de'"
      >
        Um den EE-Monitor zu zitieren, können Sie den folgenden Zitationsvorschlag verwenden:
      </span>
      <span
        v-else
      >
        To cite EE-Monitor, you can use the following citation suggestion:
      </span>
    </div>

    <div
      class="font-italic"
    >
      <span
        v-if="locale === 'de'"
      >
        Thrän, Daniela, Manske, David, Schinkel, Björn, Schmiedt, Julius, & Mittelstädt, Nora (2023). EE-Monitor - Monitoring für eine naturverträgliche Energiewende in Deutschland. Webanwendung. https://doi.org/10.5281/zenodo.7664005
      </span>
      <span
        v-else
      >
        Thrän, Daniela, Manske, David, Schinkel, Björn, Schmiedt, Julius, & Mittelstädt, Nora (2023). EE-Monitor - Monitoring for a nature-friendly energy transition in Germany. Web application. https://doi.org/10.5281/zenodo.7664005
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CitiationContent',

  computed: {
    ...mapState('main', [
      'locale'
    ])
  }
}
</script>

<style lang="scss" scoped>

</style>
