<template>
  <div>
    <v-overlay
      absolute
      :value="showOverlay"
      class="d-flex justify-center align-center"
      color="#e1e8ee"
      opacity="1"
      z-index="6"
    >
      <v-row
        justify="center"
        align="center"
        class="fill-height"
      >
        <v-progress-circular
          class="progress-circle"
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        />
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DashboardLoadingIndicator',

  data () {
    return {
      showOverlay: true
    }
  },

  computed: {
    ...mapState('dashboard_items', [
      'dashboardItemsDataYears'
    ])
  },

  watch: {
    dashboardItemsDataYears (newVal, oldVal) {
      this.isItemDataYearsLoaded()
    }
  },

  mounted () {
    this.isItemDataYearsLoaded()
  },

  methods: {
    isItemDataYearsLoaded () {
      if (this.dashboardItemsDataYears.length !== 0) {
        setTimeout(() => {
          this.showOverlay = false
        }, 600)
      }
    }
  }
}
</script>

<style lang="scss">
.v-overlay, .progress-circle {
  // align-items: center;
  // border-radius: inherit;
  // display: flex;
  // justify-content: center;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // pointer-events: none;
    // transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;

  // transition: 0.8s cubic-bezier(.17,.67,.83,.67), z-index 1ms;

  transition: 0.4s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;

}
</style>
