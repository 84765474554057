var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-fade-transition',[(_vm.layerAttributesPointerMove !== null &&
        _vm.popupData[0]?.id !== _vm.layerAttributesPointerMove.id)?_c('v-card',{staticClass:"pa-2",staticStyle:{"pointer-events":"none","position":"absolute","z-index":"12","color":"var(--primary-base)"},style:([
        _vm.$vuetify.breakpoint.xsOnly
          ? { visibility: 'hidden' }
          : { left: _vm.esriMapViewScreenpointer.x + 10 + 'px', top: _vm.esriMapViewScreenpointer.y + 15 + 'px' },
      ]),attrs:{"id":"pointerinfo","color":"rgb(225, 232, 238)"}},[_c('v-row',{staticClass:"flex-column text-center",attrs:{"no-gutters":""}},[('id_count' in _vm.layerAttributesPointerMove &&
            _vm.layerAttributesPointerMove.id_count !== null)?_c('v-col',[(_vm.layerAttributesPointerMove.id_count !== null)?_c('div',[(_vm.locale === 'de')?_c('span',[_vm._v(" Anlageneinheiten: ")]):_c('span',[_vm._v(" Installation units: ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.layerAttributesPointerMove.id_count)+" ")])]):_c('div',[_c('span',[(_vm.locale === 'de')?_c('span',[_vm._v(" unbekannt ")]):_c('span',[_vm._v(" unknown ")])])])]):_vm._e(),_c('v-col',[(_vm.layerAttributesPointerMove.year_commissioning !== null)?_c('div',[(_vm.locale === 'de')?_c('span',[_vm._v(" Inbetriebnahme: ")]):_c('span',[_vm._v(" Year commissioning: ")]),(_vm.layerAttributesPointerMove.year_commissioning !== null)?_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.layerAttributesPointerMove.year_commissioning)+" ")]):_vm._e()]):(_vm.layerAttributesPointerMove.year_commissioning_range !== null)?_c('div',[(_vm.locale === 'de')?_c('span',[_vm._v(" Inbetriebnahme (Zeitraum): ")]):_c('span',[_vm._v(" Year range of build: ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.layerAttributesPointerMove.year_commissioning_range)+" ")])]):_c('div',[_c('span',[(_vm.locale === 'de')?_c('span',[_vm._v(" unbekannt ")]):_c('span',[_vm._v(" unknown ")])])])]),_c('v-col',[(_vm.layerAttributesPointerMove.capacity_installed !== null &&
              'capacity_installed' in _vm.layerAttributesPointerMove )?_c('div',[(_vm.locale === 'de')?_c('span',[_vm._v(" Installierte Leistung: ")]):_c('span',[_vm._v(" Installed capacity: ")]),(_vm.layerAttributesPointerMove.capacity_installed !== null)?_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getCapacityValueString(_vm.layerAttributesPointerMove.capacity_installed, true))+" ")]):_vm._e()]):(_vm.layerAttributesPointerMove.capacity_installed_sum !== null &&
              'capacity_installed_sum' in _vm.layerAttributesPointerMove)?_c('div',[(_vm.locale === 'de')?_c('span',[_vm._v(" Installierte Leistung (∑): ")]):_c('span',[_vm._v(" Installed capacity (∑): ")]),(_vm.layerAttributesPointerMove.capacity_installed_sum !== null)?_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getCapacityValueString(_vm.layerAttributesPointerMove.capacity_installed_sum, true))+" ")]):_c('span',[(_vm.locale === 'de')?_c('span',[_vm._v(" unbekannt ")]):_c('span',[_vm._v(" unknown ")])])]):_c('div',[_c('span',[(_vm.locale === 'de')?_c('span',[_vm._v(" unbekannt ")]):_c('span',[_vm._v(" unknown ")])])])])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }