<template>
  <transition
    v-if="showLoadingIndicator"
    name="fade"
    mode="out-in"
  >
    <v-overlay
      :value="showLoadingIndicator"
      absolute
      class="d-flex justify-center align-center"
      opacity="1"
      z-index="6"
      color="none"
    >
      <!-- color="#e1e8ee" -->
      <v-row
        no-gutters
        justify="center"
        align="center"
        class="fill-height"
        style="width: 100%;"
      >
        <v-col
          cols="auto"
        >
          <v-card
            class="pa-6"
            light
          >
            <v-progress-circular
              class="progress-circle"
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            />
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IndicatorMapViewUpdating',

  props: {
    showLoadingIndicator: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
    }
  }

}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.v-overlay, .progress-circle {
  // align-items: center;
  // border-radius: inherit;
  // display: flex;
  // justify-content: center;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // pointer-events: none;
    // transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;

  // transition: 0.8s cubic-bezier(.17,.67,.83,.67), z-index 1ms;

  transition: 0.4s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;

}
</style>
