import _ from 'lodash'

export default {
  REMOVE_ITEM (state) {
    state.dashboardItems = {} // FIXME -> null
  },
  REMOVE_ITEM_DATA (state) {
    state.dashboardItemsData = []
  },
  SET_DASHBOARD_COMPARISON_STATUS (state, {
    status
  }) {
    state.dashboardComparisonStatus = status
  },
  SET_DASHBOARD_LAYOUT (state, {
    layout
  }) {
    state.dashboardLayout = layout
  },
  DELETE_DASHBOARD_LAYOUT (state) {
    state.dashboardLayout = null
  },
  SET_DATA_QUERY (state, {
    query
  }) {
    state.dashboardItemsDataQuery = query
  },
  SET_ITEM (state, {
    item
  }) {
    state.dashboardItems = item
  },
  SET_ITEM_DATA (state, {
    data
  }) {
    state.dashboardItemsData = data
  },
  SET_ITEM_DATA_VALUE_UNIT (state, {
    valueUnit
  }) {
    state.dashboardItemsDataValueUnit = valueUnit
  },
  SET_ITEM_DATA_MIN_MAX (state, {
    valueMax,
    valueMin
  }) {
    state.dashboardItemsDataMinMax = {
      valueMax,
      valueMin
    }
  },
  SET_ITEM_DATA_OPTION_QUERY_KEYS (state, {
    keys
  }) {
    state.dashboardItemsDataOptionQueryKeys = keys
  },
  SET_ITEM_DATA_YEARS (state, {
    years
  }) {
    state.dashboardItemsDataYears = years
  },
  SET_ITEM_YEAR_SELECTED (state, {
    year
  }) {
    state.dashboardItemsYearSelected = year
  },
  SET_SELECTS_BASIC (state, {
    selectsBasic
  }) {
    const selectsBasicSorted = _.orderBy(selectsBasic, 'type.my_order')

    state.dashboardItemsSelectsBasic = selectsBasicSorted
  },
  SET_SELECTS_OPTIONAL (state, {
    selectsOptional
  }) {
    const selectsOptionalSorted = _.orderBy(selectsOptional, 'type.my_order')

    state.dashboardItemsSelectsOptional = selectsOptionalSorted
  },
  SET_SHOW_SELECT_OPTIONAL (state, bool) {
    state.showSelectOptional = bool
  }
}
