<template>
  <v-app>
    <v-main
      v-if="Object.keys(application).length !== 0"
    >
      <!-- mode="out-in" -->
      <transition
        name="fade"
      >
        <router-view />
      </transition>
    </v-main>

    <div
      class="fill-height d-flex align-end"
      style="height: 100%; position: fixed; pointer-events: none; z-index: 2500"
    >
      <PrivacyPolicyConsent
        style="bottom: 0; positon: relative; pointer-events: all;"
      />
    </div>

    <ScrollToTop
      v-if="$vuetify.breakpoint.smAndUp"
    />

    <MainFooter
      :key="$route.name"
      :fixed="isFixed"
      :absolute="isAbsolute"
      :app="isApp"
    />
  </v-app>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { eventBus } from '@/utils/eventbus.js'

import MainFooter from '@/components/main/footer/MainFooter.vue'
import ScrollToTop from '@/components/main/button/BtnScrollToTop.vue'
import PrivacyPolicyConsent from '@/components/base/privacy/PrivacyPolicyConsent.vue'

export default {
  name: 'App',

  metaInfo: {
    title: 'EE-Monitor',
    meta: [
      {
        name: 'description',
        content: 'Auf dieser Webseite wird die Energiewende aus naturschutzfachlicher Sicht räumlich und zeitlich abgebildet.'
      },
      {
        name: 'robots',
        content: 'index,follow'
      },
      {
        property: 'og:title',
        content: 'EE-Monitor - Monitoring für eine naturverträgliche Energiewende'
      },
      {
        property: 'og:determiner',
        content: 'der'
      },
      {
        property: 'og:site_name',
        content: 'EE-Monitor'
      },
      {
        property: 'og:description',
        content: 'Auf dieser Webseite wird die Energiewende aus naturschutzfachlicher Sicht räumlich und zeitlich abgebildet.'
      },
      {
        property: 'og:locale',
        content: 'de_DE'
      },
      {
        property: 'og:locale:alternate',
        content: 'en_EN'
      },
      {
        property: 'og:site_name',
        content: 'EE-Monitor'
      },
      {
        property: 'og:url',
        content: 'https://ee-monitor.de'
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:image',
        content: 'https://ee-monitor.de/assets/og/eemonitor_image.jpg'
      },
      {
        property: 'og:image:type',
        content: 'image/jpeg'
      },
      {
        property: 'og:image:width',
        content: '1200'
      },
      {
        property: 'og:image:height',
        content: '627'
      },
      {
        property: 'og:image:alt',
        content: 'Webseite des EE-Monitor. Auf dieser Webseite wird die Energiewende aus naturschutzfachlicher Sicht räumlich und zeitlich abgebildet.'
      },
      {
        property: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        property: 'twitter:url',
        content: 'https://ee-monitor.de'
      },
      {
        property: 'twitter:title',
        content: 'EE-Monitor - Monitoring für eine naturverträgliche Energiewende'
      },
      {
        property: 'twitter:description',
        content: 'Auf dieser Webseite wird die Energiewende aus naturschutzfachlicher Sicht räumlich und zeitlich abgebildet.'
      }
    ]
  },

  // metaInfo () {
  //   return {
  //     title: 'EE-Monitor',
  //     meta: [
  //       {
  //         name: 'description',
  //         content: 'Auf dieser Webseite wird die Energiewende aus naturschutzfachlicher Sicht räumlich und zeitlich abgebildet.'
  //       },
  //       {
  //         name: 'robots',
  //         content: 'index,follow'
  //       },
  //       {
  //         property: 'og:title',
  //         content: 'EE-Monitor - Monitoring für eine naturverträgliche Energiewende'
  //       },
  //       {
  //         property: 'og:determiner',
  //         content: 'der'
  //       },
  //       {
  //         property: 'og:site_name',
  //         content: 'EE-Monitor'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Auf dieser Webseite wird die Energiewende aus naturschutzfachlicher Sicht räumlich und zeitlich abgebildet.'
  //       },
  //       {
  //         property: 'og:locale',
  //         content: 'de_DE'
  //       },
  //       {
  //         property: 'og:locale:alternate',
  //         content: 'en_EN'
  //       },
  //       {
  //         property: 'og:site_name',
  //         content: 'EE-Monitor'
  //       },
  //       {
  //         property: 'og:url',
  //         content: 'https://ee-monitor.de'
  //       },
  //       {
  //         property: 'og:type',
  //         content: 'website'
  //       },
  //       {
  //         property: 'og:image',
  //         content: `${this.appBaseURL}assets/og/eemonitor_image.jpg`
  //       },
  //       {
  //         property: 'og:image:type',
  //         content: 'image/jpeg'
  //       },
  //       {
  //         property: 'og:image:width',
  //         content: '1200'
  //       },
  //       {
  //         property: 'og:image:height',
  //         content: '627'
  //       },
  //       {
  //         property: 'og:image:alt',
  //         content: 'Webseite des EE-Monitor. Auf dieser Webseite wird die Energiewende aus naturschutzfachlicher Sicht räumlich und zeitlich abgebildet.'
  //       },
  //       {
  //         property: 'twitter:card',
  //         content: 'summary_large_image'
  //       },
  //       {
  //         property: 'twitter:url',
  //         content: 'https://ee-monitor.de'
  //       },
  //       {
  //         property: 'twitter:title',
  //         content: 'EE-Monitor - Monitoring für eine naturverträgliche Energiewende'
  //       },
  //       {
  //         property: 'twitter:description',
  //         content: 'Auf dieser Webseite wird die Energiewende aus naturschutzfachlicher Sicht räumlich und zeitlich abgebildet.'
  //       }
  //     ]
  //   }

  components: {
    MainFooter,
    PrivacyPolicyConsent,
    ScrollToTop
  },

  data () {
    return {
      isAbsolute: false,
      isApp: true,
      isFixed: false,
      route: {},
      vuetifyBreakpoint: undefined
    }
  },

  computed: {
    ...mapState('main', [
      'application',
      'appBaseURL'
    ])
  },

  watch: {
    route: {
      handler: 'watchRouteAndBreakpoint',
      immediate: true,
      deep: true
    },
    vuetifyBreakpoint: {
      handler: 'watchRouteAndBreakpoint',
      immediate: true,
      deep: true
    }
  },

  beforeMount () {
    this.route = this.$route
    this.vuetifyBreakpoint = this.$vuetify.breakpoint
  },

  beforeDestroy () {
    eventBus.$off('push-to-page-error-404')
  },

  async mounted () {
    // Set locale by browser user language
    const userLocale = navigator.language === 'de-DE' ? 'de' : 'en'
    this.SET_LOCALE(userLocale)

    this.isApp = this.$route.name === 'Dashboard' && this.$vuetify.breakpoint.mdAndUp

    await this.set_routes({ router: this.$router })

    await this.set_application_paths()

    await this.get_application()

    // await this.setApplicatonItem()

    eventBus.$on('push-to-page-error-404', () => {
      this.$router.push({
        name: 'ErrorPage404'
      //   params: {
      // TODO Indicate reason of redirect on the next page, e.g. info modal "item not found bla bla"
      //   }
      })
    })
  },

  methods: {
    ...mapActions('main', [
      'set_application_paths',
      'get_application',
      'get_application_item',
      'set_routes'
    ]),

    ...mapMutations('main', [
      'SET_LOCALE'
    ]),

    watchRouteAndBreakpoint () {
      // console.log('$route', this.$route)

      // console.log('$router', this.$router)

      if (this.$route.name === 'Dashboard' ||
      this.$route.name === 'WebGisMain') {
        this.isApp = true

        this.isAbsolute = false

        if (this.$vuetify.breakpoint.smAndDown) {
          this.isFixed = false
        } else {
          this.isFixed = true
        }
      } else {
        this.isApp = true

        this.isAbsolute = true

        if (this.$vuetify.breakpoint.smAndDown) {
          this.isFixed = false
        } else {
          this.isFixed = false
        }
      }
    },

    async setApplicatonItem () {
      // console.log('setApplicatonItem()')
      await this.get_application()
      // await this.setApplicatonItem()
    }
  }
}
</script>

<style lang="scss">
  @import 'assets/icon-font/icons.css';
  @import 'assets/fonts/roboto.scss';
  @import 'assets/esri/themes/light/main.css';

  body {
    caret-color: transparent;
  }

  // TODO Redefine Page Transitions
  // https://v2.vuejs.org/v2/guide/transitions.html
  // https://v2.vuejs.org/v2/guide/transitions.html#CSS-Animations
  // Use https://animate.style/
  // Vuetify https://stackoverflow.com/a/67110874/16963247
  // https://codingbeautydev.com/blog/vuetify-transition/
  // https://markus.oberlehner.net/blog/vue-router-page-transitions/
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.6s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0
  }
</style>
