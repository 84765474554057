import { eventBus } from '@/utils/eventbus.js' // AXIOS

import axios from 'axios'

// import { basemapOptions } from '@/assets/webgis/basemapitems/basemap.js'

import defaultValues from '@/webgis/default_values.js'
import _ from 'lodash'

export default {
  async get_webgis_items ({
    rootState
  }, {
    itemIDs
  }) {
    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/items_webgis/?id__in=${itemIDs.join()}`).catch(err => {
      // NOTE -> 404
      // TODO Error Handling
      eventBus.$emit('push-to-page-error-404')
      console.log('error get_item ', err)
    })

    if (response && response.status === 200) {
      if (response.data.length !== 0) {
        return response.data
      } else {
        // TODO Error handling
        eventBus.$emit('push-to-page-error-404')
      }
    } else {
      console.log('get_webgis_items: No items with that slug in database')
      eventBus.$emit('push-to-page-error-404')
    }
  },

  set_data_query ({
    commit
  }, payload) {
    commit('SET_DATA_QUERY', payload)
  },

  set_item_selected ({
    commit
  }, {
    item
  }) {
    commit('SET_ITEM_SELECTED', item)
  },

  set_items ({
    commit
  }, payload) {
    commit('SET_ITEMS', payload)
  },

  set_layer_statistic_values ({
    commit
  }, payload) {
    commit('SET_LAYER_STATISTIC_VALUES', payload)
  },

  set_layer_values_min_max ({
    commit
  }, payload) {
    commit('SET_LAYER_VALUES_MIN_MAX', payload)
  },

  set_main_menu_open_state ({
    commit
  }, payload) {
    commit('SET_MAIN_MENU_OPEN_STATE', payload)
  },

  set_main_menu_selected_tab ({
    commit
  }, payload) {
    commit('SET_MAIN_MENU_SELECTED_TAB', payload)
  },

  set_map_basemap ({
    commit
  }, payload) {
    commit('SET_MAP_BASEMAP', payload)
  },

  set_mapview_screenpointer ({
    commit
  }, payload) {
    commit('SET_MAPVIEW_SCREENPOINTER', payload)
  },

  set_mapview_center ({
    commit
  }, payload) {
    commit('SET_MAPVIEW_CENTER', payload)
  },

  set_menu_disabled ({
    commit
  }, payload) {
    commit('SET_MENU_DISABLED', payload)
  },

  set_attributes_pointer_move ({
    commit
  }, payload) {
    commit('SET_ATTRIBUTES_POINTER_MOVE', payload)
  },

  set_popup_data ({
    commit
  }, payload) {
    commit('SET_POPUP_DATA', payload)
  },

  set_popup_visibility ({
    commit
  }, payload) {
    commit('SET_POPUP_VISIBILITY', payload)
  },

  set_select_values ({
    commit
  }, {
    selectsBasic,
    selectsOptional
  }) {
    const selectsOptionalFiltered = _.orderBy(selectsOptional.filter(select => select.type.option_type === 'optional'), ['my_order', ['asc']])
    // const selectsOptionalQueryKeys = selectsOptionalFiltered.map(select => select.type.query_key)

    // Get all queryKeys of all levels
    const getAllSelectObj = (obj) => {
      const l1 = obj
      const l2 = obj.values.map((value) => {
        if ('data_option_sub_class' in value) {
          if (value.data_option_sub_class.length !== 0) {
            return value.data_option_sub_class.map(sc => getAllSelectObj(sc)).flat()
          } else {
            return ''
          }
        } else {
          return ''
        }
      }).filter(arr => arr).flat()
      return [l1, ...l2]
    }

    // const selectsOptionalFiltered = [...new Set(selectsOptional.map(obj => getAllSelectObj(obj)).flat())]
    // console.log('selectsOptionalFiltered ', selectsOptionalFiltered)
    const selectsOptionalQueryKeys = [...new Set(selectsOptionalFiltered.map(select => select.type.query_key))]
    // console.log('selectsOptionalQueryKeys', selectsOptionalQueryKeys)
    commit('SET_SELECTS_BASIC', {
      selectsBasic
    })
    commit('SET_SELECTS_OPTIONAL', {
      selectsOptional: selectsOptionalFiltered // [...new Set(selectsOptionalFiltered.map(opt => _.cloneDeep(opt)))]
    })
    commit('SET_SELECTS_OPTIONAL_QUERY_KEYS', {
      selectsOptionalQueryKeys
    })
    return true
  },

  set_viewdiv_dimensions ({
    commit
  }, payload) {
    commit('SET_VIEWDIV_DIMENSIONS', payload)
  },

  set_webgis_defaults ({
    commit
  }) {
    commit('main/SET_DIALOG_WELCOME', true, {
      root: true
    })
    commit('SET_MAP_BASEMAP_ITEMS', defaultValues.basemapItems)
    commit('SET_MAP_BASEMAP', defaultValues.basemapDefault)
    commit('SET_MAPVIEW_SCREENPOINTER', null)
  },

  show_mapview_updating_indicator ({
    commit
  }, payload) {
    commit('SHOW_MAPVIEW_UPDATING_INDICATOR', payload)
  },

  async update_data_query ({
    commit,
    state
  }, {
    query
  }) {
    const queryState = state.itemDataQuery
    // for (const [queryStateKey, queryStateValue] of Object.entries(queryState)) {
    //   for (const [queryKey, queryValue] of Object.entries(query)) {
    //     if (queryKey === queryStateKey) {
    //       // console.log('queryKey', queryKey)
    //       queryState[queryKey] = query[queryKey]
    //     }
    //   }
    // }
    for (const [queryKey, queryValue] of Object.entries(query)) {
      for (const [queryStateKey, queryStateValue] of Object.entries(queryState)) {
        if (queryKey === queryStateKey) {
          queryState[queryStateKey] = queryValue
        }
      }
    }

    commit('SET_DATA_QUERY', {
      query: queryState
    })
  }

}
