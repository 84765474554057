<template>
  <div
    class="fill-height"
  >
    <v-card
      ref="card_map"
      flat
      class="d-flex flex-column fill-height"
      rounded="0"
      style="background: none;"
    >
      <div
        class="d-flex flex-shrink-1 order-0"
      >
        <v-card
          ref="card_map_menu"
          flat
          rounded="0"
          class="d-flex px-6"
          style="background: #e1e8ee; width: 100%; padding-top: 2px; height: 30px;"
        >
          <DashboardItemMenu
            v-if="bkgItems.length !== 0"
            :select-query="['spatial_unit_2', 'spatial_unit_4']"
            :select-additional-values="additionalValues(['spatial_unit_2', 'spatial_unit_4'])"
            @isUpdated="isMenuUpdated = true"
          />
        </v-card>
      </div>

      <div
        class="d-flex flex-column fill-height pt-3 px-3"
      >
        <div
          class="d-flex fill-height"
        >
          <v-card
            ref="sheet_map_wrapper"
            rounded="0"
            text
            elevation="0"
            class="flex-grow-1 d-flex fill-height pa-0 ma-0"
          >
            <v-overlay
              opacity="0"
              color="#fff"
              absolute
              style="pointer-events: none; z-index: 4;"
            >
              <div
                class="d-flex flex-row align-start justify-start fill-height font-weight-light text-md-h4 text-lg-h3  ma-lg-5 ma-md-3"
                style="color: var(--v-primary-base)"
              >
                {{ dashboardItemsYearSelected }}
              </div>
            </v-overlay>

            <ChartsMap
              :dashboard-item="dashboardItem"
              :item-data="itemData"
              :spatial-type-id="spatialTypeId"
              :height="height"
              class="fill-height"
            />
          </v-card>
        </div>

        <div
          v-if="dashboardItemsDataYears.length >= 2"
          class="flex-shrink-1 order-0"
        >
          <v-card
            ref="card_map_timeslider"
            flat
            rounded="0"
            class="mb-3 pa-1"
          >
            <TimeSlider
              :item-data="itemData"
            />
          </v-card>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>

// import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

// LODASH
import _ from 'lodash'

// COMPONENTS
import DashboardItemMenu from '@/components/dashboard/inputs/DashboardItemMenu.vue'
import TimeSlider from '@/components/dashboard/slider/TimeSlider.vue'
import ChartsMap from '@/components/dashboard/charts/ChartsMap.vue'

export default {
  name: 'ChartsMapWrapper',

  components: {
    DashboardItemMenu,
    TimeSlider,
    ChartsMap
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default: 0
    },
    itemData: {
      type: Array,
      default () {
        return []
      }
    },
    spatialTypeId: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      isMenuUpdated: false
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataQuery',
      'dashboardItemsDataYears',
      'dashboardItemsSelectsBasic',
      'dashboardItemsYearSelected'
    ])
  },

  watch: {
    // locale (newVal, oldVal) {
    // },

    // async spatialTypeId (newVal, oldVal) {
    //   this.bkgItems = await this.get_bkg_items({
    //     ade: this.spatialTypeId,
    //     gf: 4
    //   })
    // },

    isMenuUpdated (newVal, oldVal) {
      // REVIEW
      if (newVal === true) {
        this.isMenuUpdated = false
      }
    }
  },

  methods: {
    ...mapActions('dashboard_main', [
      'get_bkg_items'
    ]),

    additionalValues (valueQueryKeys) {
      const addionalValuesNumbers = valueQueryKeys.map((qk) => {
        return qk.substring(qk.length - 1)
      })

      const selects = valueQueryKeys.map((qk, idx, arr) => {
        let bkgValues

        const spatialTypeId = qk.substring(qk.length - 1)

        // console.log('spatialTypeId ', spatialTypeId)
        // console.log('this.spatialTypeId ', this.spatialTypeId)

        if (spatialTypeId <= this.spatialTypeId) {
          if (idx === 0) {
            bkgValues = this.bkgItems.filter((item) => {
              return item.ade === Number(spatialTypeId)
            })
          } else {
            const previousOption = this.dashboardItemsDataQuery.filter((dq) => {
              return Object.keys(dq).some((k) => {
                return ~k.indexOf(addionalValuesNumbers[idx - 1])
              })
            })

            // console.log('previousOption ', previousOption)

            if (previousOption.length !== 0) {
              const previousOptionValue = previousOption[0][Object.keys(previousOption[0])[0]]

              if (previousOptionValue === undefined || previousOptionValue === null) {
                bkgValues = this.bkgItems.filter(item =>
                  item.ade === Number(spatialTypeId)
                )
              } else {
                bkgValues = this.bkgItems.filter(item =>
                  item.ade === Number(spatialTypeId) &&
            item.ags.startsWith(previousOptionValue)
                )
              }
            }
          }
        } else {
          bkgValues = []
        }

        // console.log(qk, bkgValues)

        return {
          queryKey: qk,
          values: bkgValues
        }
      })

      return selects
    }

    // TODO later --> needs debugging
    // additionalValues (valueQueryKeys) {
    //   let spatialTypeId

    //   const addionalValuesNumbers = valueQueryKeys.map((qk) => {
    //     return qk.substring(qk.length - 1)
    //   })

    //   const valueQueryKeysFiltered = valueQueryKeys.filter(key => Number(key.substring(key.length - 1)) <= this.spatialTypeId)
    //   console.log('valueQueryKeysFiltered ', valueQueryKeysFiltered)

    //   const selects = valueQueryKeysFiltered.map((qk, idx, arr) => {
    //     let bkgValues

    //     spatialTypeId = qk.substring(qk.length - 1)

    //     if (idx === 0) {
    //       bkgValues = this.bkgItems.filter((item) => {
    //         return item.ade === Number(spatialTypeId)
    //       })
    //     } else {
    //       const previousOption = this.dashboardItemsDataQuery.filter((dq) => {
    //         return Object.keys(dq).some((k) => {
    //           return ~k.indexOf(addionalValuesNumbers[idx - 1])
    //         })
    //       })

    //       if (previousOption.length !== 0) {
    //         const previousOptionValue = previousOption[0][Object.keys(previousOption[0])[0]]

    //         if (previousOptionValue === undefined || previousOptionValue === null) {
    //           bkgValues = this.bkgItems.filter(item =>
    //             item.ade === Number(spatialTypeId)
    //           )
    //         } else {
    //           bkgValues = this.bkgItems.filter(item =>
    //             item.ade === Number(spatialTypeId) &&
    //         item.ags.startsWith(previousOptionValue)
    //           )
    //         }
    //       }
    //     }

    //     console.log(qk, bkgValues)

    //     return {
    //       queryKey: qk,
    //       values: bkgValues
    //     }
    //   })

    //   return selects
    // }

    // REVIEW --> called in mounted
    // getAllBkgItems ({ adeArray }) {
    //   const bkgItemsFiltered = adeArray.map(async (ade) => {
    //     const agsName = await this.get_bkg_items({ ade: ade, gf: 4 })
    //     return agsName
    //   })
    //   return Promise.all(bkgItemsFiltered)
    // },
  }
}
</script>

<style lang="scss" scoped>

</style>
