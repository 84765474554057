<template>
  <div
    class="d-flex flex-column"
  >
    <ChartCardHeader
      :title="titleText"
    />

    <v-row
      v-if="itemDataFiltered.length !== 0"
      class="d-flex fill-height align-center justify-center"
    >
      <!-- <v-col
        v-for="(ds, index) in itemDataFiltered"
        :key="index"
      >
        {{ getSelectOptionalItemTitleTitle(ds) }}
        {{ ds.data_value.toLocaleString(locale) }} {{ dataValueUnit }}
      </v-col> -->
      <v-col>
        <v-slide-group
          v-model="model"
          center-active
          show-arrows
          class="d-flex align-center pa-4"
        >
          <v-slide-item
            v-for="ds in itemDataFiltered"
            :key="ds"
            v-slot="{ active, toggle }"
            class="d-flex align-self-center"
          >
            <v-card
              elevation="0"

              :color="active ? '' : 'grey lighten-1'"
              class="ma-4"
              height="150"
              width="150"
            >
              <!-- :height="active ? 200 : 150"
                :width="active ? 200 : 150" -->
              {{ getSelectOptionalItemTitleTitle(ds) }}
              {{ ds.data_value.toLocaleString(locale) }} {{ dataValueUnit }}
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//  IMPORT
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapMutations, mapActions } from 'vuex'

import _ from 'lodash'
import axios from 'axios'

import { mdiMinus } from '@mdi/js'

// COMPONENTS
import ChartCardHeader from '@/components/dashboard/cardtitle/ChartCardHeader.vue'

export default {
  name: 'InfoOneyear',

  components: {
    ChartCardHeader
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },
    cardHeight: {
      type: Number,
      default: 0
    },
    // itemData: {
    //   type: Array,
    //   default () {
    //     return []
    //   }
    // },
    spatialTypeId: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      model: 10,
      headerTitle: 'title',
      itemData: [],
      itemDataFiltered: [],
      agsId: '00000000',
      dataValueUnit: '',
      selectsOptional: [],
      selectsOptionalQueryKey: '',
      mdiMinus: mdiMinus
    }
  },

  computed: {
    ...mapState('main', [
      'baseURL',
      'locale'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItemsDataQuery'
    ]),

    columns () {
      if (this.$vuetify.breakpoint.xl) {
        return 4
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3
      }

      if (this.$vuetify.breakpoint.md) {
        return 2
      }

      return 1
    },

    titleText () {
      return this.locale === 'de' ? 'Bundesrepublik Deutschland' : 'Germany'
    }
  },

  watch: {
    itemData (newData, oldData) {
      this.updateText(newData)
    }
  },

  async mounted () {
    // Get value unit title_short (string)
    const dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.dashboardItem
    })
    this.dataValueUnit = dataValueUnitObj.title_short_de

    // this.updateText(this.itemData)

    this.selectsOptional = this.dashboardItem.data_options.filter(opt => opt.type.option_type === 'optional' && opt.type.select_type === 'select-panel-list')
    this.selectsOptionalQueryKey = this.selectsOptional[0].type.query_key

    this.itemData = await this.getData({ item: this.dashboardItem })
  },

  methods: {
    ...mapActions('dashboard_items', [
      // 'get_axis_value_interval',
      // 'get_datasets_value_min',
      // 'get_datasets_ags',
      // 'get_datasets_value_max',
      // 'get_datasets_value_min',
      // 'get_datasets_years',
      'get_item_data_value_unit'
      // 'get_max_fraction_digits'
    ]),

    async getData ({ item }) {
      const dataOptions = this.dashboardItemsDataQuery.filter(opt => 'data_category' in opt)[0]
      const dataCategory = dataOptions?.data_category || ''

      const agsId = '00000000'
      const spatialType = 1

      const response = await axios.get(`${this.baseURL}api/v1/eemonitor/data/?item=${item.id}&spatial_type=${spatialType}&ags=${agsId}&data_category=${dataCategory}`)
        .catch(err => console.log('error get_item_data ', err)) // TODO Error Handling

      return response.data
    },

    getSelectOptionalItemTitleTitle (ds) {
      return this.selectsOptional[0].values.filter(opt => opt.value_id === ds[this.selectsOptionalQueryKey])[0][`title_${this.locale}`]
    },

    updateText (data) {
      const dataCloned = _.cloneDeep(data)
      this.itemDataFiltered = dataCloned.filter(ds => ds.ags === this.agsId)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
