import axios from 'axios'
import { eventBus } from '@/utils/eventbus.js'

export default {
  async get_application ({
    commit,
    dispatch,
    rootState,
    state
  }) {
    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/application/`).catch(err => {
      // NOTE -> 404
      // TODO Error Handling
      eventBus.$emit('push-to-page-error-404')
      console.log('error get_application_item ', err)
    })

    if (response && response.status === 200) {
      if (Object.keys(response.data).length !== 0) {
        commit('SET_APPLICATION', response.data[0])
        return response.data[0]
      } else {
        // TODO Error handling
        eventBus.$emit('push-to-page-error-404')
      }
    } else {
      // TODO
      eventBus.$emit('push-to-page-error-404')
    }
  },

  async get_application_item ({
    commit,
    dispatch,
    rootState,
    state
  }, {
    applicatonName
  }) {
    // console.log('get_application_item ', applicatonName)
    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/applicationitems/?app_name=${applicatonName}`).catch(err => {
      // NOTE -> 404
      // TODO Error Handling
      eventBus.$emit('push-to-page-error-404')
      console.log('error get_application_item ', err)
    })

    if (response && response.status === 200) {
      if (response.data.length !== 0) {
        // REVIEW Possible interference because state dashboardItems array is empty on init
        commit('SET_APPLICATION_ITEM_SELECTED', response.data[0])
        return response.data[0]
      } else {
        // TODO Error handling
        eventBus.$emit('push-to-page-error-404')
      }
    } else {
      // TODO Error handling
      eventBus.$emit('push-to-page-error-404')
    }
  },

  set_application_paths ({
    commit
  }) {
    commit('SET_APPLICATION_PATHS')
  },

  set_dialog_welcome ({
    commit
  }, payload) {
    commit('SET_DIALOG_WELCOME', payload)
  },

  set_locale ({
    commit,
    state
  }, {
    item,
    locale,
    route
  }) {
    if (locale !== '') {
      if (locale === 'de') {
        commit('SET_LOCALE', 'en')
      } else {
        commit('SET_LOCALE', 'de')
      }

      if (route.name === 'Dashboard') {
        // If in Dashboard set URL slug path to selected language
        history.pushState({}, null, item[`slug_${state.locale}`])
      }
    } else {
      const routeSlug = route.params.slug

      if (routeSlug === item.slug_de) {
        commit('SET_LOCALE', 'de')
      } else if (routeSlug === item.slug_en) {
        commit('SET_LOCALE', 'en')
      }
    }

    eventBus.$emit('resize-select-main-item-list')
    eventBus.$emit('resize-dashboard')
    eventBus.$emit('set-locale', locale)
  },

  set_menu_disabled ({
    commit
  }, payload) {
    commit('SET_MENU_DISABLED', payload)
  },

  set_navigation_breadcrumbs ({
    commit
  }, {
    locale,
    route
  }) {
    const pathArray = route.path.split('/')
    pathArray.shift()
    const breadCrumbs = [] // needed to handle the intermediary entries for nested vue routes

    let breadcrumb = ''
    let lastIndexFound = 0
    let disabled

    for (let i = 0; i < pathArray.length; ++i) {
      breadcrumb = `${breadcrumb}${'/'}${pathArray[i]}`

      if (route.matched[i] && Object.hasOwnProperty.call(route.matched[i], 'meta') && Object.hasOwnProperty.call(route.matched[i].meta, 'breadCrumb') && route.matched[i].meta.breadcrumb === true) {
        // FIXME temporary workaround to make the Regionreport breadcrumb item not clickable. It is clickable because it has an (invisible) child route
        let disabled
        if (route.matched[i].name === 'ReportView') {
          disabled = true
        } else {
          disabled = i + 1 === pathArray.length
        }

        breadCrumbs.push({
          disabled: disabled,
          exact: true,
          link: true,
          text: route.matched[i].meta[`title_${locale}`] || pathArray[i],
          // href: i !== 0 && pathArray[i - (i - lastIndexFound)]
          //   ? '/' + pathArray[i - (i - lastIndexFound)] + breadcrumb
          //   : breadcrumb,
          to: {
            name: route.matched[i].name
          }
        })
        lastIndexFound = i
        breadcrumb = ''
      }
    }

    commit('SET_NAVIGATION_BREADCRUMBS', breadCrumbs)
    return breadCrumbs
  },

  set_privacy_consent ({
    commit
  }, bool) {
    commit('SET_PRIVACY_CONSENT', bool)
  },

  async set_routes ({
    commit
  }, {
    router
  }) {
    const routes = router.options.routes.filter(route => route.meta.drawer === true)
    const routesFiltered = routes.map(route => {
      if (route.children !== undefined) {
        const childrenFiltered = route.children.filter(child => child.meta.drawer === true)
        route.children = childrenFiltered
        return route
      } else {
        return route
      }
    })
    commit('SET_ROUTES', routesFiltered)
  },

  set_state_navigation_drawer ({
    commit
  }, bool) {
    commit('SET_STATE_NAVIGATION_DRAWER', bool)
  }

}
