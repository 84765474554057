<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="200"
    close-delay="200"
    :disabled="$vuetify.breakpoint.xsOnly"
  >
    <v-btn
      class="btn_menu"
      depressed
      color="white"
      plain
      elevation="0"
      @click="openDialog"
    >
      <v-icon
        size="14"
        class="mr-2"
      >
        {{ mdiTextBoxEditOutline }}
      </v-icon>

      <span
        v-if=" hover === false"
      >
        <span
          v-if="locale === 'de'"
        >
          Zitation
        </span>

        <span
          v-else
        >
          Citation
        </span>
      </span>

      <v-expand-x-transition>
        <span
          v-if="hover"
        >
          <span
            v-if="locale === 'de'"
          >
            EE-Monitor zitieren
          </span>

          <span
            v-else
          >
            How to cite EE-Monitor
          </span>
        </span>
      </v-expand-x-transition>
    </v-btn>
  </v-hover>
</template>

<script>
import { mapState } from 'vuex'
import { eventBus } from '@/utils/eventbus.js'

import { mdiTextBoxEditOutline } from '@mdi/js'

export default {
  name: 'BtnCititationDialog',

  data () {
    return {
      mdiTextBoxEditOutline: mdiTextBoxEditOutline
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  },

  methods: {
    openDialog () {
      eventBus.$emit('open-dialog-cititation-info')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
