import {
  mdiHome,
  mdiMonitorDashboard,
  mdiMapSearchOutline
} from '@mdi/js'

export default () => ({
  appBaseURL: '',
  appDjangoPort: '',
  appPort: null,
  appRootFolder: '',
  application: {},
  applicationsItemSelected: undefined,
  baseURL: '',
  isMenuDisabled: false,
  isNavigationDrawerOpen: false,
  isPrivacyConsentOpen: true,
  isWelcomeDialogOpen: false,
  locale: 'de',
  navigationBreadcrumbs: [],
  routes: [],
  user: {
    isAuthenticated: true,
    // FIXME for deployment
    token: ''
  }
})
