<template>
  <div>
    <v-dialog
      :value="isWelcomeDialogOpen"
      no-click-animation
      :retain-focus="false"
      hide-overlay
      persistent
      scrollable
      max-width="800"
      class="webgis-dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <!-- @#click:outside="clickOutside" -->
      <div
        style="background: #ffffff;"
      >
        <Transition
          name="fade"
          mode="in-out"
          appear
        >
          <slot
            :default="{content}"
          />
        </Transition>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DialogStartApp',

  data () {
    return {
      root: null,
      content: null
    }
  },

  computed: {
    ...mapState('main', [
      'locale',
      'isWelcomeDialogOpen'
    ]),

    ...mapState('webgis', [
      'items'
    ])
  },

  watch: {
    $vuetify: {
      handler (vuetify) {
        this.$nextTick(() => {
          this.root.style.setProperty('--dialog-top', vuetify.application.top + 'px')
          this.root.style.setProperty('--dialog-bottom', vuetify.application.footer + 'px')
          this.root.style.setProperty('--dialog-height', vuetify.breakpoint.height - vuetify.application.top - vuetify.application.footer + 'px')
        })
      },
      deep: true,
      immediate: true
    }
  },

  mounted () {
    this.root = document.documentElement
  },

  methods: {
    ...mapActions('main', [
      'set_dialog_welcome'
    ]),

    ...mapActions('webgis', [
      'set_main_menu_open_state'
    ]),

    // clickOutside (event) {
    //   console.log('clickOutside event ', event)
    // },

    getItemTechnologyID (item) {
      return item.categories.filter(cat => cat.type.query_key === 'technology')[0].value.value_id
    },

    selectItem (item) {
      eventBus.$emit('select-webgis-item', item)

      this.set_dialog_welcome(false)

      if (this.$vuetify.breakpoint.mdAndUp !== undefined) {
        if (this.$vuetify.breakpoint.mdAndUp) {
          this.$nextTick(() => {
            this.set_main_menu_open_state(true)
          })
        } else {
          this.set_main_menu_open_state(false)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.btn-item-select-dialog {
   .v-btn__content {
     width: 100% !important;
  }
}

.webgis-dialog {
  z-index: 2 !important;
}
:root {
  --dialog-top: 0px;
  --dialog-bottom: 0px;
  --dialog-height: auto;
}

.v-dialog--fullscreen {
  top: var(--dialog-top);
  bottom: var(--dialog-bottom);
  height: var(--dialog-height);
}

</style>
