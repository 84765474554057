<template>
  <transition
    name="scale-transition"
  >
    <v-card
      v-if="isPopupVisible &&
        graphics.length !== 0
      "
      v-show="
        popup.positionX !== 0 &&
          popup.positionY !== 0
      "
      :class="[
        $vuetify.breakpoint.xsOnly
          ? ''
          : popupClass,
      ]"
      :style="[
        $vuetify.breakpoint.xsOnly
          ? { width: '100%', top: '0', right: '0px', 'max-width': '100%' }
          : { 'min-width': '500px', 'width': '100%', left: popup.positionX + 'px', top: popup.positionY + 'px' },
      ]"
      class="popupdiv"
      style="position: absolute; z-index: 11; transition: all 0.5;"
      :rounded="$vuetify.breakpoint.smAndUp"
    >
      <v-toolbar
        dark
        dense
        flat
        color="primary"
        height="36"
        class="popup-toolbar"
        :style="[$vuetify.breakpoint.smAndUp ? {'border-radius': '6px 6px 0 0'} : {'border-radius': 0}]"
      >
        <v-toolbar-title
          class="d-flex justify-start align-center text-h6 font-weight-medium"
        >
          {{ itemSelected[`title_${locale}`] }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="closePopup"
          >
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-row
        v-if="graphics.length !== 0"
        class="pa-3 fill-height grow"
        no-gutters
      >
        <v-col
          cols="12"
        >
          <!-- CAROUSEL POPUP CONTENT -->

          <v-carousel
            :value="carouselValue"
            :show-arrows="false"
            hide-delimiters
            height="auto"
          >
            <v-carousel-item
              v-for="(graphic, index) in graphics"
              :key="index"
            >
              <!-- INSTALLATION COUNT -->

              <v-row
                v-if="'installation_count' in graphic.attributes"
                no-gutters
              >
                <v-col
                  :cols="carouselColsLeft"
                >
                  <span
                    v-if="graphic.attributes.installation_count > 1"
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Anlageneinheiten</span>
                    <span
                      v-else
                    >
                      Installation units</span>
                  </span>
                  <span
                    v-else
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Anlageneinheit</span>
                    <span
                      v-else
                    >
                      Installation unit</span>
                  </span>

                  <!-- <v-tooltip
                    color="rgb(225, 232, 238);"
                    bottom
                    max-width="230"
                    min-width="150"
                  >
                    <template
                      #activator="{ on }"
                    >
                      <div
                        class="d-inline-flex align-center"
                      >
                        <v-icon
                          style="bottom: 3px;"
                          class=""
                          size="14"
                          color="primary"
                          v-on="on"
                        >
                          {{ mdiHelpCircleOutline }}
                        </v-icon>
                      </div>
                    </template>
                    <div
                      class="text-center"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Blabla
                      </span>
                      <span
                        v-else
                      >
                        Blabla
                      </span>
                    </div>
                  </v-tooltip> -->

                  <span>:</span>
                </v-col>

                <v-col
                  :cols="carouselColsRight"
                  class="font-weight-medium"
                >
                  {{ graphic.attributes.installation_count }} <span
                    v-if="graphic.attributes.installation_count !== popupData.length"
                  ><span
                    v-if="locale==='de'"
                  >von</span><span
                    v-else
                  >of</span> {{ popupData.length }}</span>
                </v-col>
              </v-row>

              <!-- DATE COMMISSIONING -->

              <v-row
                no-gutters
              >
                <v-col
                  :cols="carouselColsLeft"
                >
                  <template
                    v-if="'date_commissioning_range' in graphic.attributes"
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Inbetriebnahmezeitraum:
                    </span>
                    <span
                      v-else
                    >
                      Date commissioning range:
                    </span>
                  </template>
                  <template
                    v-else
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Inbetriebnahme:
                    </span>
                    <span
                      v-else
                    >
                      Date commissioning:
                    </span>
                  </template>
                </v-col>
                <v-col
                  :cols="carouselColsRight"
                  class="font-weight-medium"
                >
                  <template
                    v-if="'date_commissioning_range' in graphic.attributes"
                  >
                    {{ new Date(`${graphic.attributes.date_commissioning_range[0]}`).toLocaleDateString(locale, dataOptions) }} - {{ new Date(`${graphic.attributes.date_commissioning_range[1]}`).toLocaleDateString(locale, dataOptions) }}
                  </template>
                  <template
                    v-else
                  >
                    {{ new Date(`${graphic.attributes.date_commissioning}`).toLocaleDateString(locale, dataOptions) }}
                  </template>
                </v-col>
              </v-row>

              <!-- DATE DECOMMISSIONING -->

              <v-row
                v-if="graphic.attributes.date_decommissioning !== null"
                no-gutters
              >
                <v-col
                  :cols="carouselColsLeft"
                >
                  <template
                    v-if="'date_commissioning_range' in graphic.attributes"
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Außerbetriebnahmezeitraum:
                    </span>
                    <span
                      v-else
                    >
                      Date decommissioning range:
                    </span>
                  </template>
                  <template
                    v-else
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Außerbetriebnahme:
                    </span>
                    <span
                      v-else
                    >
                      Date decommissioning:
                    </span>
                  </template>
                </v-col>
                <v-col
                  :cols="carouselColsRight"
                  class="font-weight-medium"
                >
                  <span>
                    {{ new Date(`${graphic.attributes.date_decommissioning}`).toLocaleDateString(locale,dataOptions) }}
                  </span>
                </v-col>
              </v-row>

              <!-- CAPACITY INSTALLED -->

              <v-row
                no-gutters
              >
                <v-col
                  :cols="carouselColsLeft"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    Installierte Leistung:
                  </span>
                  <span
                    v-else
                  >
                    Installed capacity:
                  </span>
                </v-col>
                <v-col
                  :cols="carouselColsRight"
                  class="font-weight-medium"
                >
                  <span
                    v-if="graphic.attributes.capacity_installed !== null"
                  >
                    <template
                      v-if="'capacity_installed_sum' in graphic.attributes"
                    >
                      {{ getCapacityValueString(graphic.attributes.capacity_installed_sum, true) }}
                    </template>
                    <template
                      v-else
                    >
                      {{ getCapacityValueString(graphic.attributes.capacity_installed, true) }}
                    </template>
                  </span>
                  <span
                    v-else
                  >
                    -
                  </span>
                </v-col>
              </v-row>

              <!-- HUB HEIGHT (only Windenergy) -->

              <v-row
                v-if="'windenergy_hub_height' in graphic.attributes"
                no-gutters
              >
                <v-col
                  :cols="carouselColsLeft"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    Nabenhöhe:
                  </span>
                  <span
                    v-else
                  >
                    Hub height:
                  </span>
                </v-col>
                <v-col
                  :cols="carouselColsRight"
                  class="font-weight-medium"
                >
                  <span
                    v-if="graphic.attributes.windenergy_hub_height !== null"
                  >
                    {{ (graphic.attributes.windenergy_hub_height).toLocaleString(locale, {minimumFractionDigits: 0, maximumFractionDigits: 1}) }} m
                  </span>
                  <span
                    v-else
                  >
                    -
                  </span>
                </v-col>
              </v-row>

              <!-- ROTOR DIAMETER (only Windenergy) -->

              <v-row
                v-if="'windenergy_rotor_diameter' in graphic.attributes"
                no-gutters
              >
                <v-col
                  :cols="carouselColsLeft"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    Rotordurchmesser:
                  </span>
                  <span
                    v-else
                  >
                    Rotor diameter:
                  </span>
                </v-col>
                <v-col
                  :cols="carouselColsRight"
                  class="font-weight-medium"
                >
                  <span
                    v-if="graphic.attributes.windenergy_rotor_diameter !== null"
                  >
                    {{ (graphic.attributes.windenergy_rotor_diameter).toLocaleString(locale, {minimumFractionDigits: 0, maximumFractionDigits: 1}) }} m
                  </span>
                  <span
                    v-else
                  >
                    -
                  </span>
                </v-col>
              </v-row>

              <!-- PLANT AREA (atm only PV) -->

              <v-row
                v-if="'area_size' in graphic.attributes"
                no-gutters
              >
                <v-col
                  :cols="carouselColsLeft"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    Anlagenfläche (aller Einheiten):
                  </span>
                  <span
                    v-else
                  >
                    Plant area (of all units):
                  </span>
                </v-col>
                <v-col
                  :cols="carouselColsRight"
                  class="font-weight-medium"
                >
                  <span
                    v-if="graphic.attributes.area_size !== null"
                  >
                    {{ (graphic.attributes.area_size).toLocaleString(locale, {minimumFractionDigits: 0, maximumFractionDigits: 3}) }} ha
                  </span>
                  <span
                    v-else
                  >
                    -
                  </span>
                </v-col>
              </v-row>

              <!-- OPTIONAL PLANT PROPERTIES -->

              <div
                v-for="(prop, idx) in itemSelectsOptionalDropdown"
                :key="idx"
              >
                <v-row
                  no-gutters
                >
                  <v-col
                    :cols="carouselColsLeft"
                  >
                    <span>
                      {{ prop.type[`title_${locale}`] }}:
                    </span>
                  </v-col>
                  <v-col
                    :cols="carouselColsRight"
                    class="font-weight-medium"
                  >
                    <span
                      v-if="graphic.attributes[prop.type.query_key] !== null"
                    >
                      {{ prop.values.filter(val => val.value_id === graphic.attributes[prop.type.query_key])[0][`title_${locale}`] }}
                    </span>
                    <span
                      v-else
                    >
                      -
                    </span>
                  </v-col>
                </v-row>

                <!-- ADDITIONAL SUBCLASS PROPERTY-->

                <div
                  v-if="prop.values.filter(val => val.value_id === graphic.attributes[prop.type.query_key])[0].data_option_sub_class.length !== 0"
                >
                  <v-row
                    v-for="(propSubclass, indexSubclass) in prop.values.filter(val => val.value_id === graphic.attributes[prop.type.query_key])[0].data_option_sub_class"
                    :key="indexSubclass"
                    no-gutters
                  >
                    <v-col
                      v-if="propSubclass"
                      :cols="carouselColsLeft"
                    >
                      <span>
                        {{ propSubclass.type[`title_${locale}`] }}:
                      </span>
                    </v-col>
                    <v-col
                      v-if="propSubclass"
                      :cols="carouselColsRight"
                      class="font-weight-medium"
                    >
                      <div
                        v-if="graphic.attributes[propSubclass.type.query_key] !== null"
                        class="d-flex flex-nowrap"
                      >
                        {{ propSubclass.values.filter(val => val.value_id === graphic.attributes[propSubclass.type.query_key])[0][`title_${locale}`] }}
                      </div>
                      <span
                        v-else
                      >
                        -
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <!-- WINDENERGY MANUFACTURER -->

              <v-row
                v-if="'windenergy_manufacturer' in graphic.attributes"
                no-gutters
              >
                <v-col
                  :cols="carouselColsLeft"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    Hersteller:
                  </span>
                  <span
                    v-else
                  >
                    Manufacturer:
                  </span>
                </v-col>
                <v-col
                  :cols="carouselColsRight"
                  class="font-weight-medium"
                >
                  <span
                    v-if="graphic.attributes.windenergy_manufacturer !== null"
                  >
                    {{ graphic.attributes.windenergy_manufacturer }}
                  </span>
                  <span
                    v-else
                  >
                    -
                  </span>
                </v-col>
              </v-row>

              <!-- WINDENERGY MANUFACTURER TURBINE TYPE -->

              <v-row
                v-if="'windenergy_turbine_type' in graphic.attributes"
                no-gutters
              >
                <v-col
                  :cols="carouselColsLeft"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    Turbinentyp:
                  </span>
                  <span
                    v-else
                  >
                    Turbine type:
                  </span>
                </v-col>
                <v-col
                  :cols="carouselColsRight"
                  class="font-weight-medium"
                >
                  <span
                    v-if="graphic.attributes.windenergy_turbine_type !== null"
                  >
                    {{ graphic.attributes.windenergy_turbine_type }}
                  </span>
                  <span
                    v-else
                  >
                    -
                  </span>
                </v-col>
              </v-row>

              <!-- REGION FEDERAL STATE -->

              <div
                class="mt-2 pt-2"
                style="border-top: dotted 1px var(--v-secondary-lighten1);"
              >
                <v-row
                  v-if="graphic.attributes.bkg_ags !== null"
                  no-gutters
                >
                  <v-col
                    :cols="carouselColsLeft"
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Bundesland:
                    </span>
                    <span
                      v-else
                    >
                      Federal state:
                    </span>
                  </v-col>
                  <v-col
                    :cols="carouselColsRight"
                    class="font-weight-medium"
                  >
                    <span>
                      {{ getAgsGenTitle({ags: graphic.attributes.bkg_ags.slice(0,2)}) }}
                    </span>
                  </v-col>
                </v-row>

                <!-- REGION COUNTY -->

                <v-row
                  v-if="graphic.attributes.bkg_ags !== null"
                  no-gutters
                >
                  <v-col
                    :cols="carouselColsLeft"
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Landkreis:
                    </span>
                    <span
                      v-else
                    >
                      County:
                    </span>
                  </v-col>
                  <v-col
                    :cols="carouselColsRight"
                    class="font-weight-medium"
                  >
                    <span>
                      {{ getAgsGenTitle({ags: graphic.attributes.bkg_ags}) }}
                    </span>
                  </v-col>
                </v-row>

                <!-- LOCATION COORDINATES -->

                <v-row
                  no-gutters
                >
                  <v-col
                    :cols="carouselColsLeft"
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      Koordinaten (Lat, Lon):
                    </span>
                    <span
                      v-else
                    >
                      Coordinates (Lat, Lon):
                    </span>
                  </v-col>
                  <v-col
                    :cols="carouselColsRight"
                    class="font-weight-medium"
                  >
                    <span
                      v-if="graphic.attributes.location_lat !== null && graphic.attributes.location_lon !== null"
                    >
                      {{ graphic.attributes.location_lat.toFixed(6) }}, {{ graphic.attributes.location_lon.toFixed(6) }}
                    </span>

                    <span
                      v-else
                    >
                      -&nbsp;,&nbsp;-
                    </span>
                  </v-col>
                </v-row>

                <!-- LOCATION ACCURACY INFO -->

                <v-row
                  v-if="graphic.attributes.location_accuracy !== null && graphic.attributes.location_accuracy !== 1"
                  no-gutters
                >
                  <v-col
                    cols="auto"
                    class="mr-2"
                    style="line-height: 1.5rem;"
                  >
                    <v-icon
                      size="14"
                      class="flex-align-self-start"
                      style="color: var(--v-primary-base);"
                    >
                      {{ mdiInformationOutline }}
                    </v-icon>
                  </v-col>

                  <v-col
                    class="grow mt-1 text-body-2"
                  >
                    <div
                      v-if="graphic.attributes.location_accuracy === 2"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Der genaue Standort der Anlage ist nicht bekannt. Der angegebene Standort ist der Mittelpunkt der Gemeinde, in der sich die Anlage befindet.
                      </span>
                      <span
                        v-else
                      >
                        The exact location of the plant is not known. The specified location is the centre of the municipality in which the plant is located.
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <!-- FOOTER ACTIONS -->

      <v-row
        no-gutters
      >
        <v-col>
          <div
            class="d-flex justify-space-between align-center px-3 py-0"
            style="width: 100%; background: rgb(225, 232, 238);"
            :style="[$vuetify.breakpoint.smAndUp ? {'border-radius': '0 0 6px 6px'} : {'border-radius': 0}]"
          >
            <div
              v-if="graphics[carouselValue].attributes.location_lat !== null &&
                graphics[carouselValue].attributes.location_lon !== null"
              class="d-flex"
            >
              <v-tooltip
                color="rgb(225, 232, 238);"
                bottom
                max-width="230"
                min-width="150"
              >
                <template
                  #activator="{ on }"
                >
                  <v-btn
                    class="pa-0 mr-3 text-body-2"
                    plain
                    text
                    small
                    style="text-transform: none;"
                    :href="`https://www.openstreetmap.org/query?lat=${graphics[carouselValue].attributes.location_lat.toFixed(6)}&lon=${graphics[carouselValue].attributes.location_lon.toFixed(6)}#map=17/${graphics[carouselValue].attributes.location_lat.toFixed(6)}/${graphics[carouselValue].attributes.location_lon.toFixed(6)}`"
                    target="_blank"
                    v-on="on"
                  >
                    <!-- :href="`https://www.openstreetmap.org/#map=17/${graphics[carouselValue].attributes.location_lat.toFixed(6)}/${graphics[carouselValue].attributes.location_lon.toFixed(6)}`" -->
                    <v-icon
                      class="mr-1"
                      size="18"
                      color="primary"
                    >
                      {{ mdiMapLegend }}
                    </v-icon>
                    OSM
                  </v-btn>
                </template>
                <div
                  class="text-center"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    {{ itemSelected[`title_${locale}`] }} in Openstreetmap anzeigen
                  </span>
                  <span
                    v-else
                  >
                    Show {{ itemSelected[`title_${locale}`] }} in Openstreetmap
                  </span>
                </div>
              </v-tooltip>

              <v-tooltip
                color="rgb(225, 232, 238);"
                bottom
                max-width="230"
                min-width="150"
              >
                <template
                  #activator="{ on }"
                >
                  <v-btn
                    class="pa-0 text-body-2"
                    plain
                    text
                    small
                    style="text-transform: none;"
                    :href="`https://www.google.com/maps/place/${graphics[carouselValue].attributes.location_lat.toFixed(6)},${graphics[carouselValue].attributes.location_lon.toFixed(6)}`"
                    target="_blank"
                    v-on="on"
                  >
                    <v-icon
                      class="mr-1"
                      size="16"
                      color="primary"
                    >
                      {{ mdiGoogleMaps }}
                    </v-icon>
                    GMaps
                  </v-btn>
                </template>
                <div
                  class="text-center"
                >
                  <span
                    v-if="locale==='de'"
                  >
                    {{ itemSelected[`title_${locale}`] }} in Google Maps anzeigen
                  </span>
                  <span
                    v-else
                  >
                    Show {{ itemSelected[`title_${locale}`] }} in Google Maps
                  </span>
                </div>
              </v-tooltip>
            </div>

            <div
              class="d-flex grow justify-end align-center"
            >
              <v-btn
                :disabled="carouselValue === 0"
                icon
                @click="carouselValue--"
              >
                <v-icon
                  size="18"
                  style="color: var(--v-primary-base);"
                >
                  {{ mdiArrowLeft }}
                </v-icon>
              </v-btn>

              <div
                class="mx-3 font-weight-bold text-body-2"
                style="user-select: none;"
                :style="[graphics.length === 1 ? {'color': '#567f9f'} : {}]"
              >
                {{ carouselValue + 1 }} / {{ graphics.length }}
              </div>

              <v-btn
                :disabled="graphics.length === carouselValue + 1"
                icon
                @click="carouselValue++"
              >
                <v-icon
                  size="18"
                  style="color: var(--v-primary-base);"
                >
                  {{ mdiArrowRight }}
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </transition>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

// ARCGIS
import Point from '@arcgis/core/geometry/Point.js'
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils'

// LODASH
import _ from 'lodash'

// MIXINS
import mixinWebgis from '@/mixins/webgis.js'

// ICONS
import { mdiClose, mdiArrowLeft, mdiArrowRight, mdiGoogleMaps, mdiMapLegend, mdiInformationOutline, mdiHelpCircleOutline } from '@mdi/js'

export default {
  name: 'PopUpContainer',

  mixins: [
    mixinWebgis
  ],

  data () {
    return {
      carouselColsLeft: 6,
      carouselColsRight: 6,
      carouselValue: 0,
      dataOptions: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      },
      graphics: [],
      mapView: null,
      mdiArrowLeft: mdiArrowLeft,
      mdiArrowRight: mdiArrowRight,
      mdiClose: mdiClose,
      mdiHelpCircleOutline: mdiHelpCircleOutline,
      mdiGoogleMaps: mdiGoogleMaps,
      mdiInformationOutline: mdiInformationOutline,
      mdiMapLegend: mdiMapLegend,
      pointerX: 0,
      pointerY: 0,
      popup: {
        bottom: 0,
        centerX: 0,
        centerY: 0,
        height: 0,
        left: 0,
        popupdiv: false,
        positionX: 0,
        positionY: 0,
        right: 0,
        top: 0,
        topOffset: 70,
        width: 0
      },
      popupClass: '',
      popupViewHandler: null,
      screenPoint: {},
      toolbarTitle: ''
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ...mapState('webgis', [
      'isPopupVisible',
      'itemSelected',
      'mapViewCenter',
      'popupData',
      'viewDivDimensions'
    ]),

    ...mapState('webgis', [
      'itemSelectsOptional',
      'selectsOptionalQueryKeys'
    ]),

    ...mapState('dashboard_main', [
      'bkgItems'
    ]),

    itemSelectsOptionalDropdown () {
      return this.itemSelectsOptional.length !== 0 ? this.itemSelectsOptional.filter(select => select.type.option_type === 'optional' && select.type.select_type !== 'select-slider-range' && select.type.select_type !== 'select-slider' && select.type.is_subclass === false) : []
    },

    getItemTechnologyTitle () {
      return this.itemSelected.categories.filter(cat => cat.type.query_key === 'technology')[0].value[`title_${this.locale}`]
    }
  },

  watch: {
    carouselValue: {
      async handler (newCarouselValue) {
        if (this.graphics.length !== 0) {
          this.toolbarTitle = this.getItemTechnologyTitle

          // const mapPoint = new Point({
          //   x: this.graphics[newCarouselValue].attributes.location_lon,
          //   y: this.graphics[newCarouselValue].attributes.location_lat
          // })

          // const screenPoint = this.mapView.toScreen(mapPoint)

          // Set pointer values
          // this.pointerX = screenPoint.x
          // this.pointerY = screenPoint.y

          eventBus.$emit('webgis-highlight-features', this.graphics[newCarouselValue])

          // TODO needs refactor / fix because popup window "jumps" on slide change if the window size/height changes (based on more/less content)
          await this.getPopupDimensions()
          await this.popupGetWindowDimensions()

          await this.mapView.goTo({
            center: [
              this.graphics[newCarouselValue].attributes.location_lon,
              this.graphics[newCarouselValue].attributes.location_lat
            ]
          }, {
            duration: 400,
            easing: 'ease-in-out'
          })
        }
      },
      immediate: true
    },

    graphics: {
      handler (newVal) {
        if (newVal.length !== 0) {
          this.toolbarTitle = this.getItemTechnologyTitle
        }
      },
      immediate: true
    },

    isPopupVisible: {
      handler (isVisible) {
        if (!isVisible) {
          this.carouselValue = 0
        }
      },
      immediate: true
    },

    locale: {
      async handler () {
        if (this.graphics.length !== 0) {
          this.toolbarTitle = this.getItemTechnologyTitle

          await this.getPopupDimensions()
          await this.popupGetWindowDimensions()
        }
      },
      immediate: true
    },

    screenPoint: {
      handler () {
        this.popupClass = this.setPopupClass()
      },
      immediate: true
    },

    mapViewCenter: {
      handler (center) {
        if (center !== null) {
          if (this.graphics[this.carouselValue] !== undefined) {
            this.setPopupScreenPoint(this.graphics[this.carouselValue])
          }
        }
      },
      immediate: true
    }
  },

  beforeDestroy () {
    eventBus.$off('open-popup')
    eventBus.$off('popup-position')
  },

  mounted () {
    eventBus.$on('open-popup', async (payload) => {
      this.mapView = payload.mapView

      // Set handler for watching the position of the selected feature relative to the mapView
      // if (this.popupViewHandler !== null) {
      //   this.popupViewHandler.remove()
      //   this.popupViewHandler = null
      // }

      // this.popupViewHandler = reactiveUtils.watch(
      //   () => this.mapView.center,
      //   () => {
      //     // NOTE Submited graphic must be either from click event (-> first element on top of clicked feature graphics) or from the graphic retrieved by switching the popup window slide-pages

      //     this.setPopupScreenPoint(payload.graphics[0])
      //   })

      const graphicsAttributes = payload.graphics.map(graphic => graphic.attributes)
      const graphics = payload.graphics

      // Test if any attribute object has at least 1 'area_id' property
      // or is part of the technology 3 (bioenergy) or 4 (hydropower)
      if (graphicsAttributes.filter(attr => 'area_id' in attr).length >= 1 ||
      [3, 4].includes(graphicsAttributes[0].technology)) {
        // Test if the all 'area_id' property values of every feature attribute object are identical
        // Explanation: The amount of single 'area_id' values could be more than 1 if a clustered or overlaying points of PV installations was clicked
        if ([...new Set(graphicsAttributes.map(attr => attr.area_id))].filter(item => item).length === 1 ||
      [3, 4].includes(graphicsAttributes[0].technology)) {
        // Group attribute objects by same key values of a) location_lat && location_lon and b) optional properties excluding 'capacity_installed'
          const optionalAttrKeys = this.selectsOptionalQueryKeys.filter(key => key !== 'capacity_installed')

          const groupByAttrKeys = ['location_lat', 'location_lon', ...optionalAttrKeys]

          const attrGroupedByAttrKeys = Object.values(graphicsAttributes.reduce((r, o) => {
            const key = groupByAttrKeys.map(k => o[k]).join('|')
            // (r[key] ??= []).push(o)
            r[key] ??= []
            r[key].push(o)
            return r
          }, {}))

          // Reduce attribute values if the group contains more than 1 object
          this.graphics = attrGroupedByAttrKeys.map((group, index) => {
            const newObj = _.cloneDeep(group[0])

            // const graphicFiltered = graphics.find(graphic => graphic.attributes.location_lat === newObj.location_lat && graphic.attributes.location_lon === newObj.location_lon)
            const graphicFiltered = graphics[index]

            newObj.installation_count = group.length

            if (group.length > 1) {
              const dateCommissioningArray = group.map(obj => obj.date_commissioning)
              const dateDecommissioningArray = group.map(obj => obj.date_decommissioning)

              const dateCommissioningRange = [
                new Date(Math.min(...dateCommissioningArray.map(date => new Date(date)))).toISOString(),
                new Date(Math.max(...dateCommissioningArray.map(date => new Date(date)))).toISOString()
              ]
              const dateDecommissioningRange = [
                new Date(Math.min(...dateDecommissioningArray.map(date => new Date(date)))).toISOString(),
                new Date(Math.max(...dateDecommissioningArray.map(date => new Date(date)))).toISOString()
              ]

              if (dateCommissioningRange[0] !== dateCommissioningRange[1]) {
                newObj.date_commissioning_range = dateCommissioningRange
              }
              if (dateDecommissioningRange[0] !== dateDecommissioningRange[1]) {
                newObj.date_decommissioning_range = dateDecommissioningRange
              }

              newObj.capacity_installed_sum = group
                .map(obj => obj.capacity_installed)
                .reduce((accumulator, current) => accumulator + current, 0)

              graphicFiltered.attributes = newObj

              return graphicFiltered
            } else {
              graphicFiltered.attributes = group[0]
              graphicFiltered.attributes.installation_count = group.length

              return graphicFiltered
              // return newObj
            }
          })
        } else {
          this.graphics = graphics
        }
      } else {
        // if ([3, 4].includes(graphicsAttributes[0].technology)) {
        //   this.graphics.attributes = graphicsAttributes
        // } else {
        this.graphics = graphics
        // }
      }

      // Set pointer values
      this.pointerX = payload.screenPoint.x
      this.pointerY = payload.screenPoint.y

      // Detect popup window size
      await this.getPopupDimensions()

      // Position Popup
      await this.popupGetWindowDimensions()

      this.popupClass = this.setPopupClass()
    })

    eventBus.$on('popup-position', (screenPoint) => {
      this.screenPoint = screenPoint

      this.pointerX = screenPoint.x
      this.pointerY = screenPoint.y

      this.popupGetWindowDimensions()
    })
  },

  methods: {
    ...mapActions('webgis', [
      'set_popup_data',
      'set_popup_visibility'
    ]),

    ...mapActions('dashboard_main', [
      'get_ags_gen_title'
    ]),

    setPopupScreenPoint (graphic) {
      // console.log('setPopupScreenPoint graphic', graphic)

      let pointX
      let pointY
      if (graphic.layer.geometryType === 'point') {
        pointX = graphic.geometry.x
        pointY = graphic.geometry.y
      } else {
        // NOTE: if polygon the center has to be retrieved from the geometry extent
        // pointX = graphic.geometry.extent.center.x
        // pointY = graphic.geometry.extent.center.y
        pointX = graphic.mapPoint.x
        pointY = graphic.mapPoint.y
      }

      const mapPoint = {
        x: pointX,
        y: pointY,
        spatialReference: {
          wkid: 3857
        }
      }

      // console.log('setPopupScreenPoint mapPoint', mapPoint)

      const screenPoint = this.mapView.toScreen(mapPoint)
      if (screenPoint !== null && screenPoint !== undefined) {
        eventBus.$emit('popup-position', {
          x: Math.round(screenPoint.x),
          y: Math.round(screenPoint.y)
        })
      }
    },

    getAgsGenTitle ({ ags }) {
      if (this.bkgItems.length !== null) {
        const bkgItem = this.bkgItems.filter(bkgItem => bkgItem.ags === ags)[0]
        if (bkgItem !== undefined) {
          if (bkgItem.nbd === false) {
            return bkgItem.gen
          } else {
            return `${bkgItem.bez} ${bkgItem.gen}`
          }
        }
      }
    },

    async closePopup () {
      this.set_popup_visibility(false)
      eventBus.$emit('webgis-reset-highlight')
      await this.set_popup_data([])
    },

    getPopupDimensions () {
      return new Promise((resolve, reject) => {
        if (this.$el !== undefined) {
          setTimeout(() => {
            this.popup.height = this.$el.clientHeight
            this.popup.width = this.$el.clientWidth
            resolve()
          }, 100)
        }
      })
    },

    setPopupClass () {
      // TODO Refactor because center point on x-Axis is not interpreted correctly
      if (
        this.pointerX >= this.viewDivDimensions.width / 2 + 211 &&
        this.pointerY >= this.popup.height + this.popup.topOffset
      ) {
        return 'popupdiv-bottom popupdiv-bottom-left'
      } else if (
        this.pointerX < this.viewDivDimensions.width / 2 - 211 &&
        this.pointerY > this.popup.height + this.popup.topOffset
      ) {
        return 'popupdiv-bottom popupdiv-bottom-right'
      } else if (
        this.pointerX > this.viewDivDimensions.width / 2 &&
        this.pointerY < this.popup.height + this.popup.topOffset
      ) {
        return 'popupdiv-side popupdiv-side-left'
      } else if (
        this.pointerX < this.viewDivDimensions.width / 2 &&
        this.pointerY < this.popup.height + this.popup.topOffset
      ) {
        return 'popupdiv-side popupdiv-side-right'
      } else {
        // console.log('fallback')
        return 'popupdiv-bottom popupdiv-bottom-left'
      }
    },

    popupGetWindowDimensions () {
      // Get PopUp outer distances to viewDiv borders
      // this.$nextTick(() => {
      this.popup.left = this.viewDivDimensions.width - this.popup.width - this.pointerX

      this.popup.right =
          this.viewDivDimensions.width - this.popup.left - this.popup.width

      if (
        this.pointerX >= this.viewDivDimensions.width / 2 - 211 &&
           this.pointerY >= this.popup.height + this.popup.topOffset
      ) {
        // pointer bottom right
        this.popup.positionX = this.pointerX + 28 - this.popup.width
        this.popup.positionY = this.pointerY - this.popup.height - 16
      } else if (
        this.pointerX < this.viewDivDimensions.width / 2 + 211 &&
          this.pointerY > this.popup.height + this.popup.topOffset
      ) {
        // pointer bottom left
        this.popup.positionX = this.pointerX - 28
        this.popup.positionY = this.pointerY - this.popup.height - 16
      } else if (
        this.pointerX > this.viewDivDimensions.width / 2 &&
          this.pointerY < this.popup.height + this.popup.topOffset
      ) {
        // pointer top right
        this.popup.positionX = this.pointerX - 20 - this.popup.width
        this.popup.positionY = this.pointerY - 50
      } else if (
        this.pointerX < this.viewDivDimensions.width / 2 &&
          this.pointerY < this.popup.height + this.popup.topOffset
      ) {
        // pointer top left
        this.popup.positionX = this.pointerX + 20
        this.popup.positionY = this.pointerY - 50
      }
      // })

      // Get PopUp center x & y
      // this.$nextTick(() => {
      this.popup.centerX = this.pointerX + this.popup.width / 2
      this.popup.centerY = this.pointerY + this.popup.height / 2
      // })
    }
  }
}
</script>

<style lang="scss">
.popup-toolbar {
  .v-toolbar__content {
    padding: 4px 0 4px 12px !important;
  }
}

.popupdiv {
  min-width: 200px;
  max-width: 480px;

  &:after {
    z-index: -1;
    border: solid transparent;
    content: ' ';
    // height: 0;
    // width: 0;
    position: absolute;
    pointer-events: none;
    text-decoration: none;
    border-width: 10px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
}

.popupdiv-side:after {
  top: 0;
  margin-top: 64px;
  border-color: #ffffff;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.popupdiv-side-left:after {
  left: 100%;
  -webkit-box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.popupdiv-side-right:after {
  left: 0;
  -webkit-box-shadow: 3px -3px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 3px -3px 3px 0 rgb(0 0 0 / 20%);
}

.popupdiv-bottom:after {
  top: 100%;
  border-color: rgb(225, 232, 238);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.popupdiv-bottom-left:after {
  right: 0;
  margin-right: 21px;
  -webkit-box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.popupdiv-bottom-right:after {
  left: 0;
  margin-left: 14px;
  -webkit-box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
}
</style>
