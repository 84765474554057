import Vue from 'vue'
import Vuex from 'vuex'

import main from './main/'
// eslint-disable-next-line camelcase
import select_items from './select_items/'
// eslint-disable-next-line camelcase
import webgis from './webgis'
// eslint-disable-next-line camelcase
import dashboard_main from './dashboard_main/'
// eslint-disable-next-line camelcase
import dashboard_items from './dashboard_items/'
// eslint-disable-next-line camelcase
import report from './report/'
// eslint-disable-next-line camelcase
import leaflet_gis from './leaflet_gis/'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    dashboard_items,
    dashboard_main,
    leaflet_gis,
    main,
    report,
    select_items,
    webgis
  }
})
