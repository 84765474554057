<template>
  <v-card
    ref="card_chart"
    class="fill-height"
    style="postion: relative; min-height: 650px;"
    rounded="0"
    elevation="0"
  >
    <div
      class="d-flex flex-column fill-height"
    >
      <div
        style="width: 100%; background: rgb(225, 232, 238); z-index: 2;"
        class="d-flex flex-column px-6 py-3"
      >
        <div
          class="d-flex align-center justify-space-between"
        >
          <div>
            <div
              class="mb-1"
              :class="[$vuetify.breakpoint.xsOnly ? 'text-h5' : 'text-h4']"
            >
              <span
                v-if="locale==='de'"
              >
                EE-Anlagenstandorte
              </span>
              <span
                v-else
              >
                RE-plant locations
              </span>
            </div>

            <div
              style="line-height: 1rem;"
              class="text-h6"
            >
              {{ technologySelected[`title_${locale}`] }} ({{ yearSelected }})
            </div>
          </div>

          <!-- BTNs ANIMATION HEADER -->

          <div
            class="d-flex justify-end"
            style="width: auto height: 100%;"
          >
            <!-- BTN START ANIMATION -->

            <v-hover
              v-if="!isAnimationRunning"
              v-slot="{ hover }"
              open-delay="200"
              close-delay="200"
              :disabled="$vuetify.breakpoint.xsOnly"
              @click="startAnimation()"
            >
              <v-btn
                elevation="0"
                rounded
                style="border: solid 1px var(--v-secondary-lighten1);"
                color="rgb(225, 232, 238)"
                :disabled="!isChartReady"
                @click="startAnimation()"
              >
                <div
                  v-if="hover === false"
                >
                  <v-icon
                    size="26"
                  >
                    {{ mdiPlayOutline }}
                  </v-icon>
                </div>

                <v-expand-x-transition>
                  <div
                    v-if="hover"
                  >
                    <span
                      style="text-transform: none;"
                    >
                      <v-icon
                        size="26"
                        class="mr-1"
                      >
                        {{ mdiPlayOutline }}
                      </v-icon>

                      <span
                        v-if="locale==='de'"
                      >
                        Animation starten
                      </span>
                      <span
                        v-else
                      >
                        Start animation
                      </span>
                    </span>
                  </div>
                </v-expand-x-transition>
              </v-btn>
            </v-hover>

            <!-- BTN STOP ANIMATION -->

            <v-hover
              v-else
              v-slot="{ hover }"
              open-delay="200"
              close-delay="200"
              :disabled="$vuetify.breakpoint.xsOnly"
              @click="stopAnimation()"
            >
              <v-btn
                elevation="0"

                rounded
                color="rgb(225, 232, 238)"
                style="border: solid 1px var(--v-secondary-lighten1);"
                :disabled="!isChartReady"
                @click="stopAnimation()"
              >
                <div
                  v-if="hover === false"
                >
                  <v-icon
                    size="26"
                  >
                    {{ mdiStop }}
                  </v-icon>
                </div>

                <v-expand-x-transition>
                  <div
                    v-if="hover"
                  >
                    <v-icon
                      class="mr-1"
                      size="26"
                    >
                      {{ mdiStop }}
                    </v-icon>

                    <span
                      style="text-transform: none;"
                    >
                      <span
                        v-if="locale==='de'"
                      >
                        Animation anhalten
                      </span>
                      <span
                        v-else
                      >
                        Stop animation
                      </span>
                    </span>
                  </div>
                </v-expand-x-transition>
              </v-btn>
            </v-hover>
          </div>
        </div>
      </div>

      <!-- CHART AREA  -->

      <div
        class="fill-height"
        style="position: relative;"
      >
        <!-- CHART OVERLAY -->

        <div
          v-if="isChartReady"
          style="position: absolute; height: 100%; width: 100%; z-index: 2;"
        >
          <!-- BTN GO TO WEB-GIS -->

          <v-hover
            v-slot="{ hover }"
            open-delay="200"
            close-delay="200"
            :disabled="$vuetify.breakpoint.xsOnly"
          >
            <v-btn
              elevation="0"
              bottom
              right
              rounded
              absolute
              :disabled="!isChartReady"
              color="rgb(225, 232, 238)"
              class="mb-5"
              style="border: solid 1px var(--v-secondary-lighten1);"
              @click="goToWebGIS()"
            >
              <div
                v-if="hover === false"
              >
                <v-icon
                  size="26"
                >
                  {{ mdiArrowRightBoldBoxOutline }}
                </v-icon>
              </div>

              <v-expand-x-transition>
                <div
                  v-if="hover"
                >
                  <v-icon
                    class="mr-1"
                    size="26"
                  >
                    {{ mdiArrowRightBoldBoxOutline }}
                  </v-icon>

                  <span
                    style="text-transform: none;"
                  >
                    <span
                      v-if="locale==='de'"
                    >
                      zum Web-GIS
                    </span>
                    <span
                      v-else
                    >
                      go to Web-GIS
                    </span>
                  </span>
                </div>
              </v-expand-x-transition>
            </v-btn>
          </v-hover>
        </div>

        <!-- CHART -->

        <VChart
          id="re-overview-map"
          ref="chart_map"
          style="width: 100%; overflow: hidden; position: relative; "
          :autoresize="true"
        />
      </div>

      <!-- BOTTOM BUTTON GROUP AREA -->

      <v-btn-toggle
        v-model="btnToggleValues"
        mandatory
        tile
        class="d-flex flex-row"
      >
        <v-tooltip
          v-for="technology in technologies"
          :key="technology.id"
          :disabled="$vuetify.breakpoint.xsOnly"
          color="rgb(225, 232, 238);"
          elevation="2"
          top
          max-width="300"
        >
          <template
            #activator="{ on }"
          >
            <v-btn
              style="background: rgb(225, 232, 238);"
              :style="`flex-basis: ${100/technologies.length}%;`"
              class="d-flex"
              text
              tile
              :disabled="!isChartReady"
              v-on="on"
              @click="btnSelectTechnology(technology.id)"
            >
              <v-icon
                style="width: auto; color: var(--v-primary-base);"
                size="26"
              >
                {{ `icon-technology-0` + technology.id }}
              </v-icon>
            </v-btn>
          </template>

          <span>
            {{ technology[`title_${locale}`] }}
          </span>
        </v-tooltip>
      </v-btn-toggle>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

// AXIOS
import axios from 'axios'

// LODASH
import _ from 'lodash'

// MATERIALDESIGN ICONS
import { mdiPlayOutline, mdiStop, mdiArrowRightBoldBoxOutline } from '@mdi/js'

// ECHARTS
import { use } from 'echarts/core'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'
import 'echarts-gl'

// LEAFLET
import 'leaflet/dist/leaflet.css'
import { tileLayer as LtileLayer } from 'leaflet'

// ECHARTS LEAFLET EXTENSION
import {
  LeafletComponent
  // LeafletComponentOption
} from '@joakimono/echarts-extension-leaflet/src/export'

use([
  LeafletComponent
])

export default {
  name: 'MapAllRenewablePlants',

  components: {
    VChart
  },

  // NOTE https://github.com/ecomfe/vue-echarts#provide--inject
  provide: {
    [INIT_OPTIONS_KEY]: {
      renderer: 'canvas'
    }
  },

  data () {
    return {
      animationTimeoutTime: 500,
      btnToggleValues: 0,
      dataFiltered: [],
      dataSeries: [],
      echartsOption: {
        lmap: {
          // roam: true,
          // tap: false,
          attributionControl: true,
          center: [10.5, 51.05],
          doubleClickZoom: false,
          dragging: false,
          echartsLayerInteractive: false,
          largeMode: false,
          renderOnMoving: false,
          resizeEnable: false,
          scrollWheelZoom: false,
          zoom: 5.7,
          zoomControl: false,
          zoomSnap: 0.1
        },
        series: [{
          coordinateSystem: 'lmap',
          data: [],
          encode: {
            value: 2
          },
          symbol: 'circle',
          symbolSize: 2,
          type: 'scatterGL'
        }],
        visualMap: {
          bottom: '12px',
          dimension: 2,
          inverse: true,
          itemSymbol: 'circle',
          itemHeight: 12,
          left: '12px',
          pieces: [],
          show: true,
          textStyle: {
            color: '#000000',
            fontFamily: 'Roboto',
            fontSize: 12,
            fontWeight: '400'
          },
          type: 'piecewise'
        }
      },
      isAnimationRunning: false,
      isChartReady: false,
      mdiArrowRightBoldBoxOutline: mdiArrowRightBoldBoxOutline,
      mdiPlayOutline: mdiPlayOutline,
      mdiStop: mdiStop,
      technologies: [{
        id: 5,
        title_de: 'Alle Technologien',
        title_en: 'All technologies'
      }, {
        id: 1,
        title_de: 'Windenergie',
        title_en: 'Windenergy'
      }, {
        id: 2,
        title_de: 'Solarenergie',
        title_en: 'Solarenergy'
      }, {
        id: 3,
        title_de: 'Bioenergie',
        title_en: 'Bioenergy'
      }, {
        id: 4,
        title_de: 'Wasserkraft',
        title_en: 'Hydropower'
      }],
      technologySelectedID: 5,
      animationTimeout: undefined,
      visualMapPieces: [{
        color: 'blue',
        label: '', // WEA
        size: 1,
        value: 1
      }, {
        color: 'red',
        label: '', // PV
        size: 1,
        value: 2
      }, {
        color: 'green',
        label: '', // BIOE
        size: 1,
        value: 3
      }, {
        color: 'black',
        label: '', // HYP
        size: 1,
        value: 4
      }],
      visualMapPiecesFiltered: [],
      yearAnimation: null,
      yearRange: {
        end: 2022,
        start: 1990
      },
      yearSelected: 2022
    }
  },

  computed: {
    ...mapState('main', [
      'baseURL',
      'locale'
    ]),

    ...mapState('leaflet_gis', [
      'data'
    ]),

    technologySelected () {
      return this.technologies.filter(technology => technology.id === this.technologySelectedID)[0]
    }
  },

  watch: {
    locale: {
      handler (locale) {
        this.echartsOption.visualMap.pieces.forEach((piece) => {
          piece.label = this.technologies.filter(technology => technology.id === piece.value)[0][`title_${locale}`]
        })

        if (this.$refs.chart_map !== undefined &&
          this.echartsOption !== undefined) {
          this.$refs.chart_map.setOption(this.echartsOption)
        }
      },
      immediate: true
    },

    technologySelected: {
      handler (technologySelected) {
        if (technologySelected.id === 5) {
          this.visualMapPiecesFiltered = this.visualMapPieces
        } else {
          this.visualMapPiecesFiltered = this.visualMapPieces.filter(piece => piece.value === technologySelected.id)
        }

        this.visualMapPiecesFiltered.forEach((piece) => {
          piece.label = this.technologies.filter(technology => technology.id === piece.value)[0][`title_${this.locale}`]
        })
        this.echartsOption.visualMap.pieces = this.visualMapPiecesFiltered

        if (this.$refs.chart_map !== undefined &&
          this.echartsOption !== undefined) {
          this.$refs.chart_map.setOption(this.echartsOption)
        }
      },
      immediate: true
    },

    '$vuetify.breakpoint': {
      handler (breakpoint) {
        if (breakpoint.xsOnly === true) {
          this.echartsOption.lmap.zoom = 5.5
        } else {
          this.echartsOption.lmap.zoom = 5.7
        }

        if (this.$refs.chart_map !== undefined) {
          this.$refs.chart_map.setOption(this.echartsOption)
        }
      },
      immediate: true,
      deep: true
    }
  },

  async mounted () {
    await this.initComponent()
  },

  beforeDestroy () {
    this.$refs.chart_map.chart.dispose()
    this.$refs.chart_map.chart = null
  },

  methods: {
    ...mapActions('leaflet_gis', [
      'get_data'
    ]),

    async initComponent () {
      await this.initMap()

      this.$refs.chart_map.chart.showLoading()

      if (this.data.length === 0) {
        await this.get_data({
          yearCommissioningMax: this.yearRange.end
        })
      }

      this.setMapData({
        technologyID: null,
        year: this.yearRange.end
      })

      this.$refs.chart_map.chart.hideLoading()
    },

    async initMap () {
      this.$refs.chart_map.setOption(this.echartsOption)

      const lmapComponent = this.$refs.chart_map.chart.getModel().getComponent('lmap')

      // Get the instance of Leaflet
      const lmap = lmapComponent.getLeaflet()

      LtileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          attribution: '© <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors'
        }
      ).addTo(lmap)
    },

    btnSelectTechnology (technologyID) {
      if (this.isAnimationRunning) {
        this.stopAnimation()
      }

      if (technologyID !== this.technologySelectedID) {
        this.technologySelectedID = technologyID

        this.setMapData({
          year: this.yearRange.end,
          technologyID: technologyID
        })
      }
    },

    setMapData ({ technologyID, year }) {
      this.isChartReady = false
      this.echartsOption.visualMap.show = false

      // Filter data
      if (technologyID <= 4 && technologyID !== null) {
        if (year !== null) {
          this.dataFiltered = this.data.filter((ds) => {
            if (ds.year_decommissioning === null) {
              return ds.technology === technologyID &&
               ds.year_commissioning <= year
            } else {
              return ds.technology === technologyID &&
              ds.year_commissioning <= year &&
              ds.year_decommissioning >= year
            }
          })
        } else {
          this.dataFiltered = this.data.filter(ds => ds.technology === technologyID)
        }
      } else {
        if (year !== null) {
          this.dataFiltered = this.data.filter((ds) => {
            if (ds.year_decommissioning === null) {
              return ds.year_commissioning <= year
            } else {
              return ds.year_commissioning <= year &&
              ds.year_decommissioning >= year
            }
          })
        } else {
          this.dataFiltered = this.data
        }
      }

      // Create data series
      this.seriesData = []
      for (const ds of this.dataFiltered) {
        const mapPoint = [
          ds.location_lon,
          ds.location_lat,
          ds.technology
        ]
        this.seriesData.push(mapPoint)
      }

      // Set data
      this.echartsOption.series[0].data = this.seriesData

      if (this.$refs.chart_map !== undefined &&
          this.echartsOption !== undefined) {
        if (this.technologySelectedID === 5) {
          this.echartsOption.series[0].symbolSize = 2
        } else {
          this.echartsOption.series[0].symbolSize = 3
        }

        this.echartsOption.visualMap.show = true

        this.$refs.chart_map.setOption(this.echartsOption)
      }

      this.isChartReady = true
    },

    startAnimation () {
      if (!this.isAnimationRunning) {
        this.yearAnimation = this.yearRange.start
      }
      this.isAnimationRunning = true

      this.animationTimeout = this.setTimeout()
    },

    setTimeout () {
      return setTimeout(() => {
        this.setMapData({
          year: this.yearAnimation,
          technologyID: this.technologySelectedID
        })

        if (this.yearAnimation < this.yearRange.end) {
          this.yearSelected = this.yearAnimation

          this.yearAnimation = this.yearAnimation + 1

          this.startAnimation()
        } else {
          this.stopAnimation()
        }
      }, this.animationTimeoutTime)
    },

    stopAnimation () {
      clearTimeout(this.animationTimeout)

      this.setMapData({
        year: this.yearRange.end,
        technologyID: this.technologySelectedID
      })

      this.yearSelected = this.yearRange.end

      this.isAnimationRunning = false
    },

    async getGeojsonData ({ baseURL, yearCommissioningMax }) {
      const response = await axios.get(`${baseURL}api/v1/eemonitor/re_location_data/?year_commissioning_max=${yearCommissioningMax}`)
        .catch(err => console.log('error getGeojsonData ', err)) // TODO Error Handling

      if (response.status === 200) {
        if (response.data.length !== 0) {
          return response.data
        } else {
          // TODO Error handling
        }
      } else {
        // TODO Error handling
      }
    },

    goToWebGIS () {
      this.$router.push({
        name: 'WebGisMain'
      })
    }
  }
}
</script>

<style lang="scss">
#re-overview-map {
  .leaflet-pane{
      z-index: 1 !important;
  }

  .leaflet-layer {
    filter: grayscale(1) opacity(0.35);
  }

  .leaflet-control-attribution{
    background: none !important;
    opacity: 0.6 !important;
    &:hover {
      opacity: 1 !important;
    }
  }

  a > svg {
    display: none !important;
  }
}
</style>
