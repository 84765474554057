export default {
  SET_ITEM_LIST_REPORT (state, {
    itemListReport
  }) {
    state.itemListReport = itemListReport
  },

  SET_ITEM_LIST_REPORT_RANGE_DATA (state, {
    data
  }) {
    state.itemListReportRangeData = data
  },

  SET_ITEM_LIST_SORTED (state, {
    itemListSorted
  }) {
    state.itemListSorted = itemListSorted
  },

  SET_REGION_AGS_SELECTED (state,
    payload
  ) {
    state.regionAgsSelected = payload
  },

  SET_SPATIAL_TYPE_SELECTED (state,
    payload
  ) {
    state.spatialTypeSelected = payload
  }

}
