<template>
  <div>
    <v-navigation-drawer
      :value="isNavigationDrawerOpen"
      style="top:28px; z-index: 250;"
      class="nav_main"
      app
      :clipped="clipped"
      temporary
      width="300"
      :height="navigationDrawerHeight !== 0 ? navigationDrawerHeight : 'auto'"
      :style="[navigationDrawerTop !== 0 ? {'top': navigationDrawerTop + 'px'} : {'top': 0},
               navigationDrawerHeight !== 0 ? {'max-height': navigationDrawerHeight + 'px'} : {}]"
      @input="setDrawer"
    >
      <v-row
        no-gutters
        class="fill-height flex-column justify-space-between"
      >
        <v-col>
          <v-list>
            <v-list-item
              style="border-bottom: 1px dotted var(--v-primary-base);"
              router
              exact
              :to="{name: 'EEMonitorHome' }"
            >
              <v-list-item-icon>
                <v-icon
                  color="primary"
                >
                  {{ mdiHome }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title
                class="text-h6"
              >
                <div
                  v-if="locale === 'de'"
                >
                  Home
                </div>
                <div
                  v-else
                >
                  Home
                </div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              style="border-bottom: 1px dotted var(--v-primary-base);"
              router
              exact
              :to="{name: 'EEMonitorAbout' }"
            >
              <v-list-item-icon>
                <v-icon
                  color="primary"
                >
                  {{ mdiInformationOutline }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title
                class="text-h6"
              >
                <div
                  v-if="locale === 'de'"
                >
                  Hintergrund EE-Monitor
                </div>
                <div
                  v-else
                >
                  About the EE-Monitor
                </div>
              </v-list-item-title>
            </v-list-item>

            <v-list-group
              v-for="(item, i) in routes"
              :key="i"
              :value="true"
              :to="{ name: item.meta[`title_${locale}`]}"
              router
              exact
              :prepend-icon="item.meta.icon"
              no-action
              style="border-bottom: 1px dotted var(--v-primary-base);"
            >
              <template
                #activator
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="text-h6"
                    v-text="item.meta[`title_${locale}`]"
                  />
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(subItem, index) in item.children"
                :key="index"
                :value="true"
                router
                exact
                :to="{name: subItem.name }"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="subItem.meta[`title_${locale}`]"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>

        <v-spacer />

        <!-- CONTACT & IMPRINT & PRIVACY POLICY -->

        <v-col
          class="d-flex align-end"
          style="width: 100%;"
        >
          <v-list
            class="d-flex flex-column"
            style="width: 100%;"
          >
            <!-- CONTACT -->

            <v-list-item
              style="width: 100%; border-top: 1px dotted var(--v-primary-base);"
            >
              <a
                :href="`mailto:ee-monitor@ufz.de?subject=%5B%20EE-Monitor%20${mailtoTitle}%20%5D`"
                target="_blank"
                class="d-flex"
                style="text-decoration: none;"
                @click="setDrawer(false)"
              >
                <v-list-item-icon>
                  <v-icon
                    color="primary"
                  >
                    {{ mdiEmailOutline }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title
                  class="text-h6"
                >
                  <div
                    v-if="locale === 'de'"
                  >
                    Kontakt (E-Mail)
                  </div>
                  <div
                    v-else
                  >
                    Contact (E-Mail)
                  </div>
                </v-list-item-title>
              </a>
            </v-list-item>

            <!-- IMPRINT -->

            <v-list-item
              style="width: 100%; border-top: 1px dotted var(--v-primary-base);"
            >
              <a
                :href="`https://www.ufz.de/index.php?${locale}=36683`"
                target="_blank"
                class="d-flex fill-height"
                style="text-decoration: none; width: 100%;"
                @click="setDrawer(false)"
              >
                <v-list-item-icon>
                  <v-icon
                    color="primary"
                  >
                    {{ mdiInformationVariant }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title
                  class="text-h6"
                >
                  <div
                    v-if="locale === 'de'"
                  >
                    Impressum
                  </div>
                  <div
                    v-else
                  >
                    Imprint
                  </div>
                </v-list-item-title>
              </a>
            </v-list-item>

            <!-- PRIVACY POLICY -->

            <v-list-item
              router
              exact
              :to="{name: 'PrivacyPolicy' }"
              style="width: 100%; border-top: 1px dotted var(--v-primary-base);"
            >
              <v-list-item-icon>
                <v-icon
                  color="primary"
                >
                  {{ mdiText }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title
                class="text-h6"
              >
                <div
                  v-if="locale === 'de'"
                >
                  Datenschutzerklärung
                </div>
                <div
                  v-else
                >
                  Privacy Policy
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import {
  mdiText,
  mdiInformationVariant,
  mdiInformationOutline,
  mdiHome,
  mdiEmailOutline
  // mdiTranslateVariant,
  // mdiNoteAlertOutline,
  // mdiMonitorDashboard,
  // mdiMapSearchOutline,
  // mdiInformation,
  // mdiChevronRight,
  // mdiChevronLeft
} from '@mdi/js'

export default {
  name: 'DrawerMain',

  props: {
    // showDrawer: {
    //   type: Boolean,
    //   default: false
    // },
    clipped: {
      type: Boolean,
      default: false
    },
    navigationDrawerTop: {
      type: Number,
      default: 0
    },
    navigationDrawerHeight: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      routesLocal: [],
      mdiText: mdiText,
      mdiInformationVariant: mdiInformationVariant,
      mdiEmailOutline: mdiEmailOutline,
      mdiHome: mdiHome,
      mdiInformationOutline: mdiInformationOutline
      // mdiTranslateVariant: mdiTranslateVariant,
      // mdiInformation: mdiInformation,
      // mdiChevronRight: mdiChevronRight,
      // mdiChevronLeft: mdiChevronLeft,
      // mdiNoteAlertOutline: mdiNoteAlertOutline
    }
  },

  computed: {
    ...mapState('main', [
      'application',
      'isNavigationDrawerOpen',
      'locale',
      'routes'
    ]),

    mailtoTitle () {
      return this.locale === 'de' ? 'Anfrage' : 'Request'
    }
  },

  beforeDestroy () {
    this.set_state_navigation_drawer(false)
  },

  methods: {
    ...mapActions('main', [
      'set_state_navigation_drawer'
    ]),

    setDrawer (value) {
      if (value === false) {
        this.set_state_navigation_drawer(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
