<template>
  <div
    class="d-flex align-center"
  >
    <v-select
      v-if="Object.keys(optionObj).length !== 0"
      ref="select"
      dense
      flat
      solo
      :value="optionQueryId"
      :item-value="optionObj.type.query_field"
      :items="optionObj.values"
      :item-text="optionObj.type.select_item_text_field"
      :placeholder="optionObj.type[`title_plural_${locale}`]"
      class="select-optional text-body-2 font-weight-medium text-truncate"
      return-object
      style="width: 100%; overflow: hidden;"
      @change="updateData({event: $event, selectValue: optionObj})"
    >
      <!-- :menu-props="{ maxWidth: dropDownWidth }" -->
      <template
        #selection="data"
      >
        <div
          v-if="locale"
          class="text-truncate text-left"
          style="overflow: hidden;"
        >
          {{ optionObj.type[`title_${locale}`] }}: {{ data.item[`title_${locale}`] }}
        </div>
      </template>

      <template
        #item="data"
      >
        <span
          v-if="locale"
        >
          {{ data.item[`title_${locale}`] }}
        </span>
      </template>
    </v-select>

    <v-tooltip
      v-if="Object.keys(optionObj).length !== 0"

      color="rgb(225, 232, 238);"
      bottom
      max-width="300"
    >
      <template
        #activator="{ on, attrs }"
      >
        <span>
          <v-icon
            size="20"
            class="text-body-1 info-tooltip-icon ml-1"
            style="width: 100%; height: 100%;"
            v-on="on"
          >
            {{ mdiInformationOutline }}
          </v-icon>
        </span>
      </template>

      <span>
        {{ optionValue.detail_info[0][`info_text_${locale}`] }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions } from 'vuex'

import { mdiInformationOutline } from '@mdi/js'

export default {
  name: 'OptionalSelectTextField',

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      optionQueryKey: '',
      optionQueryId: undefined,
      optionValue: {},
      optionObj: {},
      mdiInformationOutline: mdiInformationOutline
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    // ...mapState('dashboard_main', [
    //   'isOptionSidebarOpen'
    // ]),

    ...mapState('dashboard_items', [
      'dashboardItemsSelectsOptional',
      'dashboardItemsDataQuery'
    ])
  },

  watch: {
    dashboardItemsSelectsOptional: {
      handler: 'getSelectsOptional',
      immediate: true
    },

    locale () {
      // this.getSelectsOptional()
    }
  },

  mounted () {

  },

  methods: {
    // ...mapActions('dashboard_main', [
    //   // 'set_state_option_sidebar'
    // ]),

    ...mapActions('dashboard_items', [
      'update_data_query'
    ]),

    getSelectsOptional () {
      if (this.dashboardItemsSelectsOptional.length !== 0) {
        if (this.dashboardItemsSelectsOptional !== undefined &&
        this.dashboardItemsSelectsOptional.length !== 0) {
          this.optionObj = this.dashboardItemsSelectsOptional[0]

          this.optionQueryKey = this.dashboardItemsSelectsOptional[0].type.query_key

          this.optionQueryId = this.dashboardItemsDataQuery.filter(obj => Object.keys(obj).some(key => key.includes(this.optionQueryKey)))[0][this.optionQueryKey]

          this.optionValue = this.dashboardItemsSelectsOptional[0].values.filter(val => val.value_id === this.optionQueryId)[0]
        }
      }
    },

    async updateData ({ event, selectValue }) {
      const eventValue = event === null ? null : event[this.optionObj.type.query_field]

      await this.update_data_query({
        query: {
          [this.optionQueryKey]: eventValue
        }
      })

      if (selectValue.type.query_type === 'api') {
        // NOTE API data query

        eventBus.$emit('update-loaded-dashboard',
          this.dashboardItemsDataQuery
        )
      }
    }

    // openDrawer () {
    //   this.set_state_option_sidebar(!this.isOptionSidebarOpen)
    // }
  }

}
</script>

<style lang="scss">
.select-optional {
  &.v-text-field {
    margin: 0 !important;
  }
  .v-input__control {
    min-height: 0 !important;
  }

  .v-input__slot {
    border: 1px dotted var(--v-secondary-lighten1) !important;
    border-radius: 6px;
    margin: 0 !important;
    padding: 0 6px !important;
  }

  .v-text-field__details {
    display: none !important;
  }

  .v-input__slot {
    min-height: 10px !important;
  }

  // .v-select__slot {
  //   padding: 0 6px !important;
  // }

  .v-input__slot::before {
    border: none !important;
  }

  .v-select__selections input {
    height: 0 !important;
    // padding: 0 !important;
  }

  .v-select__slot {
    margin-top: 1px !important;
  }

  .v-input__append-inner {
    margin: 0 !important;
  }

  .v-input__icon {
    height: 18px !important;
  }
}
</style>
