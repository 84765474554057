import _ from 'lodash'

export default {
  REMOVE_TOKEN (state) {
    state.user.token = ''
    state.user.isAuthenticated = false
  },

  SET_APPLICATION (state, payload) {
    state.application = payload
  },

  SET_APPLICATION_ITEM_SELECTED (state, payload) {
    state.applicationsItemSelected = payload
  },

  SET_APPLICATION_PATHS (state) {
    state.appDjangoPort = process.env.VUE_APP_DJANGO_PORT ? process.env.VUE_APP_DJANGO_PORT : ''
    state.appRootFolder = process.env.VUE_APP_ROOT_FOLDER ? process.env.VUE_APP_ROOT_FOLDER : ''
    state.baseURL = `${process.env.VUE_APP_HOST_URL}${state.appDjangoPort}/${state.appRootFolder}`
    state.appPort = process.env.VUE_APP_PORT ? process.env.VUE_APP_PORT : ''
    state.appBaseURL = `${process.env.VUE_APP_HOST_URL}${state.appPort}/${state.appRootFolder}`
  },

  SET_DIALOG_WELCOME (state, bool) {
    state.isWelcomeDialogOpen = bool
  },

  SET_DRAWER (state, e) {
    // console.log('vuex e', e)
    state.showDrawer = e
  },

  SET_LOCALE (state, e) {
    state.locale = e
  },

  SET_MENU_DISABLED (state, payload) {
    state.isMenuDisabled = payload
  },

  SET_NAVIGATION_BREADCRUMBS (state, e) {
    state.navigationBreadcrumbs = e
  },

  SET_PRIVACY_CONSENT (state, payload) {
    state.isPrivacyConsentOpen = payload
  },

  SET_ROUTES (state, e) {
    state.routes = e
  },

  SET_STATE_NAVIGATION_DRAWER (state, e) {
    state.isNavigationDrawerOpen = e
  },

  SET_USER_TOKEN (state, token) {
    state.user.token = token
    state.user.isAuthenticated = true
  }

}
