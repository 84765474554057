<template>
  <div
    id="btn-zoom-out"
  >
    <v-tooltip
      color="rgb(225, 232, 238);"
      left
      max-width="300"
      :disabled="$vuetify.breakpoint.xsOnly"
    >
      <template
        #activator="{ on, attrs }"
      >
        <v-btn
          fab
          dark
          small
          bottom
          right
          color="rgb(225, 232, 238)"
          class="ma-2"
          style="pointer-events: all; "
          v-on="on"
          @click="zoomOut"
        >
          <v-icon
            size="24"
            style="color: rgb(0, 62, 110);"
          >
            {{ mdiMagnifyPlusOutline }}
          </v-icon>
        </v-btn>
      </template>
      <span
        v-if="locale === 'de'"
      >
        Zoom Vergrößern
      </span>
      <span
        v-else
      >
        Zoom out
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'
import { mapState } from 'vuex'

import { mdiMagnifyPlusOutline } from '@mdi/js'

export default {
  name: 'BtnZoomIn',

  data: () => ({
    mdiMagnifyPlusOutline: mdiMagnifyPlusOutline
  }),

  computed: {
    ...mapState('main', [
      'locale'
    ])
  },

  methods: {
    zoomOut () {
      eventBus.$emit('webgis-zoom-in')
    }
  }
}
</script>

<style scoped>
</style>
