<template>
  <div>
    <v-row
      no-gutters
    >
      <v-col>
        <v-card
          elevation="0"
          class="fill-height"
          rounded="0"
          style="background: rgb(225, 232, 238);"
        >
          <v-row
            no-gutters
            class="flex-column pa-6 "
          >
            <v-col
              class="mb-6"
            >
              <div
                class="text-h4"
              >
                <span
                  v-if="locale === 'de'"
                >
                  Monitoring-Webanwendungen
                </span>
                <span
                  v-else
                >
                  Monitoring web applications
                </span>
              </div>

              <div>
                <span
                  v-if="locale === 'de'"
                >
                  Der EE-Monitor stellt derzeit zwei Webanwendungen zur Verfügung, die einen datenbasierten Zugang zum naturverträglichen Ausbau der erneuerbaren Energien in Deutschland bieten. Zum einen ein Monitoringset mit 41 Kennzahlen, mit deren Hilfe die Naturverträglichkeit des Ausbaus überregional und regionalspezifisch dargestellt werden kann. Zum anderen eine hochaufgelöste Darstellung von EE-Anlagenstandorten, die die zeitliche und räumliche Entwicklung des EE-Ausbaus auf Anlagenebene abbildet.
                </span>
                <span
                  v-else
                >
                  The EE-Monitor currently provides two web applications that offer data-based access to the nature-compatible expansion of renewable energies in Germany. Firstly, a monitoring set with 41 indicators that can be used to show the nature compatibility of the expansion on a supra-regional and region-specific basis. Secondly, a high-resolution representation of RE plant locations that shows the temporal and spatial development of RE expansion at plant level.
                </span>
              </div>
            </v-col>

            <v-col>
              <v-row
                class="fill-height"
                align="stretch"
                justify="space-between"
              >
                <v-col
                  v-for="(item, index) in items"
                  :key="index"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                >
                  <v-card
                    hover
                    elevation="0"
                    class="fill-height"
                    rounded="0"
                  >
                    <div
                      class="d-flex flex-column justify-space-between fill-height"
                      @click.stop="goToPage({item: item})"
                    >
                      <div>
                        <v-img
                          class="white--text grow"
                          gradient="to bottom,rgba(0, 88, 156, 1),rgba(255, 0, 0, 0)"
                          :height="$vuetify.breakpoint.smAndUp ? '190px' : '220px'"
                          :src="require(`@/assets/img/${item.image_filename}`)"
                        >
                          <div
                            class="d-flex justify-start align-start fill-height px-6 py-5"
                          >
                            <v-row>
                              <v-col
                                xs="12"
                                cols="12"
                                fill-height
                              >
                                <span
                                  class="text-h4"
                                >
                                  {{ item[`title_${locale}`] }}
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                        </v-img>

                        <v-card-text
                          class="pa-6"
                        >
                          <div
                            class="justify text-body-1 fill-height"
                          >
                            {{ item.detail_info[0][`info_text_${locale}`] }}
                          </div>
                        </v-card-text>
                      </div>

                      <div
                        class="align-self-end"
                        style="bottom: 0; width: 100%;"
                      >
                        <v-card-actions>
                          <v-row
                            class="align-end justify-space-between"
                            style="width: 100%;"
                            no-gutters
                          >
                            <!-- :class="[item.additional_paths.length === 0 ? 'justify-end' : 'justify-space-between']" -->
                            <v-col
                              md="auto"
                              sm="auto"
                              cols="12"
                              class="shrink"
                            >
                              <v-btn
                                v-if="item.base_path"
                                color="primary"
                                text
                                plain
                                :block="$vuetify.breakpoint.xsOnly"
                                :disabled="!item.base_path.name"
                                style="color: rgb(0, 88, 156); text-transform: none;"
                                :to="item.base_path.name"
                              >
                                {{ item.base_path[`title_${locale}`] }}
                              </v-btn>
                            </v-col>

                            <v-col
                              sm="auto"
                              cols="12"
                            >
                              <v-row
                                v-if="item.additional_paths.length !== 0"
                                class="justify-sm-end"
                                no-gutters
                              >
                                <v-col
                                  v-for="(path, idx) in item.additional_paths"
                                  :key="idx"
                                  sm="auto"
                                  cols="12"
                                >
                                  <v-btn
                                    color="primary"
                                    text
                                    plain
                                    :disabled="!path.name"
                                    :to="path.name"
                                    :block="$vuetify.breakpoint.xsOnly"
                                    style="color: rgb(0, 88, 156); text-transform: none;"
                                  >
                                    {{ path[`title_${locale}`] }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiArrowRight } from '@mdi/js'

import { mapState } from 'vuex'

export default {
  name: 'HomeOverviewComponent',

  data () {
    return {
      items: [],
      mdiArrowRight: mdiArrowRight
    }
  },

  computed: {
    ...mapState('main', [
      'locale',
      'application'
    ])
  },

  mounted () {
    this.items = this.application.items
    // console.log('this.application.items ', this.application.items)
  },

  methods: {
    goToPage ({ item, pathName }) {
      // console.log('item ', item)

      if (item !== undefined) {
        if (item.base_path.name) {
          this.$router.push({
            path: item.base_path.name
          })
        }
      } else {
        // console.log('pathName ', pathName)
        this.$router.push({
          path: pathName
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
