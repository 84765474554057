export default () => ({
  // isOptionSidebarOpen: false,
  bkgItems: [],
  corineItems: [],
  echartsDefaults: {
    color: [
      '#003e6e',
      '#800e1d',
      '#166cb0',
      '#2c5942',
      '#245a8d',
      '#e465ed',
      '#800e1d',
      '#f2de93',
      '#166cb0',
      '#e68112',
      '#9ba0aa',
      '#60f58f',
      '#1e6d0a',
      '#bf8672',
      '#8b8559',
      '#6507c3',
      '#774db5',
      '#af7f67',
      '#b5c13f'
    ],
    textStyle: {
      color: '#003e6e',
      fontFamily: 'Roboto',
      fontSize: 14
      // fontWeight: '400'
    },
    title: {
      left: 'center',
      text: '',
      textStyle: {
        color: '#003e6e',
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: '400'
      }
    },
    // tooltip: {
    //   axisPointer: {
    //     label: {
    //       show: false
    //     },
    //     type: 'line',
    //     snap: true
    //   },
    //   borderWidth: 0,
    //   trigger: 'axis',
    //   alwaysShowContent: false,
    //   // backgroundColor: 'rgba(245, 245, 245, 0.9)',
    //   // confine: true,
    //   // order: 'valueDesc',
    //   // padding: 8,
    //   textStyle: {
    //     color: '#003e6e',
    //     fontFamily: 'Roboto',
    //     fontSize: 14,
    //     fontWeight: '400'
    //   }
    // },
    barChart: {
      series: [{
        emphasis: {
          itemStyle: {
            areaColor: 'inherit',
            borderColor: '#ffdf33',
            borderWidth: 2,
            opacity: 1
          },
          label: {
            show: false
          }
        },
        label: {
          show: false
        },
        selectedMode: 'single',
        select: {
          disabled: false,
          itemStyle: {
            borderColor: '#ffdf33',
            borderWidth: 2
          },
          label: {
            show: false
          }
        },
        type: 'bar'
      }]
    },
    tooltipTriggerAxis: {
      confine: true,
      alwaysShowContent: false,
      axisPointer: {
        axis: 'auto',
        snap: true,
        type: 'line'
      },
      // backgroundColor: 'rgba(245, 245, 245, 0.9)',
      // borderColor: 'unset',
      borderWidth: 0,
      // confine: true,
      order: 'valueDesc',
      renderMode: 'html',
      // padding: 8,
      textStyle: {
        color: '#003e6e',
        fontFamily: 'Roboto',
        overflow: 'break',
        width: '200px'
      },
      trigger: 'axis',
      extraCssText: 'z-index: 2;'
    },
    tooltip: {
      confine: true,
      alwaysShowContent: false,
      axisPointer: {
        axis: 'auto',
        snap: true,
        type: 'line'
      },
      // backgroundColor: 'rgba(245, 245, 245, 0.9)',
      // borderColor: 'unset',
      borderWidth: 0,
      // confine: true,
      order: 'valueDesc',
      renderMode: 'html',
      // padding: 8,
      textStyle: {
        color: '#003e6e',
        fontFamily: 'Roboto',
        overflow: 'break',
        width: '200px'
      },
      trigger: 'item',
      extraCssText: 'z-index: 2;'
    },
    // grid: {
    //   bottom: '8px',
    //   containLabel: true,
    //   height: 'auto',
    //   left: '12px',
    //   right: '12px',
    //   top: '30px',
    //   width: 'auto'
    // },
    xAxis: {
      offset: 2,
      axisLabel: {
        textStyle: {
          color: '#003e6e',
          fontFamily: 'Roboto',
          fontSize: 14,
          fontWeight: '400'
        }
      },
      axisLine: {
        lineStyle: {
          color: '#003e6e'
        },
        show: true
      },
      axisPointer: {
        handle: {
          icon: 'none',
          show: false
        },
        label: {
          show: false
        },
        shadowStyle: {
          color: 'rgb(0, 62, 110, 0.04)'
        },
        type: 'shadow',
        value: ''
      },
      axisTick: {
        alignWithLabel: true,
        show: true
      },
      boundaryGap: true,
      data: [],
      // nameGap: 30,
      // offset: 2,
      splitLine: {
        show: true
      },
      type: 'category'
    },
    yAxis: {
      axisLabel: {
        show: true,
        showMaxLabel: false
      },
      scale: false,
      nameGap: 0,
      splitLine: {
        show: true
      },
      type: 'value'
    },
    map: {
      series: [{
        layoutCenter: [
          '54%',
          '50%'
        ],
        layoutSize: '85%',
        emphasis: {
          itemStyle: {
            areaColor: 'inherit',
            borderColor: '#ffdf33',
            borderWidth: 2,
            opacity: 1
          },
          label: {
            show: false
          }
        },
        itemStyle: {
          areaColor: 'rgba(0,0,0,0)'
        },
        label: {
          show: false
        },
        map: 'germany',
        nameProperty: 'ags',
        roam: false,
        select: {
          itemStyle: {
            areaColor: null,
            borderColor: '#ffdf33',
            borderWidth: 2,
            color: null
          },
          label: {
            show: false
          }
        },
        type: 'map'
      }],
      tooltip: {
        axisPointer: {
          label: {
            show: false
          }
        },
        borderWidth: 0,
        position: 'top',
        // borderWidth: 0,
        trigger: 'item',
        z: 60
      },
      // toolbox: {
      //   show: false,
      //   feature: {
      //     saveAsImage: {
      //       show: true
      //     }
      //   }
      // },
      // toolbox: {
      //   feature: {
      //     dataView: {
      //       readOnly: false
      //     },
      //     restore: {},
      //     saveAsImage: {}
      //   },
      //   left: 'right',
      //   orient: 'vertical',
      //   show: false,
      //   top: 'center'
      // },
      visualMap: {
        bottom: 30,
        calculable: false,
        // inverse: true,
        inRange: {
          color: ['#ffffff', '#003e6e']
          // color: ['#003e6e', '#ffffff', '#003e6e']
        },
        itemHeight: 120,
        itemWidth: 15,
        left: 4,
        realtime: false,
        textStyle: {
          color: '#003e6e',
          fontFamily: 'Roboto',
          fontSize: 14,
          fontWeight: '400'
        }
      }
    }
  }
})
