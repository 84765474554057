import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue'
// import Vuetify from 'vuetify/lib/framework'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  // customVariables: [
  //   '@/styles/variables.scss'
  // ],
  icons: {
    iconfont: 'mdiSvg'
  },
  treeShake: true,
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    light: true,
    themes: {
      light: {
        primary: '#003E6E',
        accent: '#0084B5',
        secondary: '#A2A2A2',
        secondaryLight: '#F0F0F0',
        backgroundColor: '#F0F0F0',
        info: '#22B24C',
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3
        // https://coolors.co/8e6505-786907-7a4504-8a0406-1e4b3e-405d2a
        // fieldofaction1: '#8E6505',
        // fieldofaction2: '#786907',
        // fieldofaction3: '#7A4504',
        // fieldofaction4: '#8A0406',
        // fieldofaction5: '#1E4B3E',
        // fieldofaction6: '#405D2A'
      },
      dark: {
        primary: '#003E6E',
        accent: '#0084B5',
        secondary: '#A2A2A2',
        backgroundColor: '#3c3c3c',
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3
      }
    }
  }
})
