<template>
  <div
    class="chart-title-area"
    style="width: 100%; "
  >
    <div
      class="px-6 d-flex flex-row align-center justify-start fill-height text-h6"
      style="min-height: 30px; width: 100%;"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartCardHeader',

  props: {
    title: {
      type: String,
      default: ''
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
