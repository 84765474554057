<template>
  <v-btn
    elevation="0"
    :fab="$vuetify.breakpoint.mdAndDown"
    rounded
    active
    depressed
    small
    class="px-3 py-1 ma-3"
    style="background: rgb(0, 62, 110, 0.12);"
    :style="[$vuetify.breakpoint.lgAndUp ? {'height': '38px', 'border-radius': '8px'}: {}]"
    @click="$emit('open-dialog', dashboardItem)"
  >
    <div
      v-if="$vuetify.breakpoint.lgAndUp"
      class="d-flex justify-space-between"
    >
      <div
        class="mr-2"
      >
        <v-icon
          class="bounce"
          style="width: 100%; height: 100%;"
          :style="[$vuetify.breakpoint.smAndUp ? {'font-size': '30px'} : {'font-size': '20px'}]"
        >
          {{ mdiInformationOutline }}
        </v-icon>
      </div>

      <span
        class="text-capitalize"
      >
        {{ dashboardItem.type[`title_${locale}`] }}</span>

      <span
        v-if="locale === 'de'"
        class="text-lowercase"
      >beschreibung
      </span>

      <span
        v-else
        class="text-lowercase"
      >&nbsp;description
      </span>
    </div>

    <div
      v-else
    >
      <v-tooltip
        color="rgb(225, 232, 238);"
        bottom
        max-width="300"
      >
        <template
          #activator="{ on, attrs }"
        >
          <div
            class="d-flex justify-center align-center text-body-1"
            v-on="on"
          >
            <v-icon
              size="24"
              style="width: 100%; height: 100%;"
            >
              {{ mdiInformationOutline }}
            </v-icon>
          </div>
        </template>

        <span
          class="text-capitalize"
        >
          {{ dashboardItem.type[`title_${locale}`] }}</span><span
          v-if="locale === 'de'"
          class="text-lowercase"
        >beschreibung
        </span>

        <span
          v-else
          class="text-lowercase"
        >&nbsp;description
        </span>
      </v-tooltip>
    </div>
  </v-btn>
</template>

<script>
// import { eventBus } from '@/utils/eventbus.js'
import { mapState } from 'vuex'

import { mdiInformationOutline } from '@mdi/js'

export default {
  name: 'BtnOpenItemDescription',

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      mdiInformationOutline: mdiInformationOutline
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ])
  }
}
</script>

<style lang="scss" scoped>

</style>
