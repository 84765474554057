<template>
  <div>
    <DrawerMain
      :navigation-drawer-top="$vuetify.application.top"
      :navigation-drawer-height="navigationDrawerHeight"
    />

    <v-app-bar
      class="nav_main"
      :clipped-left="clipped"
      fixed
      app
      dense
      color="primary"
      height="28"
      style="z-index: 1000;"
      clipped-right
    >
      <BtnDashboardBack
        v-if="$route.name === 'Dashboard'"
      />

      <v-app-bar-nav-icon
        v-if="$route.name!== 'Dashboard'"
        class="app-bar-nav-icon"
        color="white"
        size="14px"
        @click="openDrawer"
      />

      <v-toolbar-title
        style="margin-left: 6px; color: #ffffff; opacity: 0.8;"
      >
        <div
          class="d-flex align-center text-truncate text-body-2 fill-height"
        >
          <router-link
            :to="{name: 'EEMonitorHome'}"
            class="mr-3"
          >
            <div
              class="white--text"
            >
              {{ application[`title_${locale}`] }}
            </div>
          </router-link>

          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="d-inline-flex"
          >
            <div
              class="mr-3"
              style="font-size: 14px; font-weight: 500; line-height: 1.5rem;"
            >
              >
            </div>

            <v-breadcrumbs
              divider=">"
              class="pa-0 header-breadcrumbs flex-nowrap text-truncate"
              :items="breadCrumbs"
            />
          </div>
        </div>
      </v-toolbar-title>

      <v-spacer />

      <!-- <BtnLogout
        v-if="$vuetify.breakpoint.mdAndUp"
      /> -->

      <BtnContactEmail
        v-if="$vuetify.breakpoint.smAndUp"
      />

      <BtnCititationDialog
        v-if="$vuetify.breakpoint.smAndUp"
      />

      <SelectLocale />
    </v-app-bar>
  </div>
</template>

<script>
// import { eventBus } from '@/utils/eventbus.js'
import { mapState, mapActions } from 'vuex'

import {
  mdiSlashForward
} from '@mdi/js'

// COMPONENTS
// import BtnLogout from '@/components/main/button/BtnLogout.vue'
import BtnContactEmail from '@/components/main/button/BtnContactEmail.vue'
import BtnDashboardBack from '@/components/main/button/BtnDashboardBack.vue'
import BtnCititationDialog from '@/components/main/button/BtnCititationDialog.vue'
import DrawerMain from '@/components/main/navigation/DrawerMain.vue'
import SelectLocale from '@/components/main/button/BtnSelectLocale.vue'

export default {
  name: 'HeaderMain',

  components: {
    // BtnLogout,
    BtnContactEmail,
    BtnDashboardBack,
    BtnCititationDialog,
    DrawerMain,
    SelectLocale
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      clipped: false,
      mdiSlashForward: mdiSlashForward
    }
  },

  computed: {
    ...mapState('main', [
      'application',
      'locale',
      'navigation',
      'routes',
      'isNavigationDrawerOpen'
    ]),

    ...mapState('dashboard_items', [
      'dashboardItems'
    ]),

    // navigationDrawerTop () {
    //   return this.offsetTop <= 132 ? this.$vuetify.breakpoint.xs ? 150 - this.offsetTop : 182 - this.offsetTop : this.$vuetify.breakpoint.xs ? 48 : 32 + 48
    // },

    navigationDrawerHeight () {
      return this.$vuetify.breakpoint.height - this.$vuetify.application.top
    },

    breadCrumbs () {
      const pathArray = this.$route.path.split('/')
      pathArray.shift()
      const breadCrumbs = [] // needed to handle the intermediary entries for nested vue routes

      let breadcrumb = ''
      let lastIndexFound = 0
      let disabled

      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${'/'}${pathArray[i]}`

        if (this.$route.matched[i] && Object.hasOwnProperty.call(this.$route.matched[i], 'meta') && Object.hasOwnProperty.call(this.$route.matched[i].meta, 'breadCrumb') && this.$route.matched[i].meta.breadcrumb === true) {
        // FIXME temporary workaround to make the Regionreport breadcrumb item not clickable. It is clickable because it has an (invisible) child route
          let disabled
          if (this.$route.matched[i].name === 'ReportView') {
            disabled = true
          } else {
            disabled = i + 1 === pathArray.length
          }

          breadCrumbs.push({
            disabled: disabled,
            exact: true,
            link: true,
            text: this.$route.matched[i].meta[`title_${this.locale}`] || pathArray[i],
            // href: i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? '/' + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            to: {
              name: this.$route.matched[i].name
            }
          })
          lastIndexFound = i
          breadcrumb = ''
        }
      }
      return breadCrumbs
    }
  },

  methods: {
    ...mapActions('main', [
      'set_state_navigation_drawer'
    ]),

    openDrawer () {
      this.set_state_navigation_drawer(!this.isNavigationDrawerOpen)
    }

    // toggleTheme () {
    //   this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    //   // localStorage.setItem('useDarkTheme', this.$vuetify.theme.dark.toString())
    // },

    // setItem (value) {
    //   // console.log(value.title)
    //   this.titlePage = `${this.titleMainPage} - ${value.title}`
    // },
  }
}
</script>

<style lang="scss">
.v-toolbar__extension {
  background: #ffffff !important;
}

.header-breadcrumbs {
  .v-breadcrumbs__item  {
    color: #ffffff !important;
    font-size: 0.875rem  !important;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif !important;
  }
  // .v-breadcrumbs__item--disabled {
  //   // color: #b1b1b1 !important;
  //   text-decoration: underline;
  // }
  .v-breadcrumbs__divider {
    color: #ffffff !important;
  }
}
</style>
