// TODO Composing actions?
// https://vuex.vuejs.org/guide/actions.html#composing-actions

import axios from 'axios'

import _ from 'lodash'

import { eventBus } from '@/utils/eventbus.js'

export default {
  async reset_dashboard_item ({ commit }) {
    commit('REMOVE_ITEM')
    commit('SET_ITEM_DATA_YEARS', {
      years: []
    })
  },

  async get_item ({ dispatch, commit, state, rootState }, {
    slug
  }) {
    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/items/${slug}/`)
      .catch((err) => {
        // NOTE -> 404
        // TODO Error Handling
        console.log('error get_item ', err)
        eventBus.$emit('push-to-page-error-404')
      })

    if (response && response.status === 200) {
      if (Object.keys(response.data).length !== 0) {
        // REVIEW Possible interference because state dashboardItems array is empty on init
        const item = response.data

        // commit('REMOVE_ITEM')

        commit('SET_DASHBOARD_LAYOUT', {
          layout: item.layout_type
        })

        commit('SET_ITEM', {
          item
        })

        const itemDataOptionQueryKeys = item.data_options.map((option) => {
          return option.type.query_key
        })

        commit('SET_ITEM_DATA_OPTION_QUERY_KEYS', {
          keys: itemDataOptionQueryKeys
        })

        return response.data
      } else {
        // TODO Error handling
      }
    } else {
      // console.log('get_item: No item with that slug in database')

      eventBus.$emit('push-to-page-error-404')
    }
  },

  async get_context_item ({ commit, dispatch, rootState, state }, {
    slug
  }) {
    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/items/${slug}/`)
      .catch((err) => {
        // NOTE -> 404
        // TODO Error Handling
        console.log('error get_item ', err)
        eventBus.$emit('push-to-page-error-404')
      })

    if (response && response.status === 200) {
      if (Object.keys(response.data).length !== 0) {
        // REVIEW Possible interference because state dashboardItems array is empty on init
        const item = response.data

        return response.data
      } else {
        // TODO Error handling
        eventBus.$emit('push-to-page-error-404')
      }
    } else {
      // console.log('get_item: No item with that slug in database')

      eventBus.$emit('push-to-page-error-404')

      // router.push({
      //   name: 'eemonreport'
      // //   params: {
      // // TODO Indicate reason of redirect on the next page, e.g. info modal "item not found bla bla"
      // //   }
      // })
    }
  },

  async get_item_data ({ dispatch, commit, state, rootState }, {
    isContextItem,
    item,
    itemIdArray,
    query,
    spatialTypeId
  }) {
    const queryObjectEmpty = state.dashboardItemsDataOptionQueryKeys.reduce((acc, value) => {
      return { ...acc, [value]: '' }
    }, {})

    const queryMerged = Object.assign(queryObjectEmpty, ...query)

    // Query for distinct spatial_type id if id was transmitted with the get_item_data method
    if (spatialTypeId) {
      queryMerged.spatial_type = _.cloneDeep(spatialTypeId)
    }

    // Remove all obj keys where value is Null
    Object.keys(queryMerged).forEach(key => {
      if (queryMerged[key] === null) {
        delete queryMerged[key]
      }
    })

    // Create api query with all filter values
    const queryParamArray = []
    for (const [key, value] of Object.entries(queryMerged)) {
      const queryPart = `&${key}=${value}`
      queryParamArray.push(queryPart)
    }

    // Create final api query string with item.id
    const queryParam = `?item__in=${String(itemIdArray)}${queryParamArray.join('')}`

    const response = await axios.get(`${rootState.main.baseURL}api/v1/eemonitor/data/${queryParam}`)
      .catch(err => console.log('error get_item_data ', err)) // TODO Error Handling

    if (response.status === 200) {
      if (response.data.length !== 0) {
        // REVIEW isContextItem is only necessary atm because of ContextItem Layout
        // typeof isContextItem !== 'boolean' <-- quick fix because only one call adds isContextItem prop
        if (typeof isContextItem !== 'boolean') {
        // Get data value unit
          const dataValueUnitObj = await dispatch('get_item_data_value_unit', {
            item
          })
          const dataValueUnit = dataValueUnitObj.title_short_de

          await commit('SET_ITEM_DATA_VALUE_UNIT', {
            valueUnit: dataValueUnit
          })

          // Multiply by 100 if item dataset values are of the kind percentages (data_value = 0 -> 0%; data_value = 1 -> 100%)
          if (dataValueUnit.match(/^%$/)) {
            response.data.forEach((ds) => {
              if (ds.data_value !== null) {
                ds.data_value = ds.data_value * 100
              }
            })
          }
        }

        // Get distinct data_year values from data
        const yearsInData = await dispatch('get_datasets_years', {
          data: response.data,
          saveToStore: true
        })
        commit('SET_ITEM_DATA_YEARS', {
          years: yearsInData
        })

        // Sort datasets by year
        response.data.sort((a, b) => a.data_year - b.data_year)

        return response.data
      } else {
        // TODO Error handling
        eventBus.$emit('push-to-page-error-404')
      }
    } else {
      // TODO Error handling
      eventBus.$emit('push-to-page-error-404')
    }
  },

  // async set_item_data ({ commit }, payload) {
  //   await commit('SET_ITEM_DATA', payload)
  // }, // REVIEW not used atm

  async get_item_data_value_unit ({ commit }, { item }) {
    const valueUnitObj = item.data_options.filter((opt) => {
      return opt.type.query_key === 'data_unit'
    })[0].values[0]

    return valueUnitObj
  },

  async get_datasets_years ({ state, commit }, { data, saveToStore }) {
    const yearsInData = [...new Map(data.map(ds => [ds.data_year, ds])).values()].map(ds => ds.data_year).sort()

    if (saveToStore) {
      commit('SET_ITEM_DATA_YEARS', { years: yearsInData })
    }

    return yearsInData
  },

  // async get_datasets_corine_classes ({ state }, datasets) {
  //   const array = [...new Map(datasets.map(ds => [ds.corine, ds])).values()].map(ds => ds.corine).sort((a, b) => a - b)

  //   return array
  // }, FIXME not used atm

  async get_datasets_ags ({ state }, datasets) {
    return [...new Map(datasets.map(ds => [ds.ags, ds])).values()].map(ds => ds.ags).sort()
  },

  async get_datasets_value_max ({ state }, datasets) {
    const maxValue = Math.max.apply(
      Math,
      datasets.map((ds) => {
        if (ds.data_value !== null &&
          ds.data_value !== undefined) {
          return ds.data_value
        } else {
          return 0
        }
      }))
    return maxValue
  },

  async get_datasets_value_min ({ state }, datasets) {
    const dataArray = datasets.map((ds) => {
      if (ds.data_value !== null && ds.data_value !== 0) {
        return ds.data_value.toFixed(6)
      } else {
        return undefined
      }
    })

    // NOTE 0 values will also be ignored
    const minValue = dataArray.reduce((a, b) => {
      if (b === 0 || b === null || b === undefined) {
        return a
      }

      return Math.min(a, b)
    }, 100000)

    // const minValue = Math.min.apply(
    //   Math,
    //   datasets.map((ds) => {
    //     if (ds.data_value !== null &&
    //         ds.data_value !== undefined) {
    //       console.log('ds.data_value ', ds.data_value)
    //       return ds.data_value
    //     } else {
    //       return null
    //     }
    //   }))

    return minValue
  },

  // async get_charts_tooltip_method ({ state }, {
  //   locale,
  //   dataValueUnit,
  //   minimumFractionDigits,
  //   maximumFractionDigits
  // }) {
  //   const method = (value) => {
  //     return `${value.toLocaleString(
  //       locale, {
  //         minimumFractionDigits: minimumFractionDigits,
  //        maximumFractionDigits: maximumFractionDigits
  //         }
  //        )}
  //     ${dataValueUnit}`
  //   }
  //   return method
  // }, // FIXME not used atm

  // async get_data_value_unit_title ({ state }, item) {
  //   return item.data_options.filter((opt) => {
  //     return opt.type.query_key === 'data_unit'
  //   })[0].values[0].title_short_de
  // }, // FIXME not used atm

  // NOTE not used atm
  // async set_item_data_properties ({ commit }, {
  //   agsIds,
  //   valueMax,
  //   valueMin,
  //   years
  // }) {
  //   commit('SET_ITEM_DATA_YEARS', {
  //     years
  //   })

  //   commit('SET_ITEM_DATA_MIN_MAX', {
  //     valueMin,
  //     valueMax
  //   })
  // },

  set_select_values ({ commit }, {
    selectsBasic,
    selectsOptional
  }) {
    commit('SET_SELECTS_BASIC', { selectsBasic })
    commit('SET_SELECTS_OPTIONAL', { selectsOptional })

    const selectPanelList = selectsOptional.filter(opt => opt.type.select_type === 'select-panel-list')

    if (selectPanelList.length === 0) {
      commit('SET_SHOW_SELECT_OPTIONAL', false)
    } else {
      commit('SET_SHOW_SELECT_OPTIONAL', true)
    }

    // const selectsBasicClone = _.cloneDeep(selectsBasic)
    // const selectsOptionalClone = _.cloneDeep(selectsOptional)

    // commit('SET_SELECTS_BASIC', { selectsBasicClone })
    // commit('SET_SELECTS_OPTIONAL', { selectsOptionalClone })
  },

  set_data_query ({ commit }, {
    query
  }) {
    commit('SET_DATA_QUERY', {
      query
    })
  },

  async update_data_query ({ commit, state }, {
    query
  }) {
    // console.log('query ', query)

    // TODO for Refactoring
    // for (const [key, value] of Object.entries(object1)) {
    //   console.log(`${key}: ${value}`);
    // }

    const queryState = state.dashboardItemsDataQuery

    const updatedQuery = queryState.map(selectObj => {
      // NOTE String() is needed because on update __obj__.dep.id of query and queryState.xyz are different every time
      if (String(Object.keys(selectObj)) === String(Object.keys(query))) {
        return query
      } else {
        return selectObj
      }
    })

    await commit('SET_DATA_QUERY', {
      query: updatedQuery
    })
  },

  async get_max_fraction_digits ({ state }, {
    minValue
  }) {
    const arr = []
    let maxDigits = 0

    const countedZeros = [...(minValue.toString().replace('0.', ''))].every((num) => {
      if (Number(num) !== 0) {
        return false
      }
      arr.push(num)
      return true
    })

    if (arr.length === 0) {
      maxDigits = arr.length + 2
    } else {
      maxDigits = arr.length + 1
    }

    return maxDigits

    // Not working, because it counts all zeros even after first 1-9 numbers
    // return (minValue.toString().replace('0.', '').split('0').length - 1)
  },

  async get_axis_value_interval ({ state }, {
    years,
    interval = 5
  }) {
    return (index, value) => {
      if (years.length > 9) {
        const e = Number(value)
        if (index === 0 || index === years.length - 1) {
          return true
        } else if (e % interval === 0) {
          return true
        } else if (e <= 2 || index >= years.length - 3) {
          return false
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }

  // echart_map_tooltip_formatter ({ state, rootState }, {
  //   minimumFractionDigits,
  //   maximumFractionDigits,
  //   yearSelected,
  //   dataValueUnit
  // }) {
  //   return (params, ticket, callback) => {
  //     let bkgAgsTitle
  //     const ags = params.name

  //     const bkgItem = rootState.dashboard_main.bkgItems.filter(bkgItem => bkgItem.ags === ags)[0]
  //     if (bkgItem.nbd === false) {
  //       bkgAgsTitle = bkgItem.gen
  //     } else {
  //       bkgAgsTitle = `${bkgItem.bez} ${bkgItem.gen}`
  //     }

  //     const title = `<div class="text-h6">${bkgAgsTitle} (${yearSelected})</div>`

  //     if (isNaN(params.value)) {
  //       return `${title}<div>${this.locale === 'de' ? 'kein Wert' : 'no value'}</div>`
  //     } else {
  //       return `${title}
  //       <div>${params.value.toLocaleString(this.locale, {
  //         minimumFractionDigits: minimumFractionDigits,
  //         maximumFractionDigits: maximumFractionDigits
  //       })}
  //       ${dataValueUnit}</div>`
  //     }
  //   }
  // }
}
