<template>
  <v-col
    v-show="itemListIdsSelected.includes(item.id)"
    v-resize="setItemCardHeight"
    class="grow"
    :cols="getColsForItem(item)"
    style="height: auto;"
  >
    <!-- ; border: 1px dotted var(--v-secondary-lighten1) !important; -->
    <v-card
      ref="item_card"
      style="width: 100%;"
      elevation="1"
      flat
      rounded="5"
      class="d-flex flex-column pa-5 fill-height"
    >
      <!-- :style="{'height': cardHeight + 'px'}" -->
      <div
        class="d-flex flex-column fill-height justify-space-between align-stretch"
        style="width: 100%;"
      >
        <div
          class="d-flex flex-column flex-nowrap fill-height mb-4"
          align="center"
          justify="start"
        >
          <div
            class="fill-height break-words text-left d-flex flex-column justify-space-between align-center fill-height report-card-header pb-3"
          >
            <div
              class="d-flex justify-space-between align-center fill-height"
              style="width: 100%;"
            >
              <div
                class="d-inline-flex align-center"
              >
                <v-tooltip
                  color="rgb(225, 232, 238);"
                  bottom
                  max-width="300"
                >
                  <template
                    #activator="{ on, attrs }"
                  >
                    <span
                      class="text-body-1"
                      v-on="on"
                    >
                      <div>
                        <v-icon
                          color="primary"
                          style="width: auto"
                          size="30"
                          class="mr-5"
                        >
                          {{ `icon-technology-0` + getItemTechnology.value_id }}
                        </v-icon>
                      </div>
                    </span>
                  </template>
                  <div
                    class="d-flex flex-column justify-center"
                  >
                    <div
                      class="text-h6"
                    >
                      {{ getItemTechnology[`title_${locale}`] }}
                    </div>
                    <div
                      class="text-body-1"
                    >
                      {{ getItemTechnology.detail_info[0][`info_text_teaser_${locale}`] }}
                    </div>
                  </div>
                </v-tooltip>

                <div
                  class="text-h6 text-sm-h5 font-weight-medium"
                >
                  {{ item[`title_${locale}`] }}
                </div>
              </div>

              <v-tooltip
                color="rgb(225, 232, 238);"
                bottom
                max-width="300"
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <span
                    class="text-body-1"
                    v-on="on"
                  >
                    <div>
                      <v-icon
                        class="info-tooltip-icon ml-2"
                        style="width: 100%; height: 100%; font-size: 3rem;"
                      >
                        {{ mdiInformationOutline }}
                      </v-icon>
                    </div>
                  </span>
                </template>

                <span>
                  {{ item.detail_info[0][`info_text_teaser_${locale}`] }}
                </span>
              </v-tooltip>
            </div>
          </div>

          <div
            class="d-flex report-card-header-border"
          />
        </div>

        <div
          v-if="showCards && showDataKeyValues"

          style="flex-basis: 100%;"
          class="fill-height d-flex flex-row justify-start align-end"
        >
          <div
            class="d-flex flex-column justify-center align-stretch fill-height"
            style="width: 100%;"
          >
            <v-row
              v-if="selectsOptional.length === 0"
              align="end"
              class="mb-2"
            >
              <v-col
                cols="12"
              >
                <v-card
                  class="report-card-area"
                  flat
                  rounded="0"
                >
                  <div
                    style="background: #ffffff"
                  >
                    <!-- CHART -->

                    <v-card
                      class="d-flex flex-column align-stretch justify-center pa-3 fill-height report-card-area"
                      flat
                      rounded="0"
                      style="border: solid 2px rgb(225, 232, 238); border-radius: 6px !important; height: 168px;"
                    >
                      <v-row
                        align="center"
                      >
                        <v-col
                          v-if="itemListReportRangeDataGrouped.length !== 1"
                          cols="8"
                        >
                          <div>
                            <v-row>
                              <v-col
                                style="width: 100%;"
                              >
                                <RegionReportChartLine
                                  style="height: 140px"
                                  :item="item"
                                  :item-data-value-max="itemDataValueMax"
                                  :item-data="itemListReportRangeDataGrouped"
                                  :data-value-unit-obj="dataValueUnitObj"
                                  :maximum-fraction-digits="maximumFractionDigits"
                                />
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>

                        <v-col
                          style="min-width: 33.333%;"
                          :class="[itemListReportRangeDataGrouped.length !== 1 ? 'pl-0' : '']"
                          class="grow"
                        >
                          <div
                            class="d-flex flex-column text-h6 text-sm-h5 text-center"
                          >
                            <div
                              v-if="itemListReportRangeDataGrouped.at(-1).data_value !== null"
                              class="font-weight-bold"
                            >
                              {{ getDatasetValue(itemListReportRangeDataGrouped) }} {{ dataValueUnitObj[`title_short_${locale}`] }}
                            </div>

                            <div
                              v-else
                              class="font-weight-bold"
                            >
                              0 {{ dataValueUnitObj[`title_short_${locale}`] }}
                            </div>

                            <div
                              class="font-weight-normal"
                            >
                              ({{ item.report_year }})
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row
              v-else
              dense
              class="mb-2"
            >
              <v-col
                v-for="(dataArray, index) in itemListReportRangeDataGrouped"
                :key="index"
                class="grow"
                :style="{'min-width': minWidthDataOption + '%'}"
                :cols="setWidthDataOptionCard(dataArray)"
              >
                <v-card
                  class="d-flex flex-column align-stretch justify-center pa-3 fill-height report-card-area"
                  flat
                  rounded="0"
                  style="border: solid 2px rgb(225, 232, 238); border-radius: 6px !important;"
                >
                  <v-row>
                    <v-col>
                      <div
                        class="d-flex flex-row justify-space-between"
                        style="border-bottom: 2px dotted rgb(225, 232, 238);"
                      >
                        <div
                          class="text-h6 text-sm-h5 font-weight-medium text-center"
                          style="word-break: break-word;"
                        >
                          <div
                            v-if="dataArray.at(-1).corine !== null"
                          >
                            {{ getCorineItem(dataArray.at(-1))[`title_${locale}`] }}
                          </div>

                          <div
                            v-else
                          >
                            {{ getSelectOptional(dataArray.at(-1))[`title_${locale}`] }}
                          </div>
                        </div>

                        <div>
                          <v-tooltip
                            color="rgb(225, 232, 238);"
                            bottom
                            max-width="300"
                          >
                            <template
                              #activator="{ on, attrs }"
                            >
                              <span
                                class="text-body-1"
                                v-on="on"
                              >
                                <div>
                                  <v-icon
                                    class="info-tooltip-icon ml-2"
                                    style="width: 100%; height: 100%; font-size: 3rem;"
                                  >
                                    {{ mdiInformationOutline }}
                                  </v-icon>
                                </div>
                              </span>
                            </template>

                            <span
                              v-if="dataArray.at(-1).corine !== null"
                            >
                              {{ getCorineItem(dataArray.at(-1))[`info_text_${locale}`] }}
                            </span>

                            <span
                              v-else
                            >
                              {{ getSelectOptional(dataArray.at(-1)).detail_info[0][`info_text_${locale}`] }}

                            </span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    align="center"
                  >
                    <v-col
                      v-if="dataArray.length !== 1"
                      cols="8"
                    >
                      <div>
                        <v-row>
                          <v-col
                            style="width: 100%;"
                          >
                            <RegionReportChartsBoxplot
                              v-if="item.id === 10"

                              style="height: 140px"
                              :item="item"
                              :item-data-value-max="itemDataValueMax"
                              :item-data="dataArray"
                              :data-value-unit-obj="dataValueUnitObj"
                              :maximum-fraction-digits="maximumFractionDigits"
                            />
                            <RegionReportChartLine
                              v-else

                              style="height: 140px"
                              :item="item"
                              :item-data-value-max="itemDataValueMax"
                              :item-data="dataArray"
                              :data-value-unit-obj="dataValueUnitObj"
                              :maximum-fraction-digits="maximumFractionDigits"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <v-col
                      style="min-width: 33.333333%;"
                      :class="[dataArray.length !== 1 ? 'pl-0' : '']"
                      class="grow"
                    >
                      <div
                        class="d-flex flex-column text-h6 text-sm-h5 text-center"
                      >
                        <div
                          v-if="dataArray.at(-1).data_value !== null"
                          class="pl-0"
                        >
                          <div
                            v-if="item.id !== 10"
                            class="font-weight-bold"
                          >
                            {{ getDatasetValue(dataArray) }} {{ dataValueUnitObj[`title_short_${locale}`] }}
                          </div>

                          <div
                            v-else
                          >
                            <div
                              class=""
                            >
                              Median:
                            </div>

                            <div
                              class="font-weight-bold"
                            >
                              {{ getDatasetStatisticValue(dataArray) }} {{ dataValueUnitObj[`title_short_${locale}`] }}
                            </div>
                          </div>
                        </div>

                        <div
                          v-else
                          class="font-weight-bold"
                        >
                          0 {{ dataValueUnitObj[`title_short_${locale}`] }}
                        </div>

                        <div
                          class="font-weight-normal"
                        >
                          ({{ item.report_year }})
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <!-- BUTTONS -->

            <div
              class="d-flex justify-space-between"
            >
              <BtnIndicatorDescription
                @click="openDialogItemDesc(item)"
              />

              <BtnGoToIndicator
                @click="selectItem(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import { eventBus } from '@/utils/eventbus.js'

import { mapState, mapActions, mapMutations } from 'vuex'

import _ from 'lodash'

import { mdiFinance, mdiInformationOutline, mdiArrowRightThick } from '@mdi/js'

// BUTTONS
import BtnIndicatorDescription from '@/components/main/button/BtnIndicatorDescription.vue'
import BtnGoToIndicator from '@/components/main/button/BtnGoToIndicator.vue'

// CHARTS
import RegionReportChartLine from '@/components/apps/report/region/charts/RegionReportChartLine.vue'
import RegionReportChartsBoxplot from '@/components/apps/report/region/charts/RegionReportChartsBoxplot.vue'

export default {
  name: 'RegionReportItemCard',

  components: {
    BtnIndicatorDescription,
    BtnGoToIndicator,
    RegionReportChartLine,
    RegionReportChartsBoxplot
  },

  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },
    // itemListReportYearData: {
    //   type: Array,
    //   default () {
    //     return []
    //   }
    // },
    itemListReportRangeData: {
      type: Array,
      default () {
        return []
      }
    },
    corineItems: {
      type: Array,
      default () {
        return []
      }
    },
    agsIdSelected: {
      type: String,
      default: ''
    },
    itemListIdsSelected: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      cardHeight: 0,
      corineIdsLevel3: [],
      dataValueUnitObj: {},
      itemDataValueMax: 0,
      itemListReportRangeDataFiltered: [],
      itemListReportRangeDataGrouped: [],
      maximumFractionDigits: null,
      minimumFractionDigits: 0,
      mdiArrowRightThick: mdiArrowRightThick,
      mdiFinance: mdiFinance,
      mdiInformationOutline: mdiInformationOutline,
      minValue: null,
      minWidthDataOption: 20,
      selectsOptional: [],
      showCards: false,
      showDataKeyValues: false
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    // itemListReportDataFiltered () {
    //   return this.itemListReportYearData.filter(this.filterData).sort(this.sortData)
    // },

    // itemListReportRangeDataFiltered () {
    //   return this.itemListReportRangeData.filter(this.filterData).sort(this.sortData)
    // },

    getItemTechnology () {
      return this.item.categories.filter(cat => cat.type.title_de === 'Technologie')[0].value
    }

  },

  // updated () {
  //   this.$nextTick(function () {
  //     this.$emit('updated', true)
  //   })
  // },

  async mounted () {
    this.corineIdsLevel1 = this.corineItems.filter(item => item.level === 1).map(item => item.id)

    this.selectsOptional = this.item.data_options.filter(opt => opt.type.option_type === 'optional' && opt.type.select_type === 'select-panel-list')

    const itemDataFiltered = this.itemListReportRangeData.filter(ds => ds.item === this.item.id).sort((a, b) => a.data_year - b.data_year)

    if (this.selectsOptional.length !== 0) {
      const queryKey = this.selectsOptional[0].type.query_key
      const selectOptionalIds = this.selectsOptional[0].values.map(val => val.value_id)

      if ((queryKey === 'landcover' &&
      this.selectsOptional[0].values[0].value_id === 10)) {
        // CORINE

        this.itemListReportRangeDataGrouped = this.corineIdsLevel1.map((id) => {
          const data = itemDataFiltered.filter(ds => ds.corine === id)
          return data
        })
      } else if (this.item.id === 10) {
        // BOXPLOT data item.id = 10
        this.itemListReportRangeDataGrouped = selectOptionalIds.map((id) => {
          const data = itemDataFiltered.filter(ds => ds[queryKey] === id)
          return data
        }).filter(ds => ds.length !== 0)
      } else {
        this.itemListReportRangeDataGrouped = selectOptionalIds.map((id) => {
          const data = itemDataFiltered.filter(ds => ds[queryKey] === id)
          return data
          // // Remove empty datasets
          // if (data.length !== 0) {
          //   return data
          // } else {
          //   return ''
          // }
        }).filter(ds => ds.length !== 0)
      }
    } else {
      this.itemListReportRangeDataGrouped = itemDataFiltered
    }

    this.showCards = true

    this.setItemCardHeight()

    // this.selectsOptional = this.item.data_options.filter(opt => opt.type.option_type === 'optional' && opt.type.select_type === 'select-panel-list')

    // Set data_option unit as object
    this.dataValueUnitObj = await this.get_item_data_value_unit({
      item: this.item
    })

    const maxValue = await this.get_datasets_value_max(this.itemListReportRangeDataGrouped.flat())

    if (this.dataValueUnitObj.title_short_de.match(/^%$/)) {
      this.itemDataValueMax = maxValue * 100
    } else {
      this.itemDataValueMax = maxValue
    }

    // Get minimum data_value of datasets
    this.minValue = await this.get_datasets_value_min(this.itemListReportRangeDataGrouped.flat())

    // Set maximum number of fraction digits by lowest value in dataset
    if (this.dataValueUnitObj.title_short_de.match(/^n$/)) {
      this.maximumFractionDigits = 0
      this.minimumFractionDigits = 0
    } else if (this.dataValueUnitObj.title_short_de.match(/^%$/)) {
      this.maximumFractionDigits = await this.get_max_fraction_digits({
        minValue: this.minValue * 100
      })
    } else {
      this.maximumFractionDigits = await this.get_max_fraction_digits({
        minValue: this.minValue
      })
    }

    this.showDataKeyValues = true

    this.$emit('updated', true)
  },

  methods: {
    ...mapActions('dashboard_items', [
      'get_datasets_value_max',
      'get_datasets_value_min',
      'get_item_data_value_unit',
      'get_max_fraction_digits'
    ]),

    ...mapActions('dashboard_main', [
      'get_ags_gen_title'
    ]),

    selectItem (itemObject) {
      this.$router.push({
        name: 'Dashboard',
        params: {
          fromRoute: this.$route,
          loadOnlyItem: true,
          slug: itemObject.slug_de
        }
      })
    },

    filterData (dataset) {
      if (this.selectsOptional.length !== 0) {
        // If Landcover Corine filter only Corine Level 3
        if (this.selectsOptional[0].type.query_key === 'landcover' &&
          this.selectsOptional[0].values[0].value_id === 10) {
          return dataset.item === this.item.id &&
          // dataset.data_year === this.item.report_year &&
          this.corineIdsLevel3.some(id => dataset.corine === id)
        }
      }

      return dataset.item === this.item.id // && dataset.data_year === this.item.report_year
    },

    sortData (a, b) {
      if (this.selectsOptional.length !== 0) {
        if (this.selectsOptional[0].type.query_key === 'landcover' &&
          this.selectsOptional[0].values[0].value_id === 10) {
          // Sorting for Corine
          if (a !== undefined && b !== undefined) {
            const corineA = this.corineItems.filter(item => item.id === a.corine)[0]
            const corineB = this.corineItems.filter(item => item.id === b.corine)[0]

            return corineA[`title_${this.locale}`].localeCompare(corineB[`title_${this.locale}`])
          }
        } else {
          // Get all optional value objects
          const optionalValues = this.selectsOptional[0].values

          // Sort option values by title, if not of kind:
          if (this.selectsOptional[0].type.query_key !== 'arable_potential') {
            optionalValues.sort((a, b) => a[`title_${this.locale}`].localeCompare(b[`title_${this.locale}`]))
          }

          const optionalValueA = optionalValues.filter(val => val.value_id === a[this.selectsOptional[0].type.query_key])[0]
          const optionalValueB = optionalValues.filter(val => val.value_id === b[this.selectsOptional[0].type.query_key])[0]

          // Sort by order in data_options.value (same as my_order in Django)
          return (optionalValues.indexOf(optionalValueA) + 1) - (optionalValues.indexOf(optionalValueB) + 1)

          // return a[this.selectsOptional[0].type.query_key] - b[this.selectsOptional[0].type.query_key])
        }
      }
    },

    getSelectOptional (dataset) {
      if (this.selectsOptional.length !== 0) {
        const queryKey = this.selectsOptional[0].type.query_key

        const optionId = dataset[queryKey]

        const optionValueFiltered = this.selectsOptional[0].values.filter(val => val.value_id === optionId)

        return optionValueFiltered[0]
      } else {
        return ''
      }
    },

    getDatasetStatisticValue (dataset) {
      const medianValue = dataset.filter(dataItem => dataItem.data_statistic === 3 && dataItem.data_year === this.item.report_year)[0].data_value

      return medianValue?.toLocaleString(this.locale, {
        minimumFractionDigits: this.minimumFractionDigits,
        maximumFractionDigits: this.maximumFractionDigits
      })
    },

    getDatasetValue (dataset) {
      const reportValue = dataset.filter(ds => ds.data_year === this.item.report_year)[0]

      let datasetValue

      if (this.dataValueUnitObj.title_short_de.match(/^n$/)) {
        datasetValue = reportValue.data_value
        return datasetValue?.toLocaleString(this.locale, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      } else if (this.dataValueUnitObj.title_short_de.match(/^%$/)) {
        datasetValue = reportValue.data_value * 100

        return datasetValue?.toLocaleString(this.locale, {
          minimumFractionDigits: this.minimumFractionDigits,
          maximumFractionDigits: this.maximumFractionDigits
        })
      } else {
        datasetValue = reportValue.data_value
        return datasetValue?.toLocaleString(this.locale, {
          // minimumFractionDigits: this.maximumFractionDigits,
          maximumFractionDigits: this.maximumFractionDigits
        })
      }
    },

    getCorineItem (dataset) {
      const corineItem = this.corineItems.filter(corineItem => corineItem.id === dataset.corine)
      return corineItem[0] // [`title_${this.locale}`]
    },

    openDialogItemDesc (item) {
      eventBus.$emit('open-dialog-item-description', item)
    },

    setItemCardHeight () {
      if (this.$refs.item_card) {
        this.cardHeight = this.$refs.item_card.$el.clientHeight - 12
      }
    },

    getColsForItem (item) {
      if (this.selectsOptional.length !== 0) {
        return 12
      } else {
        if (this.$vuetify.breakpoint.xl) {
          return 3
        } else if (this.$vuetify.breakpoint.lg) {
          return 4
        } else if (this.$vuetify.breakpoint.md) {
          return 6
        } else if (this.$vuetify.breakpoint.sm) {
          return 12
        } else if (this.$vuetify.breakpoint.xs) {
          return 12
        }
      }
    },

    setWidthDataOptionCard (dataset) {
      const col12 = 100
      const col6 = 50
      const col4 = 33.3333333333
      const col3 = 25
      const widthFiveItems = 20
      const col2 = 16.6666666666
      const widthSevenItems = 14.2857142857

      const optionsLength = this.selectsOptional[0].values.length
      const datasetLength = dataset.length

      // TODO Needs additional edits for edge cases (e.g. protected areas not in every county and therefore sometimes not in the dataset) and all other possible optionsLength values

      if (datasetLength !== 1) {
        //
        // DATESET LENGTH > 1
        //

        if (optionsLength === 2) {
          if (this.$vuetify.breakpoint.xl ||
              this.$vuetify.breakpoint.lg ||
              this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = col6
            return 6
          } else if (this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return 6
          } else if (this.$vuetify.breakpoint.xs) {
            this.minWidthDataOption = col12
            return 12
          }
        }

        if (optionsLength === 3) {
          if (this.$vuetify.breakpoint.xl ||
              this.$vuetify.breakpoint.lg ||
              this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = col4
            return 4
          } else if (this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return ''
          } else if (this.$vuetify.breakpoint.xs) {
            this.minWidthDataOption = col12
            return 12
          }
        }

        if (optionsLength === 4) {
          if (this.$vuetify.breakpoint.xl) {
            this.minWidthDataOption = col3
            return ''
          } else if (this.$vuetify.breakpoint.lg) {
            this.minWidthDataOption = col3
            return ''
          } else if (this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = col6
            return ''
          } else if (this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return 6
          } else if (this.$vuetify.breakpoint.xs) {
            this.minWidthDataOption = col12
            return 12
          }
        }

        if (optionsLength === 5 ||
            dataset[0].corine !== null) {
          if (this.$vuetify.breakpoint.xl) {
            this.minWidthDataOption = widthFiveItems
            return ''
          } else if (this.$vuetify.breakpoint.lg) {
            this.minWidthDataOption = col4
            return ''
          } else if (this.$vuetify.breakpoint.md ||
                     this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return ''
          } else if (this.$vuetify.breakpoint.xs) {
            this.minWidthDataOption = col12
            return 12
          }
        }

        if (optionsLength === 6) {
          if (this.$vuetify.breakpoint.xl) {
            this.minWidthDataOption = col2
            return ''
          } else if (this.$vuetify.breakpoint.lg ||
                     this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = col4
            return 4
          } else if (this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return ''
          } else if (this.$vuetify.breakpoint.xs) {
            this.minWidthDataOption = col12
            return 12
          }
        }

        //  7

        //  8

        if (optionsLength === 9) {
          if (this.$vuetify.breakpoint.xl) {
            this.minWidthDataOption = widthFiveItems
            return ''
          } else if (this.$vuetify.breakpoint.lg ||
                     this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = col4
            return 4
          } else if (this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return ''
          } else if (this.$vuetify.breakpoint.xs) {
            this.minWidthDataOption = col12
            return 12
          }
        }

        // NOTE Boxplot atm has 10 optional data_options
        if (optionsLength === 10) {
          if (this.$vuetify.breakpoint.xl) {
            this.minWidthDataOption = widthFiveItems
            return ''
          } else if (this.$vuetify.breakpoint.lg) {
            this.minWidthDataOption = col4
            return 4
          } else if (this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = col4
            return 4
          } else if (this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return ''
          } else if (this.$vuetify.breakpoint.xs) {
            this.minWidthDataOption = col12
            return 12
          }
        }

        //  11

        //  12

        if (optionsLength === 13) {
          if (this.$vuetify.breakpoint.xl) {
            this.minWidthDataOption = widthSevenItems
            return ''
          } else if (this.$vuetify.breakpoint.lg) {
            this.minWidthDataOption = widthFiveItems
            return ''
          } else if (this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = col4
            return ''
          } else if (this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return ''
          } else if (this.$vuetify.breakpoint.xs) {
            this.minWidthDataOption = col12
            return 12
          }
        }
      } else {
        //
        // DATESET LENGTH === 1
        //
        if (optionsLength === 5) {
          if (this.$vuetify.breakpoint.xl ||
              this.$vuetify.breakpoint.lg) {
            this.minWidthDataOption = widthFiveItems
            return ''
          } else if (this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = col2
            return ''
          } else if (this.$vuetify.breakpoint.smAndDown) {
            this.minWidthDataOption = col4
            return ''
          }
        }

        if (optionsLength === 7) {
          if (this.$vuetify.breakpoint.xl) {
            this.minWidthDataOption = widthSevenItems
            return ''
          } else if (this.$vuetify.breakpoint.lg) {
            this.minWidthDataOption = col3
            return ''
          } else if (this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = col3
            return ''
          } else if (this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return ''
          } else {
            this.minWidthDataOption = col6
            return ''
          }
        }

        if (optionsLength === 13) {
          if (this.$vuetify.breakpoint.xl) {
            this.minWidthDataOption = widthSevenItems
            return ''
          } else if (this.$vuetify.breakpoint.lg ||
                     this.$vuetify.breakpoint.md) {
            this.minWidthDataOption = widthFiveItems
            return ''
          } else if (this.$vuetify.breakpoint.sm) {
            this.minWidthDataOption = col6
            return ''
          } else if (this.$vuetify.breakpoint.xs) {
            this.minWidthDataOption = col6
            return ''
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
// .btn-report-card {
//   color: var(--v-secondary-base) !important;
// }
// .report-card-area {
//   border: dotted 1px var(--v-secondary-lighten1);
//   // border-top: dotted 1px var(--v-secondary-lighten1);
// }

.report-card-header-border {
  height: 2px;
  width: 100%;
  border-bottom: 2px solid var(--v-primary-base);
  opacity: 0.24;
}

.info-tooltip-icon {
  color: var(--v-primary-base) !important;
  opacity: 0.12 !important;
  &:hover{
    opacity: 1 !important;
  }
}

</style>
