<template>
  <div>
    <v-expansion-panels
      v-model="panelOpen"
      hover
      flat
      tile
      :readonly="panelReadOnly"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          style="background: #e1e8ee; width: 100%; min-height: 30px; height: 30px;"
          :class="[panelOpen === undefined ? 'border-bottom' : '']"
          class="pa-0 ma-0 pl-6 pr-1 py-1"
        >
          <template
            #actions
          >
            <v-icon
              size="20"
            >
              <!-- {{ mdiChevronDown }} -->
            </v-icon>
          </template>

          <div
            v-if="locale === 'de'"
            class="text-h6"
          >
            Kennzahlenbeschreibung
          </div>

          <div
            v-else
            class="text-h6"
          >
            Indicator description
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          eager
          class="border-bottom"
        >
          <div
            class="d-flex flex-column pt-3"
          >
            <ItemDescriptionContent
              :item-object="dashboardItem"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { eventBus } from '@/utils/eventbus.js'

import _ from 'lodash'

import { mdiClose, mdiChevronDown } from '@mdi/js'

import ItemDescriptionContent from '@/components/main/dialog/ItemDescriptionContent.vue'

export default {
  name: 'DashboardItemDescriptionComponent',

  components: {
    ItemDescriptionContent
  },

  props: {
    dashboardItem: {
      type: Object,
      default () {
        return {}
      }
    },

    openPanel: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      mdiChevronDown: mdiChevronDown,
      mdiClose: mdiClose,
      panelOpen: 0,
      panelReadOnly: true
    }
  },

  computed: {
    ...mapState('main', [
      'locale'
    ]),

    ordererdDescriptions () {
      return _.orderBy(this.dashboardItem.detail_description, 'question.id')
    }
  },

  beforeDestroy () {
    eventBus.$off('open-item-description')
  },

  mounted () {
    eventBus.$on('open-item-description', () => {
      this.panelOpen = 0
    })

    setTimeout(() => {
      if (this.openPanel === true) {
        eventBus.$emit('open-item-description')
        this.panelReadOnly = true
      }
    }, 400)
  }
}
</script>

<style lang="scss" scoped>
// .border-bottom {
//   border-bottom: 0.1px solid var(--v-primary-base);
// }

.v-expansion-panel-header--active {
  height: 24px;
  min-height: 48px;
}

.select-panel-list-title-area {
  background: #ffffff;
  position: sticky;
  top: 0px;
  z-index: 2;
}
</style>
